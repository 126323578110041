/*

*/
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { injectIntl, FormattedMessage } from 'react-intl';

/**
 * Modified ListItem to include React router links
 */
class ListItemLink extends React.Component {
    render()
    {
        return(
            <ListItem button {...this.props} component={Link}>
            <ListItemIcon>
              <Icon>{this.props.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={this.props.intl.formatMessage({id:this.props.children})} />
          </ListItem>
        );
    }
}
//<Button component={Link} {...this.props}>{this.props.children}</Button>
export default injectIntl(ListItemLink);
  