import React, { useState, useEffect } from 'react';
import { 
    MenuItem, Select, Input
} from '@material-ui/core';

import _ from 'lodash'

export const MultiSelect = (props) => {

    let { keyname, value, onChange, inputProps, error } = props
    const [options, setOptions] = useState(value.options)
    useEffect(() => {
        if(value.hasOwnProperty('fetchOptions') && typeof value.fetchOptions == 'function') {
            value.fetchOptions().then(response => {
                if(response.status == 200) {
                    let service_offers = _.get(response, 'data.data', [])
                    let opts = {}
                    for(let i=0; i<service_offers.length; i++) {
                        opts[service_offers[i]['key_name']] = service_offers[i]['id']
                    }
                    if(Object.keys(opts).length) {
                        setOptions(opts)
                    }
                }
            })
        }
    }, [])

    let selected_value = value.value

    return (
        <Select
            multiple={value.multiple}
            label={keyname} 
            value={selected_value}
            onChange={event => {debugger; onChange(keyname, event.target.value)}} 
            input={<Input id="select-multiple-service-offers" />}
            inputProps={inputProps}
            required={value.required}
            error={error}
        >
            {
                _.map(options, (val, key) => (
                    <MenuItem key={`typeselect-${val}`} value={val}>{key}</MenuItem>
                ))
            }
        </Select>
    )
}