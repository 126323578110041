import React, { useState, useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';
import {
    Select,
    Paper,
    CircularProgress,
    Button,
    IconButton,
    Typography,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    withStyles,
    List,
    ListItem,
    ListItemText,
    Modal,
    MenuItem,
    OutlinedInput,
    Box,
    InputLabel,
    } from '@material-ui/core';
import { blue, red } from '@material-ui/core/colors';
import Iframe from 'react-iframe';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PictureReordering from "./pictureOrder/PictureReordering";
import PleaseWait from '../../widgets/PleaseWait';
import Globals from '../../globals';
import { getFloorPlanRooms } from './helper';
import Floorplan from './Floorplan';
// this component should be plug-n-play
import AutomatedVideoTool from '../../components/AutomatedVideoTool';

const styles = {
    root: {
    	width: '100%',
    	marginTop: '10px'
    },
    dialog: {
        flex: 1,
        width: '50%'
    },
    navigator: {
        position: 'fixed',
        top: '40%',
        left: '70px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    navigatorItem: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '16px',
        padding: 10,
        color: 'white',
        backgroundColor: '#00bdf2',
        borderRadius: 5,
      },
    header: {
        flex: 1,
        flexDirection: 'row',
        width: '33%',
        margin: '10px'
    },
    picture_container: {
        marginBottom: '20px'
    },
    address_bar: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    list: {
        overflowY: 'scroll'
    },
    container_3d: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

// fetches all tech's schedule and maps to events object which is fed in react-big-calendar
const getListingPicturesWithComments = (listing_id) => (
    window.Hauslife
        .getListingPicturesWithComments(listing_id)
)

const getURLFor3D = (listing_id) => {
    return window.Hauslife.retreiveTokenFor3D()
        .then(result => {
            if(result.data.token) {
                return `https://oldviewer.hausvalet.ca/visit/${listing_id}?token=${result.data.token}`
            }
        })
}

const gridItemSizes = [1, 2, 3, 4, 6, 12]

const saveOrderIndexInDB = pictures => {
    if(pictures.length !== 0) {
        window.Hauslife.saveOrderIndex(pictures)
            .then(res => {
                console.log("Silently updated the order index in DB")
            })
    }
}

const ListingView = (props) => {
    let { listing_id } = props.match.params;

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [listing, setListing] = useState(null)
    const [listingPictures, setListingPictures] = useState([])
    const [shootings, setShootings] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [showVRReadyDialog, setShowNoVRDialog] = useState(false)
    const [showVideoPicturesDialog, setShowVideoPicturesDialog] = useState(false)
    const [ajaxSaving, setAjaxSaving] = useState(null)
    const [gridItemCnt, setGridItemCnt] = useState(3)
    const [ appliedFilter, setFilter ] = useState("all")
    const [virtualTourUrl, setVirtualTourUrl] = useState("")
    const [floorplanRooms, setFloorplanRooms] = useState({})
    const [savingSOData, setSavingSOData] = useState(false);
    const [savingSODataError, setSavingSODataError] = useState(false);
    const [sdCard, setSdCard] = useState("");
    const [shootingInvoices, setShootingInvoices] = useState([]);
    const [newShootingGeneralNote, setNewShootingGeneralNote] = useState('');
    const [openNoteModal, setOpenNoteModal] = useState(false);
    const [noteModalText, setNoteModalText] = useState('');
    const [extraServices, setExtraServices] = useState([]);
    const [extraServiceID, setExtraServiceID] = useState(0);
    const [addExtraServiceDialog, setAddExtraServiceDialog] = useState(false);
    const [extraServiceDialogText, setExtraServiceDialogText] = useState('');
    const [pictureCount, setPictureCount] = useState({public: 0, notPublic: 0, watermarked: 0, slideshow: 0});
    const [showWarningModal, setShowWarningModal] = useState(false);
    // assumed true to hide after loading from an api call
    const [has3D, setHas3D] = useState(true)
    // run at start to load pictures with comments
    useEffect(() => {

        getListingPicturesWithComments(listing_id)
            .then(result => {
                let { listing } = result.data
                setListing(listing)
                setShootings(listing.shootings)


                let sorted_pictures = listing.pictures.sort(
                    (a, b) => a.picture_index - b.picture_index
                )

                setListingPictures(sorted_pictures)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                setError("Error loading listing pictures::"+error)
            })

        // get url for 3d virtual visit and render in iframe
        getURLFor3D(listing_id).then(url => {
            setVirtualTourUrl(url)
        })

        // get floorplan to display room order
        window.Hauslife.getListingFloorplan(listing_id)
            .then(result => {
               
                let rooms = getFloorPlanRooms(result.data.data.floorplanRooms)
                if(rooms && typeof(rooms) === 'object') {
                    setFloorplanRooms(rooms)
                }

                // verify if listing has 3D shots
                let hasMarker = false
                Object.values(rooms).forEach( room => {
                    if(room.markers.find(marker =>
                        marker && marker.lowres_media_url
                    ) !== undefined) {
                        setHas3D(true);
                    }
                })
                setHas3D(hasMarker)

            })
            .catch(error => {
                console.log("Error fetching floorplan", error)
            })
        window.Hauslife.adminListingShootings(listing_id)
            .then((res) => {
                setShootingInvoices(res.data.data)
            })
            .catch((e) => {
                console.log(e);
            });

     

    }, [])


    useEffect(() => {
        if(listing == null) {
            return;
        }
        let filteredPictures;
        switch(appliedFilter) {
            case 'all':
                filteredPictures = listing.pictures
                break;
            case 'retouch':
                filteredPictures = listing.pictures.filter(p => p.is_validated === false)
                break;
            case 'good':
                filteredPictures = listing.pictures.filter(p => p.is_validated === true)
                break;
            case 'unvalidated':
                filteredPictures = listing.pictures.filter(p => p.is_validated === null)
                break;
            default:
                filteredPictures = listing.pictures
        }
        if(/shootingid/.test(appliedFilter)) {
            var filter = appliedFilter.substring(11, appliedFilter.length);
            filteredPictures = listing.pictures.filter(p => p.shooting_id === filter)
        }
        setListingPictures(filteredPictures)
    }, [listing, appliedFilter])


    useEffect(()=> {
        if (listing) {
            window.Hauslife.fetchExtraServices(listing.broker.subscription_id, listing.broker.gf_clause)
            .then((res) => {
                setExtraServices(res.data.services);
            })
            .catch((e) => {
                console.log(e);
            });
        }
    }, [listing]);

    useEffect(() => {
        if (listing) {

            const pictureCountCopy = {public: 0, notPublic: 0, watermarked: 0, slideshow: 0};

            for (let i = 0; i < listingPictures.length; i++) {

                if (listingPictures[i].public) {
                    pictureCountCopy.public++;
                    if (listingPictures[i].show_watermark) {
                        pictureCountCopy.watermarked++;
                    }
                } else if (!listingPictures[i].public) {
                    pictureCountCopy.notPublic++;
                }
                if (listingPictures[i].public && listingPictures[i].included_in_video) {
                    pictureCountCopy.slideshow++; 
                }
            }

            setPictureCount(pictureCountCopy);
        }
    }, [listingPictures]);

    const pictureActions = (() => ({
        markPictureAsValid: (picture_id) => {
            return window.Hauslife.markPictureValid(picture_id)
                .then(() => {
                    setListingPictures(listingPictures.map(pic => {
                        if(pic.id === picture_id) {
                            pic.is_validated = true
                        }
                        return pic
                    }))
                    return;
                })
        },
        sendPictureForReview: (picture_id) => {
            // make 2 api calls. one to create ticket, one to update picture status
            return window.Hauslife.sendPictureForReview(picture_id)
            .then(() => {
                setListingPictures(listingPictures.map(picture => {
                    if(picture.id === picture_id) {
                        picture.is_validated = false
                    }
                    return picture
                }))
                return;
            })
        },
        postComment: (picture_id, comment) => {
            return window.Hauslife.createTicket(picture_id, comment)
                .then(result => {
                    let modifiedPics = listingPictures.map(picture => {
                        if(picture.id === picture_id) {
                            if(picture.hasOwnProperty('tickets') === false) {
                                picture.tickets = []
                            }
                            picture.tickets = [result.data.data, ...picture.tickets]
                        }
                        return picture
                    })
                    setListingPictures(modifiedPics)
                    return;
                })
        },
        postReply: (picture_id, ticket_id, message) => {
            return window.Hauslife.createFollowupTicket(ticket_id, message)
                .then(result => {
                    let modifiedPics = listingPictures.map(picture => {
                        if(picture.id === picture_id) {
                            picture.tickets = picture.tickets.map(ticket => {
                                if(ticket.id === ticket_id) {
                                    if(ticket.hasOwnProperty('followups') === false) {
                                        ticket.followups = []
                                    }
                                    ticket.followups = [result.data.followup, ...ticket.followups]
                                }
                                return ticket
                            })
                        }
                        return picture
                    })
                    setListingPictures(modifiedPics)
                    return;
                })
        },
        updatePicturesVisibility: (pictureIds, is_public) => {
            return window.Hauslife.updatePicturesVisibility(pictureIds, is_public)
            .then(() => {
                setListingPictures(listingPictures.map(picture => {
                    if(pictureIds.includes(picture.id)) {
                        picture.public = is_public
                    }
                    return picture
                }))
                return;
            })
        },
        updatePicturesVideoSelection: (pictureIds, is_selected) => {
            return window.Hauslife.updatePicturesVideoSelection(pictureIds, is_selected)
            .then(() => {
                setListingPictures(listingPictures.map(picture => {
                    if(pictureIds.includes(picture.id)) {
                        picture.included_in_video = is_selected
                    }
                    return picture
                }))
                return;
            })
        },
        enableWatermark: (pictureIds, showWatermark) => { 
            return window.Hauslife.showWatermark(pictureIds, showWatermark)
            .then(() => {
                setListingPictures(listingPictures.map(picture => {
                    if (pictureIds.includes(picture.id)) {
                        picture.show_watermark = showWatermark;
                    }
                    return picture
                }));
                return;
            })
        },
        assignPicturesType: (pictureIds, picture_service_offer_id) => {
            return window.Hauslife.updatePicturesType(pictureIds, picture_service_offer_id)
            .then(() => {
                setListingPictures(listingPictures.map(picture => {
                    if (pictureIds.includes(picture.id)) {
                        picture.service_offer_id = picture_service_offer_id;
                    }
                    return picture
                }));
                return;
            });
        }
    }))()

    const picturesAreVideoReady = () => {
        let validated_count = getCurrentVideoPictureCount()

        if(validated_count < 12 && listingPictures.length >= 12){
            return false;
        }

        return true;
    }

    const getCurrentVideoPictureCount = () => {
        let validated_count = 0;

        listingPictures.map(picture => {
            if(picture.included_in_video){
                validated_count++
            }
        })

        return validated_count;
    }

    const handleSendToBroker = (ignore_virtual_tour) => {
        if(!ignore_virtual_tour && listing.state !== Globals.listing_state.vr_ready){
            setShowNoVRDialog(true);
        }
        else if(!picturesAreVideoReady()){
            setShowVideoPicturesDialog(true);
        }
        else{
            setShowDialog(true)
        }
    }
     const handleSaveServiceOfferData = () => {

        let chainPromises = [];

        listing.shootings.map((shoot)=>{
            shoot.service_offers.map((so)=>{
                let currentServiceOffer = so;
                if(currentServiceOffer.updated_url && currentServiceOffer.updated_url !== currentServiceOffer.url){
                    chainPromises.push(window.Hauslife.updateServiceOfferData(currentServiceOffer.id, currentServiceOffer.updated_url))
                }
            })
        })

        setSavingSOData(true);

        Promise.all(chainPromises)
            .then((res)=>{
                setSavingSOData(false);
                setSavingSODataError(false);
            })
            .catch((err)=>{
                setSavingSOData(false);
                setSavingSODataError(true);
            })
     }

    const handleSendForRetouch = () => {
        let retouchPictures = listingPictures.filter(p => p.is_validated === false)
        if(retouchPictures.length === 0) {
            alert("Au moins une photo doit être envoyée en retouche pour envoyer la capture en retouche");
            return
        }

        setAjaxSaving('retouch')

        let shootRetouchPromises =
            _.chain(retouchPictures)
            .map('shooting_id')
            .uniq()
            .value()
            .map(shootingId => {
                return window.Hauslife.sendShootingForRetouch(shootingId)
            })

        Promise.all(shootRetouchPromises)
            .then(res => {
                console.log("Retouch for ", res)
                props.history.push('/tech_review')
            })
            .catch(err => {
                console.log("Error sending shooting for retouch", err)
            })
            .finally(() => {
                setAjaxSaving(null)
            })
    }

    const handleValidateConfirm = (sendEmail) => {
        setAjaxSaving('validate')
    }

    const handlePublishConfirm = () => {
        return window.Hauslife.sendMediaEmail(listing_id)
            .then(() => {
                props.history.push('/tech_review')
            })
    }

    const handlePictureDrop = (picturesAfterDrop) => {
        if(appliedFilter !== 'all') {
            alert("You can only set picture order if no filter is applied")
        } else {
            setListingPictures(picturesAfterDrop)
            // save order index
            // if picture_index is not equal to array index. new array_index will be new picture_index
            let needReordering = picturesAfterDrop
                .map((pic, i) => ({...pic, new_index: i}))
                .filter(pic => pic.new_index !== pic.picture_index)
                .map(pic => ({...pic, picture_index: pic.new_index}))

            if(needReordering.length) {
                saveOrderIndexInDB(needReordering)
            }
        }
    }

    const handleFilterChange = (event) => {
        setFilter(event.target.value)
    }

    const handleSetVRReady = async () => {
        setAjaxSaving('vr')
        try {
            let result = await window.Hauslife.publishListing(listing_id)
            if(result && result.data && result.data.status === 200) {
                listing.state = Globals.listing_state.vr_ready
            }
            return true
        } catch(error) {
            console.log("Error setting state", error)
            return false
        } finally {
            setAjaxSaving(null)
        }
    }

    const handleGridCount = (type) => () => {
        let curr = gridItemSizes.findIndex(val => val === gridItemCnt),
            maxIdx = gridItemSizes.length - 1,
            minIdx = 0;

        if(curr === -1) {
            return;
        }
        switch(type) {
            case 'increment':
                if(curr < maxIdx) setGridItemCnt(gridItemSizes[curr+1])
                break;
            case 'decrement':
                if(curr > minIdx) setGridItemCnt(gridItemSizes[curr-1])
        }
    }

    const shootingsHaveNotes = () => {
        let shootingHaveNotes = false;

        listing.shootings.map((shooting)=>{
            if ( 
                shooting.note != null || 
                shooting.tech_note != null || 
                shooting.post_prod_notes != null || 
                shooting.general_notes != null 
                ) 
            {
                shootingHaveNotes = true;
            }
        })

        return shootingHaveNotes;
    }

    if(loading) {
        return <PleaseWait />
    }

    if(error) {
        return (
            <Grid style={{ height: "100%" }} alignItems="stretch" justify="space-around" container>
                <Grid xs={12} style={styles.picture_container}>
                    {error}
                </Grid>
            </Grid>
        )
    }

    let address = `${listing.app ? listing.app+', ' : ''}${listing.address}, ${listing.city}`

    let shootingInRetouch = false;
    let editableServiceOffers = ['video', 'drone video', 'matterport', 'combo video and drone'];
    let shootingServiceOffers = [];


    listing.shootings.map((shoot)=>{
        if(shoot.state === 3){
            shootingInRetouch = true;
        }

        if(shoot.state !== 10){
            shoot.service_offers.map((so)=>{
                if(editableServiceOffers.includes(so.serviceOffer.display_name.toLowerCase())){
                    shootingServiceOffers.push(so);
                }
            })
        }
    })
   const GoToInvoice = (shooting_id) => {
     let invoiceToLoad = {};
     shootingInvoices.forEach(({invoice})=>{
         if(invoice.shooting_id === shooting_id){
             invoiceToLoad = invoice;
         }
     });
    window.open('https://books.zoho.com/app#/invoices/' + invoiceToLoad.invoice_id , '_blank');
    }

  const  GenerateInvoice = (shooting_id) => {
        window.Hauslife.generateInvoice(shooting_id)
            .then(res => {
                console.log(res)
            })
            .catch(e=>{
                console.log(e);
            });
    }

    const assignCard = (shootingId) => {
        window.Hauslife.assignSDCard(shootingId,sdCard).then((res) => {
            console.log(res);
        }).catch((e) => {
            console.log(e);
        });
    }

    const setShootCompletion = (shooting) => {
        let allServicesDelivered = !shooting.all_services_delivered;
        window.Hauslife.updateShootingCompletion(shooting.id,allServicesDelivered).then((res)=>{
            console.log(res);
        }).catch((e) =>{
            console.log(e);
        })

    }

    const setPictureUpload = (shooting) => {
        let isCompleted = !shooting.saisie_source_completed;
        window.Hauslife.updateShootingSaisieSource([shooting.id], isCompleted).then((res)=>{
            console.log(res);
        }).catch((e) =>{
            console.log(e);
        })

    }

    const checkForInvoices = (shooting_id) =>{
        let hasInvoice = false;
        shootingInvoices.forEach(({invoice})=>{
            if(invoice.shooting_id === shooting_id){
                hasInvoice = true;
            }
        });
        return hasInvoice;
    }

    function changeShootingGeneralNote (shooting) {
        shooting.general_notes = newShootingGeneralNote;
        
        window.Hauslife.updateShootingNote(shooting)
        .then(res => {
            setNewShootingGeneralNote('');
            console.log(res); 
        })
        .catch((e) =>{
            setNoteModalText('Une erreur est survenue lors de la modification, veuillez réessayer.');
            setOpenNoteModal(true);
            console.log(e);
        });
    }

    function needPictureUpload (shooting) {

        for (let i = 0; i < shooting.service_offers.length; i++) {

            if (shooting.service_offers[i].serviceOffer.key_name === 'Picture Upload') {
                return true;
            }
        }

        return false;
    }

    function loadExtraServices () {
        return extraServices.map((service) => {
            return <MenuItem value={service.id}>{service.display_name}</MenuItem>
        });
    }

    async function addNewService (service_id, shooting_id) {
        
        try {
            const res = await window.Hauslife.addServiceToShooting(service_id, shooting_id);

            if (res.data.status == 200) {
                setExtraServiceDialogText(res.data.message);
                setAddExtraServiceDialog(true);
            }
            
        } catch (err) {
  
            setExtraServiceDialogText("Une erreur c'est produite lors de la demande, veuillez réessayer plus tard");
            setAddExtraServiceDialog(true);
            console.log(err);
        }
        

    }

    return (
        <>
            <Grid style={{ width: "100%" }} alignItems="flex-start" container>
                <Grid xs={12} style={styles.picture_container}>
                    <div style={{display: 'flex', justifyContent:'space-between'}}>
                       
                        <div style={styles.address_bar}>
                            <IconButton color="primary" onClick={(e) => {e.stopPropagation();}}  >
                                <Link to={"/listings"}><ArrowBackIcon /></Link>
                            </IconButton>
                            <Typography variant="h4">
                                {address}
                            </Typography>
                        </div>
                        <div style={{display:'flex', justifyContent:'space-around', width:'50%'}}>
                                {!shootingInRetouch ? (
                                    <Button
                                        variant={listing.last_shooting_state === Globals.shooting_state.retouch ? 'contained' : 'outlined'}
                                        onClick={handleSendForRetouch}
                                        style={{backgroundColor: red[500], color: 'white'}}>
                                        {ajaxSaving === 'retouch' ? <CircularProgress /> : 'Envoyer en retouche'}
                                    </Button>
                                ) : null}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=> {handleSendToBroker(false)} }
                                >
                                        Envoyer au courtier
                                </Button>
                            </div>
                        </div>
                    
                </Grid>

                {
                    <Grid xs={12}>
                        <Paper style={{margin:10, padding:10}}>
                            <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                                Courtier
                            </Typography>
                            <Link style={{color:"#000"}} to={"/users/edit/"+listing.broker.id}>{listing.broker.firstname + " " + listing.broker.lastname}</Link>
                            <p>{listing.broker.email}</p>
                            <p>{listing.broker.phone_number}</p>
                            <p>Note à propos du courtier: {listing.broker.about_broker_note}</p>
                        </Paper>
                    </Grid>
                }

                {
                    listing.distribution_token &&
                    <Grid xs={12}>
                        {
                            <Paper style={{margin:10, padding:10}}>
                                <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                                    Lien publique de la page de livraison
                                </Typography>
                                <div style={{display: 'flex', flex: 1, }}>
                                    <TextField style={{ display: "flex", flexGrow: 4, marginRight:10 }} label={"Page de livraison"} variant="outlined" value={"https://livraison.hausvalet.ca/delivery/"+listing.distribution_token} />
                                    <Button style={{ display: "flex", flexGrow: 1 }} target={"_blank"} href={"https://livraison.hausvalet.ca/delivery/"+listing.distribution_token} variant="outlined" color="primary">
                                        Aller a la page de distribution
                                    </Button>
                                </div>
                            </Paper>
                        }
                    </Grid>
                }

                {
                    <Grid xs={12}>
                        <Paper style={{margin:10, padding:10}}>
                            <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                                Captures de la propriété
                            </Typography>
                            {listing.shootings.map(shooting=>
                                <div style={{marginTop:'5px'}}>
                                    {(shooting.shooting_params && shooting.shooting_params.shooting_package) ?
                                        (
                                            (shooting.shooting_params.shooting_package.recommendation_min > 0 ?
                                                (<>
                                                    <p><b>{shooting.shooting_params.shooting_package.display_name_fr}</b></p>
                                                    <p><b>Nombre de photos à livrer:</b> {shooting.shooting_params.shooting_package.recommendation_min} - {shooting.shooting_params.shooting_package.recommendation_max}</p>
                                                </>) :
                                                (<>
                                                    <p><b>{shooting.shooting_params.shooting_package.display_name_fr}</b></p>
                                                    <p><b>Nombre de photos à livrer:</b> ~10</p>
                                                </>)
                                            )
                                        ) : null
                                    }
                                    <p><h4 style={{display: 'inline'}}>ID : </h4> {shooting.id}</p>
                                    <p><h4 style={{display: 'inline'}}>Date : </h4>{moment(shooting.scheduleddate).format('LLL')} </p>
                                    {shooting.tech != null && <p><Link style={{color:"#000"}} to={"/users/edit/"+shooting.tech.id}><h4 style={{display: 'inline'}}>Photographe : </h4>{(shooting.tech ? shooting.tech.firstname + " " + shooting.tech.lastname : "null")}</Link></p>}
                                    {shooting.pp_tech != null && <p><Link style={{color:"#000"}} to={"/users/edit/"+shooting.pp_tech.id}><h4 style={{display: 'inline'}}>Post-prod : </h4>{(shooting.pp_tech ? shooting.pp_tech.firstname + " " + shooting.pp_tech.lastname : "null")}</Link></p>}
                                    <p><h4 style={{display: 'inline'}}>Tous les services furent livrés ? : </h4>{shooting.all_services_delivered ? "Oui" : "Non (en attente de service additionnel)"}</p>
                                     {needPictureUpload(shooting) ?  <p><h4 style={{display: 'inline'}}>Saisie à la source complétée ? :</h4> {shooting.saisie_source_completed ? "Oui" : "Non"}</p> : null}
                                    <hr/>
                                   
                                        {                                             
                                            <div style={{ marginTop:20 }}>
                                                <Select
                                                    value={extraServiceID}
                                                    onChange={(e)=>{setExtraServiceID(e.target.value)}}
                                                    input={
                                                        <OutlinedInput
                                                            name="extraServices"
                                                            id="outlined-extraServices-simple"
                                                        />
                                                    }
                                                >
                                                    <MenuItem value={0}>Services additionnels</MenuItem>
                                                    {loadExtraServices()}
                                                </Select>
                                                <Button 
                                                    variant="outlined" 
                                                    style={{ marginLeft:20 }} 
                                                    color="primary" 
                                                    disabled={extraServiceID == 0}
                                                    onClick={() => { 
                                                        addNewService(extraServiceID, shooting.id);
                                                    }}
                                                >
                                                    Ajouter
                                                </Button>

                                                <Dialog open={addExtraServiceDialog} onClose={()=>{setAddExtraServiceDialog(false)}} >
                                                    <DialogTitle id="simple-dialog-title">Ajout de service additionnel</DialogTitle>
                                                    <div style={{padding: 20}}>
                                                        <p>{extraServiceDialogText}</p>
                                                    </div>
                                                    <Button onClick={()=>{setAddExtraServiceDialog(false)}} color="primary" autoFocus>
                                                        Ok
                                                    </Button>
                                                </Dialog>
                                            </div>                                                 
                                        }
                                   
                                    <div style={{display:'flex', flexFlow:'row', alignItems:'center', marginBottom:'10px'}}>
                                        <TextField
                                            label="Carte SD"
                                            defaultValue={shooting.sd_card}
                                            onChange={(e)=>{setSdCard(e.target.value) }}
                                            margin="normal"
                                        />
                                        <Button variant="outlined" color="primary" style={{ marginLeft: 20 }} onClick={()=>{assignCard(shooting.id);}}>Assign Sd card</Button>
                                    </div>

                                    <Button variant="outlined" color="primary" style={{ marginRight: 20 }} onClick={()=>{setShootCompletion(shooting)}}>  { shooting.all_services_delivered === false ? "Marquer comme complet" : "Marquer comme incomplet"}</Button>
                                    {needPictureUpload(shooting) ?  
                                        <Button variant="outlined" color="primary" style={{marginRight:'20px'}} onClick={()=>{setPictureUpload(shooting)}}> 
                                            { shooting.saisie_source_completed === false ? "Saisie à la source complétée" : "Saisie à la source à faire"}
                                        </Button> 
                                    : null}
                                    {checkForInvoices(shooting.id) ? (
                                        <Button variant="outlined" color="primary" onClick={() => {
                                            GoToInvoice(shooting.id);
                                        }}>
                                            Access invoice
                                        </Button>
                                    ) : (
                                        <Button variant="outlined" color="primary" onClick={() => {
                                            GenerateInvoice(shooting.id);
                                        }}>
                                            Generate invoice
                                        </Button>
                                    )
                                    }
                                </div>
                            )}
                        </Paper>
                    </Grid>
                }


                {
                    (listing && listing.automated_videos && listing.automated_videos.length > 0) &&
                    <Grid xs={12}>
                        <AutomatedVideoTool listing={listing} />
                    </Grid>
                }

                {
                        shootingServiceOffers.length > 0 && (
                            <Grid xs={12}>
                                <Paper style={{margin:10, padding:10}}>
                                    <Grid xs={4}>
                                        <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                                            Services additionnels
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12}>
                                        {
                                            <Grid style={{ height: "100%", padding:20 }} alignItems="flex-start" justify="space-between" container>
                                                {shootingServiceOffers.map((so)=>(
                                                    <Grid xs={12}>
                                                        <TextField
                                                            label={so.serviceOffer.display_name_fr}
                                                            type="string"
                                                            name={so.serviceOffer.display_name_fr}
                                                            margin="normal"
                                                            variant="outlined"
                                                            onChange={(e)=>{
                                                                so.updated_url = e.target.value;
                                                            }}
                                                            fullWidth="true"
                                                            defaultValue={so.url}
                                                            disabled={true}
                                                            />
                                                        {so.url !== "" && so.url != null ? (
                                                            <Iframe url={so.url}
                                                            display="initial"
                                                            position="relative"
                                                            width={"100%"}
                                                            height={"600px"}/>
                                                        ) : null}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                }

                {   shootingsHaveNotes() &&
                    <Grid xs={12}>
                        <Paper style={{margin:10, padding:10}}>
                            <List>
                                {shootings.map((shooting) => {
                                    return (
                                        <>
                                            { shooting.note && 
                                                <ListItem>
                                                    <ListItemText primary="Notes du courtier"
                                                                secondary={shooting.note}
                                                    />
                                                </ListItem>
                                            }

                                            { listing.broker.about_broker_note && 
                                                <ListItem>
                                                    <ListItemText primary="Notes à propos du courtier"
                                                                secondary={listing.broker.about_broker_note}
                                                    />
                                                </ListItem>
                                            }

                                            { shooting.tech_note && 
                                                <ListItem>
                                                    <ListItemText primary="Notes du photographe"
                                                                  secondary={shooting.tech_note}
                                                    />
                                                </ListItem>
                                            }

                                            { shooting.post_prod_notes && 
                                                <ListItem>
                                                    <ListItemText primary="Notes de la post-production"
                                                                secondary={shooting.post_prod_notes}
                                                    />
                                                </ListItem>
                                            }

                                            { shooting.general_notes && 
                                                <ListItem>
                                                    <ListItemText primary="Notes générales à propos de la capture"
                                                                secondary= {
                                                                        <React.Fragment>
                                                                            <Typography  style={{ textTransform: 'uppercase', color: 'red' }}>
                                                                                Le courtier sera en mesure de consulter ces notes. Corriger si besoin.
                                                                            </Typography>
                                                                           
                                                                            <TextField
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                defaultValue={shooting.general_notes}
                                                                                style={{ width: '600px' }}
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                onChange={(e)=>{setNewShootingGeneralNote(e.target.value)}}
                                                                            />
                                                                            <div style={{ marginTop: '10px' }}>
                                                                                {
                                                                                newShootingGeneralNote !== '' ? 
                                                                                    <Button onClick={()=>{changeShootingGeneralNote(shooting)}} variant='contained' color='primary'>
                                                                                        Appliquer modification
                                                                                    </Button> 
                                                                                : 
                                                                                    <Button disabled variant='contained' color='primary'>
                                                                                        Appliquer modification
                                                                                    </Button> 
                                                                                }
                                                                               
                                                                            </div>
                                                                        </React.Fragment>
                                                                  }
                                                    />
                                                </ListItem>
                                            }
                                        </>
                                    )
                                })}
                            </List>
                        </Paper>
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={openNoteModal}
                            onClose={() => {setOpenNoteModal(false)}}
                            style={{ 
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                width: '400px',
                                height: '300px',
                                justifyContent: 'center',
                                alignItems: 'center', 
                            }}
                        >
                            <div>
                                <Typography variant='h5' color='white'>{noteModalText}</Typography>
                            </div>
                        </Modal>
                    </Grid>
                    
                }
                

                {
                    has3D &&
                    <Grid xs={2}>
                        <Paper>
                            {
                                Object.keys(floorplanRooms).length === 0
                                ?   <div>
                                        <Typography variant="headline">Impossible de charger la plan</Typography>
                                    </div>
                                :   <Floorplan floorplanRooms={floorplanRooms} />
                            }
                        </Paper>
                    </Grid >
                }

                {
                    has3D &&
                    <>
                        <Grid xs={2}/>
                        <Grid xs={10} >
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <div style={{display: 'flex', flex: 1, maxWidth: 'fit-content'}}>
                                    <Button onClick={handleGridCount('increment')}>
                                        <ZoomInIcon />
                                    </Button>
                                    <Button onClick={handleGridCount('decrement')}>
                                        <ZoomOutIcon />
                                    </Button>
                                </div>
                                {/*<Paper style={{display: 'flex', flex: 1, maxWidth: 'fit-content'}}>
                                    <Select
                                        native
                                        value={appliedFilter}
                                        onChange={handleFilterChange}
                                        label={"Apply Filter"}
                                        variant="filled"
                                    >
                                        <option value={'all'}>Toutes</option>
                                        <option value={'good'}>Validées</option>
                                        <option value={'unvalidated'}>Non validées</option>
                                        <option value={'retouch'}>Retouche</option>
                                        {
                                            shootings.length > 1 ?
                                                (shootings.map((shoot, index) => (
                                                    <option
                                                        value={`shootingid_${shoot.id}`}>{`Afficher la capture #${index + 1}`}</option>
                                                ))) : null
                                        }
                                    </Select>
                                </Paper>*/}
                            </div>
                        </Grid>
                    </>
                }



                {
                    //This is the old code to reorder pictures
                    /*<Grid xs={12}>
                    {
                        listingPictures.length > 0 ?
                            <Paper>
                                <DndProvider backend={HTML5Backend}>
                                    <PictureOrdering pictures={listingPictures} pictureActions={pictureActions}
                                                     handlePictureOrderChange={handlePictureDrop}
                                                     setShowDialog={setShowDialog} gridItemCnt={gridItemCnt}/>
                                </DndProvider>
                            </Paper>
                            : <div style={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'center',
                                minHeight: '100px',
                                marginTop: 40
                            }}>
                                <Typography variant="h6">
                                    {`Aucune photo trouvée${appliedFilter === 'all' ? '.' : ' for applied filter.'}`}
                                </Typography>
                            </div>
                    }
                </Grid>*/}

                <Grid xs={12}>
                { listingPictures.length > 0 ?
                    <div style={{width:'100%'}}>
                        <DndProvider backend={HTML5Backend}>
                            <PictureReordering pictures={listingPictures} pictureActions={pictureActions} handlePictureOrderChange={handlePictureDrop} broker={listing.broker}/>
                        </DndProvider>
                        <Box style={styles.navigator}>
                            <Typography style={styles.navigatorItem}>
                                Publique: {pictureCount.public}
                            </Typography>
                            <Typography style={styles.navigatorItem}>
                                Diaporama: {pictureCount.slideshow}
                            </Typography>
                            <Typography style={styles.navigatorItem}>
                                Watermark: {pictureCount.watermarked}
                            </Typography>
                            <Typography style={styles.navigatorItem}>
                                Privée: {pictureCount.notPublic}
                            </Typography>
                        </Box>
                    </div>
                    :
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        minHeight: '6.25em',
                        marginTop: 40
                    }}>
                        <Typography variant="h6">
                            {`Aucune photo trouvée${appliedFilter === 'all' ? '.' : ' for applied filter.'}`}
                        </Typography>
                    </div>
                }
                </Grid>
                {
                    has3D &&
                    <Grid xs={12}>
                        <Paper style={{margin:10, padding:10}}>
                        <Grid style={{display:'flex', flexDirection:'column'}}>
                            <Grid style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignSelf:'center', margin:10, width:'100%', maxWidth:'1200px'}}>
                                <Grid xs={8}>
                                    <Typography variant="title">Revue de la visite virtuelle</Typography>
                                    <p>{"https://viewer.hausvalet.ca/visit/" + listing.id}</p>
                                </Grid>
                                <Grid xs={4} style={{alignItems:'flex-end', marginBottom: 5}}>
                                    <Button
                                        variant={listing.state === Globals.listing_state.vr_ready ? 'contained' : 'outlined'}
                                        onClick={handleSetVRReady}
                                        style={
                                            listing.state === Globals.listing_state.vr_ready
                                            ?   {backgroundColor: blue[500], color: 'white', float:'right'}
                                            :   {backgroundColor: 'white', color: 'black', float:'right'}
                                        }>
                                            {ajaxSaving === 'vr' ? <CircularProgress size={18} /> : listing.state === Globals.listing_state.vr_ready ? 'Visite virtuelle publique' : 'Visite virtuelle privée'}
                                    </Button>
                                </Grid>

                            </Grid>
                            <Grid xs={12} alignItems='center' alignSelf='center' alignContent='center'>
                                <div dangerouslySetInnerHTML={{__html: `<p align="center"><iframe src=${virtualTourUrl} width="100%" height="800px"></iframe></p>`}}/>
                            </Grid>
                        </Grid>
                    </Paper>
                    </Grid>

                }

            </Grid>

            <Dialog
                open={showVRReadyDialog}
                onClose={() => setShowNoVRDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"La visite virtuelle n'est pas publique"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                        `Voulez-vous activer la visite virtuelle avant d'envoyer la capture au courtier?`
                    }
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowNoVRDialog(false)} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => {handleSetVRReady(); setShowNoVRDialog(false);}} color="primary" autoFocus>
                    Activer la visite virtuelle
                </Button>
                <Button onClick={()=>{handleSendToBroker(true)}} color="primary" autoFocus>
                    Poursuivre sans visite virtuelle
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showVideoPicturesDialog}
                onClose={() => setShowVideoPicturesDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Il n'y a pas assez de photos validées pour les vidéos automatisées"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            `${getCurrentVideoPictureCount()} photos sont présentement sélectionnées`
                        }
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        {
                            `Voulez-vous tout de même envoyer le tout au courtier? Nous suggèrons 12 et maximum 20`
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => {setShowVideoPicturesDialog(false)}} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => {setShowDialog(true); setShowVideoPicturesDialog(false);}}>
                    suivant        
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Êtes-vous certain de vouloir envoyer la capture au courtier?"}</DialogTitle>
                <DialogContent>
                {listingPictures.filter(p => p.is_validated === null).length > 0 ? (
                    <DialogContentText id="alert-dialog-description">
                    {
                        `Il y a ${listingPictures.filter(p => p.is_validated === null).length} photos qui doit être validée`
                    }
                </DialogContentText>
                ) : null}

                {listingPictures.filter(p => p.is_validated === false).length > 0 ? (
                    <DialogContentText id="alert-dialog-description">
                    {
                        `Il y a ${listingPictures.filter(p => p.is_validated === false).length} photo qui ont été envoyées en retouche`
                    }
                </DialogContentText>
                ) : null}

                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowDialog(false)} color="primary">
                    Annuler
                </Button>
                <Button onClick={()=>{setShowWarningModal(true); setShowDialog(false);}} color="primary" autoFocus>
                    suivant
                </Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={showWarningModal}
                onClose={()=>{setShowWarningModal(false)}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box 
                    style={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        height: '300px',
                        borderRadius: 5,
                        justifyContent: 'center',
                        alignItems: 'center', 
                        backgroundColor: 'white',
                    }}
                >
                    <Box 
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: 'center' 
                        }}
                    >
                        <Typography variant='h4' style={{ color: 'red', marginBottom: 5 }}>
                            ÊTES VOUS CERTAIN QUE LES MÉDIAS RÉPONDENT AUX STANDARDS DE QUALITÉ HAUSVALET?
                        </Typography>
                        <Typography  style={{ marginBottom: 20 }} variant='body1'>
                            Si non, SVP contacter le service à la clientèle pour que nous puissions aviser le courtier.
                        </Typography>
                        <Box
                            style={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                width: '100%'
                             }}
                        >
                            <Button 
                                style={{ marginRight: 20 }} 
                                onClick={()=>{
                                    setShowWarningModal(false)
                                }} 
                                variant='contained' 
                                color="secondary"
                            >
                                Annuler
                            </Button>
                            <Button 
                                onClick={handlePublishConfirm} 
                                color="primary" 
                                variant='contained' autoFocus
                            >
                                Envoyer
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )

}

export default withRouter(withStyles(styles)(ListingView));
