import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Checkbox,
	Typography,
	FormControlLabel,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	} from '@material-ui/core';
import { VictoryBar, VictoryChart ,VictoryLabel,VictoryAxis} from 'victory';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import './admin.css';

class ListingEdit extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			open: this.props.show,
			confirm: this.props.confirm,
			address: this.props.listing.address,
			civic_number: this.props.listing.civic_number,
			street: this.props.listing.street,
			city: this.props.listing.city,
			postalcode: this.props.listing.postalcode,
			app: this.props.listing.app,
			mls: this.props.listing.mls,
			sold: props.listing.sold,
			shooting: [],
			centrisListing: [],
			centrisData: [],
			showCentrisListing: false,
			showCentrisInfo: false,
			date_notarized: '',
			badDate: false,
			notSold: false,
			expired: false,
			badMls: false,
			date_sold: this.props.listing.date_sold,
			badSoldDate: false,
			visitStatIcon:'+',
			graphLoaded:false,
			selectedFirstDate : moment().subtract(1, 'month').format('YYYY-MM-DD'),    //  moment('2019-08-28').format('YYYY-MM-DD')
            selectedSecondDate :moment().format('YYYY-MM-DD'),
			distribution_token: this.props.listing.distribution_token,
		};
	}

	componentWillMount() {
		this.loadData();
		window.Hauslife.getListingVirtualVisit(this.props.listing.id )
			.then((res) => {
				this.setState({
					visitStat: res.data.stats
				});

				if(res.data.stats != null && res.data.stats.visitor!==0){
					this.queryReportVisit();
				}
			})
			.catch((e) => {
				console.log(e);
			});
			
	}
	queryReportVisit (){
        window.Hauslife.getListingVirtualVisitReportVisitors(this.state.selectedFirstDate,this.state.selectedSecondDate, this.props.listing.id ).then((res) => {
			this.setState( {graphLoaded:true, visitors :res.data.data  });
		});
    }
	handleRefreshVisitor = name => event =>{
        this.setState({graphLoaded:false });
        this.queryReportVisit();
    }
	loadData() {
		this.setState({
			date_notarized: moment(this.props.listing.date_notarized).format('YYYY-MM-DD'),
			sold: this.props.listing.sold,
			date_sold: moment(this.props.listing.date_sold).format('YYYY-MM-DD'),
			expired: this.props.listing.expired
		})

		window.Hauslife.adminListingShootings(this.props.listing.id)
			.then((res) => {
				this.setState({
					shooting: res.data.data,
					loaded: true
				});
			})
			.catch((e) => {
				console.log(e);
			});

		if (this.props.listing.broker !== undefined && this.props.listing.broker.broker_code !== null) {
			window.Hauslife.adminCentrisListingForBroker(this.props.listing.broker.broker_code)
				.then((res) => {
					this.setState({ centrisListing: res.data.result });
				})
				.catch((e) => {
					console.log(e);
				});
		}

		if (this.props.listing.mls != null && this.props.listing.mls.length > 0) {
			window.Hauslife.adminCentrisListingForMls(this.props.listing.mls)
				.then((res) => {
					this.setState({
						centrisData: res.data.result,
						showCentrisInfo: (res.data.result.length > 0).length
					});
				})
				.catch((e) => {
					console.log(e);
				});
		}



	}

	handleClose = (button, data) => () => {

		if (button === 'ok') {
			if (this.state.sold === true) {
				var bad = false;
				if (moment(this.state.date_notarized).isValid() === false) {
					this.setState({ badDate: true });
					bad = true;
				} else {
					this.setState({ badDate: false });
				}

				if (this.state.mls == null || this.state.mls.length === 0) {
					this.setState({ 'badMls': true });
					bad = true;
				} else {
					this.setState({ 'badMls': false });
				}
				if (moment(this.state.date_sold).isValid() === false) {
					this.setState({ badSoldDate: true });
					bad = true;
				}

				if (bad === true) {
					return;
				}
			}

			var payLoad = {
				id: this.props.listing.id,
				address: this.state.civic_number + " " + this.state.street,
				civic_number: this.state.civic_number,
				street: this.state.street,
				city: this.state.city,
				postalcode: this.state.postalcode,
				app: this.state.app,
				mls: this.state.mls,
				sold: this.state.sold,
				date_sold: this.state.date_sold,
				expired: this.state.expired
			};

			if(this.state.sold === true){
				payLoad = {
					...payLoad,
					date_sold: this.state.date_sold,
					date_notarized: this.state.date_notarized
				}
			}

			window.Hauslife.adminUpdateListing(payLoad).then((res) => {
				this.props.didClose(button, data);
			}).catch((e) => {
				console.log(e);
			});

			return;
		}

		this.props.didClose(button, data);
	};

	handleChange = (name, event) => {
		var obj = {};
		obj[name] = event.target.value;
		this.setState(obj);
	}

	handleCheckChange(name) {
		let obj = {};
		obj[name] = !this.state[name];
		this.setState(obj);
	}

	componentWillReceiveProps({ someProp }) {
		this.setState({ ...this.state, someProp })
	}

	mapListingState() {
		switch (this.props.listing.state) {
			case 0:
				return 'Créé';
			case 1:
				return 'Capturé';
			case 2:
				return 'Validé';
			case 3:
				return 'Vr Prêt';
			case 4:
				return 'Remis';
		}
	}

	mapShootingState(value) {
		switch (value) {
			case 0:  return 'Created';
			case 1:  return 'Captured';
			case 2:  return 'Validated';
			case 3:  return 'Retouch';
			case 4:  return 'Processed';
			case 5:  return 'Uploaded';
			case 6:  return 'Confirmed';
			case 7:  return 'Unconfirmed';
			case 8:  return 'In progress';
			case 9:  return 'Completed';
			case 10: return 'Canceled';
			case 11: return 'Confirmation_required';
			case 12: return 'Recommended';
			case 13: return 'Abandonned';
			default: return value;
		}
	}

	shootingCompleted(shootingState){
		return shootingState === 1 || shootingState === 3 || shootingState === 4 || shootingState === 5 || shootingState === 9;
	}

	listingVisible(listingState){
		return listingState === 3 && this.props.listing.on_market === true;
	}

	techName(tech, shooting) {
		if (shooting[tech] === undefined) return '';
		return shooting[tech].firstname + ' ' + shooting[tech].lastname;
	}

	tabClick(name) {
		var tabState = {
			showCentrisInfo: false,
			showCentrisListing: false
		};

		tabState[name] = !this.state[name];

		this.setState({
			showCentrisInfo: tabState.showCentrisInfo,
			showCentrisListing: tabState.showCentrisListing
		});
	}

	changeMls(centrisListing) {
		this.setState({
			mls: centrisListing.mls,
		});
	}

	centrisBrokerString(row, name) {
		if (row[name] === undefined) {
			return '';
		}
		var b = row[name];
		var str = b.firstname + ' ' + b.lastname + ' ' + b.code;

		var JayEshEx = (
			<li
				style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
				<span>{b.firstname + ' ' + b.lastname}</span> <span>{b.code}</span>
			</li>
		)
		return JayEshEx;
	}

	AbandonShooting = (shooting_id) =>
	{
		let doAbandon = window.confirm("Are you sure you want to abandon this shooting?");
		if(doAbandon)
		{
			window.Hauslife.abandonShooting(shooting_id)
			.then(res=>{
				this.loadData();
			})
			.catch(e=>{
				console.log(e);
			});
		}
	}

	CancelShooting = (shooting_id) =>
	{
		let CancelReason = window.prompt("Please enter the cancellation reason. (5 characters or more)");
		if(CancelReason && CancelReason.length >= 5)
		{
			window.Hauslife.cancelShooting(shooting_id, CancelReason)
			.then(res=>{
				this.loadData();
			})
			.catch(e=>{
				console.log(e);
			});
		}
	}

	GoToInvoice = (shooting) => {
		window.open('https://books.zoho.com/app#/invoices/' + shooting.invoice.invoice_id, '_blank');
	}

	GenerateInvoice = (shooting_id) => {
		window.Hauslife.generateInvoice(shooting_id)
			.then(res=>{
				this.loadData();
			})
			.catch(e=>{
				console.log(e);
			});
	}

	GoToNotarizedInvoice = () => {
		window.open('https://books.zoho.com/app#/invoices/' + this.props.listing.notarizedInvoice.invoice_id, '_blank');
	}

	GenerateNotarizedInvoice = () => {
		window.Hauslife.generateNotarizedInvoice(this.props.listing.id)
			.then(res=>{
			})
			.catch(e=>{
				console.log(e);
			});
	}

	numberEqualsAny = (number, checkArray) =>
	{
		if(checkArray === undefined)
		{
			checkArray  = [];
		}

		for(var i = 0; i < checkArray.length; i++)
		{
			if(checkArray[i]===number)
			{
				return true;
			}
		}

		return false;
	}

	showVisitStat(){
		if(this.state.visitStatIcon === '-'){
			this.setState({visitStatIcon:'+'});
		} else {
			this.setState({visitStatIcon:'-'});
		}
	}
	handleFirstDateChange  = name => event => {
        var value = event.target.value;
        this.setState({ selectedFirstDate: event.target.value } );
    }

    handleSecondDateChange = name => event => {
        var value = event.target.value;
        this.setState({ selectedSecondDate: event.target.value });
    }

	render() {
		var broker_code = '';
		var broker_id = '';
		var broker_name = '';
		if (this.props.listing.broker !== undefined) {
			broker_code = this.props.listing.broker.broker_code;
			broker_id = this.props.listing.broker_id;
			broker_name = this.props.listing.broker.firstname + ' ' + this.props.listing.broker.lastname;
		}
		return (
			<Dialog
				fullWidth={true}
				maxWidth="lg"
				disableBackdropClick
				disableEscapeKeyDown
				open={this.props.show}
				onClose={this.handleClose}
				aria-labelledby="{alert}-dialog-title"
				aria-describedby="{alert}-dialog-description"

			>
				<DialogTitle id="{alert}-dialog-title">{this.props.title}</DialogTitle>
				<DialogContent>
						<div style={{
						 padding: 10,
						 marginBottom: 5,
						 flexDirection: 'row',
						 border:"solid grey 2px",
						 display: 'flex',
						 justifyContent: 'space-between',
						 alignItems: 'center'
						}}>
							<h4>Listing ID : {this.props.listing.id}</h4>
							<h4>Statut : {this.mapListingState()} </h4 >
							<Button variant="outlined" color="primary" href={"/tech_review/" + this.props.listing.id} target="_blank">Review Photos</Button>
							{this.state.distribution_token !== null
								?
								<Button
									target={"_blank"}
									href={"https://livraison.hausvalet.ca/delivery/" + this.state.distribution_token}
									variant="outlined"
									color="primary"
								>
									Aller a la page de distribution
								</Button>
								: undefined}
						</div>

						<div style={{
							backgroundColor: this.listingVisible(this.props.listing.state) ? "#76cc8d" : "#fff2b8",
							padding: 10,
							marginBottom: 10,
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'space-between' }}>
							{"HMD visibility status : " + (this.listingVisible(this.props.listing.state) ? "Visible for all" : "Not visible")}
						</div>
					<div style={{
						display: 'flex',
						marginBottom: 5,
						flexDirection: 'row'}}>
						<FormControlLabel
							label="Expiré"
							control={<Checkbox
								checked={this.state.expired}
								onChange={(event) => this.handleCheckChange('expired', event)}
								color="primary"/>
							}
						/>
						<FormControlLabel
							label="Vendu"
							control={
							<Checkbox
								checked={this.state.sold}
								onChange={(event) => this.handleCheckChange('sold', event)}
								style={{backgroundColor: this.state.notSold ? 'red' : 'transparent'}}
								color="primary"/>
							}

						/>
						{this.state.sold === true ? (
							<span style={{
							border: "solid black 2px",
							padding: 5,
							display: 'flex',
							flexDirection: 'row',
							alignItems: "center"
						}}>
							<Typography style={{display: 'flex', marginLeft: 20, marginRight: 20}}>
								<b>Date de vente:</b>
							</Typography>
							<TextField
								id="date"
								type="date"
								value={this.state.date_sold}
								disabled={!this.state.sold}
								InputProps={{style: {backgroundColor: (this.state.badSoldDate ? 'salmon' : 'transparent')}}}
								InputLabelProps={{shrink: true, style: {backgroundColor: 'white'}}}
								onChange={(event) => this.handleChange('date_sold', event)}
							/>
							<Typography style={{display: 'flex', marginLeft: 20, marginRight: 20}}>
								<b>Date du notaire:</b>
							</Typography>
							<TextField
								id="date"
								type="date"
								value={this.state.date_notarized}
								disabled={!this.state.sold}
								InputProps={{style: {backgroundColor: (this.state.badDate ? 'salmon' : 'white')}}}
								InputLabelProps={{shrink: true, style: {backgroundColor: 'white'}}}
								onChange={(event) => this.handleChange('date_notarized', event)}
							/>
							{this.props.listing.notarizedInvoice && this.props.listing.notarizedInvoice.invoice_id != null ? (
							<Button onClick={() => this.GoToNotarizedInvoice()} color="primary">Access notarized invoice</Button>) :
								moment(this.props.listing.date_sold).isValid() && moment(this.props.listing.date_notarized).isValid() ? (
							<Button onClick={() => this.GenerateNotarizedInvoice()} color="primary">Generate notarized invoice</Button>) : null}
						</span>) : null}
					</div>

					<div>
						<span style={{marginRight:10}}>
							<h4 style={{display:"inline"}}>Courtier: </h4>
							<a style={{textDecoration: 'underline', color: 'rgb(0, 0, 0, 0.87)'}}
							   href={"users/edit/" + broker_id} target="_blank">
								{broker_name}
							</a>
						</span>
						<span style={{marginRight:10}}>
							<h4 style={{display:"inline"}}>Id: </h4>
							{broker_id}
						</span>
						<span style={{marginRight:10}}>
							<h4 style={{display:"inline"}}>Code: </h4>
							{ broker_code !== null || "" ? broker_code : "Inconnu"}
						</span>
					</div>

					{this.state.visitStat && this.state.visitStat.visitor > 0 &&

						<div style={{display: 'flex', flexDirection: 'column'}}>
							<div style={{
								marginTop: 10,
								padding: 5,
								display: 'flex',
								flexDirection: 'column',
								border: 1,
								borderStyle: 'solid',
								fontWeight: 'bold',
								alignItems: 'stretch',
								justifyContent: 'center',
								borderRadius: 4,
								borderColor: '#b7b7b7',
								cursor: 'pointer'
							}}>

								<div style={{
									alignSelf: 'stretch',
									fontSize: 16,
									textAlign: 'center',
									backgroundColor: 'rgb(249, 247, 247)',
									padding: 3
								}} onClick={() => this.showVisitStat()}>Visite virtuelle

									<span style={{
										margin: 5,
										cursor: 'pointer',
										fontWeight: 'bold'
									}}>{this.state.visitStatIcon}</span>

								</div>
								{this.state.visitStatIcon === '-' && <>
									<div style={{
										marginLeft: 3,
										marginTop: 8,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										flexWrap: 'nowrap'
									}}>
										<div style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											marginBottom: 5
										}}>
											<div style={{}}><span
												style={{fontWeight: 'normal'}}>Visiteurs : </span><span
												style={{fontWeight: 'bold'}}>{this.state.visitStat.visitor}</span></div>
											<div style={{marginLeft: 0}}><span style={{fontWeight: 'normal'}}>Complétées :</span>
												<span
													style={{fontWeight: 'bold'}}>{Math.round((this.state.visitStat.views / (this.state.visitStat.photos * this.state.visitStat.session)) * 100)} %</span>
											</div>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between'
										}}>
											<div style={{marginLeft: 0}}><span
												style={{fontWeight: 'normal'}}>Visites : </span> <span
												style={{fontWeight: 'bold'}}>{this.state.visitStat.session}</span></div>

											<div style={{marginLeft: 0}}><span style={{fontWeight: 'normal'}}>Durée moyenne  : </span>
												<span
													style={{fontWeight: 'bold'}}>{Math.round(this.state.visitStat.avg_time / 60)} minutes</span>
											</div>
										</div>
									</div>
									{this.state.graphLoaded === true && <div style={{}}>

										<div style={{
											display: 'flex',
											flexDirection: 'column',
											marginTop: 5,
											borderTop: '1px solid black',
											paddingTop: 5
										}}>

											<div style={{
												backgroundColor: 'whitesmoke',
												paddingLeft: 6,
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}>
												Du :
												<TextField
													id="date"
													label="Date "
													type="date"
													disabled={!this.state.loaded}
													defaultValue={this.state.selectedDate}

													InputLabelProps={{
														shrink: true
													}}
													onChange={this.handleFirstDateChange('cal')}
													value={this.state.selectedFirstDate}
												/>

												Au :
												<TextField
													id="date"
													label="Date "
													type="date"
													disabled={!this.state.loaded}
													defaultValue={this.state.selectedDate}

													InputLabelProps={{
														shrink: true
													}}
													onChange={this.handleSecondDateChange('cal')}
													value={this.state.selectedSecondDate}
												/>
												<Button variant="contained" color="primary"
														onClick={this.handleRefreshVisitor()} style={{height: 32, width: 32}}>
													Go
												</Button>
											</div>
											<div style={{
												padding: 10,
												fontWeight: 'bold',
												fontSize: 20,
												textAlign: 'center'
											}}>Nombre de Visiteurs
											</div>


											<div style={{
												flex: 1,
												backgroundColor: 'transparent',
												border: '1px solid whitesmoke',
												margin: 2
											}}>
												<VictoryChart domainPadding={20}>
													<VictoryBar
														interpolation="linear"
														data={this.state.visitors}
														y={"visitor"} x={"localDate"}
														labels={({datum}) => `${datum._y}`}
														style={{labels: {fontSize: 7,}}}
													/>
													<VictoryAxis
														label="Date"
														style={{axisLabel: {padding: 30, size: 15}}}
														tickLabelComponent={<VictoryLabel angle={90} style={{fontSize: 10}} dx={5}/>}
													/>

													<VictoryAxis
														dependentAxis
														label="Visiteurs"
														style={{axisLabel: {padding: 30, fontSize: 10}}}
														tickLabelComponent={<VictoryLabel style={{fontSize: 10}}/>} tickFormat={(t) => `${Math.round(t)}`}
													/>
												</VictoryChart>
											</div>

											<div style={{
												padding: 10,
												fontWeight: 'bold',
												fontSize: 20,
												textAlign: 'center'
											}}>Nombre de Visites
											</div>
											<div style={{
												flex: 1,
												backgroundColor: 'transparent',
												border: '1px solid whitesmoke',
												margin: 2
											}}>
												<VictoryChart domainPadding={20}>
													<VictoryBar
														interpolation="linear"
														data={this.state.visitors}
														y={"session"} x={"localDate"}
														labels={({datum}) => `${datum._y}`}
														style={{labels: {fontSize: 7,}}}
													/>
													<VictoryAxis
														label="Date"
														style={{axisLabel: {padding: 30, size: 15}}}
														tickLabelComponent={<VictoryLabel angle={90} style={{fontSize: 10}} dx={5}/>}
													/>

													<VictoryAxis
														dependentAxis
														label="Visites"
														style={{axisLabel: {padding: 30, fontSize: 10}}}
														tickLabelComponent={<VictoryLabel style={{fontSize: 10}}/>}
														tickFormat={(t) => `${Math.round(t)}`}
													/>
												</VictoryChart>
											</div>
										</div>
									</div>}

									<div style={{}}>
										<Table size="small" className="">
											<TableHead>
												<TableRow>
													<TableCell className="listing-list-cell"
															   style={{fontWeight: 'bold', fontSize: 14}}>Visites récurrentes</TableCell>
													<TableCell className="listing-list-cell"
															   style={{fontWeight: 'bold', fontSize: 14}}>#</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.state.visitStat.visitorsVisites.map((row, idx) => (
													<TableRow className="" key={row.key} style={{height: 12}}>
														<TableCell className="listing-list-cell" style={{
															whiteSpace: 'nowrap',
															padding: 0
														}}>{row.key} x {idx === 5 && <span> et plus</span>}</TableCell>
														<TableCell className="listing-list-cell"
																   style={{whiteSpace: 'nowrap'}}>{row.label}  </TableCell>
													</TableRow>))}
											</TableBody>
										</Table>

									</div>
								</>}
							</div>
						</div>}

						<div style={{padding:5}}>
							<TextField
								style={{display:"block"}}
								label="MLS"
								value={this.state.mls}
								onChange={(event) => this.handleChange('mls', event)}
								type="text"
								margin="normal"
							/>
							<TextField
								style={{marginRight: 10}}
								label="Addresse civic"
								value={this.state.civic_number}
								onChange={(event) => this.handleChange('civic_number', event)}
								margin="normal"
							/>

							<TextField
								style={{marginRight: 10}}
								label="Rue"
								value={this.state.street}
								onChange={(event) => this.handleChange('street', event)}
								margin="normal"
							/>

							<TextField
								style={{marginRight: 10}}
								label="App #"
								value={this.state.app}
								onChange={(event) => this.handleChange('app', event)}
								margin="normal"
							/>

							<TextField
								style={{marginRight: 10}}
								label="Ville"
								value={this.state.city}
								onChange={(event) => this.handleChange('city', event)}
								margin="normal"
							/>

							<TextField
								label="Code postal"
								value={this.state.postalcode}
								onChange={(event) => this.handleChange('postalcode', event)}
								margin="normal"
							/>
						</div>
						{this.state.shooting.length === 0 &&
							<div style={{ padding:10, textAlign: 'center' }}><h2>Aucun shooting</h2></div>
						}
						{this.state.shooting.length > 0 &&
							<>
								<div style={{ padding:10, textAlign: 'center', border:"solid grey 2px"}}> <h2>Shooting(s)</h2>
									<Table className="">
										<TableHead>
											<TableRow>
												<TableCell className="listing-list-cell">Id</TableCell>
												<TableCell className="listing-list-cell">Date</TableCell>
												<TableCell className="listing-list-cell">Info</TableCell>
												<TableCell className="listing-list-cell">Actions</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.shooting.map(row => (
												<TableRow className="" key={row.id} >
												<TableCell className="listing-list-cell" style={{ whiteSpace: 'nowrap' }}>{row.id}</TableCell>
												<TableCell className="listing-list-cell"
														   style={{ whiteSpace: 'nowrap',
															   backgroundColor: 'rgb(249, 247, 247' }}>
													{moment(row.scheduleddate).format('YYYY-MM-DD')}
												</TableCell>
												<TableCell className="listing-list-cell" component="th" scope="row">
													<ul className="no-bullet">
														<li><b>Completé :</b> {this.shootingCompleted(row.state) === true ? 'oui' : 'non'}</li>
														<li><b>Statut :</b>{this.mapShootingState(row.state)}</li>
														<li><b>Tech :</b>{this.techName('tech', row)}</li>
														<li><b>PP tech :</b>{this.techName('pp_tech', row)}</li>
														{row.cancellation_reason != null &&
															<li><b>Raison annullation :</b>{row.cancellation_reason}</li>
														}
														{row.reshoot_note != null &&
															<li><b> Re-shoot note:</b>{row.reshoot_note}</li>
														}
														<li><b>Note :</b>{row.note}</li>
														{row.tech_note !== null &&
															<li><b>Tech note :</b>{row.tech_note}</li>
														}
														{row.post_prod_notes !== null &&
															<li><b>Post-prod note :</b>{row.post_prod_notes}</li>
														}
														<li><b>Nombre de photo:</b> {row.pictures.length}</li>
													</ul>
												</TableCell>
												<TableCell className="listing-list-cell" component="th" scope="row">
													<>
														{row.invoice && row.invoice.invoice_id > 0 ? (
															<Button onClick={() => this.GoToInvoice(row)} variant="outlined" color="primary">
																Access invoice
															</Button>) : (
															<Button onClick={() => this.GenerateInvoice(row.id)} variant="outlined" color="primary">
																Generate invoice
															</Button>
														)
														}
														{this.numberEqualsAny(row.state, [1, 2, 6, 8, 12]) &&
															<Button variant="outlined" onClick={() => this.CancelShooting(row.id)} color="primary">
																Cancel
															</Button>}
													</>
												</TableCell>
											</TableRow>
											))}
										</TableBody>
									</Table>
								</div>
							</>
						}

				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose('ok', this.props.data)} color="primary" autoFocus>
						Ok
            		</Button>
					<Button onClick={this.handleClose('cancel', this.props.data)} color="primary" >
						Cancel
              		</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ListingEdit;
