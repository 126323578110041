import { version } from '../package.json'
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import 'react-image-lightbox/style.css';

import { addLocaleData, IntlProvider, FormattedMessage } from 'react-intl';

import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";

import Login from './widgets/login.js'
import MenuAppBar from './widgets/MenuAppBar.js'
import CancelShooting from './widgets/CancelShooting.js'
import DisplayShooting from './widgets/DisplayShooting.js'
import BookShooting from './widgets/bookshooting.js'
import MyShootings from './widgets/MyShootings.js'
import MyTickets from './widgets/MyTickets.js'
import AddTicket from './widgets/AddTicket.js'
import ViewTicket from './widgets/ViewTicket.js'
import ChangeTimeShooting from './widgets/ChangeTimeShooting.js'
import MyDeliveries from './widgets/MyDeliveries.js'
import ViewBeautyDelivery from './widgets/ViewBeautyDelivery.js'
import ViewUsers from './widgets/ViewUsers';
import ViewDepots from './widgets/ViewDepots';
import EditUser from './widgets/EditUser';
import ShootingPlanner from './widgets/ShootingPlanner';
import Releases from './widgets/Releases';
import TechDashboard from './widgets/TechDashboard';
import TechManager from './widgets/TechManager';
import ShootingDashboard from './widgets/ShootingDashboard';
import MLSProvider from './widgets/MLSProvider';
import ListingList from './widgets/ListingList';
import HmdList from './widgets/HmdList';
import BadListings from './widgets/BadListings';
import SuperLog from './widgets/SuperLog';
import SuperAnalytics from './widgets/SuperAnalytics';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CentrisReport from './widgets/CentrisReport';
import CentrisBrokerListings from './widgets/CentrisBrokerListings';
import LinkButton from './widgets/LinkButton.js'
import ListItemLink from './widgets/ListItemLink.js'
import RemoteInjectorStatus from './widgets/RemoteInjectorStatus.js';
import TechRemoteInjector from './widgets/TechRemoteInjector.js';
import ListingStats from './widgets/ListingStats';
import ListingMlsMatch from './widgets/ListingMlsMatch';
import Zones from './screens/zones'
import TechSchedule from './screens/techSchedule'
import Review from './screens/review'
import ListingReview from './screens/review/ReviewListing'
import AddOrModifyDepot from './widgets/AddOrModifyDepot.js'
import ManageServices from './screens/ManageServices'
import ManageShootingPackages from './screens/ManageShootingPackages'


import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';


import logo from './logo.svg';
import './App.css';
import { messages as caFRMessages } from "./locales/ca-fr.json";
import { messages as usENMessages } from "./locales/us-en.json";

import detectBrowserLanguage from 'detect-browser-language'
import VideoDashboard from './screens/VideoDashboard';
import FacebookPageManager from './screens/FacebookPageManager';

let frLocaleData = require('react-intl/locale-data/fr');
addLocaleData(frLocaleData);

const drawerWidth = 280;
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
        //flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'flex-start',  //flex-start | flex-end | center | space-between | space-around | space-evenly;
        overflow:"hidden"

    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    toolbar: theme.mixins.toolbar,
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    media: {
        height: 60,
        margin: "0 auto",
    },
    card: {
        marginTop: theme.spacing.unit * 2
    },
    snackbar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.error.dark,
    },
    appBar: {
        position: 'relative',
        // marginLeft: drawerWidth,
        height: '64px',
        [theme.breakpoints.up('md')]: {
            //width: `calc(100% - ${drawerWidth}px)`,
            width: "100%",
        },
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
        //  backgroundColor:'transparent'
        // backgroundColor:'transparent'
    },
    content: {
        // padding: theme.spacing.unit,
        // margin: "0 auto",
        // marginTop:64,
        display: 'flex',
        flex: 1,
        margin: '10px'
    },
    contentbeauty: {
        padding: theme.spacing.unit,
        margin: "0 auto",
        // marginTop:64,
        height:'100%',
        overflowY:'scroll',
        [theme.breakpoints.down('sm')]: {
            width: `calc(100%)`,
        },
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.up('xl')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    mnuHidden: {
        //  width:drawerWidth,
        marginTop: 3
    },
    footer: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        height: '3%',
        borderTop: '1px solid #f1f1f1'
    },
    centerContent: {
        flexGrow: 1,
        display: 'flex',
        width: '100%',
        height: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start ',
        overflowY: 'scroll'
    }
});

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            window.Hauslife.isLoggedIn() ? (
                <Component {...props} />
            ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
        }
    />
);

const SocialRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            window.Hauslife.isLoggedIn() ? (
                (window.Hauslife.userObject.role == 2 || window.Hauslife.userObject.role == 1 || window.Hauslife.userObject.role == 10 || window.Hauslife.userObject.role == 4) ? <Component {...props} /> : <Redirect to='/' />
            ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
        }
    />
);

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            (window.Hauslife.isLoggedIn()) ? (
                (window.Hauslife.userObject.role == 2 || window.Hauslife.userObject.role == 1) ? <Component {...props} /> : <Redirect to='/' />
            ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
        }
    />
);

const RoleAccessRoute = ({component: Component, ...rest}) => {
    let { allowed_roles } = rest
    if(!allowed_roles) {
        allowed_roles = {
            1: 'superadmin',
            2: 'admin',
            4: 'tech_capture',
            5: 'tech_postprod',
            10: 'social_medias',
            12: 'sales'
        }
    }

    const isAllowed = (role) => {
        return (
            Object.keys(allowed_roles)
                .map(k => parseInt(k))
                .indexOf(role) 
            != -1
        )
    }
    
    return (
        <Route
            {...rest}
            render={props =>
                (window.Hauslife.isLoggedIn()) ? (
                    isAllowed(window.Hauslife.userObject.role) ? <Component {...props} role={allowed_roles[window.Hauslife.userObject.role]} /> : <Redirect to='/' />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    )
}

/**
 * Main App class
 */
class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            i18nConfig: {
                locale: detectBrowserLanguage().includes('fr') ? 'fr' : 'en',
                messages: detectBrowserLanguage().includes('fr') ? caFRMessages : usENMessages
            },
            initializing: true,
            userLogged: false,
            mobileOpen: false
        };
    }

    /**
     * Handles language switching
     */
    switchLanguage = () => {
        if (this.state.i18nConfig.locale == "en") {
            let newi18n = { locale: 'fr', messages: caFRMessages };
            this.setState({ i18nConfig: newi18n });
        }
        else if (this.state.i18nConfig.locale == "fr") {
            let newi18n = { locale: 'en', messages: usENMessages };
            this.setState({ i18nConfig: newi18n });
        }
    };

    /**
     * Drawer is openned on mobile websites
     */
    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    componentWillMount() {
        window.Hauslife.setAppRoot(this);
        window.Hauslife.tryToLoginWithStoredToken()
            .then(() => {
                this.setState({ initializing: false });
            })
            .catch((e) => {
            });
    }

    componentDidUpdate() {
        try {
            var xmas = document.querySelector('.xmas');
            if (xmas == null) return;
            var COUNT = 100;
            var masthead = document.querySelector('.blue-bar');
 
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            var width = masthead.clientWidth;
            var height = masthead.clientHeight;
            var i = 0;
            var active = false;

            function onResize() {
                width = masthead.clientWidth;
                height = masthead.clientHeight;
                canvas.width = width;
                canvas.height = height;
                ctx.fillStyle = '#FFF';

                var wasActive = active;
                active = true;

                if (!wasActive && active)
                    window.requestAnimFrame(update);
            }

            var Snowflake = function () {
                this.x = 0;
                this.y = 0;
                this.vy = 0;
                this.vx = 0;
                this.r = 0;

                this.reset();
            }

            Snowflake.prototype.reset = function () {
                this.x = Math.random() * width;
                this.y = Math.random() * -height;
                this.vy = 1 + Math.random() * 3;
                this.vx = 0.5 - Math.random();
                this.r = 1 + Math.random() * 2;
                this.o = 0.5 + Math.random() * 0.5;
            }

            canvas.style.position = 'absolute';
            canvas.style.left = canvas.style.top = '0';
            canvas.style.zIndex = -1;

            var snowflakes = [], snowflake;
            for (i = 0; i < COUNT; i++) {
                snowflake = new Snowflake();
                snowflake.reset();
                snowflakes.push(snowflake);
            }
            var time = new Date().getTime();


            function update() {
                var currentTime = new Date().getTime();
                var diff = currentTime - time;

                /*if( diff>5000) {
                    masthead.removeChild(canvas)
                    return;
                }*/
                ctx.clearRect(0, 0, width, height);

                if (!active)
                    return;

                for (i = 0; i < COUNT; i++) {
                    snowflake = snowflakes[i];
                    snowflake.y += snowflake.vy;
                    snowflake.x += snowflake.vx;

                    ctx.globalAlpha = snowflake.o;
                    ctx.beginPath();
                    ctx.arc(snowflake.x, snowflake.y, snowflake.r, 0, Math.PI * 2, false);
                    ctx.closePath();
                    ctx.fill();

                    if (snowflake.y > height) {
                        snowflake.reset();
                    }
                }

                window.requestAnimFrame(update);
            }

            // shim layer with setTimeout fallback
            window.requestAnimFrame = (function () {
                return window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame ||
                    function (callback) {
                        window.setTimeout(callback, 1000 / 30);
                    };
            })();

            onResize();
            window.addEventListener('resize', onResize, false);

            masthead.appendChild(canvas);

        } catch (e) {
            console.log(e);
        }

    }

    RenderRedirectForUserRole = () => {
        if (window.Hauslife.userObject.role == 10 || window.Hauslife.userObject.role == 12) {     
            return (<div><Redirect to='/listings' /></div>);
        }
        else if (window.Hauslife.userObject.role == 3 ) {
            return (<div><Redirect to='/deliveries' /></div>);
        }
        else if (window.Hauslife.userObject.role == 4) {
            return (<div><Redirect to='/schedule' /></div>);
        }
        else if (window.Hauslife.userObject.role == 5 || window.Hauslife.userObject.role == 8){
            return (<div><Redirect to='/tech_review' /></div>);
        }
        else {
            return (<div><Redirect to='/shooting' /></div>);
        }
    }

    /**
     * Renders the home page depending on login state
     * @param {*} props 
     */
    RenderHomeCompo = (props) => {
        return window.Hauslife.isLoggedIn() ?
            (
                this.RenderRedirectForUserRole()
            ) :
            (
                <div>Redirecting to login...<Redirect to='/login' /></div>
            );
    };

    isDisplayingBeautyDelivery = () => {
        return window.location.pathname.includes("/delivery/");
    }

    showIfPhotographer = () => {
        return (window.Hauslife.userObject.role == 1 || window.Hauslife.userObject.role == 2 || window.Hauslife.userObject.role == 4 || window.Hauslife.userObject.role == 5);
    }

    showIfAdmin = () => {
        return window.Hauslife.userObject.role == 1 || window.Hauslife.userObject.role == 2;
    }

    showIfPostProd = () => {
        return window.Hauslife.userObject.role == 5 || window.Hauslife.userObject.role == 8;
    }

    showIfSocialMedia = () => {
        return window.Hauslife.userObject.role == 10;
    }

    showIfSalesPerson = () => {
        return window.Hauslife.userObject.role == 12;
    }

    render() {
        const { classes, theme } = this.props;
        const year = new Date().getFullYear();

        var seasonClassName = new Date().getMonth() == 11 ? 'xmas' : '';

        // Disable season if Beauty (of the beast)Delivery
        if (this.isDisplayingBeautyDelivery()) {
            seasonClassName = '';
        }

        const drawer = (
            <div className="menu-div">
                <Divider />

                <List>
                    {(window.Hauslife.userObject.role == 3 ) &&
                        <>
                            <ListItemLink icon="images_alt" to="/deliveries">
                                menu.manage_deliveries
                            </ListItemLink>
                        </>
                    }
                    {
                        <>                        

                            {
                                this.showIfPhotographer()  && 
                                <>
                                    <ListItemLink icon="date_range" to="/schedule">
                                        menu.horaire
                                    </ListItemLink>
                                    <ListItemLink icon="picture_as_pdf" to="/shootReports">
                                        menu.tech_manager
                                    </ListItemLink>
                                    <ListItemLink icon="images_alt" to="/deliveries">
                                        menu.manage_deliveries
                                    </ListItemLink>   
                                    <ListItemLink icon="cloud_upload" to="/remote_injector">
                                        menu.remote_injector
                                    </ListItemLink>                                   
                                </>                                
                            }



                            {
                               this.showIfPostProd() &&
                               <>
                                    <ListItemLink icon="image_search" to="/tech_review">
                                        menu.tech_review
                                    </ListItemLink>
                                    <ListItemLink icon="alarm" to="/shootingdashboard">
                                        menu.shooting_dashboard
                                    </ListItemLink>
                                    <ListItemLink icon="book_online" to="/shooting">
                                        menu.manage_shootings
                                    </ListItemLink> 
                                    <ListItemLink icon="cloud_upload" to="/remote_injector">
                                        menu.remote_injector
                                    </ListItemLink> 
                                    <ListItemLink icon="homework" to="/listings">
                                        menu.listings
                                    </ListItemLink>
                                    <ListItemLink icon="poll" to="/ListingMlsMatch">
                                        menu.listingMlsMatch
                                    </ListItemLink>
                                    
                                    <ListItemLink icon="image_search" to="/video_dashboard">
                                        menu.video_dashboard
                                    </ListItemLink>
                                    <ListItemLink icon="facebook" to="/facebook_page_manager">
                                        menu.facebook_page_manager
                                    </ListItemLink>
                               </> 
                               
                            }

                            {
                                this.showIfAdmin() &&
                                <>
                                    <ListItemLink icon="alarm" to="/shootingdashboard">
                                        menu.shooting_dashboard
                                    </ListItemLink>

                                    <ListItemLink icon="book_online" to="/shooting">
                                        menu.manage_shootings
                                    </ListItemLink>
                                    <ListItemLink icon="homework" to="/listings">
                                        menu.listings
                                    </ListItemLink>
                                    <ListItemLink icon="people_alt" to="/users">
                                        menu.manage_users
                                    </ListItemLink>

                                    <ListItemLink icon="visibility" to="/HmdList">
                                        menu.hmd
                                    </ListItemLink>                            

                                    <ListItemLink icon="edit_road" to="/zones">
                                        menu.zones
                                    </ListItemLink>

                                    <ListItemLink icon="place" to="/depots">
                                        menu.depots
                                    </ListItemLink>

                                    <ListItemLink icon="menu_book" to="/releases">
                                        menu.manage_releases
                                    </ListItemLink>

                                    <ListItemLink icon="report" to="/badlistings">
                                        menu.invalid_listings
                                    </ListItemLink>

                                    <ListItemLink icon="house" to="/centris">
                                        menu.centris
                                    </ListItemLink>

                                    <ListItemLink icon="poll" to="/SuperAnalytics">
                                        menu.analytics
                                    </ListItemLink>
                                    <ListItemLink icon="poll" to="/ListingStats">
                                        menu.listingVirtualVisiteStat
                                    </ListItemLink>
                                    <ListItemLink icon="poll" to="/ListingMlsMatch">
                                        menu.listingMlsMatch
                                    </ListItemLink>
                                    
                                    <ListItemLink icon="code" to="/superlog">
                                        menu.superlog
                                    </ListItemLink>

                                    <ListItemLink icon="image_search" to="/tech_review">
                                        menu.tech_review
                                    </ListItemLink>

                                    <ListItemLink icon="image_search" to="/manage_services">
                                        menu.manage_services
                                    </ListItemLink>

                                    <ListItemLink icon="image_search" to="/manage_shooting_packages">
                                        menu.manage_shooting_packages
                                    </ListItemLink>
                                    
                                    <ListItemLink icon="image_search" to="/video_dashboard">
                                        menu.video_dashboard
                                    </ListItemLink>
                                    <ListItemLink icon="facebook" to="/facebook_page_manager">
                                        menu.facebook_page_manager
                                    </ListItemLink>
                                </>
                            }                       
                            
                        </>
                    }
                    
                </List>
                <Divider />
            </div>
        );

        if (this.state.initializing) {
            return (
                <Grid container style={{ padding: 15, height: "100%" }} alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Typography align={"center"}>
                            <object className={classes.media} type="image/svg+xml" data={logo}>
                                Your browser does not support SVG
                            </object>
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: 15, textAlign: "center" }} xs={12}>
                        <CircularProgress className={classes.progress} size={50} />
                    </Grid>
                </Grid>
            );
        }
        else {
            return (
                <IntlProvider
                    locale={this.state.i18nConfig.locale}
                    messages={this.state.i18nConfig.messages}
                >
                    <Router>

                        <div className={classes.root + ' ' + seasonClassName}>

                            {this.isDisplayingBeautyDelivery() &&
                                <main className={classes.contentbeauty}>
                                    <Route path="/delivery/:id" component={ViewBeautyDelivery} />
                                </main>
                            }


                            {(window.Hauslife.isLoggedIn() && !this.isDisplayingBeautyDelivery()) && <MenuAppBar handleLanguageSwitch={this.switchLanguage} handleDrawerToggle={this.handleDrawerToggle} className={classes.appBar+' blue-bar'} />}

                            {!this.isDisplayingBeautyDelivery() &&
                                <div className={classes.centerContent }>

                                    {window.Hauslife.isLoggedIn() &&
                                        <Hidden mdUp className={classes.mnuHidden}>
                                            <Drawer
                                                disableBackdropTransition={!iOS}
                                                disableDiscovery={iOS}
                                                variant="temporary"
                                                anchor={'left'}
                                                open={this.state.mobileOpen}
                                                onClose={this.handleDrawerToggle}
                                                classes={{
                                                    paper: classes.drawerPaper,
                                                }}
                                                ModalProps={{
                                                    keepMounted: true, // Better open performance on mobile.
                                                }}
                                            >
                                                {drawer}
                                            </Drawer>
                                        </Hidden>
                                    }


                                    {window.Hauslife.isLoggedIn() &&
                                        <Hidden smDown implementation="css" className={classes.mnuHidden}>
                                            <Drawer
                                                variant="permanent"
                                                open
                                                classes={{
                                                    paper: classes.drawerPaper,
                                                }}
                                            >
                                                {drawer}
                                            </Drawer>
                                        </Hidden>
                                    }

                                    <main className={classes.content} style={{backgroundColor:'white'}}>
                                        <Route exact path="/" render={this.RenderHomeCompo} />
                                        <Route path="/login" component={Login} />

                                        <AdminRoute path="/depots/add" component={AddOrModifyDepot} />
                                        <AdminRoute path="/depots/edit/:id(\d+)" component={AddOrModifyDepot} />
                                        <AdminRoute exact path="/depots" component={ViewDepots} />
                                        
                                        <PrivateRoute exact path="/remote_injector" component={TechRemoteInjector} />
                                        <PrivateRoute exact path="/dashboard" component={TechDashboard} />


                                        <RoleAccessRoute 
                                            exact 
                                            path="/deliveries" 
                                            component={MyDeliveries} 
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead',
                                                10: 'social_medias',
                                                12: 'sales'
                                            }}
                                        />

                                        <RoleAccessRoute
                                            exact
                                            path="/listings"
                                            component={ListingList} 
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead',
                                                10: 'social_medias',
                                                12: 'sales'
                                            }} 
                                        />

                                        <RoleAccessRoute 
                                            path="/users/add"
                                            component={EditUser}
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead',
                                                10: 'social_medias',
                                                12: 'sales'
                                            }}
                                         />
                                        <RoleAccessRoute 
                                            path="/users/edit/:id(\d+)"
                                            component={EditUser}
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead',
                                                10: 'social_medias',
                                                12: 'sales'
                                            }}
                                        />

                                        <RoleAccessRoute 
                                            exact
                                            path="/users"
                                            component={ViewUsers}
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead',
                                                10: 'social_medias',
                                                12: 'sales'
                                            }}
                                        />

                                        
                                        <AdminRoute exact path="/releases" component={Releases} />
                                        <RoleAccessRoute exact path="/shootReports" component={TechManager} />
                                        <AdminRoute exact path="/mlsProvider" component={MLSProvider} />

                                        <RoleAccessRoute 
                                            exact
                                            path="/shooting"
                                            component={ShootingPlanner}
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead'
                                            }}
                                        />

                                        <RoleAccessRoute 
                                            exact
                                            path="/shootingdashboard"
                                            component={ShootingDashboard}
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead'
                                            }}
                                        />
                                        
                                        <RoleAccessRoute 
                                            exact
                                            path="/HmdList"
                                            component={HmdList}
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead',
                                                12: 'sales'
                                            }} 
                                         />
                                        <AdminRoute exact path="/badlistings" component={BadListings} />
                                        <AdminRoute exact path="/superlog" component={SuperLog} />
                                        <AdminRoute exact path="/SuperAnalytics" component={SuperAnalytics} />
                                        <AdminRoute exact path="/ListingStats" component={ListingStats} />
                                        
                                        <RoleAccessRoute 
                                            exact
                                            path="/ListingMlsMatch"
                                            component={ListingMlsMatch} 
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead'
                                            }} 
                                        />

                                        <AdminRoute exact path="/centris" component={CentrisReport} />
                                        <AdminRoute path="/centris/broker/:code/" component={CentrisBrokerListings} />
                                        <AdminRoute exact path="/zones" component={Zones} />
                                        <RoleAccessRoute exact path="/schedule" component={TechSchedule} />
                                        <RoleAccessRoute 
                                            exact
                                            path="/tech_review"
                                            component={Review} 
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead'
                                            }} 
                                        />
                                        <RoleAccessRoute 
                                            path="/tech_review/:listing_id"
                                            component={ListingReview}
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead'
                                            }} 
                                        />
                                        <AdminRoute exact path="/manage_services" component={ManageServices} />
                                        <AdminRoute exact path="/manage_shooting_packages" component={ManageShootingPackages} />
                                        
                                        <RoleAccessRoute 
                                            path="/video_dashboard"
                                            component={VideoDashboard}
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead'
                                            }} 
                                        />
                                        <RoleAccessRoute
                                            path="/facebook_page_manager"
                                            component={FacebookPageManager}
                                            allowed_roles={{
                                                1: 'superadmin',
                                                2: 'admin',
                                                4: 'tech_capture',
                                                5: 'tech_postprod',
                                                8: 'tech_postprod_lead'
                                            }} 
                                        />
                                    </main>
                                </div>
                            }

                            {!this.isDisplayingBeautyDelivery() && <div className={classes.footer}> <Typography variant="subheading" gutterBottom>© Zenit Concept 2009 - {year} </Typography></div>}
                        </div>

                    </Router>
                </IntlProvider>
            );
        }
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
