import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';

import * as moment from 'moment';
import 'moment/locale/fr-ca';

const styles = theme => ({
    root: {
        margin: theme.spacing.unit * 3
    },
    innerGrid:{
        margin: 0
    }
});

/**
 * Class used to view a single ticket
 */
class ViewTicket extends React.Component {

    constructor(props) {
        super(props);
        this.state = {is_reloading:false,add_followup:"",ticket:null, images:[]};
    }

    componentWillMount()
    {
        this.reloadTicket();
    }

    reloadTicket = () => 
    {  
        const { match } = this.props;
        if(match.params.id != "undefined")
        {
            //match.params.id
            window.Hauslife.getTicket(match.params.id)
            .then((res)=>{
                res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
                this.setState({ticket:res.data.data, is_reloading:false});
            })
            .catch((e)=>{
                console.log(e);
                this.setState({is_reloading:false});
            });
        }
    };

    handleAddFollowupClick = () => {
        this.setState({is_reloading:true},()=>{
            window.Hauslife.followupTicket(this.state.ticket.id, this.state.add_followup)
            .then((res)=>{
                this.setState({add_followup:""},()=>{
                    this.reloadTicket();
                });
            })
            .catch((e)=>{
                this.reloadTicket();
            });
        });
    };

    handleResolveTicket = () => {
        this.setState({is_reloading:true},()=>{
            window.Hauslife.resolveTicket(this.state.ticket.id)
            .then((res)=>{
                this.reloadTicket();
            })
            .catch((e)=>{
                this.reloadTicket();
            });
        });
    };

    handleReOpenTicket = () => {
        this.setState({is_reloading:true},()=>{
            window.Hauslife.reopenTicket(this.state.ticket.id)
            .then((res)=>{
                this.reloadTicket();
            })
            .catch((e)=>{
                this.reloadTicket();
            });
        });
    };

    getUrgencyString = (urgency) => {

        const { intl } = this.props;

        const problem_urgencies = [
            {label:intl.formatMessage({id:"addticket.problem.important"}),value:1},
            {label:intl.formatMessage({id:"addticket.problem.critical"}),value:2},
            {label:intl.formatMessage({id:"addticket.problem.urgent"}),value:3}
        ];

        for(var urgob of problem_urgencies)
        {
            if(urgob.value === urgency)
            {
                return urgob.label;
            }
        }
    };

    processDescription = (ticket, description) =>
    {
        if(ticket && ticket.data)
        {
            try
            {
                var dataJson = JSON.parse(ticket.data);

                var listing_id = dataJson.listing_id;
                var shooting_id = dataJson.shooting_id;
                var picture_index = dataJson.picture_index;

                let images = [];
                images.push("https://cdn.hauslifenetwork.com/"+listing_id+"/2D/"+listing_id+"_"+shooting_id+"_"+picture_index+"_thumb.jpg");
                this.setState({images:images});
            }
            catch(e)
            {
                console.error(e);
            }
        }
        
        return description;
    };

    render()
    {
        const { classes, match, intl } = this.props;



        return(
            <Grid container>
                {this.state.ticket === null ? 
                (
                    <PleaseWait />
                ) 
                : 
                (
                    <Grid container>
                        <Grid className={classes.innerGrid} xs={12}>
                            <div style={{display: "flex",marginBottom:15,justifyContent:"space-between"}}>
                                <LinkButton to="/tickets" variant="outlined">viewticket.back</LinkButton>
                                {this.state.ticket.resolved?(<ActivityButton isWorking={this.state.is_reloading} onClick={this.handleReOpenTicket} color="primary" variant="outlined">viewticket.btn.reopen</ActivityButton>):(<ActivityButton isWorking={this.state.is_reloading} onClick={this.handleResolveTicket} color="primary" variant="outlined">viewticket.btn.resolve</ActivityButton>)}
                            </div>
                            <Paper>
                                <Table className={classes.table}>
                                    <TableBody>
                                        <TableRow style={{backgroundColor:(this.state.ticket.urgency==1?"#fbfbfb":(this.state.ticket.urgency==2?yellow[50]:red[50]))}}>
                                            <TableCell><Typography variant="h3" component="h3">{(this.state.ticket.resolved?intl.formatMessage({id:"mytickets.ticket.closed"}):intl.formatMessage({id:"mytickets.ticket.open"})) + ": " + this.state.ticket.subject}</Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography align="right" className={classes.heading}>{moment(this.state.ticket.created_at).format("LL LTS")}</Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{whiteSpace:"pre-wrap"}}>
                                                <p>{this.state.ticket.description}</p>
                                                {this.state.images.map(image => {return(
                                                    <img style={{width:"100%",maxWidth:400}} src={image} />
                                                );})}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{this.getUrgencyString(this.state.ticket.urgency)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography align="right"><FormattedMessage id="viewticket.display_creator" values={{creator:this.state.ticket.creator.firstname + " " + this.state.ticket.creator.lastname}}/></Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid style={{marginTop:15}} className={classes.innerGrid} xs={12}>
                            <Typography variant="h3" component="h3"><FormattedMessage id="viewticket.followups" /></Typography>
                        </Grid>
                        <Grid className={classes.innerGrid} xs={12}>
                        {this.state.ticket.followups.map(followup => {
                            return (
                                <ExpansionPanel defaultExpanded={true}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Grid container justify="space-between">
                                            <Typography fullWidth align="left" className={classes.heading}><b>{followup.creator.firstname + " " + followup.creator.lastname}</b></Typography>
                                            <Typography fullWidth align="right" className={classes.heading}><b>{moment(followup.created_at).format("LL LTS")}</b></Typography>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Typography style={{whiteSpace:"pre-wrap"}}>
                                        {followup.message}
                                    </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            );
                        })}
                        </Grid>
                        <Grid className={classes.innerGrid} xs={12}>
                        {!this.state.ticket.resolved?(
                            <div>
                                <TextField
                                    variant="outlined"
                                    label={intl.formatMessage({id:"viewticket.add_followup"})}
                                    multiline
                                    fullWidth
                                    rows={5}
                                    value={this.state.add_followup}
                                    onChange={(event)=>{this.setState({add_followup:event.target.value})}}
                                    margin="normal"
                                />
                                <div style={{display: "flex",marginBottom:15,justifyContent:"flex-end"}}>
                                        <ActivityButton isWorking={this.state.is_reloading} onClick={this.handleAddFollowupClick} color="primary" variant="outlined">viewticket.btn.add_followup</ActivityButton>
                                </div>
                            </div>
                        ):(
                            <Paper style={{padding:30,backgroundColor:"#f1f1f1"}}>
                                <FormattedMessage id="viewticket.cannot_add_followup" />
                            </Paper>
                        )}

                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}

ViewTicket.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default injectIntl(withRouter(withStyles(styles)(ViewTicket)));
  