import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import ShootingPlanner from './ShootingPlanner';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';
import { resolveMx } from 'dns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './admin.css';
import ListingEdit from './ListingEdit';
import InputIcon from '@material-ui/icons/Input';
import SaveIcon from '@material-ui/icons/Save';
import ReactJson from 'react-json-view';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
 
import { VictoryBar, VictoryChart ,VictoryLabel,VictoryAxis,VictoryContainer} from 'victory';
 
import SuperLog from './SuperLog';
import { CollectionsBookmarkOutlined, ContactMailSharp, ContactsOutlined, LocalConvenienceStoreOutlined } from '@material-ui/icons';
import { ListItemSecondaryAction } from '@material-ui/core';

var _ = require('lodash');


const styles = theme => ({


    root: {
        width: '100%',
       // marginTop: theme.spacing.unit * 3,
        overflowX: 'clip',
       // padding:20,
        fontSize:23,
    },
    table: {
         minWidth: 700, 
       
    },
    tableRow: {
      cursor:'pointer',
      height:36
    },
    button: {
        margin: theme.spacing.unit,
    },
    'listing-list-cell':{
        whiteSpace:'nowrap'
    },


    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
      },
      
      card:{
          marginBottom:20
      },
      itemCard : {
        marginBottom:10,
        backgroundColor:'#eeeeee'
      } ,
      
});


class ListingMlsMatch extends React.Component {

    centrisBrokerFields = ['broker_fourth',  'broker_third' ,'broker_second' ,'broker_main'];


    constructor(props) {
        
        super(props);
        this.scrollHeight=0;
        this.state = {
            activeTab:'matched',
            error:false,
            loaded: false,
            dlgShow:false,
            listing:0,
            selectedListing:0,
            selectedMls:0,
            matched:[],
            unMatched:[],
            listingMlsNull:[],
            sort:{
                order:'req_date',
                sort:'desc'
            },
            selectedFirstDate : moment().subtract(1, 'month').format('YYYY-MM-DD'),    //  moment('2019-08-28').format('YYYY-MM-DD')
            selectedSecondDate :moment().format('YYYY-MM-DD'),  
        };
    }

    componentWillMount() {
       this.executeQuery( );
    }

    executeQuery (  ){

        window.Hauslife.getListingAutoMls( )
        .then((res) => {
           // console.log(res)
            var data =res.data.data;// JSON.parse(res.data.data);

            var matched=[];
            var unMatched=[];
 
            data.forEach( (value)=>{
                if(value.matched.length>0){
                    matched.push(value);
                } else {
                    unMatched.push(value);
                }
            });
            this.setState({matched, unMatched ,loaded:true});
            
        })
        .catch((e) => {
            this.setState({loaded:true,error:true,list:[]});
            console.log(e);
        });

        var from = moment().subtract(6, 'months').format('YYYY-MM-DD');
		var to = moment().format('YYYY-MM-DD');

        var textSearch = `mls:null du:${from} au:${to}`;

        window.Hauslife.getListingsList(textSearch)
        .then((res) => {
            this.setState({ listingMlsNull:res.data.listings });
        })
        .catch((e) => {
            console.log(e);
        });

    }

    alertClose = (button,data) => {
        if(button=='cancel'){
            this.setState({dlgShow: false});
            return;
        }
    }
    handleClick = (event, listing) => {
        if(this.state.selectedListing== listing.id){
            listing.mls=this.state.selectedMls;
        }
        this.setState({ dlgShow: true, listing: listing });
    }
    handleMlsChange  = (event, index) => {
        this.state.matched[index.idx].matched[index.lidx].mls = event.target.value;
        this.setState( {matched : this.state.matched})
    }

    handleListingNullMlsChange = (event, index) => {
        this.state.listingMlsNull[index].mls = event.target.value;
        this.setState( {listingMlsNull :  this.state.listingMlsNull});
    }

   handleMlsSave  = ( index) => event=>{
        var mls = this.state.matched[index.idx].matched[index.lidx].mls;
        var id = this.state.matched[index.idx].matched[index.lidx].id;

        if(mls==null || mls.length==0){
           return;
        }
        this.state.matched[index.idx].matched[index.lidx].green=true;
        this.setState( {matched : this.state.matched})
        var payLoad = {
            id,
            mls
        };
 
        window.Hauslife.adminUpdateListing(payLoad).then((res) => {
           
        }).catch((e) => {
            console.log(e);
        });
       
    }
    handleNullMlsSave  = ( index) => event=>{
       
        var mls = this.state.listingMlsNull[index].mls;
        var id = this.state.listingMlsNull[index].id;

        if(mls==null || mls.length==0){
           return;
        }

        var payLoad = {
            id,
            mls
        };

        window.Hauslife.adminUpdateListing(payLoad).then((res) => {
           
        }).catch((e) => {
            console.log(e);
        });
    }
    alertClose = (button, data) => {
            this.setState({ dlgShow: false });
    }

    handleTab = name => event =>{
        this.setState({activeTab:name });        
    }
    listingSelect  = (listing,mls,index) => event =>{
        this.state.matched[index.idx].matched[index.lidx].mls=mls;
        this.setState({selectedListing:listing,selectedMls:mls,matched : this.state.matched});
    }
    
    matchBroker (broker, row){
        var brokerFields = ["fourth_broker_code","third_broker_code","second_broker_code","main_broker_code"];
        var match=false;
        var result = row.matched.forEach ( (listing,idx)=>{
            var field = brokerFields[broker];
            if(listing.broker && listing.broker.broker_code==row.centris[field]){
                match=true;
            } 
        });
        return match;
    }

    render() {
        const { classes } = this.props;

        var centrisFields = [   'civic_number_start',  'appartement' , 'street',   'postalcode','description' ];
       
        var listingFields = ['address',  'app',  'postalcode','city',];
        var brokerFields = ['firstname','firstname','email'];

         return (  
            <Grid style={{   height: "100%",flexGrow:0, alignContent:"flex-start" }}  container>

            {this.state.dlgShow == true &&
                <ListingEdit style={{ width: '100%' }} show={this.state.dlgShow} didClose={this.alertClose} listing={this.state.listing}></ListingEdit>
            }

            {  this.state.loaded==true &&
             <>
                <div style={{display:'flex',flexDirection:'column',width:'100%'}}>   
                    
                    <div style={{fontSize:18,fontWeight:'bold',backgroundColor:'transparent',border:'0px solid whitesmoke',margin:2,textAlign:'center'}}>
                        MLS Match Making 
                    </div>

                    <div style={{display:'flex',marginLeft:0,marginRight:0,flexDirection:'row', padding:10,paddingBottom:0,border:'1px solid silver',borderBottom:0,borderRadius:5,borderBottomRightRadius:0 ,borderBottomLeftRadius:0,backgroundColor:' '}} > 

                        <div style={{marginRight:10,padding:10,backgroundColor:this.state.activeTab=='matched'?'gainsboro':'white',border:'1px solid black',borderBottom:0,borderRadius:'5px 5px 0px 0px',cursor:'pointer' }} onClick={this.handleTab('matched')}> Assoçiées </div>
                        <div style={{marginRight:10,padding:10,backgroundColor:this.state.activeTab=='unmatched'?'gainsboro':'white',border:'1px solid black',borderBottom:0,borderRadius:'5px 5px 0px 0px',cursor:'pointer'}}  onClick={this.handleTab('unmatched')}> Non Assoçiées</div>
                        <div style={{marginRight:10,padding:10,backgroundColor:this.state.activeTab=='mlsnull'?'gainsboro':'white',border:'1px solid black',borderBottom:0,borderRadius:'5px 5px 0px 0px',cursor:'pointer'}}  onClick={this.handleTab('mlsnull')}> Mls Null</div>

                    </div>
                        {this.state.activeTab=='matched' &&
                        <div style={{display:'flex',flexDirection:'column', border:'1px solid silver',borderTop:0,backgroundColor:'gainsboro'}}>
                            {this.state.matched.map( (row,idx)=>(
                                <div key={idx} style={{backgroundColor:' ',padding:5}}>

                                    <div style={{backgroundColor:idx %2?'white':'rgb(255, 255, 240)',marginBottom:10,padding:5,border:'1px solid dimgrey'}}>
                                            <div style={{border:'1px solid lightgray',marginBottom:5,padding:5,borderRadius:3}}>
                                                <div style={{display:'flex',flexDirection:'row',margin:0}}>  
                                                    <div style={{fontSize:15,}}>Mls <span style={{fontWeight:'bold'}}> {row.centris.mls}</span> </div>    
                                                </div>

                                                <div style={{display:'flex',flexDirection:'row',paddingLeft:32,paddingBottom:5,paddingTop:5}}>   

                                                <img src="/centris_icon-32x32.png" style={{width:16,height:16}} />
                                                { centrisFields.map( (fields,idx)=> ( 
                                                        <div key={'cr_'+idx} style={{marginLeft:5, }}>
                                                            {row.centris[fields]}         
                                                        </div>
                                                    ) )}
                                                </div>

                                                <div  style={{flex:1,display:'flex' ,flexDirection:'row',justifyContent:'flex-end', marginLeft:5}}>
                                                            {this.centrisBrokerFields .map ( (broker,idx) =>(
                                                                    <div key={ row.centris.mls+'_bf_'+idx} style={{marginRight:5,fontWeight:this.matchBroker(idx,row)==true?'bold':'' }}> {row.centris[broker] }</div>
                                                            ))}
                                                            <div style={{minWidth:138,marginRight:5}}> Date : {moment(row.centris.listing_date).format('YYYY-MM-DD')}</div>
                                                </div>
                                            </div>

                                        <div style={{border:'1px solid lightgray',borderRadius:3}}>
                                        {row.matched.map ( (listing,lidx)=>(
                                            <div key={listing.id+'-'+lidx}  style={{border:'',marginBottom:5,padding:5,backgroundColor:listing.green===true ?'lime':''}}>
                                                <div style={{display:'flex',flexDirection:'row',paddingLeft:32,paddingBottom:5 }}>

                                                    <img src="/favicon.ico" style={{width:16,height:16}} />
                                                    { listingFields.map( (fields,idx)=> ( 
                                                        <div key={ listing.id+'_'+idx} style={{marginLeft:5, }}>
                                                            {listing[fields]}      
                                                        </div>
                                                    ) )}
                                                    <div style={{marginLeft:4,cursor:'pointer',textDecoration: 'underline'}} onClick={event => this.handleClick(event, listing)}> Listing ID : {listing.id}</div>
                                                </div>

                                                <div  style={{flex:1,display:'flex' ,flexDirection:'row',justifyContent:'flex-end', marginLeft:5}}>
                                                    <InputIcon style={{marginLeft:48,marginRight:10,cursor:'pointer'}} onClick={this.listingSelect(listing.id,row.centris.mls,{idx,lidx})} />
                                                    <input style={{marginLeft:1,marginRight:10}}
                                                                value={listing.mls}
                                                                onChange={(event) => this.handleMlsChange( event,{idx,lidx})}
                                                                type="number"
                                                                margin="normal"
                                                            />
                                                    {listing.mls!=null && listing.mls.length>1 &&
                                                        <SaveIcon style={ {marginLeft:0,cursor:'pointer'}} onClick={this.handleMlsSave({idx,lidx})} />
                                                    }
                                                    <div style={{marginRight:'auto'}}> </div>

                                                    <div style={{marginRight:5,fontWeight:'bold'}}> {listing.broker.firstname } {listing.broker.lastname }</div>
                                                    <div style={{minWidth:138,marginRight:5}}> Date : {moment(listing.shootingdate).format('YYYY-MM-DD')}</div>
                                                    
                                            </div>
                                        </div>
                                        ))}
                                            
                                    </div>
                                    </div>
                                </div> 
                            ) )}
                        </div>
                        }

                        {this.state.activeTab=='unmatched' &&
                        <div style={{display:'flex',flexDirection:'column',border:'1px solid silver',borderTop:0,backgroundColor:'gainsboro'}}>
                            {this.state.unMatched.map( (row,idx)=>(
                            <div key={idx} style={{backgroundColor:'',padding:5}}>

                                <div style={{backgroundColor:idx %2?'white':'rgb(255, 255, 240)',marginBottom:5,padding:5,border:'1px solid dimgrey'}}>
                                            <div style={{border:'1px solid lightgray',marginBottom:5,padding:5,borderRadius:3}}>
                                                <div style={{display:'flex',flexDirection:'row',margin:0}}>  
                                                    <div style={{fontSize:15,}}>Mls <span style={{fontWeight:'bold'}}> {row.centris.mls}</span> </div>    
                                                </div>

                                                <div style={{display:'flex',flexDirection:'row',paddingLeft:32,paddingBottom:5,paddingTop:5}}>   

                                                <img src="/centris_icon-32x32.png" style={{width:16,height:16}} />
                                                { centrisFields.map( (fields,idx)=> ( 
                                                        <div key={'cr_'+idx} style={{marginLeft:5, }}>
                                                            {row.centris[fields]}         
                                                        </div>
                                                    ) )}
                                                </div>
                                                <div  style={{flex:1,display:'flex' ,flexDirection:'row',justifyContent:'flex-end', marginLeft:5}}>
                                                            {this.centrisBrokerFields .map ( (broker,idx) =>(
                                                                    <div key={ row.centris.mls+'_bf_'+idx} style={{marginRight:5,fontWeight:this.matchBroker(idx,row)==true?'bold':'' }}> {row.centris[broker] }</div>
                                                            ))}
                                                            <div style={{minWidth:138,marginRight:5}}> Date : {moment(row.centris.listing_date).format('YYYY-MM-DD')}</div>
                                                </div>
                                            </div>
                                    </div>
                            </div>
                             ) )}
                        </div>
                        }
                        {this.state.activeTab=='mlsnull' &&
                        <div style={{display:'flex',flexDirection:'column', border:'1px solid silver',borderTop:0,backgroundColor:'gainsboro'}}>


                            <div style={{border:'1px solid dimgrey',borderTop:'0px',borderRadius:3}}>
                                        {this.state.listingMlsNull.map ( (listing,lidx)=>(

                                        <div style={{marginBottom:5,padding:5, }}>
                                           <div style={{backgroundColor:lidx %2?'white':'rgb(255, 255, 240)',border:'1px solid dimgrey',marginBottom:5,padding:5,borderRadius:3}}> 
                                            <div style={{border:'',marginBottom:5,padding:5,}}>
                                                <div key={listing.id+'-'+lidx} style={{display:'flex',flexDirection:'row',paddingLeft:0,paddingBottom:5 }}>

                                                    <img src="/favicon.ico" style={{width:16,height:16}} />
                                                    <div style={{marginLeft:4,cursor:'pointer',textDecoration: 'underline'}} onClick={event => this.handleClick(event, listing)}> Listing ID : {listing.id}</div>
                                                    { listingFields.map( (fields,idx)=> ( 
                                                        <div key={ listing.id+'_'+idx} style={{marginLeft:5, }}>
                                                            {listing[fields]}      
                                                        </div>
                                                    ) )}
                                                </div>

                                                <div  style={{flex:1,display:'flex' ,flexDirection:'row',justifyContent:'flex-end', marginLeft:5}}>
                                                    <div style={{marginLeft:15 ,marginRight:'5'}}>Mls : </div>
                                                    <input style={{marginLeft:1,marginRight:10}}
                                                                value={listing.mls}
                                                                onChange={(event) => this.handleListingNullMlsChange( event,lidx)}
                                                                type="number"
                                                                margin="normal"
                                                            />
                                                    {listing.mls!=null && listing.mls.length>1 &&
                                                        <SaveIcon style={ {marginLeft:0,cursor:'pointer'}} onClick={this.handleNullMlsSave(lidx)} />
                                                    }
                                                    <div style={{marginRight:'auto'}}> </div>
                                                    <div style={{marginRight:5,fontWeight:'bold'}}> {listing.broker.firstname } {listing.broker.lastname }</div>
                                                    <div style={{minWidth:138,marginRight:5}}> Date : {moment(listing.shootingdate).format('YYYY-MM-DD')}</div>
                                            </div>
                                          </div>
                                         </div>
                                        </div>
                                        ))}
                                            
                                    </div>




                        </div>
                        }
                </div> 
            </>
            }
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(ListingMlsMatch));

