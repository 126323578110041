import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import ShootingPlanner from './ShootingPlanner';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';
import { resolveMx } from 'dns';

var _ = require('lodash');

const styles = theme => ({
    root: {
        width: '100%',
       // marginTop: theme.spacing.unit * 3,
        overflowX: 'clip',
       // padding:20,
    },
    table: {
        minWidth: 700,
    },
    tableRow: {
      cursor:'pointer'
    },
    button: {
        margin: theme.spacing.unit,
    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
      },
      
      card:{
          marginBottom:20
      },
      itemCard : {
        marginBottom:10,
        backgroundColor:'#eeeeee'
      } ,
      
});

class TechDashboard extends React.Component {
    constructor(props) {
        super(props);

       
        this.state = {
             shootings:[],
             tomorrow:false,
             loading:false
        };
    }

    componentDidMount() { 
        this.reloadShootings();
    }

    reloadShootings = () =>
    {
        this.setState({loading:true},()=>{
            window.Hauslife.techDailyShootings(this.state.tomorrow ? moment().add(1,"day").format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
            .then((res)=> {
                this.setState({loading:false, shootings:_.sortBy(res.data.data, function(o) { return new moment(o.scheduleddate); })});
            }).catch((e) => {
                console.log('Some error : ',e);
                this.setState({loading:false});
            });
        });
    }

    renderShootingCard = (item) =>
    {
        let shootingAddress = "";
                    
        if(item.listing.app !== null && item.listing.app !== ""){
            shootingAddress = item.listing.address + ', #' + item.listing.app  + ', ' + item.listing.city + ' ' + item.listing.postalcode
        }
        else{
            shootingAddress = item.listing.address + ', ' + item.listing.city + ', ' + item.listing.postalcode
        }

        let banner = (item.listing.broker.franchise_id!=null?item.listing.broker.franchise.name:null);

        if(this.state.loading)
        {
            return (
                <PleaseWait />
            );
        }

        let endTime = moment(item.scheduleddate);
        endTime.add(item.estimated_duration, "seconds");
        endTime = moment(endTime).format('HH:mm');

        return (
            <Card 
                style={{marginBottom:10,backgroundColor:"#ededed"}}
                key={item.id}                                          
            >
                <CardContent>
                    <Typography variant="subtitle1" style={{ display:'flex',flexDirection:'row',justifyContent:'flex-end' }}>
                        {item.reshoot==true &&
                            <span style={{color:'#3f51b5',fontWeight:'bold'}}>Reshoot ({item.reshootIndex})</span>    
                        }
                    </Typography>
                    <Typography variant="subtitle1" style={{ display:'flex',flexDirection:'row',justifyContent:'flex-end' }}>
                        {item.seasonal==true &&
                            <span style={{color:'#3f51b5',fontWeight:'bold'}}>Saisonnière</span>    
                        }
                        {item.blue_hour==true &&
                            <span style={{color:'#3f51b5',fontWeight:'bold'}}>Heure Bleue</span>    
                        }
                    </Typography>                                                         
                        <Typography variant="h8" component="h8">
                            {item.only360 ? "360 Seulement" : "2D + 360"}
                        </Typography>
                        <Typography variant="h6" component="h6">
                            {shootingAddress}
                        </Typography>   
                        <Typography style={{fontWeight:"bold"}}>
                            {"Broker : " + item.listing.broker.firstname + " " + item.listing.broker.lastname}
                        </Typography>     
                        {  banner!=null && 
                                <Typography component="p">
                                    {banner}
                                </Typography>                                                                     
                        }                                                          
                        {item.note ?
                            (
                                <Typography component="p">
                                    {item.note}
                                </Typography>
                            ) : null
                        }
                                        
                    <Typography component="p">
                        {moment(item.scheduleddate).format('HH:mm') + " - " + endTime}
                    </Typography>
                    <Typography component="p">
                        {item.listing.room_count} pièces
                    </Typography>
                    <Typography component="p">
                        {item.distance} KM
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    setDateToToday = () =>
    {
        this.setState({tomorrow:false},()=>{
            this.reloadShootings();
        });
    }

    setDateToTomorrow = () =>
    {
        this.setState({tomorrow:true},()=>{
            this.reloadShootings();
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper style={{padding:15}} className={classes.root}>
                <div style={{marginBottom:10}}>
                    <h2>{window.Hauslife.userObject.firstname + " " + window.Hauslife.userObject.lastname}</h2>
                    <Button onClick={this.setDateToToday}>Aujourd'hui</Button>
                    <Button onClick={this.setDateToTomorrow}>Demain</Button>
                </div>
                
                {this.state.tomorrow && <p style={{fontWeight:"bold", marginBottom:10}}>Les captures du lendemain sont sujettes à changement.</p>}


                {this.state.shootings.map((shooting)=>this.renderShootingCard(shooting))}
            </Paper>
        );

    }
}


export default withRouter(withStyles(styles)(TechDashboard));