import React from "react";
import PropTypes from "prop-types";
import CardContent from "./CardContent";

const CardsDragPreview = ({card} ) => {
  return (
    <div>
        <div
          key={card.id}
          className="card card-dragged"
        >
          {<img src={card.url}
                style={{
                  cursor: "pointer",
                  maxWidth: '400px',
                  height: 'auto',}}
                draggable={false}
          />}
        </div>
     )
    </div>
  );
};

CardsDragPreview.propTypes = {
  cards: PropTypes.array
};

export default CardsDragPreview;
