import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';

import Link from '@material-ui/core/Link';
import { throws } from 'assert';
 
import './admin.css';


function Label(props){

    return (  <div style={{}}>
                <span style={{fontWeight:'bold'}}>{ props.title} </span> {props.value} 
             </div>
        )
}
class CentrisBroker extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.show,
      confirm:this.props.confirm,
      search:'',
      data:{},
      list:[],
      changed:false
    };
  }

  componentWillMount() {
    window.Hauslife.getCentrisbroker(this.props.brokerCode)
    .then((res) => {
        //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
        console.log('data ' ,res.data.data)
        this.setState({ list:[],data: res.data.data,loaded:true });
        //  this.setState({ticket:res.data.data, is_reloading:false});
    })
    .catch((e) => {
        console.log(e);
    });

    /* window.Hauslife.adminCentrisListingForBroker(this.props.brokerCode)
    .then((res) => {
        //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
        console.log('lisiting data data ' ,res.data);
        //this.setState({ list:res.data.data });
        //  this.setState({ticket:res.data.data, is_reloading:false});
    })
    .catch((e) => {
        console.log(e);
    }); */
    


  }

  handleClose = (button,data) => () =>{
    this.props.didClose(button,data);
     
  };

 

  handleChange  = (name,event) => {
    
    //this.setState({changed:true, hmd :{...this.state.hmd, notes:event.target.value }});

  }

  handleClick = (user) =>{
   // this.setState({changed:true,hmd :{...this.state.hmd, user_id:user.id, user:user }});
  }

   
  componentWillReceiveProps({someProp}) {
    this.setState({...this.state,someProp})
  }




  render() {
  

    return (
      <div>
        
        <Dialog
        fullWidth={true}
         disableBackdropClick
         disableEscapeKeyDown
          open={this.props.show}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
 

                        <Paper className={" "} style={{}}>

                        <Paper className={""} style={{padding:5}}>   
                           

                            <Label title="Code :" value={this.state.data.code} />
                            <Label title="Nom :" value={this.state.data.firstname + ' ' + this.state.data.lastname } />
                            <Label title="email :" value={this.state.data.email} />

                            <Label title="fax :" value={this.state.data.fax} />
                            <Label title="language :" value={this.state.data.language} />
                            <Label title="main_phone :" value={this.state.data.main_phone} />
                            
                            <Label title="societe :" value={this.state.data.societe} />
                            <Label title="societe_description_fr :" value={this.state.data.societe_description_fr} />

                            <div style={{}}>
                                <span style={{fontWeight:'bold'}}> Website  </span>  <a style={{color:'blue'}} href={this.state.data.website}>{this.state.data.website} </a>
                            </div>

                         </Paper>
                            <Table  >
                                    
                                <TableBody>
                                    {this.state.list.map(row => (
                                        <TableRow  style={{cursor:'pointer' }}key={row.id} onClick={event => this.handleClick(row)}>
                                            <TableCell className="listing-list-cell">{row.firstname + ' '+ row.lastname}</TableCell>
                                            
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </Paper>


                 
                        
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            
            <Button onClick={this.handleClose('ok',this.props.data)} color="primary" autoFocus>
              Ok
            </Button>
            
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default  CentrisBroker;
