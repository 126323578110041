import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PleaseWait from './PleaseWait.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CentrisBroker from './CentrisBroker';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import InputIcon from '@material-ui/icons/Input';
import {SortIcon} from './SuperLog';
import './admin.css';
import * as moment from 'moment';
//import { VictoryBar, VictoryChart ,VictoryLine,VictoryAxis} from 'victory';
function Label(props){

    return (  <div style={{}}>
                <span style={{fontWeight:'bold'}}>{ props.title} </span> {props.value} 
             </div>
        )
}
class CentrisBrokerListings extends React.Component {
    constructor(props) {
        super(props);
        this.scrollHeight=0;

        this.state = {
            error:false,
            loaded: false,
            dlgShow:false,
            brokerInfo:{},
            redirect:false,
            sort:{
                order:'count',
                sort:'desc'
            }
        };

    }
    componentWillMount() {
        console.log('props',this.props.match.params.code)
        this.queryData(this.props.match.params.code);

    }
    queryData(code ){
        window.Hauslife.getCentrisbroker(code)
            .then((res) => {
                  console.log('data ' ,res.data.data)
                this.setState({ brokerInfo: res.data.data  },()=>{
                    window.Hauslife.adminCentrisListingForBroker(code)
                        .then((res) => {
                            //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
                           // console.log('lisiting data data ' ,res.data.result);
                            this.setState({ list:res.data.result,loaded:true});
                            
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                });

            })
            .catch((e) => {
                console.log(e);
            });
    }

    alertClose = (button,data) => {
        this.setState({dlgShow: false,alertShow:false});
    }
    
    sortData (name,dir) {

      //  console.log('sortData',name,dir);

        var type='str';
        switch(name){
            case 'mls':
            
                type='int';
        }

        function compareAsc(a, b) {

            var aValue = a[name];
            var bValue = b[name];

            if(type=='str'){
                aValue=aValue.toUpperCase();
                bValue=bValue.toUpperCase();
            }
            if(aValue<bValue)
                return -1;
            if(aValue>bValue)
                return 1;
          
            if(aValue==bValue)
                return 0;
          }

          function compareDesc(a, b) {
            var aValue = a[name];
            var bValue = b[name];

            if(type=='str'){
                aValue=aValue.toUpperCase();
                bValue=bValue.toUpperCase();
            }

            if(aValue>bValue)
                return -1;
            if(aValue<bValue)
                return 1;
            
            if(aValue==bValue)
                return 0;
            }

            if(dir=='asc'){
                var data = this.state.data.sort( compareAsc);
            } else {
                var data = this.state.data.sort( compareDesc);
            }
            this.setState({data:data});
    }
    get = (p, o) => {
        p=p.split('.');
        var x= p.reduce((xs, x) =>{
            return ((xs && xs[x]) ? xs[x] : null);
        },o)
        return x;
     }
    sortColumn = (name)=>{
        if(this.state.sort.order==name){
            var dir = this.state.sort.sort=='desc'?'asc':'desc';
            this.setState({sort:{order:this.state.sort.order,sort: dir }},function(err,result){
             //   this.executeQuery ( );
             this.sortData(name,dir);
            });
        } else {
            this.setState({sort:{order:name,sort:'asc' } },function(err,result){
                this.sortData ( name,'asc');
            });
            //console.log({sort:{order:name,sort:'asc' }})
        }
    }

    clickOtherBroker =(code)=>{
       this.queryData(code);
    }

    otherBrokers =( row)=>{
        return ['main_broker','second_broker','third_broker','fourth_broker']
                .filter( (i=>row[i]!=undefined&&row[i].code!=this.state.brokerInfo.code))
                .map( i=>(
                    <ListItem style={{cursor:'pointer',color:'black'}} to={`/centris/broker/${row[i].code}/`} component={Link} onClick={()=>this.clickOtherBroker(row[i].code )}>
                        {row[i].firstname}  {row[i].lastname}
                    </ListItem>
                ));
    }

    render(){

        return (
                <div style={{backgroundColor:'',padding:5}}>  
                {this.state.redirect!=false &&
                    <Redirect push to={`/centris/broker/${this.state.redirect}/`} />
                }
                    {this.state.dlgShow==true &&
                        <CentrisBroker style={{width:'100%'}} show={this.state.dlgShow} didClose={this.alertClose}   brokerCode ={this.state.selectedBroker}/>
                    }

                    { this.state.loaded==false &&
                        <PleaseWait />
                    }
                    <span style={{display:"table",margin:'auto',fontSize:30,marginTop:5,fontWeight:'bold' }}>{this.props.title}</span>
                    
                        { this.state.loaded==true &&
                            <>
                                <Paper style={{margin:5,padding:15,textAlign:'center'}}>Listing CENTRIS pour <span style={{fontWeight:'bold'}}> {this.state.brokerInfo.firstname +' ' + this.state.brokerInfo.lastname } </span> </Paper>
                                <Paper className={""} style={{margin:5,padding:5}}>   
                                        <Label title="Code :" value={this.state.brokerInfo.code} />
                                        <Label title="Franchise :" value={this.get('state.brokerInfo.office.franchise.legal_name',this)} />
                                        <Label title="Bannière :" value={this.get('state.brokerInfo.office.franchise.banner.french_name',this)} />
                                        <Label title="email :" value={this.state.brokerInfo.email} />
                                        <Label title="fax :" value={this.state.brokerInfo.fax} />
                                        <Label title="language :" value={this.state.brokerInfo.language} />
                                        <Label title="main_phone :" value={this.state.brokerInfo.main_phone} />
                                        <Label title="societe :" value={this.state.brokerInfo.societe} />
                                        <Label title="societe_description_fr :" value={this.state.brokerInfo.societe_description_fr} />
                                <div style={{}}>
                                    <span style={{fontWeight:'bold'}}> Website  </span>  <a style={{color:'blue'}} href={this.state.brokerInfo.website}>{this.state.brokerInfo.website} </a>
                                </div>
                            </Paper>

                              <Table style={{margin:5}} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('mls')} >  <SortIcon label="Mls" sort={this.state.sort} column="mls"/>  </TableCell>
                                        <TableCell className="listing-list-cell"   ><SortIcon label="Adresse"  column="void"/></TableCell>
                                        <TableCell className="listing-list-cell" > <SortIcon label="Autres brokers"  /></TableCell>
                                        <TableCell className="listing-list-cell" > <SortIcon label="Lien"  /></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.list.map(row => (
                                        <TableRow className={{}} style={{backgroundColor:''}} key={row.id} >
                                            <TableCell className="listing-list-cell">{row.mls}</TableCell>
                                            <TableCell className="listing-list-cell" style={{whiteSpace:'nowrap',backgroundColor:'rgb(249, 247, 247'}}> 
                                                                                                                    <ul className="no-bullet"> 
                                                                                                                        <li>{row.civic_number_start } {row.street }</li> 
                                                                                                                        <li>{row.appartement}</li>
                                                                                                                        <li>{row.municipality.description}</li>
                                                                                                                        <li>{row.postalcode}</li>
                                                                                                                    </ul>
                                            </TableCell>
                                            <TableCell className="listing-list-cell" style={{whiteSpace:'nowrap',backgroundColor:'rgb(249, 247, 247'}}> 
                                                                                                                    <List > 
                                                                                                                        {this.otherBrokers(row)}
                                                                                                                    </List>
                                            </TableCell>
                                            <TableCell className="listing-list-cell"><a href={row.url_description} style={{color:'black'}} target="_blank"> {row.url_description}</a></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </>
                        }
                   
                </div>

        );
    }

}

 
export default withRouter( CentrisBrokerListings);