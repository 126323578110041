import React, { useState, useEffect, useRef, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { withRouter, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import { NewAvailabilityDialog, RemoveAvailabilityDialog, NewUnavailabilityDialog, RemoveUnAvailabilityDialog, DisplayShootingsDialog } from './components/Dialogs'

// import FilterHelper from './components/FilterHelper'
import EventCalendar from './components/EventCalendar'
import moment from 'moment';
import _, { filter } from 'lodash';
import { seconds } from 'react-big-calendar/lib/utils/dates';
import { Button } from '@material-ui/core';

const styles = {
    root: {
    	width: '100%',
    	// display: 'flex',
    	// flexDirection: 'column',
    	// justifyContent: 'flex-start',
    	// alignItems: 'space-between',
    	marginTop: '10px'
    },
    dialog: {
        flex: 1,
        width: '50%'
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        width: '33%',
        margin: '10px'
    }
};

// fetches all tech's schedule and maps to events object which is fed in react-big-calendar
const fetchUsersCalendar = (_startDate, _endDate) => (
    window.Hauslife
        .getAllUsersCalendarForMonth(_startDate, _endDate)
)

const fetchCalendarForTech = (userId, _startDate, _endDate) => (
    window.Hauslife
        .getUserScheduleForTimeperiod(userId, _startDate, _endDate)
)

const createEventsObjForMonthView = (data) => {
    const {availabilities = [], unAvailabilities = [], shootings = []} = data
    let available_events = availabilities.map(value => ({
        start: moment(value.date).add(23, 'hours').toDate(),
        end: moment(value.date).add(23, 'hours').toDate(),
        allDay: false,
        type: 'availabilities',
        availability: value
    }))
    
    let unavailable_events = unAvailabilities.map(unavail => ({
        id: unavail.id,
        start: moment(unavail.start).toDate(),
        end: moment(unavail.end).toDate(),
        type: 'unavailability',
        tech: unavail.user,
        summary: unavail.summary
    }))
    
    let shooting_events = _.map(
        shootings.reduce((accumulator, current) => {
            let todayDate = moment(current.scheduleddate).format('L')
            if(!accumulator.hasOwnProperty(todayDate)) {
                accumulator[todayDate] = []
            }
            accumulator[todayDate].push(current)
            return accumulator
        }, {}),
        (value, date) => {
            return {
                start: moment(date).toDate(),
                end: moment(date).add(value.estimated_duration, 'seconds').toDate(),
                type: 'shooting',
                techs: value.length
            }
        }
    )

    return [].concat(available_events, unavailable_events, shooting_events)
}

const createEventsObjForWeekView = (data) => {
    const {availabilities = [], unAvailabilities = [], shootings = []} = data

    return [].concat(
        availabilities.map(unavail => ({
            start: moment(unavail.date).add(12, 'hours').toDate(),
            end: moment(unavail.date).add(12, 'hours').toDate(),
            allDay: true,
            type: 'availabilities',
            tech: unavail.user
        })),
        unAvailabilities.map(unavail => ({
            id: unavail.id,
            start: moment(unavail.start).toDate(),
            end: moment(unavail.end).toDate(),
            type: 'unavailability',
            tech: unavail.user,
            summary: unavail.summary
        })),
        shootings.map(shoot => ({
            start: moment(shoot.scheduleddate).toDate(),
            end: moment(shoot.scheduleddate).add(shoot.estimated_duration, 'seconds').toDate(),
            type: 'shooting',
            tech: shoot.tech,
            address: `${shoot.listing.app ? shoot.listing.app+', ' : ''} ${shoot.listing.address}, ${shoot.listing.city}, ${shoot.listing.postalcode}`,
            shoot: shoot
        }))
    )
}

const isAdmin = role => {
    if(role == 'admin' || role == 'superadmin') {
        return true
    }
    return false
}

const TechSchedule = (props) => {
    let { role } = props

    const [currentView, setCurrentView] = useState('month')
    const [currentDate, setCurrentDate] = useState(moment().toDate())
    const [currentMonth, setCurrentMonth] = useState(moment().month())
    const [schedule, setSchedule] = useState({})
    const [events, setEvents] = useState([])
    const [whichDialog, setWhichDialog] = useState(null)
    const [availabilityToBeDeleted, setAvailabilityToBeDeleted] = useState(null)
    const [unavailabilityToBeDeleted, setUnavailabilityToBeDeleted] = useState(null)
    const [filteredTech, setFilteredTech] = useState(null)

    const [allTechs, setAllTechs] = useState([])

    // runs on load
    useEffect(() => {
        if(isAdmin(role)) {
            window.Hauslife.getPhotographers().then(techs => {
                setAllTechs(techs.users)
            })
        }
    }, [])

    // runs on load & every month change
    useEffect(() => {
        let _startDate = moment(currentDate).startOf('month').subtract(7, 'days').format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        let _endDate = moment(currentDate).endOf('month').add(7, 'days').format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        if(isAdmin(role)) {
            fetchUsersCalendar(_startDate, _endDate)
                .then(data => {
                    setSchedule(data)
                })
        } else {
            fetchCalendarForTech(window.Hauslife.userObject.id, _startDate, _endDate)
                .then(data => {
                    setSchedule(data)
                })
        }
    }, [currentMonth])

    // runs every time schedule or view changes
    useEffect(() => {
        if(currentView == 'month') {
            setEvents(createEventsObjForMonthView(schedule, filterByTech))
        } else {
            // for now agenda view
            setEvents(createEventsObjForWeekView(schedule, filteredTech))
        }
    }, [schedule, currentView])

    const onNavigate = (date) => {
        if(moment(date).month() !== currentMonth) {
            setCurrentMonth(moment(date).month())
        }
        setCurrentDate(date)
    }

    const onViewChange = (view) => {
        setCurrentView(view)
    }

    const onDateChange = (date) => {
        if(moment(date).month() !== currentMonth) {
            setCurrentMonth(moment(date).month())
        }
        setCurrentDate(moment(date).toDate())
    }

    const onCreate = (slots) => {

        if(slots.length < 1) {
            return;
            // stay safe
        }
        let start = moment(slots[0])
        let end;

        // there is a bug in the react-big-calendar (or the way i have used it)
        if(start.get('hour') == 7) {
            // if we start with initial time, the end time needs to be increased by 30 minutes
            end = moment(slots[slots.length - 1]).add(30, 'minute')
        } else {
            end = moment(slots[slots.length - 1])
        }

        if(currentView == 'month') {
            start = start.toDate()
            end = end.endOf('day').toDate()

            setEvents(events => events.concat({
                start: start,
                end: end,
                allDay: true,
                type: 'new-availability'    
            }))

            setWhichDialog('new-availability')
        } else {
            start = start.second(0).toDate()
            end = end.second(0).toDate()

            setEvents(events => events.concat({
                start: start,
                end: end,
                type: 'new-unavailability'    
            }))

            setWhichDialog('new-unavailability')
        }
    }

    const handleDialogClose = () => {
        if(whichDialog == 'new-availability') {
            setEvents(events => events.filter(event => event.type != 'new-availability'))
        } else if(whichDialog == 'remove-availability') {
            setAvailabilityToBeDeleted(null)
        } else if(whichDialog == 'new-unavailability') {
            setEvents(events => events.filter(event => event.type != 'new-unavailability'))
        }
        setWhichDialog(null)
    }

    const saveNewAvailability = (techs) => {
        let availability = events.find(event => event.type == 'new-availability');
        // let tech = allTechs.find(t => t.id == techId)

        if(availability == undefined) {
            return
        }

        let dates = [],
            start = moment(availability.start),
            end   = moment(availability.end)
        

        while(start <= end) {
            dates.push(moment(start).format("YYYY-MM-DD"))
            start.add(1, 'days')
        }

        let techIds = techs.map(t => t.id)

        window.Hauslife.addAvailabilityForTechs(techIds, dates).then( res => {
            let newAvailabilities = res.data.availabilities.map(avail => {
                // add tech details
                return {
                    ...avail,
                    user: {
                        id: avail.user_id,
                        firstname: techs.find(t => t.id == avail.user_id)['firstname'],
                        lastname: techs.find(t => t.id == avail.user_id)['lastname']
                    }
                }
            })

            setSchedule(schedule => {
                return {
                    ...schedule,
                    availabilities: [
                        ...schedule.availabilities,
                        ...newAvailabilities
                    ]
                }
            })
        })

        setWhichDialog(null)
    }

    const saveNewUnavailability = (newUnavailability) => {
        setSchedule(schedule => ({
            ...schedule, 
            unAvailabilities: [
                ...schedule.unAvailabilities,
                newUnavailability
            ]
        }))
        setWhichDialog(null)
    }

    const onAvailabilityClick = (event) => {
        // window.Hauslife.removeAvailability()
        setAvailabilityToBeDeleted(event)
        setWhichDialog('remove-availability')
    }

    const onUnavailabilityClick = event => {
        setUnavailabilityToBeDeleted(event)
        setWhichDialog('remove-unavailability')
    }

    const [shootingsToDisplay, setShootingsToDisplay] = useState([])
    const onShootingClick = event => {
        let shoots;
        if(currentView != 'month') {
            // single shoot clicked
            shoots = [event.shoot]
        } else {
            shoots = schedule.shootings.filter(shoot => (
                moment(shoot.scheduleddate).isSame(moment(event.start), 'date')
            ))
        }

        setWhichDialog('display-shootings')
        setShootingsToDisplay(shoots)
    }

    const handleAvailabilityDelete = (event, techId) => {
        let availabilities = schedule.availabilities.filter(a => 
            !(moment(a.date).isSame(event.start, 'day') &&
            a.user_id == techId)
        )

        setSchedule(schedule => {
            return {
                ...schedule,
                availabilities: availabilities
            }
        })
    }

    const handleUnavailabilityDelete = (event) => {
        setSchedule(schedule => ({
            ...schedule,
            unAvailabilities: schedule.unAvailabilities.filter(unavail => unavail.id != event.id)
        }))
    }

    const filterByTech = techId => {
        setFilteredTech(allTechs.find(tech => tech.id == techId))
    }

    const filteredEvents = (events) => {
        if(filteredTech == null) {
            return events
        }
        
        let filteredSchedule = {
            ...schedule,
            availabilities: schedule.availabilities.filter(a => a.user_id == filteredTech.id),
            shootings: schedule.shootings.filter(s => s.tech_id == filteredTech.id),
            unAvailabilities: schedule.unAvailabilities.filter(u => u.user_id == filteredTech.id)
        }

        if(currentView == 'month') {
            return createEventsObjForMonthView(filteredSchedule)
        } else {
            // for now agenda view
            return createEventsObjForWeekView(filteredSchedule)
        }

    }

    return (
        <div style={styles.root}>
            {
                isAdmin(role)
                    ?   <div style={styles.header}>
                            <h4>Filter By Tech</h4>
                            <FormControl style={{flex: 1, flexDirection: 'row', width: '100%'}}>
                                <Select
                                    style={{flex: 1, marginRight: '10px'}}
                                    value={filteredTech}
                                    renderValue={value => {
                                        return value && value.id && `${value.firstname} ${value.lastname}` || ''
                                    }}
                                    onChange={e => filterByTech(e.target.value)}
                                >
                                    {
                                        allTechs.map(tech => <MenuItem key={tech.id} value={tech.id}>{`${tech.firstname} ${tech.lastname}`}</MenuItem>)
                                    }
                                </Select>
                                <Button style={{flex: 1}} variant="contained" color="secondary" onClick={() => setFilteredTech(null)}>Reset</Button>
                            </FormControl>
                        </div>
                    :   null
            }
            <div>
                <EventCalendar 
                    events={filteredEvents(events)} 
                    onNavigate={onNavigate} 
                    currentView={currentView} 
                    onViewChange={onViewChange} 
                    currentDate={currentDate} 
                    onDateChange={onDateChange}
                    onCreate={onCreate} 
                    onAvailabilityClick={onAvailabilityClick}
                    onUnavailabilityClick={onUnavailabilityClick}
                    onShootingClick={onShootingClick}
                    isAdmin={isAdmin(role)}
                />
            </div>
            <Dialog open={whichDialog != null} onClose={handleDialogClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                {
                    whichDialog == 'new-availability'
                        ? <NewAvailabilityDialog 
                            techs={allTechs}
                            handleCancel={handleDialogClose}
                            handleSave={saveNewAvailability}
                            event={events.find(event => event.type == 'new-availability')}
                            isAdmin={isAdmin(role)}
                        />
                        : whichDialog == 'remove-availability'
                            ? <RemoveAvailabilityDialog 
                                event={availabilityToBeDeleted}
                                onCancel={handleDialogClose}
                                onDelete={handleAvailabilityDelete}
                                isAdmin={isAdmin(role)}
                                />
                            : whichDialog == 'new-unavailability'
                                ?   <NewUnavailabilityDialog 
                                        techs={allTechs}
                                        handleCancel={handleDialogClose}
                                        handleSave={saveNewUnavailability}
                                        event={events.find(event => event.type == 'new-unavailability')}
                                        isAdmin={isAdmin(role)}
                                    />
                                :   whichDialog == 'remove-unavailability'
                                    ?   <RemoveUnAvailabilityDialog 
                                            event={unavailabilityToBeDeleted}
                                            onCancel={handleDialogClose}
                                            onDelete={handleUnavailabilityDelete}
                                            isAdmin={isAdmin(role)}
                                        />
                                    :   whichDialog == 'display-shootings'
                                        ?   <DisplayShootingsDialog 
                                                shootings={shootingsToDisplay}
                                                onCancel={handleDialogClose}
                                                isAdmin={isAdmin(role)}
                                            />
                                        :   null  
                }
            </Dialog>
        </div>
    )
}

// export default TechSchedule;

export default withRouter(withStyles(styles)(TechSchedule));