import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter, Link } from "react-router-dom";

import moment from 'moment'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button, Typography, Tooltip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';

import PleaseWait from '../../widgets/PleaseWait';
import { filterListings } from './helper'

const styles = {
    root: {
    	width: '100%',
    	marginTop: '10px',
    },
    dialog: {
        flex: 1,
        width: '50%'
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        width: '33%',
        margin: '10px'
    },
    picture_container: {
        marginBottom: '20px'
    },
    list: {
        overflowY: 'scroll'
    },
};

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);

// fetches all tech's schedule and maps to events object which is fed in react-big-calendar
const getListingsForReview = () => (
    window.Hauslife
        .getListingsForReview()
)

const shootingStates = {
    validated: 2,
    retouch: 3,
    uploaded: 5
}

function numberOfDaysElapsed(numberOfDays){
    
    if(numberOfDays <= 2){
        return '#00b518';
    }else if(numberOfDays <= 4){
        return '#fc7303';
    }else if(numberOfDays >= 5){
        return '#a80000';
    }
}

const Review = (props) => {

    const [loading, setLoading] = useState(false)
    const [currentTab, setCurrentTab] = useState(0)

    const [underReviewShootings, setUnderReviewShootings] = useState([]);
    const [retouchShootings, setRetouchShootings] = useState([]);
    const [publicAndSentShootings, setPublicAndSendShootings] = useState([]);
    const [incompletPostProduction, setIncompletPostProduction] =  useState({listings:[],shootings:[]});

    useEffect(() => {
        handleReset()
    }, [])

    const handleReset = () => {
        setLoading(true)
        getListingsForReview()
            .then(data => {
                setLoading(false);
                let listings = data.data.data
                setUnderReviewShootings(listings.filter(l => l.last_shooting_state === shootingStates['uploaded'] && l.last_shooting_is_public_for_broker === false ));
                setRetouchShootings(listings.filter(l => l.last_shooting_state === shootingStates['retouch']));
                setPublicAndSendShootings(listings.filter(l => l.last_shooting_is_public_for_broker === true));
                let incompletShootings = {listings:[],shootings:[]};
                listings.forEach((listing)=>{
                    listing.shootings.forEach((shooting)=>{
                        if(!shooting.all_services_delivered){
                            shooting.elapsedDays = moment().diff(moment(shooting.scheduleddate),'days');
                            incompletShootings.shootings.push(shooting);
                            incompletShootings.listings.push(listing);
                        }
                    });
                });
                incompletShootings.shootings.sort((a,b)=>{
                    return new Date(a.scheduleddate) - new Date(b.scheduleddate);
                });
                setIncompletPostProduction(incompletShootings);
            });
    }

    if(loading) {
        return <PleaseWait />
    }

    const handleTabChange = (e, v) => {
        setCurrentTab(v)
    }

    return (
        <div style={{   flexGrow: 1 }}>
            <AppBar position="static">
                <Tabs value={currentTab} onChange={handleTabChange} aria-label="simple tabs example" variant="fullWidth">
                    <Tab label={`Under Review (${underReviewShootings.length})`} />
                    <Tab label={`Retouch (${retouchShootings.length})`} />
                    <Tab label={`Public and Sent (${publicAndSentShootings.length})`} />
                    <Tab label={`post-production incomplet (${incompletPostProduction.shootings.length})`} />
                </Tabs>
            </AppBar>
            <TabPanel value={currentTab} index={0} listings={underReviewShootings} />
            <TabPanel value={currentTab} index={1} listings={retouchShootings} />
            <TabPanel value={currentTab} index={2} listings={publicAndSentShootings} />
            <TabPanel value={currentTab} index={3} listings={incompletPostProduction.listings} shootings={incompletPostProduction.shootings} />
        </div>
    )
}

function TabPanel(props) {

    let { shootings, listings, value, index } = props
    
    const [filterText, setFilterText] = useState("")
    const [currListings, setCurrListings] = useState([])
    const [incompletShootings, setincompletShootings] = useState([]);

    useEffect(() => {
        let listingsToDisplay = listings
        if(filterText) {
            listingsToDisplay = filterListings(listingsToDisplay, filterText)
        }
        setincompletShootings(shootings);
        setCurrListings(listingsToDisplay);
    }, [listings, filterText])

    if(value !== index) {
        return null
    }

    const handleFilterChange = (event) => {
        let searchFor = event.target.value
        if(searchFor != "" && searchFor != filterText) {
            setFilterText(searchFor)
        }
    }



    return (
        <Grid xs={12} style={{marginBottom:20, overflowY: 'auto'}}>
            <Paper style={styles.root}>
                <List>
                    { index !== 3 ?
                        currListings.map((listing, index) => (
                            <React.Fragment key={listing.id}>
                                <ListItem 
                                    style={{ cursor: "pointer" }} 
                                    button
                                    component={Link}
                                    to={`/tech_review/${listing.id}`}>
                                    <ListItemAvatar>
                                        <Avatar src={listing.picture_url} />
                                    </ListItemAvatar>
                                    <ListItemText 
                                        primary={(listing.app ? listing.app + "-" : "") + listing.address + ", " + listing.city} 
                                        secondary={moment(listing.last_shooting_scheduleddate).format("dddd, MMMM Do YYYY, h:mm:ss a")}   
                                    />
                                    
                                    <ListItemSecondaryAction style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                                        
                                        {
                                            (listing.last_shooting_media_email_sent === true)
                                            ?   <Tooltip
                                                    title={
                                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                            <Typography variant="caption" color="inherit">
                                                                Last email sent at:
                                                            </Typography>
                                                            <Typography variant="subtitle1" color="inherit">
                                                                {moment(listing.last_shooting_media_email_sent_at).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                                            </Typography>
                                                        </div>
                                                    }
                                                >
                                                    <EmailIcon style={{marginRight: '5px', color: 'green'}} />
                                                </Tooltip>   
                                            :   <EmailIcon style={{marginRight: '5px', color: 'red'}} />
                                        }
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))
                    :
                        incompletShootings.map((shooting => (
                            <React.Fragment key={shooting.id} >
                            
                            <ListItem 
                                style={{ cursor: "pointer", backgroundColor:numberOfDaysElapsed(shooting.elapsedDays)}} 
                                button
                                component={Link}
                                to={`/tech_review/${shooting.listing_id}`}>
                                <ListItemAvatar>
                                    <Avatar src={currListings[currListings.findIndex(l => l.id === shooting.listing_id)].picture_url} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<WhiteTextTypography color='white' variant='h6'>Number of elapsed days since capture: {shooting.elapsedDays}</WhiteTextTypography>} 
                                    secondary={
                                        <React.Fragment>
                                            <WhiteTextTypography >
                                                { (currListings[currListings.findIndex(l => l.id === shooting.listing_id)].app ? 
                                                currListings[currListings.findIndex(l => l.id === shooting.listing_id)].app + "-" : "")   
                                                + currListings[currListings.findIndex(l => l.id === shooting.listing_id)].address + ", " 
                                                + currListings[currListings.findIndex(l => l.id === shooting.listing_id)].city}
                                            </WhiteTextTypography>
                                            <WhiteTextTypography color='textSecondary'>
                                                { moment(currListings[currListings.findIndex(l => l.id === shooting.listing_id)].last_shooting_scheduleddate).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                            </WhiteTextTypography>
                                        </React.Fragment>
                                    }   
                                />
                                <ListItemSecondaryAction style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                                        
                                    {
                                        (currListings[currListings.findIndex(l => l.id === shooting.listing_id)].last_shooting_media_email_sent === true)
                                        ?   <Tooltip
                                                title={
                                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                        <Typography variant="caption" color="inherit">
                                                            Last email sent at:
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="inherit">
                                                            {moment(currListings[currListings.findIndex(l => l.id === shooting.listing_id)].last_shooting_media_email_sent_at)
                                                            .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                                        </Typography>
                                                    </div>
                                                }
                                            >
                                                <EmailIcon style={{marginRight: '5px', color: 'green'}} />
                                            </Tooltip>   
                                        :   <EmailIcon style={{marginRight: '5px', color: 'red'}} />
                                    }
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                        )))
                    }
                </List>
            </Paper>
        </Grid>
    )
}

export default withRouter(withStyles(styles)(Review));