import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import ReleasesApp from './ReleasesApp';

import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    tableRow: {
      cursor:'pointer'
    },
    button: {
        margin: theme.spacing.unit,
    }
});

class Releases extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {



    }


    render() {
        const { classes } = this.props;
        return (

            <div>
                <ReleasesApp appname="ppworker" />
                <ReleasesApp appname="pptechapp" />
                <ReleasesApp appname="mobilevrapp" />
                <ReleasesApp appname="vrupdater" />
                <ReleasesApp appname="techappupdater" />
                <ReleasesApp appname="techapp" />
                <ReleasesApp appname="remote-injector-gui" />
            </div>

        );
    }
}

export default withRouter(withStyles(styles)(Releases));
