import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import ShootingPlanner from './ShootingPlanner';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';
import { resolveMx } from 'dns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './admin.css';
import HmdEdit from './HmdEdit';

var _ = require('lodash');

const styles = theme => ({


    root: {
        width: '100%',
       // marginTop: theme.spacing.unit * 3,
        overflowX: 'clip',
       // padding:20,
        fontSize:23,
    },
    table: {
         minWidth: 700, 
       
    },
    tableRow: {
      cursor:'pointer'
    },
    button: {
        margin: theme.spacing.unit,
    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
      },
      
      card:{
          marginBottom:20
      },
      itemCard : {
        marginBottom:10,
        backgroundColor:'#eeeeee'
      } ,
      
});

class HmdList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loaded: false,
            userId: null,
            selectedRole: -1,
            search:"",
            list:[],
            dlgShow:false,
            alertShow:false,
            dlgData:{},
            showHMDAdded:false,
            addingHMD: false,
            hmdtoadd:"",
            count:0,
            sortCol :'serial_number',
            sortDir:'asc'
        };
    }

    componentWillMount() {
      
        window.Hauslife.adminHmdList('',this.state.sortCol,this.state.sortDir)
            .then((res) => {
                //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
               // console.log( res.data.listings  )
                this.setState({ list: res.data.result,loaded:true,count:res.data.result.length });
                //  this.setState({ticket:res.data.data, is_reloading:false});
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleClick = (event, hmd) => {
        this.setState({dlgShow:true,hmd});
    }

    searchChange= (event)=>{
        this.setState({search:event.target.value})
    }

    handleChangeSearch = (event) => {
 
        this.setState({loaded:false});
        window.Hauslife.adminHmdList(event.target.value,this.state.sortCol,this.state.sortDir)
        .then((res) => {
            //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
           // console.log( res.data.listings  )
            this.setState({ list: res.data.result,loaded:true ,count:res.data.result.length});
            //  this.setState({ticket:res.data.data, is_reloading:false});
        })
        .catch((e) => {
            console.log(e);
        });

        this.setState({search:event.target.value});
    }

    brokerName (listing){
        if(listing.broker==undefined) return;
        return listing.broker.firstname+' '+listing.broker.lastname
    }

    alertClose = (button,data) => {

        if(button=='cancel' || data=='warning'){
            this.setState({dlgShow: false,alertShow:false});
            return;
        }

        if(data!=undefined && data.hmdDel){

            window.Hauslife.adminDeleteHmd(data.hmdDel )
                .then((res) => {
                    this.setState({dlgShow: false,alertShow:false,loaded:false});

                    window.Hauslife.adminHmdList(this.state.search,this.state.sortCol,this.state.sortDir )
                        .then((res) => {
                            this.setState({ list: res.data.result,loaded:true ,dlgShow: false,count:res.data.result.length});
                        })
                    .catch((e) => {
                        alert('Une erreur c\'est produite.');
                        console.log(e);
                    });
            })
            .catch((e) => { 
                alert('Une erreur c\'est produite.');
                console.log(e);
            });
            return;
        }

        
        this.setState({loaded:false});

        window.Hauslife.adminHmdList(this.state.search,this.state.sortCol,this.state.sortDir )
            .then((res) => {
            this.setState({ list: res.data.result,loaded:true ,dlgShow: false,count:res.data.result.length});
        })
        .catch((e) => {
            console.log(e);
        });
    }

    userRow(row){
        if (row.user_id==null) return '';

        var code   = row.user.broker_code==null?'':', Broker code : '+row.user.broker_code;
        var str = row.user.firstname+' '+ row.user.lastname ;//+' (Id: '+row.user.id+code+')';
        return str;
    }

    userId(row){
        if (row.user_id==null) return '';
        return row.user_id;
    }

    courtierRow(row){
        if (row.user_id==null) return '';

        var str =''  

        if( row.user.broker){  
            //row.user.broker_code = row.user.broker_code==null?'':', Broker code : '+row.user.broker_code;
            str = row.user.broker.firstname +' ' +row.user.broker.lastname ;
        }
        return str;
    }

    courtierId( row){

        if (row.user_id==null) return '';

        var str =''  

        if( row.user.broker){
            return row.user.broker.id;
        }
        return str;
    }
    courtierCode (row){
        if (row.user_id==null) return '';

        var str =''  

        if( row.user.broker){
            return row.user.broker.broker_code;
        }
        return str;
    }
    handleChangeHmd = (event) =>
    {
        this.setState({hmdtoadd:event.target.value});
    }

    addHmd = () =>
    {
        this.setState({addingHMD:true}, () => {
            window.Hauslife.registerHMD(this.state.hmdtoadd)
            .then((res)=>{
                if(res.data.status == 200)
                {
                    //good
                    this.setState({hmdtoadd:"",addingHMD:false, showHMDAdded: true});
                }
                else
                {
                    this.setState({addingHMD:false, showHMDAdded: false});
                }
            })
            .catch((err)=>{
                alert(err.message);
                this.setState({addingHMD:false, showHMDAdded: false});
            });
        });
    }

    deleteHmd (e,hmd){
        e.stopPropagation();
        if(hmd.user_id!=null){
            this.setState({ 
                alertShow: true,
                dlgConfirm:false,
                dlgTitle: 'HDM ',
                dlgText: `Ce Hmd  : ${hmd.serial_number} est assginée ${hmd.user.firstname} ${hmd.user.lastname}, il doit être déassignée pour pouvoir l'effacer.`,
                dlgData:'warning'
            });
            return;
        }
        this.setState({ 
            alertShow: true,
            dlgConfirm:true,
            dlgTitle: 'HDM ',
            dlgData:{hmdDel:hmd.id},
            dlgText: `Voulez-vous vraiment éffacer : ${hmd.serial_number} ? `
        });
    }

    render() {
        const { classes } = this.props;
        
        var rows =this.state.list;
        var loaded = this.state.loaded;

        return (

            <Grid style={{   height: "100%",flexGrow:0, alignContent:"flex-start" }}  container>
                 <AlertDialog show={this.state.alertShow} didClose={this.alertClose} title={this.state.dlgTitle} 
                                                                                      text={this.state.dlgText} 
                                                                                      confirm={this.state.dlgConfirm} 
                                                                                      data={this.state.dlgData}
                                                                                      ></AlertDialog>
            {this.state.dlgShow==true &&
                <HmdEdit style={{width:'100%'}} show={this.state.dlgShow} didClose={this.alertClose}   hmd ={this.state.hmd}></HmdEdit>
            }
                <Grid xs={12} style={{marginBottom:20}}>
                    <Paper style={{padding:10,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        
                        <TextField
                            label="Search"
                            value={this.state.search}
                            onChange={this.handleChangeSearch}
                            margin="normal"
                        />
                        
                        
                        
                        <Paper style={{display:'flex',alignSelf:'flex-end'}}>
                            <TextField
                                label="SERIAL NUMBER"
                                value={this.state.hmdtoadd}
                                onChange={this.handleChangeHmd}
                                margin="normal"
                            />
                            <Button onClick={this.addHmd} disabled={this.state.addingHMD}>
                                Add HMD
                            </Button>
                        </Paper>
                    </Paper>
                </Grid>
                { loaded==false &&
                     <PleaseWait />
                }

                {this.state.showHMDAdded == true &&
                    <Grid xs={12} style={{marginBottom:20}}>
                        <SnackbarContent
                            style={{backgroundColor: green[600], width:"100%", maxWidth:"100%"}}
                            message={"HMD added!"}
                        />
                    </Grid>
                }

                { loaded==true  &&
                    <Grid xs={12} style={{alignSelf:"flex-start",flex:1}}>
                        <Typography style={{ marginBottom:5}}>
                             Le total de cette Requête : { this.state.count}
                        </Typography>
                        <Paper className={styles.root}>
                        
                            <Table className={styles.table}>
                                <TableHead>
                                    <TableRow>
                                    <TableCell className="listing-list-cell">Id</TableCell>
                                        <TableCell className="listing-list-cell">serial_number</TableCell>
                                        
                                        <TableCell className="listing-list-cell">Assigné à</TableCell>
                                        <TableCell className="listing-list-cell">Id</TableCell>

                                        <TableCell className="listing-list-cell">Courtier</TableCell>
                                        <TableCell className="listing-list-cell">Courtier ID</TableCell>
                                        <TableCell className="listing-list-cell">Courtier Code</TableCell>
                                        <TableCell className="listing-list-cell">Note</TableCell>
                                        <TableCell className="listing-list-cell">Éffacer</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                        <TableRow className={classes.tableRow} key={row.id} onClick={event => this.handleClick(event, row)}>
                                            <TableCell className="listing-list-cell">{row.id}</TableCell>
                                            <TableCell className="listing-list-cell" component="th" scope="row"><ul className="no-bullet"> <li>{row.serial_number}</li><li>{row.city}</li><li>{row.postalcode}</li></ul> </TableCell>
                                            <TableCell className="listing-list-cell"> { this.userRow(row)}  </TableCell>
                                            <TableCell className="listing-list-cell"> { this.userId(row)}  </TableCell>
                                            <TableCell className="listing-list-cell"> { this.courtierRow(row)}  </TableCell>
                                            <TableCell className="listing-list-cell"> { this.courtierId(row)}  </TableCell>
                                            <TableCell className="listing-list-cell"> { this.courtierCode(row)}  </TableCell>
                                            <TableCell className="listing-list-cell" > {row.notes} </TableCell>
                                            <TableCell className="listing-list-cell"  onClick={ (e)=>this.deleteHmd(e,row) }> X </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                }
            </Grid>

        );
    }
}


export default withRouter(withStyles(styles)(HmdList));