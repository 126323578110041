import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter, Link } from "react-router-dom";
import { 
    Grid, Paper, Button, Typography, CircularProgress, TextField,
    List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Divider,
    IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ListItems from './ListItems'
import ViewEditCreate from './ViewEditCreate'

import _ from 'lodash'

const styles = {
    root: {
    	display: 'flex',
    	flexDirection: 'column',
    	justifyContent: 'center',
    	alignItems: 'center',
    },
    header: {
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        flex: 0.1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '10px',
        minHeight: '60px'
    },
    content: {
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    service: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        padding: '18px'
    },
    headerRightButton: {
        marginLeft: 'auto',
        marginRight: '10px'
    },
    dialog: {
        width: '100%',
        textAlign: 'center'
    }
};

const MODES = {
    'list': 0,
    'view': 1,
    'edit': 2,
    'create': 3
}

const SuperCrud = (props) => {

    let { superFetchAll, superDelete, superCreate, superUpdate, title, display_name_key, baseForm } = props

    // stores the entities received as props
    const [entities, setEntities] = useState([])
    // keeps the selected entity
    const [selected, setSelected] = useState(null)
    
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    
    const [showDialog, setShowDialog] = useState(null)
    const [showDialogError, setShowDialogError] = useState(null)
    
    /**
     * mode is one of list, view, edit, create
     */
    const [mode, setMode] = useState(MODES.list)

    useEffect(() => {
        setLoading(true)
        superFetchAll.apply(this)
            .then(result => {
                if(result.status == 200 && result.data && result.data.data) {
                    setEntities(result.data.data)
                } else {
                    throw new Error("No Service Error Found")
                }
            })
            .catch(error => {
                console.log("Error loading service offers", error)
                setError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const handleSelection = (entity, mode) => {
        setSelected(entity)
        setMode(mode)
    }

    const handleDelete = (entity) => {
        setShowDialog(entity)
    }

    const handleDeleteConfirm = () => {
        superDelete(showDialog.id)
            .then(result => {
                if(result.status == 200) {
                    setEntities(_entities => _entities.filter(x => x.id != showDialog.id))
                    setShowDialog(null)
                } else {
                    throw new Error('Error Deleting Entity. Please Try again')
                }
            }).catch(error => {
                setShowDialogError(error)
            })
    }

    const handleUpsert = async (formValues, onSuccess, onFailure) => {

        let api = superCreate
        if(mode == MODES.edit) {
            api = superUpdate
        }

        let payload = _.mapValues(formValues, (value, key) => {
            return (value.type == 'number' && value.value == "") ? 0 : value.value
        })
        try {
            let result = await api(payload)
            if(result.data && result.data.data) {
                let entity = result.data.data
                onSuccess()
                let idx = entities.findIndex(_entity => _entity.id == entity.id)
                if(idx == -1) {
                    // add mode
                    setEntities(_entities => [..._entities, entity])
                    setMode(MODES.list)
                } else {
                    // else mode
                    setEntities(_entities => [].concat(
                        _entities.slice(0, idx),
                        entity,
                        _entities.slice(idx+1)
                    ))
                }

            } else {
                onFailure("Database Error")
            }
        } catch(error) {
            onFailure(error)
        }
    }

    return (
        <Grid container style={styles.root}>
            <Paper style={styles.header}>
                {
                    selected && <IconButton color="primary" onClick={() => setSelected(null)} style={{}}>
                                    <ArrowBackIcon />
                                </IconButton>
                }
                <Typography variant="headline">
                    {title}
                </Typography>
                {
                    selected && 
                    mode == MODES.view && 
                    <Button 
                        onClick={() => setMode(MODES.edit)} 
                        variant="outlined"
                        style={styles.headerRightButton}
                    >
                        Edit
                    </Button>
                }
            </Paper>
            
            <Paper item xs={12} style={styles.content}>
                {
                    loading ? <CircularProgress /> :
                    error   ? <LoadingError errorMessage={error} /> : 
                    (mode == MODES.create || selected)
                    ?   <ViewEditCreate 
                            baseForm={baseForm}
                            entity={selected} 
                            mode={mode} 
                            handleUpsert={handleUpsert} 
                        /> 
                    :   <ListItems 
                            entities={entities} 
                            handleSelect={handleSelection} 
                            onCreateStart={() => setMode(MODES.create)}
                            onDelete={handleDelete}
                            display_name_key={display_name_key}
                        />
                }
            </Paper>

            {
                showDialog != null &&
                <Dialog open={showDialog != null} onClose={() => setShowDialog(null)} maxWidth="md" style={styles.dialog} >
                    <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography 
                                variant="subheading" 
                            >{
                                `You are deleting the Entity ID: ${showDialog.id}. Are you sure?`
                            }</Typography>
                        </DialogContentText>
                        {
                            showDialogError && 
                            <DialogContentText>
                                <Typography 
                                    variant="subheading" 
                                    style={{color: "red"}}
                                >{
                                    `Error deleting service offer ${showDialogError}`
                                }</Typography>
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions style={{alignSelf: 'center'}}>
                        <Button onClick={() => setShowDialog(null)} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteConfirm} color="primary" variant="outlined">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </Grid>
    )
}

const LoadingError = ({errorMessage}) => {
    return (
        <Typography variant="subheading" color="red">
            {`Error Fetching Data : ${errorMessage}`}
        </Typography>
    )
}

export default SuperCrud;