import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { injectIntl, FormattedMessage } from 'react-intl';

import InformationMessage from './InformationMessage.js';

import { Redirect, withRouter } from "react-router-dom";

import logo from '../logo.svg';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  media:{
    height: 100,
    margin:theme.spacing.unit*4,
  },
  snackbar:{
    padding: theme.spacing.unit,
    backgroundColor: theme.palette.error.dark,
  },
});

/**
 * Login widget
 */
class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {email: '', password:'',loginError:false,isWorking:false, rediectAfterLogin:false};
    this.tryToLogin = this.tryToLogin.bind(this);
  }

  /**
   * Try to the log the user with given parameters
   */
  tryToLogin(event)
  {
    event.preventDefault();

    var Hauslife = window.Hauslife;

    this.setState({isWorking:true},()=>{
      Hauslife.login(this.state.email,this.state.password)
      .then(()=>{
        this.setState({loginError:false,isWorking:false,rediectAfterLogin:true},()=>{
            var root = Hauslife.getAppRoot();
            if(root != null)
            {
                root.forceUpdate();
            }
        });

      })
      .catch((e)=>{
        console.log("error while logging", e);
        this.setState({loginError:true,isWorking:false});
      });
    });
  }

  render() {
    const { classes, intl } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container justify="center">
            <Grid item xs={12}>
                <div style={{textAlign:"center",paddingTop:15}}>
                    <object className={classes.media} type="image/svg+xml" data={logo}>
                    Your browser does not support SVG
                    </object>
                </div>
            </Grid>
                
            {this.state.loginError && <Grid item style={{maxWidth:600}} xs={12}><InformationMessage error={true} id={"login.error_while_login"} /></Grid>}

            <Grid item xs={12}>
                {this.state.isWorking ? (
                    <CircularProgress className={classes.progress} />
                ):(
                <div>
                    <Grid container justify="center">
                    <Grid item style={{maxWidth:600}} xs={12}>
                        <form onSubmit={this.tryToLogin}>
                            <TextField
                            label={intl.formatMessage({id:"login.email"})}
                            type="email"
                            name="email"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={(event)=>this.setState({email:event.target.value})}
                            />
                            <TextField
                            label={intl.formatMessage({id:"login.password"})}
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={(event)=>this.setState({password:event.target.value})}
                            />

                            <Button style={{padding:"18.5px 14px", marginTop:16, marginBottom:8}} fullWidth type="submit" variant="outlined" color="primary">
                                <FormattedMessage id="login.btn.login"/>
                            </Button>
                        </form>
                        </Grid>
                    </Grid>
                </div>
            )}
            </Grid>

          </Grid>
          {this.state.rediectAfterLogin && <Redirect push to='/' />}
          </React.Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(withStyles(styles)(Login)));