import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { FormattedMessage } from 'react-intl';
import { Redirect, Link } from "react-router-dom";

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	navIconHide: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
});

class MenuAppBar extends React.Component {

	state = {
		auth: true,
		anchorEl: null,
		didLogOut: false,
		redirectToProfile: false
	};

	handleChange = event => {
		this.setState({ auth: event.target.checked });
	};

	handleMenu = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleLogout = () => {
		window.Hauslife.logout();
		this.setState({ anchorEl: null, didLogOut: true }, () => {
			var root = window.Hauslife.getAppRoot();
			if (root != null) {
				root.forceUpdate();
			}
		});
	};

	handleToProfile = () => event => {
		this.setState({ redirectToProfile: '/users/edit/' + window.Hauslife.userObject.id });
	}

	render() {
		const { classes } = this.props;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		const auth = window.Hauslife.isLoggedIn();

		/*
					<IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
					  <MenuIcon />
					</IconButton>
		*/

		return (
			<AppBar className={this.props.className}> 
				{this.state.didLogOut && <Redirect to='/' />}

				{this.state.redirectToProfile != false &&
					<>
						{this.state.redirectToProfile}
						<Redirect push to={this.state.redirectToProfile} />
						{this.setState({ redirectToProfile: false })}
					</>
				}
				<Toolbar> 
					<IconButton
						color="inherit"
						aria-label="Open drawer"
						onClick={this.props.handleDrawerToggle}
						className={classes.navIconHide}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="title" color="inherit" className={classes.grow}>
						<Link to="/"><FormattedMessage id="generic.title" /></Link>
					</Typography>
					{auth && (
						<div>
							<IconButton
								aria-owns={open ? 'menu-appbar' : null}
								aria-haspopup="true"
								onClick={this.handleMenu}
								color="inherit"
							>
								<AccountCircle />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={open}
								onClose={this.handleClose}
							>
								<MenuItem onClick={this.handleLogout}><FormattedMessage id="login.logout" /></MenuItem>

								{
									window.Hauslife.userObject.role == 2 ?	
									(<MenuItem onClick={this.handleToProfile()} ><FormattedMessage id="menu.profile" /></MenuItem>)
									: null
								}
							</Menu>
						</div>
					)}
				</Toolbar>
			</AppBar>
		);
	}
}

MenuAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
	handleDrawerToggle: PropTypes.func.isRequired,
	handleLanguageSwitch: PropTypes.func.isRequired,
};

export default withStyles(styles)(MenuAppBar);