import React, { Component, StyleSheet } from 'react';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TablePagination from '@material-ui/core/TablePagination';


class MLSProvider extends Component {

    constructor(props)
    {
        super(props);
        this.state = {itemsMLS:{},activePage:0, itemsPerPage:10,loadingJobs:false, listingsWithNoMLS:[], selectedListing:null,currentUrl:"", currentListingDownloadProgress:0, currentListingDownloading:false, currentListingDownloaded:false};
    }

    componentDidMount()
    {
        this.refreshJobs();
    }

    refreshJobs = () => {
        this.setState({listingsWithNoMLS:[],loadingJobs:true,selectedListing:null},()=>{
            window.Hauslife.getListingsWithNoMLS()
            .then((res)=>{
                this.setState({listingsWithNoMLS: res.data.listings, loadingJobs: false});
            })
            .catch(e=>{
                this.setState({listingsWithNoMLS:[], loadingJobs: false});
                window.ErrorHandler.logError(e);
            });
        });
    }

    handlePaginationChange = (e, activePage) => {console.log(activePage);this.setState({ activePage:activePage })};

    handleChangeRowsPerPage = (e) => this.setState({ itemsPerPage:e.target.value, activePage:0 })

    setItemMLS = (listing_id, mls) =>
    {
        let copyItemMLS = {...this.state.itemsMLS};
        copyItemMLS[listing_id] = mls;
        this.setState({itemsMLS:copyItemMLS});
    }

    getItemMls = (listing_id) =>
    {
        if(this.state.itemsMLS[listing_id])
        {
            return this.state.itemsMLS[listing_id];
        }

        return "";
    }

    setMLS = (listing_id) =>
    {
        this.setState({doingAction:true}, () =>{
            window.Hauslife.setMLS(listing_id, this.state.itemsMLS[listing_id])
            .then((res)=>{

                let copyItemMLS = {...this.state.itemsMLS};

                delete copyItemMLS[listing_id];

                this.setState({itemsMLS:copyItemMLS,doingAction: false},()=>{
                    this.refreshJobs();
                });
            })
            .catch(e=>{
                this.setState({doingAction: false});
                window.ErrorHandler.logError(e);
            });
        });
    }

    render() {

        return (
            <Paper>
                {this.state.loadingJobs && <Paper padded>Loading...</Paper>}
                {!this.state.loadingJobs &&
                    <Paper>
                            <Button disabled={this.state.loadingJobs} onClick={this.refreshJobs} color='blue'>Refresh</Button>
                    </Paper>
                }

                {this.state.listingsWithNoMLS.length > 0 &&
                    <>
                        <Paper>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={this.state.listingsWithNoMLS.length}
                                rowsPerPage={this.state.itemsPerPage}
                                page={this.state.activePage}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={this.handlePaginationChange}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />

                            <List>
                                {this.state.listingsWithNoMLS.slice((this.state.activePage)*this.state.itemsPerPage, ((this.state.activePage)*this.state.itemsPerPage) + this.state.itemsPerPage).map((item,i) => 
                                    <>
                                        <ListItem>
                                            <img style={{width:120}} src={item.picture_url} />

                                            <div>
                                                <div>{item.address + (item.app ? ", #"+item.app : "") + ", " + item.city}</div>
                                                <div style={{marginTop:10}}>
                                                    <input style={{marginRight:10}} value={this.getItemMls(item.id)} onChange={(event)=>this.setItemMLS(item.id, event.target.value)} />
                                                    <Button onClick={()=>this.setMLS(item.id)} disabled={this.state.doingAction} loading={this.state.doingAction}>Save MLS</Button>
                                                </div>
                                            </div>
                                        </ListItem>
                                        <Divider />
                                    </>
                                )}
                            </List>
                                
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={this.state.listingsWithNoMLS.length}
                                rowsPerPage={this.state.itemsPerPage}
                                page={this.state.activePage}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={this.handlePaginationChange}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Paper>
                    </>
                }
            </Paper>
        );
    }
    
}

export default MLSProvider;
