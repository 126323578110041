import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import { FormattedMessage } from 'react-intl';

const styles = theme => ({
    informationPaperError: {
        padding: theme.spacing.unit,
        backgroundColor: theme.palette.error.dark,
        maxWidth:"100%",
        marginBottom: theme.spacing.unit * 2
    },
    informationPaperInfo:{
        padding: theme.spacing.unit,
        backgroundColor: "#fbfbfb",
        maxWidth:"100%",
        marginBottom: theme.spacing.unit * 2
    },
    informationTextError: {
        color:"white"
    },
    informationTextInfo:{
        color:"black"
    }
});

class InformationMessage extends React.Component {
    render()
    {
        const { classes } = this.props;
        return(
            <Paper style={{marginBottom:5,padding:15}} className={this.props.error?classes.informationPaperError:classes.informationPaperInfo} elevation={1}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography className={this.props.error?classes.informationTextError:classes.informationTextInfo} component="p">
                        <Icon style={{verticalAlign:"middle",marginRight:15}}>{this.props.error?"error":"info"}</Icon> <FormattedMessage id={this.props.id} />
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

// Specifies the default values for props:
InformationMessage.defaultProps = {
    error: false
};

InformationMessage.propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired
};

export default withStyles(styles)(InformationMessage);
  