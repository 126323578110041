import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class DisplayShooting extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
    }

    render()
    {
        return(<p>DisplayShooting</p>);
    }
}

export default withRouter(DisplayShooting);
  