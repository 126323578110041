import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    active:{
        background: 'white',
        padding: '8px 5px',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        fontSize: '15px',        
        opacity: 1,
        transition: 'opacity 150ms ease',
        margin:5,
        border:'1.5px solid blue',
        borderRadius:'20px',
        fontWeight:'bold',
        minWidth:100
    },
    inactive:{
        background: 'white',
        padding: '8px 5px',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        fontSize: '15px',        
        opacity: 1,
        transition: 'opacity 150ms ease',
        margin:5,
        border:'1.5px solid blue',
        borderRadius:'20px',
        opacity: 0.5,
        fontWeight:'bold',
        minWidth:100
    },
})

class ZoneButton extends React.Component {
      render () {
        const { classes } = this.props;
        
        return (
            <button   
                className={this.props.active ? classes.active : classes.inactive}
                onMouseDown={this.props.buttonClick}
            >
              {this.props.zoneName}
            </button>
        )
      }
}

ZoneButton.propTypes = {
    isWorking: PropTypes.bool.isRequired,
};


export default (withStyles(styles)(ZoneButton));
  