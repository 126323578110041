import React, { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import LinkButton from '../../../widgets/LinkButton'

import { withRouter, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

export const NewAvailabilityDialog = (props) => {

    let { techs, handleSave, handleCancel, isAdmin, event } = props
    const [selectedTechs, setSelectedTechs] = useState([])

    const handleClick = (techId) => {
        setSelectedTechs(selected => {
            if(selected.indexOf(techId) == -1) {
                return selected.concat(techId)
            } else {
                return selected.filter(s => s != techId)
            }
        })
    }

    const onSave = () => {
        if(!isAdmin) {
            handleSave([window.Hauslife.userObject])
        } else {
            handleSave(selectedTechs.map(techId => techs.find(tech => tech.id == techId)))
        }
    }

    return (
        <React.Fragment>
            <DialogTitle id="form-dialog-title">Ajouter une disponibilité</DialogTitle>
            <DialogContent>
                {
                    isAdmin 
                        ?   <>
                                <DialogContentText>
                                    Choisir un photographe
                                </DialogContentText>
                                <List>
                                    {
                                        techs.map(tech => (
                                            <ListItem key={tech.id} dense button onClick={() => handleClick(tech.id)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={selectedTechs.indexOf(tech.id) != -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={`Tech Name - ${tech.firstname} ${tech.lastname}`} />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </>

                        :   <>
                                <DialogContentText>
                                    {`Confirm Availability`}
                                </DialogContentText>
                                <DialogContentText>
                                    {`${moment(event.start).format("dddd, MMMM Do YYYY")} - ${moment(event.end).format("dddd, MMMM Do YYYY")}`}
                                </DialogContentText>
                            </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Annuler
                </Button>
                {
                    isAdmin 
                        ?   selectedTechs.length &&
                            <Button onClick={onSave} color="primary">
                                {`Sauvegarder (${selectedTechs.length})`}
                            </Button>
                        :   <Button onClick={onSave} color="primary">
                                {`Sauvegarder`}
                            </Button>
                }
            </DialogActions>
        </React.Fragment>
    )
}

export const RemoveAvailabilityDialog = (props) => {
    let { event, onDelete, onCancel, isAdmin } = props
    const [selectedTech, setSelectedTech] = useState(null)
    const [status, setStatus] = useState(null)

    let tech = {}

    if(event.hasOwnProperty('tech')) {
        tech = event['tech']
    } else if(event.hasOwnProperty('availability')) {
        tech = event['availability']['user']
    } else if(event.hasOwnProperty('user')) {
        tech = event['user']
    }

    const handleDelete = () => {
        if(isAdmin && selectedTech == null) {
            return;
        }

        let techId = selectedTech
        if(!isAdmin) {
            techId = tech.id
        }

        window.Hauslife.removeAvailability(techId, event.start)
            .then(response => {
                if(response.status == 200) {
                    setStatus(true)
                    onDelete(event, techId)
                } else {
                    setStatus(false)
                }
            })
            .catch(err => {
                console.log("Error removing Availability", err)
                setStatus(false)
            })
    }

    if(status === true) {
        return (
            <React.Fragment>
                <DialogTitle id="form-dialog-title">Supprimer la disbonibilité</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Availability has been successfully removed`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }

    if(status === false) {
        return (
            <React.Fragment>
                <DialogTitle id="form-dialog-title">Supprimer la disbonibilité</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Une erreur est survenue, veuillez essayer à nouveau.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="secondary">
                        OK
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <DialogTitle id="form-dialog-title">Supprimer la disbonibilité</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Date - ${moment(event.start).format("YYYY-MM-DD")}`}
                </DialogContentText>
                {
                    isAdmin
                    ?   <ListItem key={tech.id} dense button onClick={() => setSelectedTech(tech.id)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedTech == tech.id}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={`Tech Name - ${tech.firstname} ${tech.lastname}`} />
                        </ListItem>
                    :   null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleDelete} color="primary">
                    Supprimer
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}

export const NewUnavailabilityDialog = props => {
    let { techs, handleSave, handleCancel, event, isAdmin } = props
    const [selectedTech, setSelectedTech] = useState({})
    const [status, setStatus] = useState(null)
    const [reason, setReason] = useState("")

    const onSave = () => {
        if(isAdmin && selectedTech == null) {
            return;
        }

        let start = moment(event.start).utc().second(0).format(),
            end   = moment(event.end).utc().second(0).format(),
            unavailability_id = null,
            tech = techs.find(t => t.id == selectedTech)

        let techId = selectedTech
        if(!isAdmin) {
            techId = window.Hauslife.userObject.id
            tech = window.Hauslife.userObject
        }

        window.Hauslife.createUserUnavailability(techId, start, end, unavailability_id, reason)
            .then( res => {
                let newUnavailability = {
                    ...res.data.result,
                    user: tech
                }
                handleSave(newUnavailability)
            })
            .catch(err => {
                setStatus(false)
            })
    }

    const handleChange = (event) => {
        setReason(event.target.value)
    }

    if(status === false) {
        return (
            <React.Fragment>
                <DialogTitle id="form-dialog-title">Ajouter une indisponibilité</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Error in adding Unavailability. Please try again.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                        OK
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <DialogTitle id="form-dialog-title">Ajouter une indisponibilité</DialogTitle>
            <DialogContent>
                {
                    isAdmin
                        ?   <>
                                <DialogContentText>
                                    Choisir un photographe
                                </DialogContentText>
                                <FormControl style={{width: '100%'}}>
                                    <Select
                                        value={selectedTech}
                                        onChange={e => setSelectedTech(e.target.value)}
                                    >
                                        {
                                            techs.map(tech => <MenuItem key={tech.id} value={tech.id}>{`${tech.firstname} ${tech.lastname}`}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </>
                        :   <>
                                <DialogContentText>
                                    {
                                        `${moment(event.start).second(0).format("dddd, MMMM Do YYYY, h:mm:ss a")} - ${moment(event.end).second(0).format("dddd, MMMM Do YYYY, h:mm:ss a")}`
                                    }
                                </DialogContentText>
                            </>
                }
                <TextField
                    id="filled-multiline-static"
                    label="Raison"
                    multiline
                    rowsMax={2}
                    fullWidth={true}
                    value={reason}
                    margin='normal'
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => onSave()} color="primary">
                    Sauvegarder
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}

export const RemoveUnAvailabilityDialog = (props) => {
    let { event, onDelete, onCancel } = props
    const [status, setStatus] = useState(null)

    const handleDelete = () => {

        window.Hauslife.removeUnavailability(event.id)
            .then(response => {
                if(response.status == 200) {
                    setStatus(true)
                    onDelete(event)
                } else {
                    setStatus(false)
                }
            })
            .catch(err => {
                console.log("Error removing Availability", err)
                setStatus(false)
            })
    }

    if(status === true) {
        return (
            <React.Fragment>
                <DialogTitle id="form-dialog-title">Supprimer l'indisponibilité</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Unavailability has been successfully removed`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }

    if(status === false) {
        return (
            <React.Fragment>
                <DialogTitle id="form-dialog-title">Supprimer l'indisponibilité</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Error in removing Unavailability. Please try again.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="secondary">
                        OK
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <DialogTitle id="form-dialog-title">Supprimer l'indisponibilité</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Photographe - ${event.tech && `${event.tech.firstname} ${event.tech.lastname}`}`}
                </DialogContentText>                
                <DialogContentText>
                    {`Début - ${moment(event.start).format("dddd, MMMM Do YYYY, h:mm:ss a")}`}
                </DialogContentText>
                <DialogContentText>
                    {`Fin - ${moment(event.end).format("dddd, MMMM Do YYYY, h:mm:ss a")}`}
                </DialogContentText>
                {event.summary != null && event.summary != "" ? (
                    <DialogContentText>
                        {`Raison - ${event.summary}`}
                    </DialogContentText>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleDelete} color="primary">
                    Supprimer
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}

export const DisplayShootingsDialog = (props) => {
    let { shootings, onCancel, isAdmin } = props

    const RenderListingAddress = ({listing}) => {
        if(!listing || !listing.hasOwnProperty('address')) {
            return null
        }
        return <h3 style={{marginBottom:10}}>{`${listing.app ? '#'+listing.app+', ' : ''} ${listing.address}, ${listing.city}, ${listing.postalcode}`}</h3>
    }

    const RenderDetails = ({shoot}) => {

        let { listing, tech } = shoot

        

        let shootStartTime = moment(shoot.scheduleddate).format('hh:mm a')
        let shootEndTime = moment(shoot.scheduleddate).add(shoot.estimated_duration || 0, 'seconds').format('hh:mm a')
        let pingTime = moment(shoot.ping_time).format('hh:mm a');
        let startTime = moment(shoot.starttime).format('hh:mm a');
        let endTime = moment(shoot.endtime).format('hh:mm a');
        let totalTimeSeconds;
        
        if(shoot.ping_time != null){        
            totalTimeSeconds = moment(shoot.endtime).diff(moment(shoot.ping_time), 'seconds');
        }
        else{
            totalTimeSeconds = moment(shoot.endtime).diff(moment(shoot.starttime), 'seconds');
        }
        let totalTime = moment("2015-01-01").startOf('day').seconds(totalTimeSeconds).format('H:mm')

        return (
            <div>
                <RenderListingAddress listing={listing} />
                <div style={{marginBottom:20}}>
                    {
                        <>           
                            <p>{`Courtier: ${shoot.listing.broker.firstname} ${shoot.listing.broker.lastname}`}</p>             
                            <p>{`Horaire prévue: ${shootStartTime} - ${shootEndTime}`}</p>                           

                            {shoot.shooting_params != null && shoot.shooting_params.shooting_package != null? (
                                <>
                                    <p style={{marginBottom:10 }}>{`Forfait: ${shoot.shooting_params.shooting_package.display_name_fr} (${shoot.shooting_params.shooting_package.hdr_photo_count} photos)`}</p>
                                </>
                            ) : null}
                            
                            {
                                shoot.service_offers != null && shoot.service_offers.length > 0 ? (
                                    <>                               
                                        <p style={{fontWeight:'bold', marginTop: 10,marginBottom:10, fontSize:18}}>{`Services additionnels:`}</p>
                                        {shoot.service_offers.map((so)=>{
                                            return <>
                                                <p style={{marginBottom:5}}>{so.serviceOffer.display_name_fr}</p>
                                            </>
                                        })}
                                    </>
                                ) : null
                            }

                            <p style={{fontWeight:'bold', marginBottom: 10, fontSize: 18}}>Autres détails</p>

                            <p>{`Nombre de pièces: ${shoot.room_count}`}</p>

                            {
                                shoot.note != null
                                    ? <p>{`Note du courtier: ${shoot.note}`}</p> : null
                            } 

                            {
                                tech != null
                                    ?   <p>{`Photographe: ${tech.firstname} ${tech.lastname}`}</p>
                                    :   <p style={{color: 'red'}}>{`Unassigned`}</p>
                            }                         
                        </>
                    }
                    {
                        shoot.ping_time != null && shoot.starttime != null && shoot.endtime != null ?
                        (<>
                            <p style={{fontWeight:'bold'}}>{`Temps de capture: ${totalTime} `}</p>
                            
                            <p>{`Ping: ${pingTime} `}</p>
                            <p>{`Début: ${startTime}  Fin: ${endTime}`}</p>                            
                        </>) : null
                    }   
                    <hr style={{marginTop:20}}></hr>                 
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <DialogTitle id="form-dialog-title">
                <div style={{display: 'flex', flex: 1, alignContent: 'center', justifyContent: 'space-around'}}>
                    <p>Captures</p>
                    <p>{moment(shootings[0]['scheduleddate']).format("YYYY-MM-DD")}</p>
                    {
                        isAdmin &&
                        <LinkButton variant="outlined" color="primary" to={{pathname: '/shooting', state: shootings[0]['scheduleddate']}}>
                            Gérer les captures
                        </LinkButton>
                    }
                </div>
            </DialogTitle>
            <DialogContent>
                <List>
                    {
                        shootings.map(shooting => (
                            <RenderDetails shoot={shooting} />
                        ))
                    }
                </List>
            </DialogContent>
        </React.Fragment>
    )
}