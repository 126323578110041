var globals = {
	role: {
		superadmin: 1,
		admin: 2,
		broker: 3,
		tech_capture: 4,
		tech_postprod: 5,
		client: 6,
		tech_injector: 7,
		tech_postprod_lead: 8,
		releaser: 9,
		social_medias: 10,
		stats_query: 11
	},
	response_code: {
		success: 200,
		bad_request: 400,
		unauthorized: 401,
		forbidden: 403,
		not_found: 404,
		conflict: 409,
		database_error: 500,
		unprocessable_entity: 422,
		solver_error: 500,
		simulation_error: 500,
		external_api_error: 503
	},
	listing_state: {
		created: 0,
		captured: 1,
		validated: 2,
		vr_ready: 3,
		postponed: 4
	},
	marker_state: {
		created: 0,
		validated: 1,
		rejected: 2,
		fixStitch: 3,
		fixHdr: 4,
		fixPan: 5,
		fixManual: 6,
		processed: 7,
		enqueued: 100
	},
	
	review_mode: {
		false: 0,
		true: 1
	},
	shooting_state: {
		created: 0,
		captured: 1,
		validated: 2,
		retouch: 3,
		processed: 4,
		uploaded: 5,
		confirmed: 6,
		inprogress: 8,
		completed: 9,
		canceled: 10,
		recommended: 12,
		abandonned: 13,
		delivered: 14
	},
	shooting_capture_state: {
		another_shoot_recommended: 0,
		finished_and_complete: 1
	},
	por_status: {
		pending: 0,
		processing: 1,
		success: 2,
		failure: 3
	},
	subscription: {
		base: 1,
		rabais_dix: 2,
		rabais_vingt: 3,
		rabais_trente: 4,
		platform: 8,
		application: 9
	},
	service_offers: {
		seasonal_shoot: 1,
		measures: 2,
		one_week_rental: 3,
		blue_hour: 4,
		vr: 7,
		two_d: 8,
		drone: 9,
		staging: 10,
		video: 12
	},
	picture_service_offer:{
        applicationTraditionalPic: 180,
        applicationBlueHourPic: 179,
        applicationDronePic: 181,
        plateformeTraditionalPic: 176,
        plateformeBlueHourPic: 178,
        plateformeDronePic: 177, 
		carteTraditionalPic: 245,
        carteBlueHourPic: 243,
        carteDronePic: 244, 
    },
	solver_input_status: {
		valid: 200,
		invalid: 500,
		no_techs_available: 404,
		out_of_zone: 404
	},
	videoCreationStatus: {
		'requested': 0,
		'enqueued': 1,
		'success': 2,
		'failure': 3
	},
	kmCost: 0.5,
	kmCostException: [1016,1017],
}

module.exports = globals;