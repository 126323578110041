import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { injectIntl, intl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import PleaseWait from './PleaseWait.js'
import HausError from './HausError.js'
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Iframe from 'react-iframe'
import ReactPlayer from 'react-player'
import {
    FacebookIcon,
    FacebookShareButton,
    FacebookShareCount,
    EmailShareButton
} from 'react-share';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import withWidth from '@material-ui/core/withWidth';

import CircularProgress from '@material-ui/core/CircularProgress';


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FeedbackIcon from '@material-ui/icons/Feedback';
import CloseIcon from '@material-ui/icons/Close';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Icon from '@material-ui/core/Icon';
import green from '@material-ui/core/colors/green';

import Lightbox from 'react-image-lightbox';

import logo_dropbox from './../Dropbox.png';

import * as moment from 'moment';
import 'moment/locale/fr-ca';

var _ = require('lodash');

const Line = () => (
    <hr
        style={{
            color: 'black',
            backgroundColor: 'black',
            height: 1,
            marginTop: 40,
            marginBottom: 20,
            borderBottom: 0,
            borderWidth: 1
        }}
    />
);

const styles = theme => ({
    root: {
    },
    cell: {
        padding: 5
    },
    innerGrid: {
        margin: 0
    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)'
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.0) 0%, ' +
            'rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    },
    topButtons: {
        display: "flex",
        marginTop: 15,
        marginBottom: 15,
        justifyContent: "space-between",
        flexWrap: "wrap",
        width: "100%"
    },
    videoContainer: {
        marginTop: 30,
        marginBottom: 30
    },
    virtualVisitContainer:{
        display: "flex",
        flexDirection: "row",
        marginTop: 15,
        
        alignItems: "center",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: 'space-between'
    },
    virutalVisitTextField:{
        marginBottom: 15,
        width: "100%"
    }
});

/**
 * Class used to display tickets owned by the user
 */
class ViewBeautyDelivery extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            dropboxProgress: 0,
            savingToDropbox: false,
            photoIndex: 0,
            displayedPictres: [],
            displayedListing: null,
            currentFeedback: "",
            leftFeedback: null,
            leavingFeedback: false,
            openFeedback: false,
            pic_url: null,
            open: false,
            loading: true,
            rows: [],
            distribution_token:""
        };
    }

    componentWillMount() {
        this.refreshListing();
    }

    refreshListing = () => {
        const { match } = this.props;
        if (match.params.id != "undefined") {
            window.Hauslife.getDeliveriesFromToken(match.params.id)
                .then((result) => {

                    //reorder pictures
                    if(result.data.listing.pictures && result.data.listing.pictures.length > 0)
                    {
                        let pictures = result.data.listing.pictures;
                        var sortedpictures = _.orderBy(pictures, ['picture_index'], ['asc']);
                        result.data.listing.pictures = sortedpictures;
                    }

                    this.setState({ distribution_token:match.params.id ,loading: false, listing: result.data.listing });
                })
                .catch((e) => {
                    this.setState({ loading: false });
                });
        }
    };

    handleClickOpen = (pictures, index) => {
        let apictures = [];

        let momentofopen = "?"+moment().format('MMMM-DD-YYYY-h-mm');

        for (var i = 0; i < pictures.length; i++) {
            apictures.push(pictures[i].distrib_url+momentofopen);
        }

        this.setState({ photoIndex: index, displayedPictres: apictures, open: true });
    };

    handleClickOpenFeedback = (picture) => {
        if (picture.feedback_ticket_id != null) {
            this.props.history.push({ pathname: "/tickets/" + picture.feedback_ticket_id });
        }
        else {
            this.setState({ leftFeedback: null, picture: picture, openFeedback: true });
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleCloseFeedback = () => {
        if (this.state.leavingFeedback) return;

        this.setState({ openFeedback: false });

        this.refreshListing();
    };

    handleFeedbackChange = (event) => {
        this.setState({ currentFeedback: event.target.value });
    };

    IsDisplayingMe = (listing_id) => {
        return this.state.displayedListing === listing_id;
    };

    displayListingPictures = (listing_id) => {
        this.setState({ displayedListing: listing_id });
    };

    handSendFeedback = () => {
        this.setState({ leavingFeedback: true }, () => {
            window.Hauslife.leaveFeedbackOnPicture(this.state.picture, this.state.currentFeedback)
                .then(res => {
                    this.setState({ leavingFeedback: false, leftFeedback: res.data });
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ leavingFeedback: false });
                })
        });
    };

    downloadFile = (file_url) => {
        window.location = file_url;
    };

    downloadAllFiles = () => {
        window.location.href = window.Hauslife.getDeliveriesFromTokenZip(this.state.distribution_token);
    }

    saveToDropbox = (pictures) => {

        let apictures = [];

        let listing = this.state.listing;

        let prettyname = ((listing.app?listing.app+"-":"")+listing.address+","+listing.city).toLowerCase().replace(/[^a-z0-9]+/g, "-");

        for (var i = 0; i < pictures.length; i++) {
            apictures.push({ url: pictures[i].distrib_url, filename: (i + 1) + "-" + prettyname + ".jpg" });
        }

        let thisRef = this;

        var options = {
            files: apictures,

            // Success is called once all files have been successfully added to the user's
            // Dropbox, although they may not have synced to the user's devices yet.
            success: function () {
                // Indicate to the user that the files have been saved.
                thisRef.setState({ savingToDropbox: false });
            },

            // Progress is called periodically to update the application on the progress
            // of the user's downloads. The value passed to this callback is a float
            // between 0 and 1. The progress callback is guaranteed to be called at least
            // once with the value 1.
            progress: function (progress) {
                //console.log("importing " + (progress * 100) + " %");
                thisRef.setState({ savingToDropbox: true, dropboxProgress: progress });
            },

            // Cancel is called if the user presses the Cancel button or closes the Saver.
            cancel: function () { },

            // Error is called in the event of an unexpected response from the server
            // hosting the files, such as not being able to find a file. This callback is
            // also called if there is an error on Dropbox or if the user is over quota.
            error: function (errorMessage) {
                console.log(errorMessage);
            }
        };

        //console.log(options);
        window.Dropbox.save(options);
    };

    floorToHuman = (floor) =>
    {
        if(floor === 0)
        {
            return "1er niveau/RDC";
        }
        else if(floor === 1)
        {
            return "2e niveau";
        }
        else if(floor === -1)
        {
            return "Sous-sol 1";
        }
        else
        {
            return "Autre";
        }
    }

    englishFloorType = (frenchFloorType) => {
        switch (frenchFloorType){
            case 'Ardoise':
                return 'Slate';
            case 'Béton':
                return 'Concrete';
            case 'Bois':
                return 'Wood';
            case 'Céramique':
                return 'Ceramic';
            case 'Granite':
                return 'Granite';
            case 'Linoléum/Vinyle/Couvre-Planchers Souple':
                return 'Linoleum / Vinyl / Soft Flooring';
            case 'Marbre':
                return 'Marble';
            case 'Parqueterie':
                return 'Parquetry';
            case 'Plancher flottant':
                return 'Laminate floor';
            case 'Tapis':
                return 'Carpet';
            case 'Tuile':
                return 'Tiles';
            case 'Autre':
                return 'Other';
            default:
                return '-';
        }
    }

    filterInsideRooms = (room) =>
    {
        var outsideRooms = ["Backyard","Balcony","Pool","Facade","Picsine","Cour arrière"];

        if(outsideRooms.includes(room.roomname)) return false;

        return true;
    }

    floorType(room){

        return;
        if(room.metadata.length==0) return;
       
        var metaOnFloor = room.metadata.find ( (i)=>i.metadata_identifier_id==52);

        if(!metaOnFloor) return;

       // console.log('Found',metaOnFloor);

        if(metaOnFloor.identifier==undefined) return '';
        var label = metaOnFloor.identifier.option_array[metaOnFloor.value];
        return label;
    }

    generateFloorplanMeasures = (rooms) =>
    {
        var metas = rooms.reduce ( function(acc,value){
            return acc + value.metadata.length;
        },0 )
        return (
            
            <div style={{marginTop: 30, marginBottom: 30}}>
                <Line/>
                <Typography style={{ display: "flex", flexGrow: 1 }} gutterBottom variant="h5" component="h2">
                    Mesures
                </Typography>
                <Table style={{ tableLayout: 'auto' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {"Pièces"}
                            </TableCell>
                            <TableCell>{"Niveau"}</TableCell>
                            <TableCell>{"Dimensions"}</TableCell>                       
                            <TableCell>{"Revêtement"}</TableCell>
                            <TableCell>{"Flooring"}</TableCell>                       
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rooms.filter(this.filterInsideRooms).map(row => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.roomname}
                                    </TableCell>
                                    <TableCell>{this.floorToHuman(row.floor)}</TableCell>
                                    <TableCell>{row.width + "'" + row.width_inches+"\" X " + row.height + "'" + row.height_inches+"\""+ (row.irregular ? " irr.":"")}</TableCell>
                                    <TableCell> {row.floor_type != null ? row.floor_type : '-'}</TableCell>
                                    <TableCell> {row.floor_type != null ? this.englishFloorType(row.floor_type) : '-'}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        );
    }
    copyLink(link){
        navigator.clipboard.writeText(link).then( function(value){
            console.log('text copied to clipboard');
        }).catch(function (err) {
            console.log("error copy to clipboard",err)
        });
    }

    getAutomatedVideos = () => {
        let youtube_vids = this.state.listing.automated_videos.map(video => ({...video, url: video.gcs_path_mp4}))
        let instagram_video = this.state.listing.automated_videos.find(av => av.instagram_video)
        if(instagram_video) {
            youtube_vids = [
                ...youtube_vids,
                {
                    ...instagram_video, 
                    url: instagram_video.instagram_video,
                    is_instagram: true
                }
            ]
        }
        return youtube_vids
            
    }

    render() {
        const { classes, intl, fullScreen, width } = this.props;

        let momentofopen = "?"+moment().format('MMMM-DD-YYYY-h-mm');
        var row = this.state.listing;

        return (
            <div>
                {this.state.open && (
                    <Lightbox
                        mainSrc={this.state.displayedPictres[this.state.photoIndex]}
                        nextSrc={this.state.displayedPictres[(this.state.photoIndex + 1) % this.state.displayedPictres.length]}
                        prevSrc={this.state.displayedPictres[(this.state.photoIndex + this.state.displayedPictres.length - 1) % this.state.displayedPictres.length]}
                        onCloseRequest={() => this.setState({ open: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (this.state.photoIndex + this.state.displayedPictres.length - 1) % this.state.displayedPictres.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (this.state.photoIndex + 1) % this.state.displayedPictres.length,
                            })
                        }
                    />
                )}

                <Dialog
                    fullScreen={this.props.fullScreen}
                    open={this.state.openFeedback}
                    onClose={this.handleCloseFeedback}
                    fullWidth={true}
                >
                    {this.state.leftFeedback === null && <DialogTitle id="form-dialog-title"><FormattedMessage id="deliveries.feedback_dialog_title" /></DialogTitle>}

                    {this.state.leavingFeedback &&
                        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <CircularProgress fullWidth variant="determinate" value={this.state.dropboxProgress} className={classes.progress} />
                        </DialogContent>
                    }

                    {(!this.state.leavingFeedback && this.state.leftFeedback !== null) &&
                        <DialogContent>
                            <Grid container justify={"center"} className={classes.root}>
                                <Grid item xs={12}>
                                    <Typography align={"center"}><Icon style={{ color: green[600], fontSize: 120 }}>check_circle</Icon></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h3" style={{ margin: 15 }} align={"center"}><FormattedMessage id="deliveries.received_feedback" /></Typography>
                                </Grid>
                            </Grid>

                        </DialogContent>
                    }

                    {(!this.state.leavingFeedback && this.state.leftFeedback === null) &&
                        <DialogContent>
                            <DialogContentText>
                                <FormattedMessage id="deliveries.explain_feedback" />
                            </DialogContentText>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                fullWidth
                                value={this.state.multiline}
                                onChange={this.handleFeedbackChange}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </DialogContent>
                    }

                    {this.state.leftFeedback === null &&
                        <DialogActions>
                            <Button disabled={this.state.leavingFeedback} onClick={this.handleCloseFeedback} color="primary">
                                <FormattedMessage id="deliveries.cancel" />
                            </Button>
                            <Button disabled={this.state.leavingFeedback} onClick={this.handSendFeedback} color="primary">
                                <FormattedMessage id="deliveries.send_feedback" />
                            </Button>
                        </DialogActions>
                    }

                    {this.state.leftFeedback !== null &&
                        <DialogActions>
                            <Button onClick={this.handleCloseFeedback} color="secondary"><FormattedMessage id="deliveries.close" /></Button>
                            <LinkButton color="primary" to={"/tickets/" + this.state.leftFeedback.ticket.id}>
                                deliveries.view_left_feedback
                    </LinkButton>
                        </DialogActions>
                    }

                </Dialog>

                {(this.state.loading == true) ? (<PleaseWait />) : (
                    <div>
                        {row ? (
                            <div>
                                <div className={classes.topButtons}>
                                    <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                                        {row.address + (row.app ? ", # " + row.app + " " : "") + ", " + row.city}
                                    </Typography>
                                    {row.pictures.length > 0 &&
                                        <Button style={{ display: "flex", flexGrow: 1 }} disabled={this.state.savingToDropbox} onClick={() => { this.saveToDropbox(row.pictures.filter((pic)=>pic.thumbnail_url!=null)); }} variant="outlined" color="primary">
                                            {this.state.savingToDropbox &&
                                                <CircularProgress size={18} />
                                            }
                                            {!this.state.savingToDropbox &&
                                                <span style={{ display: "inherit", justifyContent: "inherit", alignItems: "inherit", verticalAlign: "middle" }}><FormattedMessage id="deliveries.dropbox" /><img style={{ paddingLeft: 5 }} height={20} src={logo_dropbox} /></span>
                                            }
                                        </Button>
                                    }
                                    {row.pictures.length > 0 &&
                                        <Button style={{marginLeft:10}} onClick={this.downloadAllFiles} variant="outlined" color="primary">
                                            <FormattedMessage id="deliveries.download_all" />
                                        </Button>
                                    }
                                </div>
                                {row.pictures.length > 0 &&
                                    <GridList fullWidth cols={(width == "xl") ? 5 : (width == "lg") ? 4 : (width == "md") ? 3 : (width == "sm") ? 2 : 1} spacing={1} className={classes.gridList}>
                                        {row.pictures.filter((pic)=>pic.thumbnail_url!=null).map((picture, i) => {
                                            return (
                                                <GridListTile key={picture.id} cols={1} rows={1}>
                                                    <img style={{ cursor: "pointer" }} onClick={() => { this.handleClickOpen(row.pictures.filter((pic)=>pic.thumbnail_url!=null), i); }} src={picture.thumbnail_url+momentofopen} />
                                                </GridListTile>
                                            );
                                        })}
                                    </GridList>
                                }

                                {this.state.listing.show_mesures == true ? this.generateFloorplanMeasures(row.rooms) : null}

                                <Line/>

                                {
                                    this.state.listing.video_360_url ?                            

                                    (<div className={classes.videoContainer}>   
                                                                             
                                        {                                            
                                            this.state.listing.facebook_video_360_url ? 
                                            (                                            
                                            <div style ={{display:"flex", flexDirection:"column"}}>
                                                <FacebookShareButton 
                                                    url={this.state.listing.facebook_video_360_url}
                                                    >   
                                                    <div style={{display:"flex", justifyContent:"space-between", cursor: "pointer" }}>
                                                        <Typography style={{ display: "flex"}} gutterBottom variant="h5" component="h2">
                                                            Vidéo 360
                                                        </Typography>
                                                        
                                                        <div style={{display:"flex"}}>
                                                            <FacebookIcon size={32} round={false} />
                                                        
                                                            <Typography style={{fontWeight: 'bold', marginLeft: 20, alignSelf:'center' }} gutterBottom variant="h7" component="h4">
                                                                Partager la vidéo sur Facebook
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </FacebookShareButton>
                                                <div style={{marginTop: 20, alignSelf:"flex-end"}}>
                                                    <Button    onClick={ ()=>{window.open(this.state.listing.video_360_url,'_blank')} } color="primary"> Télécharger </Button>
                                                </div>
                                            </div>
                                            ) 
                                            : (
                                                <Typography style={{ display: "flex"}} gutterBottom variant="h5" component="h2">
                                                    Vidéo 360
                                                </Typography>
                                            ) 
                                        }
                                        <div style={{display:'flex', justifyContent:'center', marginTop:30}}>
                                            <ReactPlayer 
                                                url={this.state.listing.video_360_url+momentofopen}
                                                controls width={'60%'}
                                            />
                                        </div>
                                    </div>) : null
                                }

                                {
                                    this.state.listing.video_service_offer_media.map((so)=>(
                                        <>
                                            <Line/>
                                            <div style={{marginBottom:30}}>
                                                <div className={classes.virtualVisitContainer}>
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                    {so.name}
                                                    </Typography>
                                                </div>

                                                <TextField
                                                        id="outlined-multiline-flexible"
                                                        value={so.url}
                                                        className={classes.virutalVisitTextField}
                                                        margin="normal"
                                                        variant="outlined"
                                                    />
                                                <Button onClick={()=>this.copyLink(so.url)} >Copier le lien</Button>
                                                <div style={{display:'flex', justifyContent:'center', marginTop:30}}>
                                                    <ReactPlayer 
                                                        url={so.url+momentofopen}
                                                        controls width={'60%'}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ))
                                }

                                {
                                    this.state.listing.iframe_service_offer_media.map((so)=>(
                                        <>
                                            <Line/>
                                            <div style={{marginBottom:30}}>
                                                <div className={classes.virtualVisitContainer}>
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                    {so.name}
                                                    </Typography>
                                                </div>

                                                <TextField
                                                        id="outlined-multiline-flexible"
                                                        value={so.url}
                                                        className={classes.virutalVisitTextField}
                                                        margin="normal"
                                                        variant="outlined"
                                                    />
                                                <Button onClick={()=>this.copyLink(so.url)} >Copier le lien</Button>
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                    <Iframe url={so.url}
                                                        width="100%"
                                                        height="700px"
                                                        id="myId"
                                                        className="myClassname"
                                                        display="initial"
                                                        position="relative"/>
                                                </div>

                                            </div>
                                        </>
                                    ))
                                }

                                <Line/>

                                {
                                    ((typeof this.state.listing.markers_count == "undefined" || this.state.listing.markers_count > 0) && this.state.listing.state == 3) ? (
                                    <div style={{marginBottom:30}}>
                                        <div className={classes.virtualVisitContainer}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Visite virtuelle sur le web:
                                            </Typography>
                                        </div>
                                        <Typography>Afin de mettre la visite virtuelle en ligne, copiez/collez ce lien dans la section "Visite Virtuelle" lors de l'inscription de votre propriété.

                                        </Typography>
                                        <TextField
                                                id="outlined-multiline-flexible"
                                                value={"https://viewer.hausvalet.ca/visit/" + this.state.listing.id}
                                                className={classes.virutalVisitTextField}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        <Button onClick={()=>this.copyLink("https://viewer.hausvalet.ca/visit/" + this.state.listing.id)} >Copier le lien</Button>
                                        <div style={{display:'flex', justifyContent:'center'}}>
                                            <Iframe url={"https://viewer.hausvalet.ca/visit/" + this.state.listing.id}
                                                width="100%"
                                                height="700px"
                                                id="myId"
                                                className="myClassname"
                                                display="initial"
                                                position="relative"/>
                                        </div>

                                    </div>) : null
                                }

                                <Line/>

                                {
                                    (this.state.listing.automated_videos && this.state.listing.automated_videos.length) &&
                                    <div style={{marginBottom:30}}>
                                        <div className={classes.virtualVisitContainer}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Photo Exposition
                                            </Typography>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignContent: 'center'}}>
                                        {   
                                            this.getAutomatedVideos()
                                                .map(video => {
                                                return (
                                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                                                        <Typography variant="h5" gutterBottom align="center">
                                                            {
                                                                video.is_instagram === true
                                                                ?   `Instagram`
                                                                :   video.broker_team_member_id != null
                                                                    ?   `Team Member Included`
                                                                    :   video.is_broker_photo_visible == true
                                                                        ?   `Solo With Image`
                                                                        :   `Solo Without Image`
                                                            }
                                                        </Typography>
                                                        <video width="320" height="240" controls>
                                                            <source src={video.url} type="video/mp4" />
                                                        </video>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>

                                    </div>
                                }
                                
                            </div>
                        ) :
                            (
                                <HausError
                                style={{ marginTop: 15, marginBottom: 15 }}
                                variant="error"
                                message="deliveries.cannot_find"
                            />
                            )

                        }
                    </div>
                )
                }
            </div>
        );
    }
}

ViewBeautyDelivery.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withWidth()(withMobileDialog()(injectIntl(withRouter(withStyles(styles)(ViewBeautyDelivery)))));
