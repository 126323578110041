import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';

import Link from '@material-ui/core/Link';
import { throws } from 'assert';
 
import './admin.css';
class HmdEdit extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.show,
      confirm:this.props.confirm,
      search:'',
      hmd: this.props.hmd,
      list:[],
      changed:false
    };
  }

  componentWillMount() {
    window.Hauslife.adminUsersSearch('')
    .then((res) => {
        //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
        this.setState({ list: res.data.result,loaded:true });
        //  this.setState({ticket:res.data.data, is_reloading:false});
    })
    .catch((e) => {
        console.log(e);
    });
  }

  handleClose = (button,data) => () =>{
       
    if(button=='ok') {
        if(this.state.changed==true){
            var payLoad = { id:this.state.hmd.id,
                user_id:this.state.hmd.user_id,
                notes:this.state.hmd.notes                     
              };
            //console.log('payLoad',payLoad);

            window.Hauslife.adminEditHmd(payLoad).then((res) => {
                this.props.didClose(button,data);
            }).catch((e) => {
                console.log(e);
            });

        } else {
            this.props.didClose(button,data);
        }
        return;
    }

    this.props.didClose(button,data);
  };

  handleSearch = ( event) =>{

    this.setState({search:event.target.value});
 
    window.Hauslife.adminUsersSearch(event.target.value)
    .then((res) => {
        //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
        //console.log( res.data.result  )
        this.setState({ list: res.data.result,loaded:true });
        //  this.setState({ticket:res.data.data, is_reloading:false});
    })
    .catch((e) => {
        console.log(e);
    });
  }

  handleChange  = (name,event) => {
    
    this.setState({changed:true, hmd :{...this.state.hmd, notes:event.target.value }});

  }

  handleClick = (user) =>{
    this.setState({changed:true,hmd :{...this.state.hmd, user_id:user.id, user:user }});
  }

  assigedto(){

    if (this.state.hmd.user_id==null) return '';
    var str = this.state.hmd.user.firstname+' '+ this.state.hmd.user.lastname;

     return( <>  
                  <span style={{fontWeight:'bold'}}>Assigné à : </span>
                  <span style={{marginLeft:5,marginRight:5}} > {str}</span>
                  <span style={{fontWeight:'bold'}}>id :</span>
                  <span style={{marginLeft:5,marginRight:5}}> {this.state.hmd.user.id}</span>
                  <span style={{fontWeight:'bold'}}>Code :</span>
                  <span style={{marginLeft:5,marginRight:5}}> {this.state.hmd.user.broker_code}   </span>
      </> );
}
  componentWillReceiveProps({someProp}) {
    this.setState({...this.state,someProp})
  }
  render() {
  

    return (
      <div>
        
        <Dialog
        fullWidth={true}
         disableBackdropClick
         disableEscapeKeyDown
          open={this.props.show}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

                <Paper style={{margin:5,padding:5,textAlign:'center'}}>{this.state.hmd.serial_number}  </Paper>

                <Paper style={{margin:5,marginTop:10,padding:5,textAlign:'center'}}>{this.assigedto()} </Paper>
                <Link   style={{ position:'absolute',right:0,marginRight:29}} 
                        component="button"
                        variant="body2"
                        onClick={() => {
                            this.setState({changed:true,hmd :{...this.state.hmd, user_id:null }});
                        }}> Déassigner  </Link>
                <TextField
                            fullWidth={true}
                            label="Recherche"
                            value={this.state.search}
                            onChange={(event)=>this.handleSearch(event)}
                            margin="normal"
                        />
                        <Paper className={"hmdUserList"} style={{}}>
                            <Table  >
                                    
                                <TableBody>
                                    {this.state.list.map(row => (
                                        <TableRow  style={{cursor:'pointer' }}key={row.id} onClick={event => this.handleClick(row)}>
                                            <TableCell className="listing-list-cell">{row.firstname + ' '+ row.lastname}</TableCell>
                                            
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </Paper>


                <TextField
                            fullWidth={true}
                            label="Note"
                            value={this.state.hmd.notes}
                            onChange={(event)=>this.handleChange('notes',event)}
                            margin="normal"
                        />
                        
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            
            <Button onClick={this.handleClose('ok',this.props.data)} color="primary" autoFocus>
              Ok
            </Button>

            
              <Button onClick={this.handleClose('cancel',this.props.data)} color="primary"  >
                cancel
              </Button>
            
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default  HmdEdit;
