import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';


import Icon from '@material-ui/core/Icon';
import FolderIcon from '@material-ui/icons/Folder';

import ShootingTimeInformation from './ShootingTimeInformation'

import * as moment from 'moment';
import 'moment/locale/fr-ca';

var _ = require('lodash');

const styles = theme => ({
    root: {
        margin: theme.spacing.unit * 3
    },
    innerGrid: {
        margin: 0
    },
    dateField: {
        marginTop:20,
        marginBottom:20,
        display:'flex'
    }
});

/**
 * Class used to view a single ticket
 */
class RemoteInjectorStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = { files_on_server:[], remote_cards: [], startDate: moment().format('YYYY-MM-DD'), is_reloading: false, is_reloading_files: false };
    }

    componentWillMount() {
        this.reloadCards();
        this.reloadFilesOnServer();
    }

    reloadCards = () => {
        this.setState({is_reloading:true},()=>{
            window.Hauslife.rij_getStatesByDate(moment(this.state.startDate).format('DD-MM-YYYY'))
                .then((res) => {
                    

                    let cardData = res.data.cards;

                    let tempMap = {};

                    cardData.forEach((state)=>{
                        if(tempMap[state.sd_card] == undefined)
                        {
                            tempMap[state.sd_card] = {sd_card:state.sd_card, states:[]};
                        }

                        tempMap[state.sd_card].states.push(state);
                    });

                    var arr = _.values(tempMap);

                    this.setState({ remote_cards: arr, is_reloading: false });
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ is_reloading: false });
                });
        });
    };

    reloadFilesOnServer = () =>
    {
        this.setState({is_reloading_files:true},()=>{
        window.Hauslife.getFilesForRemoteInjection(moment(this.state.startDate).format('DD-MM-YYYY'))
            .then((res) => {
                this.setState({ files_on_server: res.data.files, is_reloading_files: false });
            })
            .catch((e) => {
                console.log(e);
                this.setState({ is_reloading_files: false });
            });
        });
    }

    handleDateChange = (event) => {
        this.setState({ startDate: event.target.value }, () => {
            this.reloadCards();
            this.reloadFilesOnServer();
        });

    }

    stateToIcon = (state) =>
    {
        var icoName = "backup";

        if(state == "upload") icoName = "backup";
        if(state == "tar") icoName = "archive";
        if(state == "copy") icoName = "filter9plus";

        return (<Icon style={{marginRight:10}} title={state}>{icoName}</Icon>);
    }

    drawRemoteCard = (card) => {
        
        return(
            <>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <FolderIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={card.sd_card}
                        secondary={
                            card.states.map(state=>
                            <p>{this.stateToIcon(state.state)}{moment(state.end_date).toLocaleString() +  " and took " + moment.duration((moment(state.end_date).diff(moment(state.start_date)))).humanize()}</p>
                            )
                        }
                    />
                </ListItem>
                
            </>
        );
    }

    humanFileSize = (size) => {
        var i = Math.floor( Math.log(size) / Math.log(1024) );
        return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    };

    downloadFile = (filename) => {
        window.Hauslife.getFileDownloadLink(filename)
        .then(res=>{
            if(res.data.status == 200)
            {
                //console.log(res.data.url);
                var win = window.open(res.data.url, '_blank');
                win.focus();
            }
        })
        .catch(e=>{
            window.alert("Cannot fetch download link.");
            console.log(e);
        });
    }

    drawRemoteFile = (file) => {
        const updateTime = moment(file.updated);
        const deletionTime = moment(file.updated).add(90,"days");
        return(
            <>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <Icon>description</Icon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={file.name}
                        secondary={
                            <>
                                <p>{this.humanFileSize(file.size)}</p>
                                <p title={updateTime.toLocaleString()}>{"Updated : " + updateTime.fromNow()}</p>
                                <p title={deletionTime.toLocaleString()}>{"Will be deleted in " + moment.duration(deletionTime.diff(moment())).humanize()}</p>
                                <Button onClick={()=>this.downloadFile(file.name)}>Download</Button>
                            </>
                            
                        }
                    />
                </ListItem>
                
            </>
        );
    }

    render() {
        const { classes, match, intl } = this.props;

        return (
            <Grid container>
                
                {/*<Grid className={classes.innerGrid} xs={12} sm={6}>
                    {this.state.is_reloading === true ?
                    (
                        <PleaseWait />
                    )
                    :
                    (
                        <>
                            <b>Files status (Generated by remote injector)</b>
                            <List>
                                {this.state.remote_cards.map(card => (
                                    this.drawRemoteCard(card)
                                ))}
                            </List>
                        </>
                    )}
                </Grid>*/}
                <Grid className={classes.innerGrid} xs={12} sm={6}>
                    {this.state.is_reloading_files === true ?
                    (
                        <PleaseWait />
                    )
                    :
                    (
                        <>
                            <b style={{marginTop:'10px'}}>Files in the cloud</b>
                            <TextField
                                id="date"
                                label="Date "
                                type="date"
                                disabled={this.state.is_reloading}
                                value={this.state.startDate}
                                className={[classes.textField, classes.dateField]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={this.handleDateChange}
                            />
                            <List>
                                {this.state.files_on_server.map(file => (
                                    this.drawRemoteFile(file)
                                ))}
                            </List>
                        </>
                    )}
                </Grid>
            </Grid>
        );
    }
}

RemoteInjectorStatus.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(withStyles(styles)(RemoteInjectorStatus)));
