import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

class AlertDialog extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.show,
      confirm:this.props.confirm
    };
  }
  handleClose = (button,data) => () =>{
    this.props.didClose(button,data);
  };

  componentWillReceiveProps({someProp}) {
    this.setState({...this.state,someProp})
  }
  render() {
    return (
      <div>
        
        <Dialog
         disableBackdropClick
         disableEscapeKeyDown
          open={this.props.show}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <Typography id="alert-dialog-description" style={{whiteSpace: 'pre-line'}}>
               {this.props.text}
            </Typography>
          </DialogContent>
          <DialogActions>
            
            <Button onClick={this.handleClose('ok',this.props.data)} color="primary" autoFocus>
              Ok
            </Button>

            { this.props.confirm ==true &&
              <Button onClick={this.handleClose('cancel',this.props.data)} color="primary"  >
                cancel
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
