import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import HausButton from './HausButton'

import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';

var _ = require('lodash');

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing.unit,
    }
});

class ViewDepots extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            depots: [],
            loaded: false,
            search:"",
            showingConfirmDelete:false,
            deletionError:null,
            deletingDepot:null,
            callingDeletingDepot:false
        };
    }

    componentDidMount() {
        this.fetchDepots();
    }

    fetchDepots = () =>
    {
        window.Hauslife.getDepots()
            .then((res) => {
                //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
                this.setState({ depots: res.data.depots });
                //  this.setState({ticket:res.data.data, is_reloading:false});
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleClick = (event, depot) => {
        this.props.history.push('/depots/edit/' + depot.id, {depot:depot});
    }

    handleClickDelete = (event, depot) => {
        this.setState({deletionError: null, deletingDepot: null},()=>{
            this.showDeleteConfirmForDepot(depot);
        });
    }

    handleChangeRole = (event) => {
        this.setState({selectedRole:event.target.value});
    }

    handleChangeSearch = (event) => {
        this.setState({search:event.target.value});
    }

    showDeleteConfirmForDepot = (depot) => 
    {
        this.setState({showingConfirmDelete:true, deletingDepot: depot});
    }

    handleClose = () =>
    {
        this.setState({showingConfirmDelete:false});
    }

    handleCloseConfirmed = () =>
    {
        this.setState({showingConfirmDelete:false, callingDeletingDepot: true}, ()=>{
            window.Hauslife.removeDepot(this.state.deletingDepot.id)
            .then((res) => {
                //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
                this.setState({ deletingDepot: null, deletionError: null, callingDeletingDepot: false }, ()=>{
                    this.fetchDepots();
                });
                //  this.setState({ticket:res.data.data, is_reloading:false});
            })
            .catch((e) => {
                if(e.response.data.detail != undefined)
                {
                    if(e.response.data.detail.includes('is still referenced from table "hauslife_user"'))
                    {
                        e.message = "Depot is still assigned to one or more users. Please remove the depot from all users.";
                    }
                }

                this.setState({showingConfirmDelete:true,deletionError:e, callingDeletingDepot: false});
            });
        });
    }

    render() {
        const { classes } = this.props;
        let rows = this.state.depots;

        let search = this.state.search;
        if(search != "")
        {
            rows = _.filter(rows, function(o) { return o.street.toLowerCase().includes(search) || o.civic_number.toLowerCase().includes(search); });
        }

        return (
            <>
                <Dialog
                    open={this.state.showingConfirmDelete}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete confirmation"}</DialogTitle>
                    <DialogContent>
                        {this.state.deletionError != null &&
                            <SnackbarContent style={{backgroundColor:"darkred",maxWidth:"98%", margin:"1%", marginBottom:30}} message={this.state.deletionError.message} />
                        }

                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the depot {this.state.deletingDepot != null ? " at " + this.state.deletingDepot.civic_number + " " + this.state.deletingDepot.street + " ?" : " ?"}
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                    <HausButton onClick={this.handleClose} color="primary">
                        Cancel
                    </HausButton>
                    <HausButton onClick={this.handleCloseConfirmed} color="primary" autoFocus>
                        Delete
                    </HausButton>
                    </DialogActions>
                </Dialog>
                <Grid direction="column"
                justify="flex-start"
                alignItems="stretch" container>
                    <Grid item xs={12} style={{marginBottom:20}}>
                        <Paper style={{padding:10}}>
                            <Button style={{width:"18%"}} component={Link} to="/depots/add" variant="outlined" color="inherit" className={styles.button}>
                                Ajouter
                            </Button>
                            <div style={{width:"100%"}}>
                                <TextField
                                    fullWidth
                                    label="Search"
                                    value={this.state.search}
                                    onChange={this.handleChangeSearch}
                                    margin="normal"
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper style={{overflowX:"auto"}} className={styles.root}>
                            <Table className={styles.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Depot Id</TableCell>
                                        <TableCell>LNG</TableCell>
                                        <TableCell>LAT</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                        <TableRow className={classes.tableRow} key={row.id}>
                                            <TableCell component="th" scope="row">{row.id}</TableCell>
                                            <TableCell >{row.lng}</TableCell>
                                            <TableCell>{row.lat}</TableCell>
                                            <TableCell>{row.civic_number + " " + row.street + ", " + row.city}</TableCell>
                                            <TableCell>
                                                <HausButton disabled={this.state.callingDeletingDepot} loading={this.state.callingDeletingDepot} style={{marginRight:5}} size={"small"} onClick={event => this.handleClick(event, row)} variant="outlined" className={styles.button}>
                                                    Modifier
                                                </HausButton>
                                                <HausButton disabled={this.state.callingDeletingDepot}  loading={this.state.callingDeletingDepot} size={"small"} color={"secondary"}  onClick={event => this.handleClickDelete(event, row)} variant="outlined" className={styles.button}>
                                                    Supprimer
                                                </HausButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withRouter(withStyles(styles)(ViewDepots));
