import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import VideocamIcon from "@material-ui/icons/Videocam";
import Copyright from "@material-ui/icons/Copyright";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import ImageIcon from "@material-ui/icons/Image";
import AirplanemodeActiveIcon from "@material-ui/icons/AirplanemodeActive";
import { Tooltip, Box } from "@material-ui/core";

const styles = {
  status_icon: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "white",
    borderRadius: "50%",
    zIndex: 1,
    padding: 2,
  },
  video_selection_icon: {
    position: "absolute",
    bottom: "8px",
    left: "8px",
    backgroundColor: "white",
    borderRadius: "50%",
    zIndex: 1,
    padding: 2,
  },
  Copyright_icon: {
    position: "absolute",
    bottom: "8px",
    right: "8px",
    backgroundColor: "white",
    borderRadius: "50%",
    zIndex: 1,
    padding: 2,
  },
  visibilityIcon: {
    position: "absolute",
    top: "8px",
    left: "8px",
    zIndex: 1,
  },
  pictureTypeBox: {
    display: "flex",
    justifyContent: "space-around",
    padding: 8,
    backgroundColor: "#a6a6a6",
    color: "white",
  },
  pictureTypeIcon: {
    fontSize: "2.5em",
  },
};

const icon_style = (extraStyle) => {
  return {
    fontSize: "2em",
    ...extraStyle,
  };
};

const watermarkText = "Noter qu'il vous sera impossible d'ajouter un watermark sur une image acheter par le courtier";
const visibilityText = "Noter qu'il vous sera impossible de changer la visibilité d'une image acheter par le courtier";

const CardContent = ({
  url,
  picture,
  imageClick,
  onVideoSelectionToggle,
  onVisibilityToggle,
  onWatermarkToggle,
  pictureServiceOfferId,
  handlePictureTypeChange
}) => (
  <div className="card-outer">
    <div className="card-inner">
      <Tooltip
        title={<h2 style={{ fontWeight: "500" }}>{visibilityText}</h2>}
        maxWidth="none"
        placement="top"
        disableHoverListener={!picture.public || !picture.is_purchased}
      >
        <VisibilityIcon
          style={{
            ...icon_style(styles.visibilityIcon),
            ...{ color: "white" },
            ...{ opacity: picture.public ? "100%" : "75%" },
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!picture.is_purchased) {
              onVisibilityToggle(picture.id, !picture.public);
            }
          }}
        />
      </Tooltip>

      {picture.is_validated ? (
        <CheckCircleOutlineIcon
          style={{ ...icon_style(styles.status_icon), ...{ color: "green" } }}
          onClick={imageClick}
        />
      ) : picture.is_validated === false ? (
        <BlockIcon
          style={{ ...icon_style(styles.status_icon), ...{ color: "red" } }}
          onClick={imageClick}
        />
      ) : (
        <RadioButtonUncheckedIcon
          style={{ ...icon_style(styles.status_icon), ...{ color: "grey" } }}
          onClick={imageClick}
        />
      )}


      <VideocamIcon
        style={{
          ...icon_style(styles.video_selection_icon),
          ...{ color: "green" },
          ...{ opacity: picture.included_in_video ? "100%" : "50%" },
        }}
        onClick={(e) => {
          e.stopPropagation();
          onVideoSelectionToggle(picture.id, !picture.included_in_video);
        }}
      />

      <Tooltip
        title={<h2 style={{ fontWeight: "500" }}>{watermarkText}</h2>}
        maxWidth="none"
        placement="top"
        disableHoverListener={picture.show_watermark || !picture.is_purchased}
      >
        <Copyright
          style={{
            ...icon_style(styles.Copyright_icon),
            ...{ color: picture.show_watermark ? "red" : "grey" },
            ...{ opacity: picture.show_watermark && !picture.is_purchased ? "100%" : "50%" },
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!picture.is_purchased && picture.public) {
              onWatermarkToggle(picture.id, !picture.show_watermark);
            }
          }}
        />
      </Tooltip>

      <img
        src={url}
        style={{
          filter: picture.public ? "" : "grayscale(1)",
          cursor: "pointer",
          maxWidth: "100%",
          height: "auto",
        }}
        draggable="false"
      />
    </div>
    <Box style={styles.pictureTypeBox}>
      <ImageIcon
        style={{
          ...icon_style(styles.pictureTypeIcon),
          ...{ opacity:picture.service_offer_id == pictureServiceOfferId.traditional ? 1 : 0.5 },
        }}
        onClick={(e)=>{
            e.stopPropagation();
            handlePictureTypeChange(picture.id, 'traditional');
        }}
      />
      <AirplanemodeActiveIcon
        style={{
          ...icon_style(styles.pictureTypeIcon),
          ...{ opacity: picture.service_offer_id == pictureServiceOfferId.drone ? 1 : 0.5 },
        }}
        onClick={(e)=>{
            e.stopPropagation();
            handlePictureTypeChange(picture.id, 'drone');
        }}
      />
      <Brightness4Icon
        style={{
          ...icon_style(styles.pictureTypeIcon),
          ...{ opacity: picture.service_offer_id == pictureServiceOfferId.blueHour ? 1 : 0.5 },
        }}
        onClick={(e)=>{
            e.stopPropagation();
            handlePictureTypeChange(picture.id, 'blueHour');
        }}
      />
    </Box>
  </div>
);

export default CardContent;
