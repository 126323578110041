import React from 'react';
import { withRouter } from "react-router-dom";
import SuperCrud from '../components/SuperCrud'

const ServiceOfferTypes = {
    'boolean': 1,
    'number': 2
}


//TODO load this from API
const Subscriptions = {
    'Base': 'Base',
    '10% Discount': 'Rabais 10',
    '20% Discount': 'Rabais 20',
    '30% Discount': 'Rabais 30'
}

const BaseForm = {
    id: {
        "type": 'number',
        "editable": false
    },
    key_name: {
        "type": 'text',
        "editable": true,
        "required": true
    },
    display_name: {
        "type": 'text',
        "editable": true,
        "required": true
    },
    display_name_fr: {
        "type": 'text',
        "editable": true,
        "required": true
    },
    description: {        
        "type": 'multiline',
        "editable": true,
        "required": true
    },
    description_fr: {
        "type": 'multiline',
        "editable": true
    },
    price: {
        "type": 'number',
        "editable": true,
        "required": true
    },
    type: {
        "type": 'select',
        "editable": true,
        "required": true,
        options: ServiceOfferTypes
    },
    processing_time_in_minutes: {
        "type": 'number',
        "editable": true,
        "required": true
    },
    zoho_item_id: {
        "type": 'number',
        "editable": true,
        "required": true
    },
    order_index: {
        "type": 'number',
        "editable": true,
        "required": true
    },
    available: {
        "type": 'boolean',
        "editable": true,
        "required": true
    },
    is_global: {
        "type": 'boolean',
        "editable": true,
        "required": true
    },
    subscription: {
        "type": 'select',
        "editable": true,
        "required": true,
        options: Subscriptions
    },
    require_mls: {
        "type": 'boolean',
        "editable": true,
        "required": true
    }
}

const ManageServiceOffer = () => {
    return (
        <SuperCrud 
            superFetchAll={window.Hauslife.getAllServiceOffers.bind(this)}
            superDelete={window.Hauslife.deleteServiceOffer.bind(this)}
            superCreate={window.Hauslife.createServiceOffer.bind(this)}
            superUpdate={window.Hauslife.updateServiceOffer.bind(this)}
            title={'Service Offer Management'} 
            display_name_key={'key_name'}
            baseForm={BaseForm}
        />
    )
}

export default withRouter(ManageServiceOffer);