import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';

class ActivityButton extends React.Component {
    render()
    {
        return(
            <Button disabled={this.props.isWorking} {...this.props}>
                {this.props.isWorking ? (
                    <CircularProgress size={18} />
                ) : (
                    <FormattedMessage id={this.props.children} />
                )}
            </Button>
        );
    }
}

ActivityButton.propTypes = {
    isWorking: PropTypes.bool.isRequired,
};


export default ActivityButton;
  