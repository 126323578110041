import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PleaseWait from './PleaseWait.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CentrisBroker from './CentrisBroker';
import InputIcon from '@material-ui/icons/Input';
import {SortIcon} from './SuperLog';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';  
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import './admin.css';
import * as moment from 'moment';
//import { VictoryBar, VictoryChart ,VictoryLine,VictoryAxis} from 'victory';

class FilterSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {currentFranchise:this.props.value};
      }

    handleFranchiseChange = name => event => {
        var value = event.target.value;
        this.setState({currentFranchise:value});

        if(this.props.onChange!=undefined){
            this.props.onChange(value);
        }
      }

    render() {
     
        return (
            <div style={{marginRight:10,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center' }}>
                <span> {this.props.title}</span>
                <Select
                    style={{marginLeft:4,minWidth:150}}
                    value={this.state.currentFranchise}
                    onChange={this.handleFranchiseChange()}
                    input={<Input id="select-multiple" />}>
        
        
                    <MenuItem key={-1} value={null} >
                        
                    </MenuItem>
                    {this.props.franchiseList.map(item => (
                    <MenuItem key={item.code} value={item.code} >
                        {item.legal_name}
                    </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }
  }

class CentrisReport extends React.Component {
    constructor(props) {
        super(props);
        this.scrollHeight=0;
        this.bfbUrlObj=null;
        this.state = {
            error:false,
            loaded: false,
            dlgShow:false,
            sort:{
                order:'count',
                sort:'desc'
            },
            tab:'broker',
            currentFranchise:null,
            currentBanner:null,
            franchiseList:[],
            bannerList:[],

            data:[]

        };
    }

    franchisesMeatGrinder (meat) {
       
        var grinded=  Object.keys(meat).reduce( (acc,value,idx)=>{

            var franchise = meat[value];
            franchise.code=idx;
            if(acc[franchise.banner_code]==undefined){
                acc[franchise.banner_code]={name:franchise.banner,
                                            data:[],
                                            centrisTotal:0,
                                            hausTotal:0,
                                            pcent:0 ,
                                        };
            }
 
            acc[franchise.banner_code].data.push(franchise);
            acc[franchise.banner_code].centrisTotal += franchise.centrisCount;
            acc[franchise.banner_code].hausTotal += franchise.hausCount;

            return acc;

        },{});

        Object.keys(grinded).forEach( (i)=>{
            var value=grinded[i];
 
            value.pcent = value.hausTotal / value.centrisTotal *100;
            value.data = value.data.sort ( (a,b)=>{
                if (a.centrisCount > b.centrisCount ) {
                    return -1;
                  }
                  if (a.centrisCount < b.centrisCount ) {
                    return 1;
                  }
                  if(a.centrisCount==b.centrisCount){
                    return 0;
                  }
            } );
        });
        return grinded;
    }

    componentWillMount() {

        window.Hauslife.getCentrisBanner()
                .then((res) => {
                    //('  DATA',res.data.data);
                    var data = res.data.result;
                    
                    this.setState({bannerList:data});
                })
                .catch((e) => {
                    this.setState({loaded:true,error:true,list:[]});
                    console.log(e);
                });
        window.Hauslife.getCentrisFranchises()
                .then((res) => {
                    //('  DATA',res.data.data);
                    var data = res.data.result;
                    
                    this.setState({franchiseList:data});
                })
                .catch((e) => {
                    this.setState({loaded:true,error:true,list:[]});
                    console.log(e);
                });
        
        this.executeQuery(0);
        }

    executeQuery(offset ){
         
        window.Hauslife.centrisBrokerReport({banner:this.state.currentBanner,
                                             franchise: this.state.currentFranchise,
                                             offset:offset,
                                             sortColumn:this.state.sort.order,
                                             sortDir:this.state.sort.sort
                                            })
        .then((res) => {
             
            var data = res.data.data;
            var offset= res.data.offset;
          
            if(offset==0){
                this.setState({loaded:true,
                               error:false,
                               data:data.data,
                               franchises: this.franchisesMeatGrinder( data.franchises),
                               count:data.data.length
                              });
            } else {
                var newList = this.state.data.concat( data.data);
                this.setState({loaded:true,error:false,data:newList , count:data.data.length });
            }
            
        })
        .catch((e) => {
            this.setState({loaded:true,error:true,list:[]});
            console.log(e);
        });

    }

    alertClose = (button,data) => {
            this.setState({dlgShow: false,alertShow:false});
    }

    handleClick = (event, broker) => {
       // console.log('broker',broker);
        this.setState({dlgShow:true,selectedBroker:broker.code});
    }

    showBrokerListing= (event, broker) => {
        // console.log('showBrokerListing',broker);
         event.stopPropagation();
         window.open(`/centris/broker/${broker.code}/`,'_blank');
     }

    sortColumn = (name)=>{
        if(this.state.sort.order==name){
            var dir = this.state.sort.sort=='desc'?'asc':'desc';
            this.setState({sort:{order:this.state.sort.order,sort: dir }},function(err,result){
                this.executeQuery (0 );
            });
        } else {
            this.setState({sort:{order:name,sort:'asc' } },function(err,result){
                this.executeQuery (0 );
            });
            //console.log({sort:{order:name,sort:'asc' }})
        }
    }

    rowColor(hausBroker){
       
        if(hausBroker===1){
            return 'lime';
        }

        if(hausBroker===3){
            return 'white';
        }
    
        if(hausBroker==2){
            return 'yellow';
        }
     }
    
    hausBrokerValue (hausBroker){
        if(hausBroker===1){
            return 'Oui';
        }

        if(hausBroker===3){
            return 'Non';
        }
    
        if(hausBroker==2){
            return 'Peut être';
        }
    }
    opacity(value){
        if(value<0.1 && value>0){
            return 0.1;
        }
        return value;
    }
    
    franchiseFilterChanged( code){
        this.setState({currentFranchise:code,loaded:false},(error,result)=>{
            this.executeQuery(0);
        });
    }
    bannerFilterChanged (code){
        this.setState({currentBanner:code,currentFranchise:null,loaded:false},(error,result)=>{
            this.executeQuery(0);
        });
    }

    filterList(row){

        if(this.state.currentBanner!=null){

            if(row.banner_code!=this.state.currentBanner){
                return false;
            }
        }

        if(this.state.currentFranchise==null){
            return true;
        }

        if(row.main_franchise==this.state.currentFranchise){
            return true;
        } else{
            return false;
        }
    }
    handleScroll  = e =>{
        let element = e.target;

        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            // do something at end of scroll
            console.log('end of the wtf');
            if(this.state.count!=0 && this.state.loaded==true){
                this.scrollHeight= element.scrollHeight;
                this.executeQuery(this.state.data.length);
            }
          }
    }
    downloadBroker(){

        var columns = ["code","firstname" ,"lastname","main_phone","email", "allListing","banner","franchise", "city","count", "hausBroker","marketListing" ,"suspended"];

        var header = columns.reduce( (acc,value,idx)=>{
             acc += `"${value}"`;
             if(idx<columns.length-1){
                 acc+=',';
             }else {
                 acc+='\n';
             }
             return acc;
        },'');
        
        var csv = this.state.data.reduce( (acc,value)=>{
            var row = columns.reduce( (acc,props,idx)=>{
                acc += '"'+String(value[props]).replace('"','""')+'"'; 
                if(idx<columns.length-1){
                    acc+=',';
                } else {
                    acc+='\n';
                }
                return acc;
            },'')
            acc +=row;
            return acc;
        },header);

        var blob = new Blob(
            [// new Uint8Array([0xEF, 0xBB, 0xBF]),
             '\ufeff' + csv ], // Blob parts.
            {
                type : "text/plain;charset=utf-8"
            }
        );

        if(this.bfbUrlObj!=null){
            URL.revokeObjectURL(this.bfbUrlObj);
        }

        var a = document.createElement('a');
        this.bfbUrlObj =window.URL.createObjectURL(blob);
        a.href = this.bfbUrlObj
        a.download = 'centris.csv';
        a.click();

    }
    render(){
        return (
                <Grid style={{ height: "100%", flexDirection: 'column' }} alignItems="stretch" justify="space-around" container>

                    {this.state.dlgShow==true &&
                        <CentrisBroker style={{width:'100%'}} show={this.state.dlgShow} didClose={this.alertClose}   brokerCode ={this.state.selectedBroker}/>
                    }
                    { this.state.loaded==false &&
                        <PleaseWait />
                    }
                    { this.state.loaded==true &&
                            <>
                            
                                <Grid xs={12} style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-start'}}>
                                    <Paper style={{display:'inline-block',cursor:'pointer',margin:10,padding:5,fontWeight:'bold',backgroundColor:(this.state.tab=='broker'?'whitesmoke':'white')}}
                                           onClick={()=>this.setState({tab:'broker'})}> 
                                        Courtiers
                                    </Paper>
                                    <Paper style={{display:'inline-block',cursor:'pointer',margin:10,padding:5,fontWeight:'bold',backgroundColor:(this.state.tab=='franchises'?'whitesmoke':'white')}}
                                      onClick={()=>this.setState({tab:'franchises'})}>  
                                        Franchises
                                    </Paper>
                                </Grid>
                            {this.state.tab=='broker' && 
                                <>
                                    <Grid xs={12} style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>

                                        <Button style={{marginRight:50}} onClick={()=>this.downloadBroker()} >Télécharger</Button>
                                        <FilterSelect title="Filtrer par Bannière"  franchiseList={this.state.bannerList.map( (i)=>{ return {code:i.code,legal_name:i.french_name}})} onChange={(code)=>this.bannerFilterChanged(code)} value={this.state.currentBanner}/>
                                        <FilterSelect title="Franchise"   franchiseList={this.state.franchiseList.filter ( (i)=>this.state.currentBanner!=null?(i.banner_code==this.state.currentBanner):true  )} onChange={(code)=>this.franchiseFilterChanged(code)} value={this.state.currentFranchise}/>
                                    </Grid>
                                    
                                    <Grid xs={12} style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                                    
                                        <Paper style={{margin:10,padding:5,fontWeight:'bold'}}>Total listing par courtiers</Paper>
                                        <div style={{maxWidth: '900px', width: '75%', maxHeight: '600px'}}>
                                            <Table size="small" stickyHeader={true}>
                                                <TableHead>
                                                    <TableRow>
                                                    <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('code')} >  <SortIcon label="Code" sort={this.state.sort} column="code"/>  </TableCell>
                                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('lastname')}  ><SortIcon label="Nom" sort={this.state.sort} column="lastname"/></TableCell>
                                                        <TableCell className="listing-list-cell" ><SortIcon label="Téléphone"  /></TableCell>
                                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('email')}><SortIcon label="Courriel" sort={this.state.sort}  column="email"/></TableCell>

                                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('banner')}><SortIcon label="Bannière" sort={this.state.sort}  column="banner"/></TableCell>
                                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('franchise')}><SortIcon label="Franchise" sort={this.state.sort}  column="franchise"/></TableCell>
                                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('city')}><SortIcon label="Ville" sort={this.state.sort}  column="city"/></TableCell>

                                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('count')} > <SortIcon label="Listings" sort={this.state.sort} column="count" />  </TableCell>
                                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('allListing')} > <SortIcon label="HausValet Listings" sort={this.state.sort} column="hausListing" />  </TableCell>
                                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('marketListing')} > <SortIcon label="HausValet on market Listings" sort={this.state.sort} column="marketListing" />  </TableCell>
                                                        <TableCell className="listing-list-cell" onClick={(e)=>this.sortColumn('hausBroker')}><SortIcon label="Client" sort={this.state.sort} column="hausBroker"  /> </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.data.map(row => (
                                                        <TableRow className={{}} style={{backgroundColor:this.rowColor(row.hausBroker),cursor:'pointer'}} key={row.id} onClick={event => this.showBrokerListing(event, row)}>
                                                            <TableCell className="listing-list-cell">{row.code}</TableCell>
                                                            <TableCell className="listing-list-cell"> <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>  
                                                                                                        <div style={{alignSelf:'center' }}> {row.firstname  + ' '+ row.lastname}   
                                                                                                        </div>
                                                                                                    </div>
                                                            </TableCell>
                                                            <TableCell className="listing-list-cell">{row.main_phone}  </TableCell>
                                                            <TableCell className="listing-list-cell">{row.email}  </TableCell>

                                                            <TableCell className="listing-list-cell">{row.banner}  </TableCell>
                                                            <TableCell style={{maxWidth:250}} >{row.franchise}  </TableCell>
                                                            <TableCell className="listing-list-cell">{row.city}  </TableCell>
                                                            <TableCell className="listing-list-cell">{row.count}  </TableCell>
                                                            <TableCell className="listing-list-cell">{row.allListing}  </TableCell>
                                                            <TableCell className="listing-list-cell">{row.marketListing}  { row.allListing!=0?'( '+Math.round(( row.marketListing  / row.count  )*100)+'% )':'' }   </TableCell>
                                                            <TableCell className="listing-list-cell">{this.hausBrokerValue(row.hausBroker)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Grid>
                                </>
                            }
                            {this.state.tab=='franchises' &&
                                <>
                                     <Grid xs={12} style={{display: 'flex', flex: 1}}>
                                                <Paper style={{margin:10,padding:5,fontWeight:'bold'}}>Total listing des Franchises</Paper>
                                     </Grid>
                                     
                                     <Grid xs={12} style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        {Object.keys(this.state.franchises).map(keys => (
                                            <div key={keys} style={{display:'flex',flexDirection:'column', marginBottom:20}}>
                                                    <Paper style={{display:'flex',flexDirection:'row',justifyContent:'space-between',margin:10,padding:5,fontWeight:'bold',backgroundColor:'whitesmoke'  }}> {this.state.franchises[keys].name} <span style={{fontWeight:'normal',justifyContent	:'flex-end'}}>  Centris : {this.state.franchises[keys].centrisTotal} Haus : {this.state.franchises[keys].hausTotal} ({Math.round(this.state.franchises[keys].pcent)} %)</span></Paper> 
                                                    <div style={{marginLeft:15,display:'flex',flexDirection:'row',flexWrap:'wrap' }}>
                                                        {this.state.franchises[keys].data.map( (value)=>(

                                                            <div key={value.code} style={{position:'relative',backgroundColor:'transparent',fontSize:12,display:'flex',flexDirection:'column',border:'1px solid grey',margin:5,padding:5,borderRadius:5,}}>
                                                                <div style={{fontSize:12,fontWeight:'bold',backgroundColor:'transparent'}}> {value.name} </div>
                                                                <div style={{alignSelf:'flex-end',backgroundColor:'transparent' }}>Centris : {value.centrisCount}</div>
                                                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between' ,backgroundColor:'transparent'}}>
                                                                    <div style={{display:'inline-block' ,backgroundColor:'transparent' }}> { Math.round( (value.hausCount / value.centrisCount)*100 )+'%' }</div>
                                                                    <div style={{display:'inline-block' ,backgroundColor:'transparent' }}>&nbsp; </div>
                                                                    <div style={{display:'inline-block'  ,backgroundColor:'transparent' }}> HausValet : {value.hausCount}  </div>
                                                                </div>

                                                                <div style={{position:'absolute',
                                                                             zIndex:10,
                                                                             left:0,top:0,
                                                                             borderRadius:5,
                                                                             width:'100%',height:'100%',
                                                                             backgroundColor:'#1aa260',
                                                                             opacity:this.opacity(value.hausCount / value.centrisCount )}} > </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                            </div>
                                        ))}
                                    </Grid>
                                </>
                            }
                            </>

                        }
                   
                </Grid>

        );
    }

}


            
export default  CentrisReport;