import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter, Link } from "react-router-dom";
import { 
    Grid, Paper, Button, Typography, CircularProgress, TextField,
    List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction,
    Select, Option, InputLabel, FormControl, MenuItem, FormControlLabel, Checkbox, 
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText
} from '@material-ui/core';

import { MultiSelect } from './MultiSelect'

import _ from 'lodash'

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        placeSelf: 'flex-start',
        flexDirection: 'column'
    },
    items: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        width: '30ch',
        margin: '2%'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '25px'
    },
    dialog: {
        width: '100%',
        textAlign: 'center'
    },
};

const MODES = {
    'list': 0,
    'view': 1,
    'edit': 2,
    'create': 3
}

// takes the base form and adds the value field fetched from the entity param
// the keys in base form must match the keys of entity
const FormProcessor = (baseForm, entity) => {
    return _.mapValues(baseForm, (value, key) => {
        let default_value = 
            (value.type == 'select')
            ?   []
            :   value.type == "boolean" 
                ? false 
                : ""
        
        let field_value = _.get(entity, key, null) || default_value
        
        return {...value, 'value': field_value}
    })
}

export default (props) => {

    let { baseForm, entity, mode, handleUpsert } = props
    
    const [ superForm, updateSuperForm ] = useState(FormProcessor(baseForm, entity))
    const [saving, setSaving] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [error, setError] = useState(null)
    const [validationError, setValidationError] = useState(false)

    const handleFormUpdate = (key_name, updated_value) => {
        updateSuperForm(_form => {
            return {
                ..._form, 
                ...{
                    [key_name]: {
                        ..._form[key_name],
                        value: updated_value,
                        error: false
                    }
                }
            }
        })
    }

    const handleFormValidate = () => {
        let hasError = false
        _.forEach(superForm, function(value, key) {
            if(value.required == true && value.value === '' && value.type != 'boolean') {
                value['error'] = true
                hasError = true
            } else if(value.required == true && value.type == 'select' && value.multiple == true && value.value.length == 0) {
                value['error'] = true
                hasError = true
            }
        })
        if(hasError) {
            setValidationError(true)
            return false
        } else {
            setValidationError(false)
            return true
        }
    }

    const handleSave = () => {

        if(handleFormValidate() === false) {
            return;
        }

        setError(null)
        setSaving(true)
        setDialogOpen(true)

        handleUpsert(
            superForm, 
            // onSuccess
            () => {
                setSaving(false)
                setDialogOpen(false)
            },
            // onFailure
            (error) => {
                setSaving(false)
                setError(error)
            }
        )
    }

    return (
        <div style={styles.container}>
            
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" style={styles.dialog} >
                <DialogTitle id="form-dialog-title">Saving in DB</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            saving === true
                            ?   <CircularProgress />
                            :   error != null
                                ?   <Typography 
                                        variant="subheading" 
                                        style={{color: "red"}}
                                    >{
                                        `Something went wrong..${error}`
                                    }</Typography>
                                :   <Typography 
                                        variant="subheading" 
                                        style={{color: "green"}}
                                    >{
                                        `Successful`
                                    }</Typography>

                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{alignSelf: 'center'}}>
                    <Button onClick={() => setDialogOpen(false)} color="primary" variant="outlined">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <div style={styles.items}>
                <RenderItems mode={mode} serviceOffer={superForm} onChange={handleFormUpdate} showError={validationError} />
            </div>
            <RenderButtons {...props} onSuccess={handleSave} />
        </div>
    )
}

const RenderItems = ({serviceOffer, onChange, mode, showError}) => {

    let inputProps = {}
    if(mode == MODES.view) {
        inputProps = {
            readOnly: true
        }
    }

    // value refers to serviceOfferForm hashmap
    const getInputProps = value => {
        if(value.editable === false) {
            return {
                readOnly: true
            }
        }
        return {}
    }

    return _.map(serviceOffer, (value, key) => {

        inputProps = {...inputProps, id: `select-${key}`}

        return (
            <FormControl variant="outlined" style={styles.textField}>
                {/* <InputLabel htmlFor={`select-${key}`}>{key}</InputLabel> */}
                {
                    value.type == 'select'
                    ?  <MultiSelect keyname={key} value={value} onChange={onChange} inputProps={inputProps} error={showError && value.error} /> 
                    :   value.type == 'boolean'
                            ?   <>
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={value.value == true} 
                                                onChange={event => onChange(key, event.target.checked)} 
                                                required={value.required}
                                                size="medium"
                                            />
                                        }
                                        label={key}
                                        disabled={mode == MODES.view}
                                    />
                                    {
                                        showError && value.error && <FormHelperText style={{color: 'red'}}>Required</FormHelperText>
                                    }
                                </>
                            :   <TextField 
                                    label={key}
                                    value={value.value}
                                    variant="outlined"
                                    style={styles.textField}
                                    onChange={event => onChange(key, event.target.value)}
                                    inputProps={{...inputProps, ...getInputProps(value)}}
                                    required={value.required}
                                    error={showError && value.error}
                                    multiline={value.type == "multiline"}
                                    type={value.type}
                                />
                }
            </FormControl>
        )
    })
}

const RenderButtons = ({mode, onSuccess}) => {

    const [loading, setLoading] = useState(false)

    const ButtonFn = ({title, onClick}) => (
        <Button variant="contained" color="primary" onClick={onClick} style={{marginLeft: '6px', marginRight: '6px'}}>
            {title}
        </Button>
    )

    return (
        <div style={styles.buttonContainer}>
            {
                mode == MODES.create
                ? <ButtonFn title={'Create'} onClick={onSuccess} /> 
                :   mode == MODES.edit
                    ?   <ButtonFn title={'Save'} onClick={onSuccess} /> 
                    :   null
            }
            <ButtonFn title={'Cancel'} onClick={() => null} /> 
        </div>
    )
}