import _ from 'lodash'

function getFloorName(floor) {
	switch (String(floor)){
        case "4":
            return 'Étage ' + 4;
        case "3":
            return 'Étage ' + 3;
        case "2":
            return 'Étage ' + 2;
        case "1":
            return 'Étage ' + 1;
        case "0":
            return 'Rez-de-chaussée';
        case "-1":
            return 'Sous-sol';
        case "-2":
            return 'Aire commune';
    }
}

export function getFloorPlanRooms(rooms) {
    let floorPlanRooms = {}
    // changes array in place
    const sortedPush = ( floor_rooms, room ) => {
        let indexToInsert = _.sortedIndexBy(floor_rooms, room, (roomObj) => {
            return roomObj['markers'][0]['order_index']
        })
        floor_rooms.splice( indexToInsert, 0, room );
    }

    rooms.filter(room => {
        return room.markers.length > 0
    }).map(room => {
        let floor = room.floor;
        if(!floorPlanRooms.hasOwnProperty(floor)) {
            floorPlanRooms[floor] = {rooms: [], floorname: getFloorName(floor)}
        }
        sortedPush(floorPlanRooms[floor]['rooms'], room)
    })
    return floorPlanRooms
}

// receives
export const filterListings = (listings, searchFor) => {
    return listings
}