import React, { useState, useEffect, useRef, useContext } from 'react';
import { 
    Grid, Paper, Button, Typography, CircularProgress, TextField,
    List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Divider,
    IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Avatar, Icon
} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import Globals from '../globals'
import moment from 'moment'
import { injectIntl, intl, FormattedMessage } from 'react-intl';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { get, last } from 'lodash';

export default ({listing}) => {
    const [automatedVideos, setAutomatedVideos] = useState([])
    const [instagramVideo, setInstagramVideo] = useState([])
    useEffect(() => {
        let { automated_videos = [] } = listing
        setAutomatedVideos(automated_videos)
    }, [])
    const [templates, setTemplates] = useState([])
    useEffect(() => {
        window.Hauslife.getVideoTemplates().then(templates => {
            if(templates && templates.data && templates.data.data) {
                console.log("data", templates.data.data)
                setTemplates(templates.data.data)
            }
        })
    }, [])

    // check if instagram video is available
    useEffect(() => {
        let instagram_vid = automatedVideos.find(av => av.instagram_video)
        if(instagram_vid) {
            setInstagramVideo(instagram_vid)
        }
    }, [automatedVideos])

    const [creatingVideo, setCreatingVideo] = useState(false)

    const onCreateVideoClick = () => {
        if(creatingVideo == true) {
            return;
        }
        setCreatingVideo(true)
    }

    const refreshListingVideos = () => {
        window.Hauslife.getListingVideos(listing.id).then(response => {
            if(response && response.data && response.data.data) {
                console.log("videos", response.data.data)
                setAutomatedVideos(response.data.data)
            }
        }).catch(error => {
            console.log("Error refreshing listing videos", error)
        })
    }

    const createVideo = (template_id) => {
        window.Hauslife.createVideo(listing.id, template_id).then(result => {
            console.log("Result create video", result)
            setCreatingVideo(false)
            refreshListingVideos()
            // refetch listing videos
        }).catch(error => {
            console.log("Error creating video", error)
            alert("Error")
            setCreatingVideo(false)
        })
    }

    const addVideoToRenderQueue = (video) => {
        window.Hauslife.addVideoToQueue(video.id).then(result => {
            refreshListingVideos()
        }).catch(error => {
            console.log("Error creating video", error)
            alert("Error", error)
        })
    }

    const [playingVideoUrl, setPlayingVideoUrl] = useState(null)
    const playVideo = (video_url) => {
        setPlayingVideoUrl(video_url)
    }

    return (
        <React.Fragment>
            <VideoCreationDialog isOpen={creatingVideo} onSubmit={createVideo} onClose={() => setCreatingVideo(false)} templates={templates} />
            <VideoPlayer isOpen={playingVideoUrl != null} video={playingVideoUrl} onClose={() => setPlayingVideoUrl(null)} />
            <Paper style={{margin:10, padding:10}}>
                <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                    Vidéos automatisés
                </Typography>
                {/* <Button variant="contained" color="primary" onClick={onCreateVideoClick}>
                    Create New Video
                </Button> */}
                <List style={{display: 'flex', flexWrap: 'wrap', flex: 1, marginLeft: '80px'}}>
                    {
                        automatedVideos.sort((a, b) => b.id - a.id).map((video, idx) => (
                            <ListItem 
                                alignItems="flex-start"
                            >
                                <ListItemAvatar>
                                    <Avatar>{idx+1}</Avatar>
                                </ListItemAvatar>
                                <YoutubeVideoStatusRender video={video} addToQueue={addVideoToRenderQueue.bind(null, video)} playVideo={playVideo} />
                            </ListItem>
                        ))
                    }
                </List>
                {
                    instagramVideo && 
                    <>
                        <Typography style={{ display: "flex", flexGrow: 2 }} gutterBottom variant="h5" component="h2">
                            Vidéos Instagram automatisés
                        </Typography>
                        <List style={{display: 'flex', flexWrap: 'wrap', flex: 1, marginLeft: '80px'}}>
                            <ListItem 
                                alignItems="flex-start"
                            >
                                <ListItemAvatar>
                                    <Avatar>{1}</Avatar>
                                </ListItemAvatar>
                                <InstagramVideoStatusRender video={instagramVideo} playVideo={playVideo} />
                            </ListItem>
                        </List>
                    </>
                }
            </Paper>
        </React.Fragment>
    )
}

const InstagramVideoStatusRender = ({video, ...rest}) => {
    return <VideoStatusRender video={{...video, video_url: video.instagram_video}} {...rest}  />
}

const YoutubeVideoStatusRender = ({video, ...rest}) => {
    return <VideoStatusRender video={{...video, video_url: video.gcs_path_mp4}} {...rest}  />
}

const VideoStatusRender = ({video, addToQueue, playVideo}) => {
    let Subheading;
    let Action;

    switch(video.status) {
        case Globals.videoCreationStatus.success:
            Subheading = <p>{`Uploaded at - ${moment(video.video_uploaded_at).format('lll')}`}</p>
            Action = (<IconButton edge="end" aria-label="delete">
                        <VisibilityIcon fontSize="large" onClick={playVideo.bind(null, video.video_url)} />
                    </IconButton>)
            break;
        case Globals.videoCreationStatus.enqueued:
            let enqueued_at = last(get(video, 'queued_at', []) || [])
            Subheading = <p>{`Last Enqueued at - ${moment(enqueued_at).format('lll')}`}</p>
            Action = (<IconButton edge="end" aria-label="delete">
                        <HourglassEmptyIcon fontSize="large" onClick={() => null} />
                    </IconButton>)
            break;
        case Globals.videoCreationStatus.requested:
            if(video.errors && video.errors.length) {
                Subheading = (
                    <React.Fragment>
                        <h3>{`Video was requested by broker. Fix Errors and add to Queue`}</h3>
                        {
                            video.errors.map(error => (
                                <p>{error}</p>
                            ))
                        }
                    </React.Fragment>
                )
            } else {
                Subheading = <p>{`Video was requested by broker. Ensure enough Photos are selected for video and add to Queue`}</p>
            }
            if(addToQueue) {
                Action = (<IconButton edge="end" aria-label="delete">
                        <AddToQueueIcon fontSize="large" onClick={addToQueue} />
                    </IconButton>)
            }
            break;
        default:
            Subheading = () => null
            Action = () => null
    }

    return (
        <React.Fragment>
            <ListItemText 
                primary={`Requested at - ${moment(video.requested_at).format('lll')}`}
                secondary={<div>{Subheading}</div>}
            />
            <ListItemSecondaryAction>
                {Action}
            </ListItemSecondaryAction>
        </React.Fragment>
    )
}

const VideoCreationDialog = (props) => {
    let { isOpen, onClose, templates, onSubmit} = props
    const [selected, setSelected] = useState(null)

    const handleTemplateClick = (template_id) => {
        if(template_id == selected) {
            setSelected(null)
        } else {
            setSelected(template_id)
        }
    }

    const handleSave = () => {
        if(selected == null) {
            return;
        }
        onSubmit(selected)
    }

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={onClose}
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title"><FormattedMessage id="video_automate.select_template" /></DialogTitle>

            <DialogContent>
                <Grid container justify={"center"}>
                    {
                        templates.map(template => (
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', border: '4px solid', borderColor: selected == template.id ? 'blue' : 'grey', padding: '10px'}} onClick={handleTemplateClick.bind(null, template.id)}>
                                <img 
                                    src={template.thumbnail_url}
                                    alt={template.template_name}
                                    width={100}
                                    height={100}
                                />
                                <p>{template.template_name}</p>
                            </div>
                        ))
                    }
                </Grid>

            </DialogContent>
            
            <DialogActions>
                <Button color="primary">
                    <FormattedMessage id="deliveries.cancel" />
                </Button>
                <Button color="primary" onClick={handleSave}>
                    Create
                </Button>
            </DialogActions>

        </Dialog>
    )
}

const VideoPlayer = (props) => {

    let { isOpen, video, onClose } = props

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={onClose}
            fullWidth={true}
            maxWidth={'lg'}
        >

            <DialogContent>
                <Grid container justify={"center"}>
                    <video width="840" height="720" autoPlay controls>
                        <source src={video} type="video/mp4" />
                    </video>
                </Grid>

            </DialogContent>

        </Dialog>
    )

}