import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";

import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import AlertDialog from "./AlertDialog";
import { red } from '@material-ui/core/colors';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    button: {

        marginTop: 15,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,


        width: 200,
    },
    paper: {
        margin: 10,
        fontSize: 24

    }
});




class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPasswordField: '',
            passwordField: '',
            passwordValidationField: '',
            dlgShow: false,
        };
    }
    componentWillMount() {


    }

    handleChangePassword = name => event => {
        this.setState({ [name]: event.target.value });
    }




    handleSubmit = event => {
        event.preventDefault();


        if (this.state.passwordField.length < 6) {
            this.setState({
                dlgShow: true,
                dlgTitle: 'Une erreur est survenue.',
                dlgText: 'Le mot de passe doit contenir six caractères.',
            });
            return;
        }

        if (this.state.passwordField != this.state.passwordValidationField) {
            this.setState({
                dlgShow: true,
                dlgTitle: 'Une erreur est survenue.',
                dlgText: 'La validation du mot de passe a échoué.'
            });
            return;
        }

        if (this.state.passwordField.length < 6) {
            this.setState({
                dlgShow: true,
                dlgTitle: 'Une erreur est survenue.',
                dlgText: 'Le mot de passe doit contenir six caractères.',
            });
            return;
        }

        if (this.state.passwordField != this.state.passwordValidationField) {
            this.setState({
                dlgShow: true,
                dlgTitle: 'Une erreur est survenue.',
                dlgText: 'La validation du mot de passe a échoué.'
            });
            return;
        }

        var data = {
            userId: this.props.userId,
            oldPassword: this.state.oldPasswordField,
            password: this.state.passwordField,
            first_login: true
        };
        window.Hauslife.changePassword(data)
            .then((res) => {
                this.setState( { dlgShow: true,
                               dlgTitle:'HauseLife',
                               dlgText:"Le mot de passe a été changé avec succès." ,
                               oldPasswordField: '',
                               passwordField: '',
                               passwordValidationField: ''
                            });


            }).catch((e) => {
                let errObj = { dlgShow: true,
                    oldPasswordField: '',
                    passwordField: '',
                    passwordValidationField: '' };
 
                if(e.response==undefined) {
                    errObj.dlgTitle = 'Exception';
                    errObj.dlgText = e.toString();
                    this.setState(errObj);
                    return;
                }
                switch (e.response.status) {
                    case 409:
                        errObj.dlgTitle = 'Une erreur est survenue.';
                        errObj.dlgText = "L'ancien mot de passe est invalide.";
                        break;
                    default:
                        errObj.dlgTitle = e.response.statusText;
                        errObj.dlgText = e.response.data.message;
                }
            this.setState(errObj);
                console.log(e)
            });
    }

    isAdmin= () =>{
        if(window.Hauslife.userObject.role==2 || window.Hauslife.userObject.role==1 ){
            return true;
        }
        return false;
    };

    alertClose = () => {
        this.setState({ dlgShow: false });
    };

    render() {

        const { classes } = this.props;

        return (
            <Paper className={classes.root}>

                <AlertDialog show={this.state.dlgShow} didClose={this.alertClose} title={this.state.dlgTitle} text={this.state.dlgText} ></AlertDialog>

                <form onSubmit={this.handleSubmit} className={classes.container} autoComplete="off" autoFill="off">
                    {this.isAdmin() == false && 
                    <TextField
                        id="oldPasswordField"
                        label="Ancien mot de passe"
                        className={classes.textField}
                        value={this.state.oldPasswordField}
                        onChange={this.handleChangePassword('oldPasswordField')}
                        margin="normal"
                        variant="outlined"
                        type="password"
                        required
                    />
                    }
                    <TextField
                        id="passwordField"
                        label="Mot de passe"
                        className={classes.textField}
                        value={this.state.passwordField}
                        onChange={this.handleChangePassword('passwordField')}
                        margin="normal"
                        variant="outlined"
                        type="password"
                        required
                    />

                    <TextField
                        id="passwordValidationField"
                        label="Vérification mot de passe"
                        className={classes.textField}
                        value={this.state.passwordValidationField}
                        onChange={this.handleChangePassword('passwordValidationField')}
                        margin="normal"
                        variant="outlined"
                        type="password"
                        required
                    />

                    <Button type="submit" variant="outlined" color="inherit" className={classes.button}>Ok</Button>
                </form>
            </Paper>
        );
    }
}
export default withStyles(styles)(ChangePassword);