import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
    root: {
      flexGrow: 1,
      height: 250,
    },
    input: {
      display: 'flex',
      padding: 0,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
    list: {
      width: '100%'
    },
    listItem: {
        backgroundColor: 'white',
        width: '100%',
    }
});
function NoOptionsMessage(props) {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }
  
  function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
  }
  
  function Control(props) {
    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    );
  }
  
  function Option(props) {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }
  
  function Placeholder(props) {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }
  
  function SingleValue(props) {
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }
  
  function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
  }
  
  function Menu(props) {
    return (
      <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    );
  }
const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  };
const FacebookPageManager = (props) => {
    const { classes } = props;
    const [pages, setPages] = useState([]);
    const [brokers, setBrokers] = useState([]);
    const [selectedBroker, setSelectedBroker] = useState();
    const [selectedPage, setSelectedPage] = useState();
    const hydrateData = () => {
        window.Hauslife.getUnlikedFacebookPages().then(res => {
            if(res.status === 200) {
                let pages = res.data.unlinked_facebook_page;
                setPages(pages);
            }
        }).catch(error => {
            console.log(error)
        })
        window.Hauslife.getUsers().then(res => {
            if(res.status === 200) {
                let brokers = res.data.user.map(broker => ({
                    value: broker.id,
                    label: broker.firstname + ' ' + broker.lastname
                }));
                setBrokers(brokers);
            }
        }).catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        hydrateData()
    }, [])
    const handleChangeSearch = (e) => {
        setSelectedBroker(e);
    }
    const handleSelectedPage = (page) => {
        if (selectedPage === page) {
          setSelectedPage(null);
        } else {
          setSelectedPage(page);
        }
    }
    const handleSaveBrokerPageInfo = () => {
        let selectedBrokerId = selectedBroker? selectedBroker.value : null;
        let selectedPageId = selectedPage? selectedPage.page_id : null;
        if (selectedBrokerId && selectedPageId) {
            window.Hauslife.linkFacebookPageToBroker(selectedBrokerId, selectedPageId).then(res => {
                    alert('Save successfully!');
                    window.location.reload();
            }).catch(error => {
                alert('Save failed!', error.message);
                console.log(error)
            })
        } else {
            alert('Please select both facebook page and broker!');
        }
        
    }
    return (
        <Grid style={{   height: "100%",flexGrow:0, alignContent:"flex-start" }}  container>
            <Grid xs={9} style={{marginBottom:20}}>
                <Paper style={{padding:10, textAlign: 'center'}}>
                    <Select
                        id="search-broker"
                        classes={classes}
                        options={brokers}
                        value={selectedBroker}
                        components={components}
                        onChange={handleChangeSearch}
                        placeholder="Search a broker"
                    />
                </Paper>
            </Grid>
            <Grid xs={1} />
            <Grid xs={2}>
                <Button
                    onClick={handleSaveBrokerPageInfo}
                    color="primary"
                    variant="outlined"
                >
                    Save
                </Button>
            </Grid>
            <List className={classes.list}>
                {pages.map(page => (
                    <ListItem key={page.page_id} button divider variant="outlined" className={classes.listItem} onClick={() => handleSelectedPage(page)}>
                      <Checkbox
                        checked={selectedPage === page}
                        color='primary'
                        tabIndex={-1}
                        disableRipple
                       />
                        <Grid xs={9}>
                            <Button type="primary" target="_blank" href={'https://www.facebook.com/'+ page.page_id}>
                            Page name: {page.page_name}
                        </Button>
                        </Grid>
                        <Grid xs={9}>
                            <iframe src={"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F" + page.page_id + "&tabs=timeline&width=300&height=200&small_header=false&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"} width="300" height="200" />
                        </Grid>
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
    
}

export default withRouter(withStyles(styles, { withTheme: true })(FacebookPageManager));