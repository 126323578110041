import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import ShootingPlanner from './ShootingPlanner';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';
import { resolveMx } from 'dns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './admin.css';
import ListingEdit from './ListingEdit';
 
import ReactJson from 'react-json-view';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
 
import { VictoryBar, VictoryChart ,VictoryLabel,VictoryAxis,VictoryContainer} from 'victory';

import SuperLog from './SuperLog';
import { CollectionsBookmarkOutlined, ContactMailSharp, ContactsOutlined, LocalConvenienceStoreOutlined } from '@material-ui/icons';

var _ = require('lodash');


const styles = theme => ({


    root: {
        width: '100%',
       // marginTop: theme.spacing.unit * 3,
        overflowX: 'clip',
       // padding:20,
        fontSize:23,
    },
    table: {
         minWidth: 700, 
       
    },
    tableRow: {
      cursor:'pointer',
      height:36
    },
    button: {
        margin: theme.spacing.unit,
    },
    'listing-list-cell':{
        whiteSpace:'nowrap'
    },


    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
      },
      
      card:{
          marginBottom:20
      },
      itemCard : {
        marginBottom:10,
        backgroundColor:'#eeeeee'
      } ,
      
});


class ListingStats extends React.Component {
    constructor(props) {
        super(props);
        this.scrollHeight=0;
        this.state = {
            error:false,
            loaded: false,
            dlgShow:false,
            sort:{
                order:'req_date',
                sort:'desc'
            },
            selectedFirstDate : moment().subtract(1, 'month').format('YYYY-MM-DD'),    //  moment('2019-08-28').format('YYYY-MM-DD')
            selectedSecondDate :moment().format('YYYY-MM-DD'),  
        };
    }

    componentWillMount() {
        this.executeQuery(true);
    }

    executeQuery (  ){
        window.Hauslife.getListingVirtualVisitReportTop( )
        .then((res) => {
            var data = res.data.data;

            var listings = data.map (  (item=>{
                    return { listing_id:item.id.toString(),
                             pcent :  Math.round ( (item.stats.views /  (item.stats.photos * item.stats.sessions))*100),
                             visotor: item.vistor
                    }
            }));

            this.setState({topListing:listings  });
            this.queryReportVisit();
        })
        .catch((e) => {
            this.setState({loaded:true,error:true,list:[]});
            console.log(e);
        });
    }

    queryReportVisit (){
        window.Hauslife.getListingVirtualVisitReportVisitors(this.state.selectedFirstDate,this.state.selectedSecondDate).then((res) => {
            this.setState( {loaded:true, visitors :res.data.data  });
        });
    }

    sortColumn = (name)=>{
        return;
        if(this.state.sort.order==name){
            var dir = this.state.sort.sort=='desc'?'asc':'desc';
            this.setState({sort:{order:this.state.sort.order,sort: dir }},function(err,result){
                this.executeQuery ( );
            });
        } else {
            this.setState({sort:{order:name,sort:'asc' } },function(err,result){
                this.executeQuery ( );
            });
            //console.log({sort:{order:name,sort:'asc' }})
        }
    }

    searchChange= (event)=>{
       // this.setState({search:event.target.value})
    }

    handleChangeSearch = (event) => {
 /*
        this.setState({search:event.target.value});
        return;
         if(this.queryTimer){
            clearTimeout(this.queryTimer);
        }

        this.queryTimer = setTimeout(()=>{
            this.setState({loaded:false});
           // this.executeQuery();
            }
        ,1000);   
                
        this.setState({search:event.target.value});
        */
    }
    
    alertClose = (button,data) => {
        if(button=='cancel'){
            this.setState({dlgShow: false});
            return;
        }
    }
      
    handleFirstDateChange  = name => event => {
        var value=event.target.value;    
        this.setState({ selectedFirstDate: event.target.value } );
    }
     
    handleSecondDateChange = name => event => {
        var value=event.target.value;    
        this.setState({ selectedSecondDate: event.target.value });
    }

    handleRefreshVisitor = name => event =>{
        this.setState({loaded:false });        
        this.queryReportVisit();
    }
    render() {
        const { classes } = this.props;

        return (
            
            <Grid style={{   height: "100%",flexGrow:0, alignContent:"flex-start" }}  container>
    
            {  this.state.loaded==true &&
             <>
                <div style={{display:'flex',flexDirection:'row',width:'100%'}}>   
                    <div style={{flex:1,backgroundColor:'transparent',border:'1px solid whitesmoke',margin:2}}>

                        <div style={{display:'flex',flexDirection:'column' }}>
                           
                            <div style={{backgroundColor:'whitesmoke',paddingLeft:6}}>
                                Du : 
                                <TextField
                                        id="date"
                                        label="Date "
                                        type="date"
                                        disabled={!this.state.loaded}
                                        defaultValue={ this.state.selectedDate }
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={this.handleFirstDateChange('cal')}
                                        value={this.state.selectedFirstDate}
                                    />      

                                Au :
                                <TextField
                                        id="date"
                                        label="Date "
                                        type="date"
                                        disabled={!this.state.loaded}
                                        defaultValue={ this.state.selectedDate }
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={this.handleSecondDateChange('cal')}
                                        value={this.state.selectedSecondDate}
                                    />
                                    <Button variant="contained" color="primary" onClick={this.handleRefreshVisitor()}>
                                        Go
                                    </Button>      
                            </div>
                            <div style= {{padding:10, fontWeight:'bold',fontSize:20,textAlign:'center'}}>Nombre de Visiteurs  </div>
                        </div>

                        {this.state.error==false && this.state.loaded==true && this.state.visitors.length > 0 &&
                            <div style={{display:'flex',flexDirection:'row',width:'100%'}}>   
                                
                                <div style={{flex:1,backgroundColor:'transparent',border:'1px solid whitesmoke',margin:2}}>
                                <VictoryChart  domainPadding={20}    >
                                        <VictoryBar 
                                                    interpolation="linear"
                                                    data={this.state.visitors}
                                                    y={"visitor"} x={"localDate"}
                                                    labels={({ datum }) => `${datum._y}`}
                                                    style={{labels: { fontSize: 7, }}}
                                                    />
                                        <VictoryAxis
                                            label="Date" 
                                            style={{ axisLabel: { padding:30,size: 15} }}
                                            tickLabelComponent={<VictoryLabel angle={90} style={{fontSize:10}} dx={5} />}
                                        />

                                        <VictoryAxis dependentAxis
                                            label="Visiteurs"
                                            style={{
                                                axisLabel: { padding: 30,fontSize:10 }
                                            }}
                                            tickLabelComponent={<VictoryLabel  style={{fontSize:10}}  />}
                                            tickFormat={(t) => `${Math.round(t)}`}
                                            />
                                </VictoryChart>
                                </div>
                            </div>
                        }

                        {this.state.error==false && this.state.loaded==true && this.state.visitors.length ==0 &&

                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',width:'100%',minHeight:100,backgroundColor:'lightgrey'}}>   
                             <div style={{fontSize:20,textAlign:'center',fontWeight:'bold',alignSelf:'stretch'}}>L'intervalle de dates choisies ne retournent aucun résultat.</div>
                            </div>
                        }
                    </div>                             
                </div> 

                <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:20 }}>   
                    <div style={{flex:1,backgroundColor:'transparent',border:'1px solid whitesmoke',margin:2}}>
                    
                    <div style={{ padding:10,textAlign:'center',fontWeight:'bold',fontSize:20}}>Pourcentage de visities complétées pour les 20 listings ayant le plus de visiteurs uniques.</div>

                         <VictoryChart  domainPadding={20}    >
                                        <VictoryBar 
                                                    interpolation="linear"
                                                    data={this.state.topListing}
                                                    y={"pcent"} x={"listing_id"}
                                                    labels={({ datum }) => `${datum._y} %`}
                                                    style={{labels: { fontSize: 7, },}}
                                                    />

                                        <VictoryAxis
                                            label="Listing"
                                            style={{
                                                axisLabel: { padding: 30 }
                                            }}
                                            tickLabelComponent={<VictoryLabel angle={90} style={{fontSize:10}} dy={-5} />}
                                            />

                                        <VictoryAxis dependentAxis
                                            label="Visities complétées %"
                                            style={{
                                                axisLabel: { padding: 30,fontSize:10 }
                                            }}
                                            />
                                </VictoryChart>
                    </div>
                </div>
            </>
            }
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(ListingStats));

