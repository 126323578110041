import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import ShootingPlanner from './ShootingPlanner';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';
import { resolveMx } from 'dns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './admin.css';
import ListingEdit from './ListingEdit';
 
import ReactJson from 'react-json-view';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AnalyticVisiteGraph from './AnalyticVisiteGraph';

import SuperLog from './SuperLog';

var _ = require('lodash');

const styles = theme => ({


    root: {
        width: '100%',
       // marginTop: theme.spacing.unit * 3,
        overflowX: 'clip',
       // padding:20,
        fontSize:23,
    },
    table: {
         minWidth: 700, 
       
    },
    tableRow: {
      cursor:'pointer',
      height:36
    },
    button: {
        margin: theme.spacing.unit,
    },
    'listing-list-cell':{
        whiteSpace:'nowrap'
    },


    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
      },
      
      card:{
          marginBottom:20
      },
      itemCard : {
        marginBottom:10,
        backgroundColor:'#eeeeee'
      } ,
      
});

function SortIcon(props){
    
    var sort =props.sort?props.sort:{};

    return (<div style={{display:'flex',alignItems:'center'}}>
        <label style={{display:'flex',fontWeight:'bold',cursor:(sort.sort!=undefined?'pointer':'default')}} >{props.label}</label>
        {props.column==sort.order &&  sort.sort=='asc' &&
            <ExpandLessIcon style={{ }} />
        }
        {props.column== sort.order && sort.sort=='desc' &&
            <ExpandMoreIcon style={{ }}/> 
        }
    </div>);
}
class SuperAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.scrollHeight=0;
        this.state = {
            error:false,
            loaded: false,
            userId: null,
            selectedRole: -1,
            search:"",
            list:[],
            count:-1,
            dlgShow:false,
            spandex:{},
            showHelp:false,
            sort:{
                order:'req_date',
                sort:'desc'
            }
        };
    }

    componentWillMount() {
        this.executeQuery(true);
    }

    executeQuery ( a2m ,offset){
      /*   this.setState({loaded:true});
        return; */
        if(a2m!==true){
            if(this.state.loaded==false){
                return;
            }
            this.setState({loaded:false});
        }        
        window.Hauslife.getSuperAnalytics(this.state.search,this.state.sort,offset)
        .then((res) => {
            console.log('count',res.data.logs.length);
            console.log('data',res.data.logs)
            console.log('receive offset',res.data.offset)
            if(res.data.offset==null){
                this.setState({ list: res.data.logs,loaded:true,count:res.data.count,error:false,count:res.data.logs.length,scrollTo:0 });
            } else {
                if(res.data.logs.length==0){
                    
                }
                var newList = this.state.list.concat( res.data.logs);
                this.setState({ list: newList,loaded:true,count:res.data.count,error:false,count:res.data.logs.length,scrollTo: this.scrollHeight });
            }
            
        })
        .catch((e) => {
            this.setState({loaded:true,error:true,list:[]});
            console.log(e);
        });
    }

    sortColumn = (name)=>{
        return;
        if(this.state.sort.order==name){
            var dir = this.state.sort.sort=='desc'?'asc':'desc';
            this.setState({sort:{order:this.state.sort.order,sort: dir }},function(err,result){
                this.executeQuery ( );
            });
        } else {
            this.setState({sort:{order:name,sort:'asc' } },function(err,result){
                this.executeQuery ( );
            });
            //console.log({sort:{order:name,sort:'asc' }})
        }
    }

    handleClick = (event, row) => {
        
        if(this.state.spandex[row.user_id]==undefined){
            this.state.spandex[row.user_id]=true;
            this.setState({spanded:this.state.spandex});
            return;
        }

        this.state.spandex[row.user_id]= ! this.state.spandex[row.user_id];
        this.setState( {spandex:this.state.spandex});

        // this.setState({dlgShow:true,listing:listing});
        //   this.props.history.push('/users/edit/' + id);
    }

   
    searchChange= (event)=>{
        this.setState({search:event.target.value})
    }

    handleChangeSearch = (event) => {
 
        this.setState({search:event.target.value});
        return;
         if(this.queryTimer){
            clearTimeout(this.queryTimer);
        }

        this.queryTimer = setTimeout(()=>{
            this.setState({loaded:false});
           // this.executeQuery();
            }
        ,1000);   
                
        this.setState({search:event.target.value});
    }
    
    alertClose = (button,data) => {
        if(button=='cancel'){
            this.setState({dlgShow: false});
            return;
        }
    }
   
    displayUser( user){
        return `${user.id} ${user.email}`;
    }
    
    displayShortJson(value){
        var str = JSON.stringify(value);
        if(str.length>30){
            return str.substring(0,30)
        }
        return str;
    }

    handleScroll  = e =>{
        let element = e.target;

        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            // do something at end of scroll
            console.log('end of the wtf');
            if(this.state.count!=0 && this.state.loaded==true){
                this.scrollHeight= element.scrollHeight;
                this.executeQuery(false,this.state.list.length);
            }
          }
    }
    //onScroll

    displayLogs(logs){
        if(logs==null){
            return;
        }
        // console.log('the logs of hell', logs );
        // Les logs est un tableau, pour chaque occurence qui contiennent à leur tour un tableau, 
        
        return ( <ul>
                {logs.map((log,index) => (
                    <li style={{backgroundColor: (index % 2)?'#FAFAFA':'white'}}>
                        {log.map(item=>( 
                            <>
                            {typeof item=='object' &&
                                 <ReactJson name={null} src={item} />  
                            }
                            {typeof item!='object' &&
                                <span style={{marginRight:5}}>{item}</span>
                            }
                            </>
                        ))}
                    </li>
                ))}

        </ul> );
        
    }

    displayFull(row){
        return (<div style={{display:'flex',flexDirection:'row',width:'100%',backgroundColor:'#DCDCDC'}}>
            
                <div style={{flex:1,margin:5,backgroundColor:'white'}}>
                    <div style={{padding:5,backgroundColor:'#F5F5F5'}}>
                        Params
                     </div>
                     <div style={{padding:5}} >
                         <ReactJson name={null}  src={row.params} />     
                     </div>
                </div>

                <div style={{flex:1,margin:5,backgroundColor:'white'}}>
                    <div style={{padding:5,backgroundColor:'#F5F5F5'}}>
                        Query
                     </div>
                     <div style={{padding:5}} >
                          <ReactJson name={null}  src={row.query} />     
                     </div>
                </div>

                <div style={{flex:1,margin:5,backgroundColor:'white'}}>
                    <div style={{padding:5,backgroundColor:'#F5F5F5'}}>
                        Body
                     </div>
                     <div style={{padding:5}} >
                        <ReactJson name={null}  src={row.body} />     
                     </div>
                </div>

                <div style={{flex:1,margin:5,backgroundColor:'white'}}>
                    <div style={{padding:5,backgroundColor:'#F5F5F5'}}>
                        Logs
                     </div>
                     <div style={{padding:5}} >
                            {this.displayLogs(row.logs)}
                     </div>
                </div>

              </div>)
    }

    render() {
        const { classes } = this.props;
       // console.log('Not ....',this.state.list)
        var rows =this.state.list;
        var loaded = this.state.loaded;

        return (

            <Grid style={{   height: "100%",flexGrow:0, alignContent:"flex-start" }}  container>

            {this.state.dlgShow==true &&
                <ListingEdit style={{width:'100%'}} show={this.state.dlgShow} didClose={this.alertClose}   listing ={this.state.listing}></ListingEdit>
            }
                <Grid xs={12} style={{marginBottom:20}}>
                    <Paper style={{padding:10,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        
                        <TextField
                            label="Search"
                            value={this.state.search}
                            onChange={this.handleChangeSearch}
                            onKeyDown={(e)=>{if(e.keyCode==13)this.executeQuery()}}
                        
                            margin="normal"
                            style={{width:'75%'}}
                        />
                        <Button style={{color:'black'}} onClick={()=>{this.executeQuery()}}  >Go PqL!!</Button>

                        <Button style={{color:'black'}} onClick={()=>{this.setState({showHelp:!this.state.showHelp}) }}  >Aide</Button>
                        <Typography style={{display:'flex',alignSelf:'flex-end'}}>
                         
                        </Typography>
                    </Paper>
                </Grid>
                { loaded==false &&
                     <PleaseWait />
                }
                { this.state.error==true &&
                    <div style={{width:"100%",height:"100%",backgroundImage:"url(/crap.jpg)",backgroundRepeat: 'repeat',color:"#33ff22"}}>
                        <div style={{margin:"auto",width:400,marginTop:231,height:200,  }}>  QUEL EST LE BUT DE VOTRE VISITE ?</div>
                    </div>
                }

                {this.state.showHelp==true &&
                
                    <div style={{margin:'auto'}}>
                                <p>Commandes supportées :<span style={{fontWeight:'bold'}}> method:</span> (<span style={{fontWeight:'bold'}}>du:  au: </span>)  <span style={{fontWeight:'bold'}}>date: status: uid: time: logs: query:	params:  body: user:</span>  </p>
                                <br/>
                                <p>Commandes avancées :  <span style={{fontWeight:'bold'}}>logs: query: params:  body: user:</span> </p>
                                <br/>
                                <p>Language pour commandes avancées (recherche json):</p> 
                                <br/>
                                <p><span style={{fontWeight:'bold'}}>query:{`{`}champs:[valeur[…,champs:[=!=&gt;&lt;]valeur]]{`}`}</span>  : recherche si le ou les  champs contient la valeur spécifié pour celui ci.</p>
                                <br/>
                                <p><span style={{fontWeight:'bold'}}>query:{`{`}listing_id:5204} </span>   : retourne si query contient un listing_id avec 5204. </p>
                                <br/>
                                <p><span style={{fontWeight:'bold'}}>query:{`{`}listing_id:5204,listing_id:688} </span>: retourne si query contient un listing_id avec 5204 ou 668</p>
                                <br/>
                                <p><span style={{fontWeight:'bold'}}>query:{`{`}listing_id:}</span></p>
                                <br/>
                                <p>Retourne tous les enregistrements qui contient un champ listing_id peut importe sa valeur.</p>
                                <br/>
                                <p><span style={{fontWeight:'bold'}}>query:{`{`}listing_id:[!]=null}</span></p>
                                <br/>
                                <p>Retourne tous les enregistrements qui contient un champ listing_id qui a une valeur null ou non null (!=null)</p>
                                <br/>
                                <p><span style={{fontWeight:'bold'}}>time:[&gt;&lt;=]400</span> </p>
                                <br/>
                                <p>Retourne les enregistrements selon l’opérateur utiliser.</p>
                                <br/>
                                <p><span style={{fontWeight:'bold'}}>uid:668</span> </p>
                                <br/>
                                <p>Retourne les enregistrements de l’utilisateur avec le id 668.</p>
                                <br/>
                                <p><span style={{fontWeight:'bold'}}>du:2019-09-01 au:2019-09-13</span></p>
                                <br/>
                                <p>Retourne les enregistrements entre les dates spécifiés.</p>
                                <br/>
                                <p><span style={{fontWeight:'bold'}}>date:2019-09-13</span></p>
                                <br/>
                                <p>Retourne les enregistrements entre de la dates spécifiés.</p>
                                <br/>
                    </div>      
                }

                {this.state.error==false && this.state.loaded==true &&
                    <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                        <Grid xs={12} style={{margin:2,border:'1px solid whitesmoke',height:'100%',overflow:'scroll'}} onScroll={this.handleScroll}>
                            
                                <Table className={styles.table}>
                                    <TableHead>
                                        <TableRow>
                                        
                                            <TableCell style={{ whiteSpace:'nowrap', cursor:'pointer'}} onClick={(e)=>this.sortColumn('user_id')}  className="listing-list-cell">   <SortIcon label="User id" sort={this.state.sort} column="user_id"/> </TableCell>
                                            <TableCell style={{ whiteSpace:'nowrap', cursor:'pointer'}} onClick={(e)=>this.sortColumn('firstname')}  className="listing-list-cell"> <SortIcon label="Prénom" sort={this.state.sort} column="firstname"/></TableCell>
                                            <TableCell style={{ whiteSpace:'nowrap', cursor:'pointer'}} onClick={(e)=>this.sortColumn('lastname')}  className="listing-list-cell"> <SortIcon label="Nom" sort={this.state.sort} column="lastname"/></TableCell>
                                            <TableCell style={{ whiteSpace:'nowrap', cursor:'pointer'}} onClick={(e)=>this.sortColumn('count')}  className="listing-list-cell"> <SortIcon label="Requètes" sort={this.state.sort} column="count"/></TableCell>
                                            <TableCell style={{ whiteSpace:'nowrap', cursor:'pointer'}} onClick={(e)=>this.sortColumn('max')}  className="listing-list-cell"> <SortIcon label="Derniere requète" sort={this.state.sort} column="max"/></TableCell>
                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map( (row,idx) => (
                                            <>
                                            <TableRow  style={{backgroundColor: row.response_status!=500?( idx % 2 ?'white':'#FFFFF0'):'salmon' }} className={classes.tableRow} key={row.id} onClick={event => this.handleClick(event, row)}>
                                            
                                                <TableCell className="listing-list-cell">{ row.user_id}</TableCell>
                                                <TableCell className="listing-list-cell">{ row.firstname }</TableCell>
                                                <TableCell className="listing-list-cell">{ row.lastname }</TableCell>

                                                <TableCell className="listing-list-cell">{ row.count}</TableCell>
                                                <TableCell className="listing-list-cell">{ moment(row.max).format('LLL') }</TableCell>

                                            
                                            </TableRow>
                                        
                                            <TableRow  style={{display:this.state.spandex[row.user_id]==true?'table-row':'none',backgroundColor:'whiteSmoke'}}  >
                                                <TableCell  colspan={9}  >
                                                    {this.state.spandex[row.user_id]==true &&
                                                            <SuperLog query={this.state.search + ' uid:'+row.user_id}  />
                                                        }
                                                </TableCell>
                                            </TableRow>
                                            </>
                                            
                                        ))}

                                    </TableBody>
                                </Table>
                            
                        </Grid>
                    </div>
                }

                {this.state.error==false && this.state.loaded==true &&
                    <div style={{display:'flex',flexDirection:'row',width:'100%'}}>   
                        <div style={{flex:1,backgroundColor:'transparent',border:'1px solid whitesmoke',margin:2}}>
                               <AnalyticVisiteGraph route="analyticsRequestByDay"
                                                    title="Requètes par jour"
                                                    query={this.state.search}  
                                                    dataTrx={(i)=> {return {mydate:moment(i.mydate).format('DD') ,count:parseInt(i.count)} }}
                                                    x="mydate" y="count" 
                                                    bottomLabel={'Jour'}
                                                />
                        </div>
                        
                        <div style={{flex:1,backgroundColor:'transparent',border:'1px solid whitesmoke',margin:2}}>
                            <AnalyticVisiteGraph route="analyticsUserRequest"
                                                title="Visiteurs individuels par jour "
                                                query={this.state.search}  
                                                dataTrx={(i)=> {return {mydate:moment(i.mydate).format('DD') ,count:parseInt(i.count)} }}
                                                x="mydate" y="count" 
                                                bottomLabel={'Jour'}
                                                />
                        </div>
                    </div>
                }
            </Grid>

        );
    }
}


export default withRouter(withStyles(styles)(SuperAnalytics));

