import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 * Simple widget used to display a loading spinner and text
 */
class PleaseWait extends React.Component {

    constructor(props) 
    {
        super(props);
        
        var displayMessage = false;

        if(typeof(props.alwaysShow) != "undefined" && props.alwaysShow === true)
        {
            displayMessage = true;
        }

        this.enableMessage = this.enableMessage.bind(this);
    
        this.state = {
          displayMessage: displayMessage,
        };
    
        this.timer = displayMessage===false ? setTimeout(this.enableMessage, 190) : null;
    }

    componentWillUnmount() 
    {
        clearTimeout(this.timer);
    }
    
    enableMessage() {
        this.setState({displayMessage: true});
    }
      
    render()
    {
        const {displayMessage} = this.state;

        if (!displayMessage) {
          return null;
        }

        return(
        <Grid container alignItems="center" justify={"center"} style={{marginTop:30}}>
            <Grid item style={{textAlign:"center"}} xs={12}>
                <CircularProgress size={50} />
            </Grid>
            <Grid item xs={12}>
                <Typography align={"center"}><FormattedMessage id="generic.please_wait" /></Typography>
            </Grid>
        </Grid>
        );
    }
}

export default PleaseWait;
  