import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ShootDepotManager from './ShootDepotManager'
import ZoneProvider from "./store"

import Zones from './CreateZone'


const styles = {
    root: {
    	width: '100%',
    	display: 'flex',
    	flexDirection: 'column',
    	justifyContent: 'flex-start',
    	alignItems: 'space-between',
    	marginTop: '10px'
    }
};

const ZonesWithProvider = (props) => {

	const [currentTabIndex, setCurrentTabIndex] = useState(0)

	return (
		<ZoneProvider>
			<div style={styles.root}>
				<Tabs value={currentTabIndex} onChange={(e, val) => setCurrentTabIndex(val)}>
					<Tab label={"Create Zone"} />
				</Tabs>
				{currentTabIndex == 0 && <Zones {...props} />}
			</div>
		</ZoneProvider>
	)
}

export default withRouter(withStyles(styles)(ZonesWithProvider));