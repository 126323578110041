import React, {useState, useEffect, useRef} from 'react'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import { CircularProgress, Button } from '@material-ui/core';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import ContentLoader from 'react-content-loader'
import { GlassMagnifier, SideBySideMagnifier, PictureInPictureMagnifier } from 'react-image-magnifiers'

import moment from 'moment'

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '1%'
    },
    image_container: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    comment_box_container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minWidth: '450px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: 'auto',
        position: 'relative'
    },
    comment_box: {
        position: 'absolute', 
        bottom: 0, 
        left: 0, 
        backgroundColor: 'white',
        borderWidth: "2px",
        width: '100%'
    },
    change_icon: {
        position: 'absolute', 
        top:"48%", 
        color: 'white', 
        fontSize: '60px',
        cursor: 'pointer'
    },
    imageOverlay: {

    }
}

const Comments = (props) => {
    let {
        picture,
        reply,
        setReply,
        postReply,
        fullScreen
    } = props

    const [maxHeight, setMaxHeight] = useState(0)
    useEffect(() => {
        let h = document.getElementById("picture-dialog").clientHeight;
        let listMaxHeight = h*0.6
        if(fullScreen) {
            listMaxHeight = h*0.9
        }
        setMaxHeight(listMaxHeight)
    }, [fullScreen])

    return (
        <React.Fragment> 
            <List style={{display: 'flex', flexWrap: 'wrap', flex: 1, maxHeight: maxHeight, overflow: 'auto', paddingBottom: '60px'}}>
                {
                    picture.tickets.map(ticket => (
                        <React.Fragment key={ticket.id}>
                            <ListItem 
                                alignItems="flex-start"
                            >
                                <ListItemAvatar>
                                    <Avatar>H</Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={ticket.creator.firstname+' '+ticket.creator.lastname}
                                    secondary={
                                        <div>
                                            <div>{ticket.description}</div>
                                            <div>
                                                <div>{moment(ticket.created_at).format('lll')}</div>
                                                <div onClick={() => setReply({parentId: ticket.id, value: ""})} style={{cursor: 'pointer', color: 'blue'}}>reply</div>
                                            </div>
                                        </div>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete">
                                        <DeleteIcon fontSize={"small"}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                            {
                                ticket.hasOwnProperty('followups') &&
                                <List style={{display: 'flex', flexWrap: 'wrap', flex: 1, marginLeft: '80px'}}>
                                    {
                                        ticket.followups.map(reply => (
                                            <ListItem 
                                                alignItems="flex-start"
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>H</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText 
                                                    primary={reply.creator.firstname+' '+reply.creator.lastname}
                                                    secondary={
                                                        <div>
                                                            <div>{reply.message}</div>
                                                            <div>{moment(reply.created_at).format('lll')}</div>
                                                        </div>
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="delete">
                                                        <DeleteIcon fontSize={"small"}/>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            }
                            {
                                (reply.parentId == ticket.id) &&
                                <div style={{position: 'relative', marginTop: '5px', marginLeft: '80px'}}>
                                    <TextField 
                                        label="Add new Reply" 
                                        variant="outlined"
                                        style={{display: 'flex'}}
                                        value={reply.value}
                                        onChange={e => setReply({...reply, value: e.target.value})}
                                    />
                                    <DoneIcon 
                                        style={{position: 'absolute', top: '10px', right: '5px', color: 'green'}} 
                                        onClick={postReply}
                                    />
                                    <ClearIcon 
                                        style={{position: 'absolute', top: '30px', right: '5px', color: 'red'}} 
                                        onClick={() => setReply({parentId: null, value: ''})}
                                    />
                                </div> 
                            }
                        </React.Fragment>
                    ))
                }
            </List>
        </React.Fragment>
    )
}

const Review = (props) => {
    let { picture, onClose, handleChange, pictureActions, setNextUnseenPictureModal } = props
    const [fullScreen, setFullScreen] = useState(false)
    const [comment, setComment] = useState("")
    const [commentError, setCommentError] = useState(null)

    const [reply, setReply] = useState({
        parentId: null,
        value: ""
    })

    const [loadingRetouch, setLoadingRetouch] = useState(false)
    const [loadingValidate, setLoadingValidate] = useState(false)

    const [hasImageLoaded, setHasImageLoaded] = useState(false)

    const handleRetouch = () => {
        if(picture.tickets.length == 0) {
            setCommentError('error')
            return;
        }
        setLoadingRetouch(true)
        pictureActions.sendPictureForReview(picture.id)
            .then(() => {
                setCommentError(null)
                setComment("")
                setLoadingRetouch(false)
                setHasImageLoaded(false)
            }).catch((err) => {
                console.log("Error retouch", err)
            }).finally(() => {
                setNextUnseenPictureModal()
            })
    }

    const handleValidate = () => {
        setLoadingValidate(true)
        pictureActions.markPictureAsValid(picture.id)
            .then(() => {
                setCommentError(null)
                setComment("")
                setLoadingValidate(false)
                setHasImageLoaded(false)
                setNextUnseenPictureModal()
            }).catch((err) => {
                console.log("Error validate", err)
            }).finally(() => {
                setNextUnseenPictureModal()
            })
    }

    const postComment = () => {
        pictureActions.postComment(picture.id, comment)
            .then(() => {
                setCommentError(null)
                setComment("")
            })
    }

    const postReply = () => {
        pictureActions.postReply(picture.id, reply.parentId, reply.value)
            .then(() => {
                setReply({parentId: null, value: ""})
            })
    }

    const clearComment = () => {
        setCommentError(null)
        setComment("")
    }

    const handleImageChange = () => (dirBool) => {
        setHasImageLoaded(false)
        handleChange(dirBool)
    }
    
    const handleKeyPress = (event) => {
        if(event.keyCode == 37) {
            // left arrow
            handleImageChange()(false)
        } else if(event.keyCode == 39) {
            handleImageChange()(true)
        }
    }

    return (
        <Dialog id="picture-dialog" fullScreen={fullScreen} onClose={onClose} open={true} maxWidth="lg" style={{height: '100%'}}>
            <div style={styles.container}>
                <div style={styles.image_container} onKeyDown={handleKeyPress} tabIndex="-1" >
                    {
                        (hasImageLoaded == false) && 
                        <ImageOverlay />
                    }
                    {
                        picture.public === true
                        ?   <VisibilityIcon style={{zIndex: 1, position: 'absolute', top: '2px', left: '2px', color: 'white', fontSize: 30}} />
                        :   <VisibilityOffIcon style={{zIndex: 1, position: 'absolute', top: '2px', left: '2px', color: 'white', fontSize: 30}} />
                    }
                    <SideBySideMagnifier 
                        style={{ 
                            width: '100%', 
                            height: 'auto',
                            display: hasImageLoaded ? 'block' : 'none',
                        }} 
                        imageSrc={picture.original_url || picture.distrib_url}
                        onImageLoad={() => setHasImageLoaded(true)}
                        magnifierSize="20%"
                    />
                    <ArrowLeftIcon style={{...styles.change_icon, ...{left: 5} }} onClick={() => handleImageChange()(false)} />
                    <ArrowRightIcon style={{...styles.change_icon, ...{right: 5} }} onClick={() => handleImageChange()(true)} />
                    {
                        fullScreen 
                            ?   <FullscreenExitIcon style={{...styles.change_icon, ...{fontSize: '30px', top: 5, right: 5} }} onClick={() => setFullScreen(false)} />
                            :   <FullscreenIcon style={{...styles.change_icon, ...{fontSize: '30px', top: 5, right: 5} }} onClick={() => setFullScreen(true)} />
                    }
                </div>
                <div style={styles.comment_box_container}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        {
                            <>
                                <Button variant="contained" color="primary" onClick={handleValidate} disabled={picture.is_validated === true || hasImageLoaded === false}>
                                    {
                                        loadingValidate ? <CircularProgress size={18} color="white" /> : "Validate"
                                    }
                                </Button>
                                <Button variant="contained" color="secondary" onClick={handleRetouch} disabled={picture.is_validated === false || hasImageLoaded === false}>
                                    {
                                        loadingRetouch ? <CircularProgress size={18} color="white" /> : "Send For Retouch"
                                    }
                                </Button>
                            </>
                        }
                    </div>
                    <Comments 
                        picture={picture} 
                        comment={comment} 
                        setComment={setComment}
                        reply={reply}
                        setReply={setReply}
                        postReply={postReply}
                        fullScreen={fullScreen}
                    />
                    <div style={styles.comment_box}>
                        <TextField 
                            id="new-comment-box" 
                            label="Add new Comment" 
                            variant="outlined"
                            multiline={true}
                            style={{display: 'flex', flex: 1}}
                            value={comment}
                            onChange={e => {
                                setCommentError(null)
                                setComment(e.target.value)
                            }}
                            error={commentError != null}
                        />
                        <DoneIcon style={{position: 'absolute', top: '5px', right: '5px', color: 'green', cursor: 'pointer'}} onClick={postComment} />
                        <ClearIcon style={{position: 'absolute', bottom: '5px', right: '5px', color: 'red', cursor: 'pointer'}} onClick={clearComment} />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default Review

const ImageOverlay = props => {
    return (
        <ContentLoader
            speed={2}
            width={784}
            height={522}
            viewBox="0 0 934 625"
            backgroundColor="#f5f5f5"
            foregroundColor="#dbdbdb"
            {...props}
        >
            <rect x="0" y="0" rx="3" ry="3" width="784" height="522" />
        </ContentLoader>
    )
}