import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import PleaseWait from './PleaseWait.js'
import Icon from '@material-ui/core/Icon';

import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';

import * as moment from 'moment';
import 'moment/locale/fr-ca';

const humanizeDuration = require('humanize-duration')

const styles = theme => ({
    root: {
        margin: theme.spacing.unit * 3
    },
    card: {
        marginBottom: theme.spacing.unit * 3
    },
});

class MyShootings extends React.Component {

    constructor(props) {
      super(props);
      this.state = {loading:true,rows:[]};
    }

    componentWillMount()
    {
        window.Hauslife.getAllShootings()
        .then((result)=>{
            this.setState({loading:false,rows:result.data.data});
        })
        .catch((e)=>{
            this.setState({loading:false});
        });
    }

    /**
     * Calculate service time
     * 
     * Return time in seconds
     * 
     * @param {any} nb_rooms
     * @param {any} is_house
     * @param {any} is_existing_listing
     */
    calculateServiceTime = (nb_rooms, is_house, is_existing_listing ) =>
    {
        const { intl } = this.props;

        var new_service_time;
        var hauslife_room_count;
        var threeD_house_setup_facade_backyard_minutes = 14;
        var hybrid_house_setup_facade_backyard_minutes = 24;
        var threeD_time_per_room = 4;
        var hybrid_time_per_room = 6.5;
        var threeD_condo_setup_facade = 7;
        var hybrid_condo_setup_facade = 11;

        if (is_house === true) {
            hauslife_room_count = parseInt(nb_rooms) + 1; //entree

            if (is_existing_listing) {
                new_service_time = threeD_time_per_room * hauslife_room_count + threeD_house_setup_facade_backyard_minutes;
            }
            else {
                new_service_time = hybrid_time_per_room * hauslife_room_count + hybrid_house_setup_facade_backyard_minutes;
            }
        }
        else {
            hauslife_room_count = parseInt(nb_rooms) + 2; //entree + terasse

            if (is_existing_listing) {
                new_service_time = threeD_time_per_room * hauslife_room_count + threeD_condo_setup_facade;
            }
            else {
                new_service_time = hybrid_time_per_room * hauslife_room_count + hybrid_condo_setup_facade;
            }
        }

        //Convert minutes to seconds
        return humanizeDuration(new_service_time * 60 * 1000, { language: intl.locale, round: true, units: ['h'] });
    };

    RenderCardHeader = (row) => {
        return(
            <Grid style={{backgroundColor:(row.is_confirmed?green[100]:yellow[100])}} container alignItems="center">
                <div item style={{flex:"0 0 auto",padding:15}}>
                    <Icon style={{verticalAlign:"middle"}}>{row.is_confirmed?"check":"timer"}</Icon>
                </div>
                {row.is_confirmed &&
                <Grid item style={{padding:15}} xs={5}>
                    <Typography color="textSecondary"><FormattedMessage id={"myshootings.status.confirmed_time"} /></Typography>
                    <Typography>{moment(row.scheduleddate).format("LL")} <b><FormattedMessage id={"myshootings.status.at_time"} values={{time: moment(row.scheduleddate).format("LT")}} /></b></Typography>
                </Grid>
                }
                {!row.is_confirmed &&
                <Grid item style={{padding:15}} xs={5}>
                    <Typography color="textSecondary"><FormattedMessage id={"myshootings.status.pending"} /></Typography>
                    <Typography><FormattedMessage id={row.am?"myshootings.date.am":"myshootings.date.pm"} values={{date: moment.utc(row.scheduleddate).format("LL")}}/></Typography>
                </Grid>
                }
                <Grid item style={{padding:15}} xs={5}>
                    <Typography color="textSecondary"><FormattedMessage id={"myshootings.status.estimated_time"} /></Typography>
                    <Typography><b>{this.calculateServiceTime(row.listing.room_count,row.listing.is_house,row.only_360)}</b></Typography>
                </Grid> 
            </Grid>
        );
    };

    render()
    {
        const { classes } = this.props;
//avatar={<Icon style={{verticalAlign:"middle", marginRight:5}}>{row.is_confirmed?"check":"timer"}</Icon>} style={{backgroundColor:(row.is_confirmed?green[100]:yellow[100])}} subheader={row.is_confirmed?(<span><Typography>{moment(row.scheduleddate).format("LL")} <b><FormattedMessage id={"myshootings.status.at_time"} values={{time: moment(row.scheduleddate).format("LT")}} /></b></Typography><Typography><FormattedMessage id={"myshootings.status.estimated_time"} />{this.calculateServiceTime(row.listing.room_count,row.listing.is_house,row.only_360)}</Typography></span>):(<FormattedMessage id={row.am?"myshootings.date.am":"myshootings.date.pm"} values={{date: moment.utc(row.scheduleddate).format("LL")}}/>)} title={<Typography><FormattedMessage id={row.is_confirmed?"myshootings.status.confirmed":"myshootings.status.pending"} values={{time: moment(row.scheduleddate).format("LT")}} /></Typography>}
        return(
            <div>
                {this.state.loading == true ? (<PleaseWait />):(
                <div>
                    <div style={{display: "flex",marginTop:15,marginBottom:15,justifyContent:"flex-end"}}>
                        <LinkButton variant="outlined" color="primary" to="/shooting/add">myshootings.btn.book_shooting</LinkButton>
                    </div>
                    {this.state.rows.length == 0 &&
                            <Grid container alignItems="center" justify="center">
                                <Grid xs={12}>
                                <Typography align="center" variant="title" gutterBottom><Icon>event_note</Icon></Typography>
                                </Grid>
                                <Grid xs={12}>
                                <Typography align="center" variant="title" gutterBottom>
                                    <FormattedMessage id="myshootings.no_shootings" />
                                </Typography>
                                </Grid>
                            </Grid>
                    }
                    {this.state.rows.map(row=>{
                        return(
                        <Card key={row.id} className={classes.card}>
                            <CardHeader component={(props)=>this.RenderCardHeader(row)}  />
                            <CardContent>
                                <Grid container> 

                                    <Grid item xs={12}>
                                        <Typography gutterBottom>
                                            {row.listing.address + (row.listing.app?", Apt. "+row.listing.app:"") + ", " + row.listing.city}
                                        </Typography>
                                    </Grid>
   
                                    <Grid item xs={12}>
                                        <Typography color="textSecondary" gutterBottom>
                                            {row.note}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </CardContent>
                            <CardActions>
                                {row.is_confirmed?"":<div><LinkButton style={{marginRight:15}} variant="outlined" color="primary" to={"/shooting/changeTime/"+row.id}>myshootings.btn.change_time</LinkButton><LinkButton variant="outlined" color="secondary" to={"/shooting/cancel/"+row.id}>myshootings.btn.cancel_shooting</LinkButton></div>}
                            </CardActions>
                        </Card>
                        );
                    })}
                </div>
                )}
            </div>
        );
    }
}

MyShootings.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default injectIntl(withRouter(withStyles(styles)(MyShootings)));