import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

class LinkButton extends React.Component {
    render()
    {
        return(<Button component={Link} {...this.props}><FormattedMessage id={this.props.children} /></Button>);
    }
}

export default LinkButton;
  