import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Link as MaterialUILink} from '@material-ui/core';
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import {ArrowBackIos, ArrowForwardIos, TripOriginSharp} from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import './admin.css';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListingEdit from './ListingEdit';
import ZoneButton from './ZoneButton';
import { withGoogleMap, GoogleMap, Marker,Circle, InfoWindow, DirectionsRenderer} from "react-google-maps";
import IconButton from '@material-ui/core/IconButton';
import globals from '../globals.js';
import Icon from '@material-ui/core/Icon';

const invalideColor ='#F5C3C2';
const valideColor ='#98FB98';
const lockedTechColor = '#CCC';
const normalTechColor = 'transparent';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        fontSize:23,
    },
    table: {
        minWidth: 700,
    },
    tableRow: {
        cursor:'pointer'
    },
    button: {
        margin: theme.spacing.unit,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    wideTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400
    },
    recalculateDistancesButton:{
        marginTop: '5px',
        float: 'right',
        marginRight: theme.spacing.unit,
        width: 200,
        height:50,
        backgroundColor:'#179935',
        color:'white', 
        '&:hover': {
            backgroundColor: '#106b25',

        }
    },
    showMapButton:{
        marginTop: '5px',
        float: 'right',
        marginRight: theme.spacing.unit,
        width: 200,
        height:50,

    },
    modalBoxClass:{
        position: 'absolute',
        width: theme.spacing.unit * 200,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)' ,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 2,
        outline: 'none',
    },
    forceAssignContainer:{
        float: 'right',
        marginRight: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        width: 200,
        height:50,
        backgroundColor:'white',
        border:'2px solid red',
        borderRadius:'5px',
        color:'black',
        marginTop: '5px'
    },
    shootingExpandSwitch:{
        float: 'right',
        marginRight: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        width: 200,
        height:50,
        backgroundColor:'white',
        border:'2px solid blue',
        borderRadius:'5px',
        color:'black',
        marginTop: '5px'
    },
    forceAssignLabel:{
        color:'black',
        fontWeight: 800
    },
    expandShootingsLabel:{
        color:'black',
        fontWeight: 800
    },
    title:{
        fontSize:30,
        marginBottom:20,
        textAlign:'center'
    },
    card:{
        marginBottom:20
    },
    itemCard : {
        marginBottom:10,
        margin: 5,
        backgroundColor:'rgba(62,80,178,0.1)'
    },
    cardScroll :{
        maxHeight:560,
        overflowY:'scroll'
    },
    noShooting:{
        margin:40,
        padding:10,
        textAlign:'center'
    },
    mainCol:{
        height:'100%',
        unavailabilityTitle:{
            fontWeight: 800
        },
        //overflowY:'scroll'
    },
    unassignedShoots:{
        minHeight:'500px'
    },
    dispatchCheckbox:{
        paddingTop:0,
        paddingBottom:0
    },
    selfAssign:{
        backgroundColor:'white'
    },
    techLock:{
        float: 'right'
    },
    removeShootingFlags:{
        backgroundColor:'green',
        color:'white',
        margin:'0px 0px 20px 20px',
        display:'block',
        float:'right'
    }
});

const DailyShootingsMap = withGoogleMap((props) => {

    const techDirections = {};
    const availableInfoWindows = {};
    const availableInfowWindowsMouseOver = {};
    props.availableTechs.forEach(({user_id}) => {
        availableInfoWindows[user_id] = false;
        availableInfowWindowsMouseOver[user_id] = false;
    });
    props.todayShootings.forEach(({listingId}) => availableInfoWindows[listingId] = false);
    const [infoWindow, setInfoWindow]= useState(availableInfoWindows);
    const [infoWindowMouseOver, setinfoWindowMouseOver]= useState(availableInfowWindowsMouseOver);
    const directionsService = new window.google.maps.DirectionsService();


    const calculateTechRoute = (tech) =>{

        const origin = new window.google.maps.LatLng(parseFloat(tech.depot.lat), parseFloat(tech.depot.lng));
        const destination = new window.google.maps.LatLng(parseFloat(tech.depot.lat), parseFloat(tech.depot.lng));
        const waypoints = [];
        tech.shootings.forEach(shooting => {
            let coordinates = {location:{lat:parseFloat(shooting.listing.lat), lng: parseFloat(shooting.listing.lng)}};
            waypoints.push(coordinates);
        });
        directionsService.route({
                origin: origin,
                destination: destination,
                waypoints: waypoints,
                avoidTolls : true,
                travelMode: 'DRIVING'
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {

                    techDirections[tech.user_id] = result;

                }
            });
    }

    useEffect(() => {
        props.availableTechs.forEach(tech => {
            if(tech.shootings.length !== 0){
                    calculateTechRoute(tech);
                }
        });
      }, [])

    const [techRoutes, setTechRoutes] = useState(techDirections);


    const toggleInfoWindow = (id) =>  {
      const infoWindowClone = {...infoWindow};
      infoWindowClone[id] = !infoWindowClone[id];
      setInfoWindow(infoWindowClone);
    }

    const toggleInfoWindowMouseOver = (id) =>{
        const infoWindowMouseOverClone = {...infoWindowMouseOver};

        if(!infoWindow[id]){
            infoWindowMouseOverClone[id] = !infoWindowMouseOverClone[id];
            setinfoWindowMouseOver(infoWindowMouseOverClone);
        }else{
            infoWindowMouseOverClone[id] = false;
            setinfoWindowMouseOver(infoWindowMouseOverClone);
        }

    }

    //this allow us to map the content of the availableTechs array and create and place a marker for each element in it
    const generateTechMarkers = (availableTechs) =>{

        return availableTechs.map((tech,index) => {

            const icon = {
                url : "",
                scaledSize: new window.google.maps.Size(40, 40),
            };

            if(tech.picture_url != null){
                icon.url = tech.picture_url;

            }else{
                icon.url = "http://maps.google.com/mapfiles/ms/micons/man.png";
            }

            return(

              <Marker key={index} options={{icon:icon}} onClick={() => {toggleInfoWindow(tech.user_id); }} onMouseOver={() =>{toggleInfoWindowMouseOver(tech.user_id);}}
                onMouseOut={()=>{toggleInfoWindowMouseOver(tech.user_id);}} position={{ lat: parseFloat(tech.depot.lat), lng: parseFloat(tech.depot.lng)} } >

                    {infoWindow[tech.user_id] || infoWindowMouseOver[tech.user_id] ?
                        <>
                            <InfoWindow onCloseClick={() => toggleInfoWindow(tech.user_id)}>
                            <h3>{tech.firstname + " " + tech.lastname}</h3>
                            </InfoWindow>

                            <Circle
                                //This allow us to draw a circle that represent the 25km coverage zone of the photographer.
                                options={{
                                strokeColor: "black",
                                strokeOpacity: 1,
                                strokeWeight: 2,
                                fillColor: "blue",
                                fillOpacity: 0.35,
                                //distance in meter (diameter of a circle is 2x the radius which gives us our 25km coverage zone)
                                radius: 20000,
                                center : { lat: parseFloat(tech.depot.lat), lng: parseFloat(tech.depot.lng)}
                                }}
                            />
                        </>
                     : undefined}
                     { infoWindow[tech.user_id] ? <DirectionsRenderer  directions={techRoutes[tech.user_id]} /> : undefined}
                </Marker>

            );
    })
  }
    //this allow us to map the content of the allDailyShootings array and create and place a marker for each element in it
    const generateShootingMarkers = (todayShootings) => {
        const icon_ = {
            url: "https://storage.googleapis.com/cdn.hauslifenetwork.com/logo/HausValet%20_fav.png",
            scaledSize: new window.google.maps.Size(35, 39),
        }

        return todayShootings.map((shooting,index) => {
            return (
                <Marker key={index} options={{icon:icon_}} onClick={()=> toggleInfoWindow(shooting.listingId)} onMouseOver={() =>{toggleInfoWindowMouseOver(shooting.listingId);}}
                    onMouseOut={()=>{toggleInfoWindowMouseOver(shooting.listingId);}} position={{ lat: parseFloat(shooting.listing.lat), lng: parseFloat(shooting.listing.lng) }}>

                    {infoWindow[shooting.listingId] || infoWindowMouseOver[shooting.listingId] ?
                        <InfoWindow onCloseClick={() => toggleInfoWindow(shooting.listingId)}>
                            <div>
                                <h3>{shooting.address}({shooting.schedule})</h3>
                                <h4 style={{textDecoration: "underline"}}>Informations Générales:</h4>
                                <div>
                                    Listing Id : {shooting.listingId ? shooting.listingId : "inconnu"}
                                </div>
                                <div>
                                    shootin Id : {shooting.shootingId ? shooting.shootingId : "inconnu"}
                                </div>
                                <div>
                                    Courtier : {shooting.broker ? shooting.broker : "inconnu"}
                                </div>
                                <hr style={{margin:"1.25em 0 1.25em 0"}}/>
                                <h4 style={{textDecoration: "underline"}}>Informations relative au shoot:</h4>
                                <div>
                                    Nombre de pièces : {shooting.rooms ? shooting.rooms : "inconnu" }
                                </div>
                                <div>
                                    Service offert: {shooting.serviceOffers && shooting.serviceOffers.length > 0  ? 
                                        shooting.serviceOffers.map((serviceOffer) => {
                                            return `${serviceOffer.display_name_fr}, `
                                        })
                                    : "Aucun"}
                                </div>
                                <hr style={{margin:"1.25em 0 1.25em 0"}}/>
                                <h4 style={{textDecoration: "underline"}}>Package</h4>
                                <div>
                                    Type de package : {shooting.shootingPackage ? shooting.shootingPackage.display_name_fr : "Aucun forfait définit"}
                                </div>
                                <div>
                                    Nombre de photo (approx) : {shooting.shootingPackage ? shooting.shootingPackage.hdr_photo_count : "0"}
                                </div>
                            </div>
                        </InfoWindow>
                    : undefined}
                </Marker>
            )
        })
  }



  return (
    <GoogleMap
        defaultZoom={7}
    defaultCenter={new window.google.maps.LatLng(45.6035, -73.5107)}
    >


    {generateTechMarkers(props.availableTechs)}
    {generateShootingMarkers(props.todayShootings)}
    </GoogleMap>
)});



class ShootingPlanner extends React.Component {
    constructor(props) {
        super(props);

        var dispatch={};

        Object.defineProperties(dispatch, {
            needTo: {
                enumerable:false,
                get: function () {
                    for(let props in this){
                            if(this[props]===true){
                                return true;
                            }
                    }
                    return false;
        },
      },
      reset: {
        enumerable: false,
        value: function () {
          for (let props in this) {
            if (this[props] === true) {
              this[props] = false;
                }
                        }
        },
      },
        });

        this.state = {
            techs:[],
            unAssignedShootings:[],
            selectedDate : moment().format('YYYY-MM-DD'),
            allTech:[],
            dropZoneColor:normalTechColor,
            dlgShow: false,
            dlgConfirm:false,
            dlgDelegate:null,
            dispatch,
            forceAssignEnabled: false,
            showPartTime: false,
            loaded:false,
            loadingData: false,
            listEditShow:false,
            listingToEdit:null,
            locks:[],
            loadingLocks:false,
            open: false,
            allDailyShootings : [],
            allAvailableTechs: [],
            infoWindowStatus: {}
        };
    }


    componentWillMount() {
        if(this.props.location.state) {
            this.setState({selectedDate: moment(this.props.location.state).format('YYYY-MM-DD')})
        }
    }

    componentDidMount() {
        this.getShooting().then(() => {});     
    }


    fetchTechLocksForSelectedDay = () =>
    {
        this.setState({loadingLocks:true}, () => {
            window.Hauslife.getAllTechLocksForTheDay(this.state.selectedDate)
            .then((res)=>{
                let locks = res.data.locks;
                this.setState({
                    locks:locks,
                    loadingLocks: false
                },()=>{
                    this.calculateColorsForTechs();
                    this.sortTechs();
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
                this.setState({loadingLocks: false});
            });
        });

    }

    sortTechs = () => {
        //Unlocked on top and lowest shoots on top
        this.state.techs.sort((firstTech, secondTech)=>{
            if(firstTech.shootings.length > secondTech.shootings.length){
                return -1
            }
            else if(firstTech.shootings.length < secondTech.shootings.length){
                return 1
            }
            else if(!this.isTechDayLocked(firstTech.techId) && this.isTechDayLocked(secondTech.techId)){
                return -1;
            }
            else if(this.isTechDayLocked(firstTech.techId) && !this.isTechDayLocked(secondTech.techId)){
                return 1;
            }
            else if(this.isTechDayLocked(firstTech.techId) && this.isTechDayLocked(secondTech.techId)){
                if(firstTech.shootings.length == secondTech.shootings.length){
                    return 0;
                }
                if(firstTech.shootings.length > secondTech.shootings.length){
                    return 1
                }
                else{
                    return -1;
                }
            }
            else{
                if(firstTech.shootings.length == secondTech.shootings.length){
                    return 0;
                }
                if(firstTech.shootings.length > secondTech.shootings.length){
                    return 1
                }
                else{
                    return -1;
                }
            }
        })
    }

    calculateColorsForTechs = () =>
    {
        let dropZoneColor = [];

        for(let i=0; i<this.state.techs.length; i++){
            dropZoneColor[this.state.techs[i].techId] = this.isTechDayLocked(this.state.techs[i].techId) ? lockedTechColor : normalTechColor;
        }

        this.setState({dropZoneColor:dropZoneColor});
    }

    shootingActivityResult (res) {
        var data = res.data.data;
        var techs =[];
        var unAssignedOut = [];
        var keys = Object.keys(data);
        let availableTechs = []
        let allDailyShootings = []

        if(data.scheduledTechs==undefined){
            data.scheduledTechs=[
                {
                    firstname:window.Hauslife.userObject.firstname,
                    lastname:window.Hauslife.userObject.lastname,
                    id: window.Hauslife.userObject.id
                }
            ];
        }

        let scheduledTechs = data.scheduledTechs;
        data.scheduledTechs = data.scheduledTechs.filter( (element)=>{
            if( keys.indexOf(element.user.id.toString())!= -1){
                return false;
            }
            return true;
        });

        for(var prop in data){
            var obj = {
                techName : 'Non assignée',
                techId:-1,
                key:-1,
                distanceTotal:0,
                shootings:[],
                unavailabilities: [],
            };

            if(prop=='scheduledTechs'){
                data[prop].forEach( (_data)=>{
                    let tech = _data.user;
                    let unavailabilities = tech.unavailabilities;
                    unavailabilities.sort((a, b)=>{
                        return moment.utc(a.start).diff(moment.utc(b.start))
                    })

                    unavailabilities.forEach((unavailability)=>{
                        unavailability.unavailability = true;
                        unavailability.scheduleddate = unavailability.start
                        unavailability.schedule =  moment(unavailability.start).format('HH:mm') + ' - ' + moment(unavailability.end).format('HH:mm');
                    })

                    obj = {
                        techName :tech.firstname + ' ' + tech.lastname,
                        freelance: tech.freelance,
                        key : tech.id,
                        techId : tech.id,
                        shootings:[],
                        distanceTotal:0,
                        unavailabilities: unavailabilities,
                        depot: tech.depot,
                        techDailyActivity: []

                    };


                    if(tech.userShootingPackages != null && tech.userShootingPackages.length > 0){
                        let shootingPackages = "";

                        for(let i=0; i<tech.userShootingPackages.length; i++){
                            if(!shootingPackages.includes(tech.userShootingPackages[i].shootingPackage.display_name_fr)){
                                shootingPackages += tech.userShootingPackages[i].shootingPackage.display_name_fr;

                                if((i + 1) < tech.userShootingPackages.length && tech.userShootingPackages.length > 1){
                                    shootingPackages += ", ";
                                }
                            }
                        }
                        shootingPackages = shootingPackages.replace(/(^\s*,)|(,\s*$)/g, '');

                        obj.userShootingPackages = shootingPackages;
                    }

                    if(tech.userServiceOffers != null && tech.userServiceOffers.length > 0){
                        let serviceOffers = "";

                        for(let i=0; i<tech.userServiceOffers.length; i++){
                            if(!serviceOffers.includes(tech.userServiceOffers[i].serviceOffer.display_name_fr)){
                                serviceOffers += tech.userServiceOffers[i].serviceOffer.display_name_fr;

                                if((i + 1) < tech.userServiceOffers.length && tech.userServiceOffers.length > 1){
                                    serviceOffers += ", ";
                                }
                            }
                        }
                        serviceOffers = serviceOffers.replace(/(^\s*,)|(,\s*$)/g, '');

                        obj.userServiceOffers = serviceOffers;
                    }

                    techs.push(obj);
                });
                continue;
            }

            var shootings = data[prop];

            if(shootings.length==0) continue;

            if(prop != 'null'){
                let currentTech = shootings[0].tech;
                obj.techName = currentTech.firstname + ' ' + currentTech.lastname;

                for(let i=0; i<scheduledTechs.length; i++){
                    if(scheduledTechs[i].user.id == currentTech.id){
                        let unavailabilities = scheduledTechs[i].user.unavailabilities;

                        unavailabilities.sort((a, b)=>{
                            return moment.utc(a.start).diff(moment.utc(b.start))
                        })

                        unavailabilities.forEach((unavailability)=>{
                            unavailability.unavailability = true;
                            unavailability.scheduleddate = unavailability.start
                            unavailability.schedule =  moment(unavailability.start).format('HH:mm') + ' - ' + moment(unavailability.end).format('HH:mm');
                        })

                        obj.unavailabilities = unavailabilities;
                        break;
                    }
                }

                if(currentTech.userShootingPackages != null && currentTech.userShootingPackages.length > 0){
                    let shootingPackages = "";

                    for(let i=0; i<currentTech.userShootingPackages.length; i++){
                        if(!shootingPackages.includes(currentTech.userShootingPackages[i].shootingPackage.display_name_fr)){
                            shootingPackages += currentTech.userShootingPackages[i].shootingPackage.display_name_fr;

                            if((i + 1) < currentTech.userShootingPackages.length && currentTech.userShootingPackages.length > 1){
                                shootingPackages += ", ";
                            }
                        }
                    }

                    shootingPackages = shootingPackages.replace(/(^\s*,)|(,\s*$)/g, '');

                    obj.userShootingPackages = shootingPackages;
                }

                if(currentTech.userServiceOffers != null && currentTech.userServiceOffers.length > 0){
                    let serviceOffers = "";

                    for(let i=0; i<currentTech.userServiceOffers.length; i++){
                        if(!serviceOffers.includes(currentTech.userServiceOffers[i].serviceOffer.display_name_fr)){
                            serviceOffers += currentTech.userServiceOffers[i].serviceOffer.display_name_fr;

                            if((i + 1) < currentTech.userServiceOffers.length && currentTech.userServiceOffers.length > 1){
                                serviceOffers += ", ";
                            }
                        }
                    }

                    serviceOffers = serviceOffers.replace(/(^\s*,)|(,\s*$)/g, '');

                    obj.userServiceOffers = serviceOffers;
                }

                obj.techId = shootings[0].tech_id;
                obj.key = shootings[0].tech_id;
                obj.depot = currentTech.depot;
            }

            shootings.forEach((shooting)=>{
                let shootingAddress = "";

                if(shooting.listing.app !== null && shooting.listing.app !== ""){
                    shootingAddress = shooting.listing.address + ', #' + shooting.listing.app  + ', ' + shooting.listing.city + ' ' + shooting.listing.postalcode
                }
                else{
                    shootingAddress = shooting.listing.address + ', ' + shooting.listing.city + ', ' + shooting.listing.postalcode
                }

                var s = {
                    shootingId:shooting.id,
                    listingId: shooting.listing.id,
                    address: shootingAddress,
                    ignore_scheduler: shooting.ignore_scheduler,
                    force_assigned: shooting.force_assigned,
                    scheduleddate : new Date(shooting.scheduleddate),
                    rooms : shooting.listing.room_count,
                    broker : shooting.listing.broker.firstname + ' '+shooting.listing.broker.lastname,
                    brokerId : shooting.listing.broker.id,
                    distance : shooting.distance,
                    transit_time: shooting.transit_time,
                    note: shooting.note,
                    only360 : shooting.only_360,
                    billable:shooting.billable,
                    banner: (shooting.listing.broker.franchise_id!=null?shooting.listing.broker.franchise.name:null),
                    reshoot:false,
                    listing:shooting.listing,
                    use_backup: shooting.use_backup,
                    serviceOffers: null,
                    shootingPackage: null,
                    brokerGfClause: shooting.listing.broker.gf_clause,
                    invoice: shooting.invoice,
                    direct_drive: shooting.direct_drive,
                    production_incomplete: shooting.production_incomplete
                };

                if(shooting.service_offers != null && shooting.service_offers.length > 0){
                    const serviceOffers = [];

                    for(let i = 0; i < shooting.service_offers.length; i++){
                        serviceOffers.push(shooting.service_offers[i].serviceOffer);
                    }

                    s.serviceOffers = serviceOffers;
                }

                if(shooting.shooting_params != null && shooting.shooting_params.shooting_package != null){
                    s.shootingPackage = shooting.shooting_params.shooting_package
                }

                shooting.listing.shootings =  shooting.listing.shootings.filter( function(value){if(value.state!=10 && value.state!=12 )return true});

                if(shooting.listing.shootings.length>1){
                    s.reshootIndex=0;
                    shooting.listing.shootings.forEach((value,idx)=>{
                        if(value.id==shooting.id){
                            s.reshootIndex=idx+1;
                        }
                    });
                    s.reshoot=true;
                }

                if(  shooting.distance !=null ){
                    obj.distanceTotal+=shooting.distance;
                }  else {
                    s.distance='';
                }

                s.schedule  =  moment(s.scheduleddate).format('HH:mm');

                if(shooting.estimated_duration!=null){
                    s.endTime = new Date(s.scheduleddate.getTime());
                    s.endTime.setSeconds(shooting.estimated_duration);
                    s.schedule+= ' - ' + moment(s.endTime).format('HH:mm');
                }
                obj.shootings.push(s);
            });

            obj.shootings = obj.shootings.sort((a,b)=>{
                return moment(a.scheduleddate).format('X')-moment(b.scheduleddate).format('X')
            })

            obj.techDailyActivity = obj.shootings.concat(obj.unavailabilities);

            obj.techDailyActivity = obj.techDailyActivity.sort((a,b)=>{
                return moment(a.scheduleddate).format('X')-moment(b.scheduleddate).format('X')
            })

            if(prop!='null'){
                techs.push(obj);
            } else {
                unAssignedOut.push(obj);
            }
        }

        techs.forEach ( (tech)=>{
            tech.days = {};
            tech.shootings.forEach ( (item)=> {
                var key =  moment( item.scheduleddate).format('YYYY-MM-DD');  ;
                if(tech.days[key]==undefined){
                    tech.days[key]=[];
                }
                tech.days[key].push(item);
            });
        });

        if(unAssignedOut.length==0){
            unAssignedOut.push({key:-1,techId:-1,techName:'Non assignée',shootings:[]});
        }

        let dropZoneColor = [];

        for(let i=0; i<techs.length; i++){
            dropZoneColor[techs[i].techId] = this.isTechDayLocked(techs[i].techId) ? lockedTechColor : normalTechColor;
        }

        scheduledTechs.forEach(tech => {

            let availableTech = {
                user_id: 0,
                firstname:"",
                lastname:"",
                picture_url : "",
                shootings:[],
                depot:[]
            };

            availableTech.user_id = tech.user_id;
            availableTech.firstname = tech.user.firstname;
            availableTech.lastname = tech.user.lastname;
            availableTech.depot = tech.user.depot;
            availableTech.picture_url = tech.user.picture_url;

            techs.forEach(tech => {
                if(tech.techId ===  availableTech.user_id){

                    availableTech.shootings = tech.shootings;
                }
            })

            availableTechs.push(availableTech);

          });

        techs.forEach(tech =>{
            /*We look if a technicien have any assigned shoot, if he does,
            we add them in an array that will generate markers to represent shoots*/
            if(tech.shootings.length !== 0){
                tech.shootings.forEach(shootings_ => {
                allDailyShootings.push(shootings_);
              })
              }
          });


        /*We fetched all the unassigned shoot and add them in the array that will help us generate markers for the shoots*/
        unAssignedOut[0].shootings.forEach(shootings => {
              if(shootings){
                allDailyShootings.push(shootings);
              }
          })

        this.setState({
          dropZoneColor: dropZoneColor,
          techs: techs,
          unAssignedShootings: unAssignedOut,
          loaded: true,
          loadingData: false,
          allAvailableTechs: availableTechs,
          allDailyShootings: allDailyShootings
        });



    }



  getShooting(value) {
    return new Promise((resolve, reject) => {
      this.setState({ loaded: false });
      if (value === undefined) {
        value = moment(this.state.selectedDate).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
            }


      window.Hauslife.dailyShootingActivity(
        moment(value).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      )
        .then((res) => {
          this.setState({ loaded: true });
                    this.shootingActivityResult(res);
                    this.fetchTechLocksForSelectedDay();
                    resolve();
        })
        .catch((e) => {
          console.log("Some error : ", e);
          reject(e);
                });
    });
    }


    alertClose = (button,data) => {
        this.setState({dlgShow: false, dlgData:null });

        if(button=='cancel') return;

        switch(data){
            case 'dispatch':
                let sId=[];
                for(var p in this.state.dispatch){
                    if(this.state.dispatch[p]===true){
                        sId.push(p)
                    }
                }

                window.Hauslife.dispatchShooting(sId)
                    .then((res) => {
                        if(res.data.userDispatched==0){
                            this.state.dispatch.reset();

                            this.setState({
                                dlgShow: true,
                                dlgConfirm:false,
                                dlgTitle: 'HausValet',
                                dlgText: "Aucun technicien n'est disponible pour la répartition.",
                                dispatch:this.state.dispatch
                            });

                        } else {
                            this.getShooting(this.state.selectedDate);

                            var totalShootiong =Object.keys( this.state.dispatch).reduce((acc,value)=>{ this.state.dispatch[value]&&acc++;return acc  },0);
                            var dispatchedShooting = res.data.shootingDispatched.length;

                            this.state.dispatch.reset();
                            this.setState({
                                dlgShow: true,
                                dlgConfirm:false,
                                dlgTitle: 'HausValet',
                                dlgText: `${dispatchedShooting} / ${totalShootiong} des captures on étés réparties.`,
                                dispatch:this.state.dispatch
                            });
                        }
                    })
                    .catch((e) => {
                        console.log('Some error : ',e);
                    });
                break;
            default:
        }
    }

    handleChange = name => event => {
        var value=event.target.value;
        this.setState({loadingData:true });

        clearTimeout(this.dateTimer);
        this.dateTimer=setTimeout(()=>{
            this.setState({loaded:false });
            this.getShooting(value);
        },800);

        this.setState({ selectedDate: event.target.value });
    }

    changeCalendayDay = (forward = false) => {
        let targetDay;

        if(forward){
            targetDay = moment(this.state.selectedDate).add(1,'day').format('YYYY-MM-DD');
        }
        else{
            targetDay = moment(this.state.selectedDate).subtract(1,'day').format('YYYY-MM-DD');
        }

        this.setState({loadingData:true, selectedDate: targetDay});

        clearTimeout(this.dateTimer);
        this.dateTimer=setTimeout(()=>{
            this.setState({loaded:false });
            this.getShooting(targetDay);
        },800);
    }

    dragStart = (shooting,sourceTech) => event => {
        var currentStartTime = shooting.scheduleddate.getTime();
        var currentEndTime = currentStartTime + 3600000;
        if(shooting.endTime!=undefined){
            currentEndTime=shooting.endTime.getTime();
        }

        var colors = this.state.techs.map( (item)=>{
            let currentTech = item;
            let color= item.shootings.reduce((acc,currentValue)=>{
                if(acc==invalideColor) {
                    return acc;
                }

                var startTime = currentValue.scheduleddate.getTime();
                var endTime = startTime + 3600000;
                if(currentValue.endTime!=undefined){
                    endTime = currentValue.endTime.getTime();
                }

                if(startTime >= currentStartTime && endTime <= currentEndTime){
                    return invalideColor;
                }

                return valideColor;
            },valideColor );

            if(this.isTechDayLocked(currentTech.techId) == true)
            {
                color = lockedTechColor;
            }

            if(this.state.forceAssignEnabled){
                color = valideColor;
            }

            return {id:item.techId,color};
        });

        colors = (
            function(arr){
                return arr.reduce((obj, item,index) => {
                    obj[item.id]=item.color;
                    return obj
                }, {});
            })(colors);

        this.setState({
            dropZoneColor:colors,
            shootingInDrag:shooting,
            sourceTech
        });
    }

    dragEnter = tech  => event =>{
        event.preventDefault();
        event.stopPropagation();
    }
    dragLeave= tech  => event =>{
        event.preventDefault();
    }
    dragOver = shooting  => event =>{
        event.preventDefault();
    }
    dragDrop = tech  => event =>{
        event.preventDefault();
        // Drop on same tech (drop on origin)
        if(tech.techId==this.state.sourceTech.techId){
            return;
        }
        // The tech is red or locked, so no drop allowed
        if(this.state.dropZoneColor[tech.techId]==invalideColor){
            if(this.state.dropZoneColor[tech.techId]==lockedTechColor)
            {
                window.alert("Tech is currently locked for the day!");
            }
            return;
        }

        var shootings =  this.state.sourceTech.shootings;
        var shootingInDrag = this.state.shootingInDrag;



        window.Hauslife.assignProdShooting(this.state.forceAssignEnabled, this.state.shootingInDrag.shootingId,tech.techId)
            .then((res) => {
                // remove shooting from source tech
                if(res.data.success===false){
                    this.setState({
                        dlgShow: true,
                        dlgConfirm:false,
                        dlgTitle: 'HausValet',
                        dlgText: "Conflit d'horaire, l'assignation n'a pas été effectuée."
                    });
                    return;
                }
                this.state.sourceTech.shootings =  shootings.filter( (item)=>{
                    if(item.shootingId==shootingInDrag.shootingId){
                        return false;
                    }
                    return true;
                });

                if(tech.techId > 0){
                    shootingInDrag.force_assigned = this.state.forceAssignEnabled;
                }

                tech.shootings.push(shootingInDrag);

                if(res.data.shootings){
                    tech.shootings.forEach((shooting)=>{
                        res.data.shootings.forEach((updatedShoot)=>{
                            if(shooting.shootingId == updatedShoot.id){
                                shooting.distance = updatedShoot.distance;
                                shooting.transit_time = updatedShoot.transit_time;
                            }
                        })
                    })

                    let distanceTotal = 0;

                    tech.shootings.forEach((shooting)=>{
                        distanceTotal += shooting.distance;
                    })

                    tech.distanceTotal = distanceTotal;
                }

                if(this.state.sourceTech.techDailyActivity){
                    this.state.sourceTech.techDailyActivity =  this.state.sourceTech.techDailyActivity.filter( (item)=>{
                        if(item.shootingId==shootingInDrag.shootingId){
                            return false;
                        }
                        return true;
                    });
                }

                if(tech.techDailyActivity){
                    tech.techDailyActivity.push(shootingInDrag);
                    tech.techDailyActivity = tech.techDailyActivity.sort((a,b)=>{
                        return moment(a.scheduleddate).format('X')-moment(b.scheduleddate).format('X')
                    })
                }

                this.state.dispatch[shootingInDrag.shootingId]=false;
                this.setState({techs:this.state.techs,dispatch:this.state.dispatch}, ()=>{
                    this.sortTechs();
                });

                if(this.state.forceAssignEnabled || shootingInDrag.ignore_scheduler){
                    this.fetchTechLocksForSelectedDay();
                }
            }).catch((e) => {
                console.log('Some error : ',e);
                this.setState({
                    dlgShow: true,
                    dlgConfirm:false,
                    dlgTitle: 'HausValet',
                    dlgText: "L\'assignation n'a pas pu être effectuée."
                });
            });

        this.dragCounter=0;
    }

    dragEnd(){
        return event =>{
            event.preventDefault();

            let dropZoneColor = [];

            for(let i=0; i<this.state.techs.length; i++){
                dropZoneColor[this.state.techs[i].techId] = this.isTechDayLocked(this.state.techs[i].techId) ? lockedTechColor : normalTechColor;
            }

            this.setState({
                dropZoneColor:dropZoneColor,
                shootingInDrag:null
            });
        }
    };

    dateToText (value){
        return moment(value).format('YYYY-MM-DD');
    }

    handleBillableCheckboxChange = (shooting,source,techId) => {
        return (event) => {
            var sho =  this.state.unAssignedShootings;
            if(sho.length>0){
                sho = sho[0].shootings;
            }

            if(source!='unassigned'){
                var tech = this.state.techs.find( (tech)=>tech.techId==techId);
                sho = tech.shootings;
            }

            var conf = window.confirm('Voulez vous vraiment changer le statut de facturation ?');

            if(conf == false) return;

            var target = sho.find( (i)=>i.shootingId==shooting.shootingId);

            if(target.billable==false){
                target.billable=true;
            }  else {
                target.billable=false;
            }

            window.Hauslife.updateShootingBillable(target )
                .then((res)=> {

                }).catch((e) => {
                    console.log('Some error : ',e);
                });

            this.setState({void:1});
        }
    }

    handleDispatchCheckboxChange = (shootingId ) =>{
        return (event) => {
            if(this.state.dispatch[shootingId]==undefined){
                this.state.dispatch[shootingId]=false;
            }
            if(this.state.dispatch[shootingId]==true){
                this.state.dispatch[shootingId]=false;
            } else {
                this.state.dispatch[shootingId]=true;
            }
            this.setState({dispatch:this.state.dispatch})
        }
    }

    handleDispatch ( ){
        this.setState({
            dlgShow: true,
            dlgTitle: 'HausValet',
            dlgText: "Voulez-vous repartir les captures selectionnées ?",
            dlgConfirm:true,
            dlgData:'dispatch'
        })
    }

    recalculateShootingDistances(){
        window.Hauslife.recalculateShootingDistances(moment(this.state.selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
            .then((res)=> {
                this.getShooting(moment(this.state.selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
            }).catch((e) => {
                console.log('Some error : ',e);
            });
    }

    toggleForceAssign(){
        this.setState({forceAssignEnabled:!this.state.forceAssignEnabled});
    }

    getDispatchCheckBoxValue (shootingId){
        var disp = this.state.dispatch[shootingId];

        if(this.state.dispatch[shootingId]==undefined) return false;
        return this.state.dispatch[shootingId]
    }

    countTotalShootings = () =>
    {
        let total = 0;
        this.state.techs.forEach((tech, i)=>{
            total += tech.shootings.length;
        });

        this.state.unAssignedShootings.forEach((tech, i)=>{
            total += tech.shootings.length;
        });

        return total;
    }

    getFormattedDate = () => {
        return moment(this.state.selectedDate).format('dddd') + " " + moment(this.state.selectedDate).format('ll')
    }

    removeShootingFlags = (row)=>()=>{
        window.Hauslife.attemptShootingFlagRemoval(row.shootingId)
            .then((res) => {
                // remove shooting from source tech
                if(res.data.success===false){
                    this.setState({
                        dlgShow: true,
                        dlgConfirm:false,
                        dlgTitle: 'HausValet',
                        dlgText: "La réinitilisation n'a pas pu être effectuée."
                    });
                    return;
                } else {
                    this.setState({
                        dlgShow: true,
                        dlgConfirm:false,
                        dlgTitle: 'HausValet',
                        dlgText: "La réinitilisation a été effectuée avec succès."
                    });
                    this.getShooting(this.state.selectedDate);
                }
            })
            .catch(err => {
                this.setState({
                    dlgShow: true,
                    dlgConfirm:false,
                    dlgTitle: 'HausValet',
                    dlgText: "La réinitilisation n'a pas pu être effectuée."
                });
                return;
            })
    }

    assignTech = (row) => (e) => {
        window.Hauslife.assignProdShooting(this.state.forceAssignEnabled, row.shootingId,e.target.value)
            .then((res) => {
                this.getShooting(this.state.selectedDate);

                // remove shooting from source tech
                if(res.data.success===false){
                    this.setState({
                        dlgShow: true,
                        dlgConfirm:false,
                        dlgTitle: 'HausValet',
                        dlgText: "Conflit d'horaire, l'assignation n'a pas été effectuée."
                    });
                    return;
                } else {

                    this.setState({
                        dlgShow: true,
                        dlgConfirm:false,
                        dlgTitle: 'HausValet',
                        dlgText: "L'Assignation a été effectuée avec succès."
                    });

                    if(this.state.forceAssignEnabled){
                        this.fetchTechLocksForSelectedDay();
                    }
                }
            });
    }

    showListing(item){
        this.setState({listEditShow:true,listingToEdit:item.listing});
    }

    toggleLockForTech = (tech_id) =>
    {
        if(this.isTechDayLocked(tech_id)==true)
        {
            this.unlockDayOfTech(tech_id);
        }
        else
        {
            this.lockDayOfTech(tech_id);
        }
    }

    lockDayOfTech = (tech_id) =>
    {
        window.Hauslife.updateLockStatus(tech_id, true, this.state.selectedDate)
        .then((res)=>{
            this.fetchTechLocksForSelectedDay();
        }).catch( (e)=>{
            console.log('Error   : ',e);
        });
    }

    unlockDayOfTech = (tech_id) =>
    {
        window.Hauslife.updateLockStatus(tech_id, false, this.state.selectedDate)
        .then((res)=>{
            this.fetchTechLocksForSelectedDay();
        }).catch( (e)=>{
            this.setState({
                dlgShow: true,
                dlgConfirm:false,
                dlgTitle: 'HausValet',
                dlgText: `Impossible de débarrer la journée du photographe, il se peut qu\'il aille des captures avec un conflit.`
            });
            console.log('Error   : ',e);
        });
    }

    isTechDayLocked = (tech_id) =>
    {
        for(var i = 0; i < this.state.locks.length; i++)
        {
            if(this.state.locks[i].tech_id == tech_id)
            {
                return true;
            }
        }
    }

    readableTransitTime = (seconds) => {
        return moment.utc(seconds*1000).format('HH:mm:ss');
    }

    firstShoot = (shootings, shoot) => {
        for(let i=0; i<shootings.length; i++){
            if(shootings[i].shootingId == shoot.shootingId){
                return true;
            }
            if(shootings[i].unavailability != true){
                return false;
            }
        }
    }

    lastShoot = (shootings, shoot) => {
        for(let i=shootings.length-1; i>=0; i--){
            if(shootings[i].shootingId == shoot.shootingId){
                return true;
            }
            if(shootings[i].unavailability != true){
                return false;
            }
        }
    }

    onlyShoot = (shootings, shoot) => {
        let shootCount = 0;

        for(let i=0; i<shootings.length; i++){
            if(shootings[i].unavailability != true){
                shootCount++;
            }
        }

        return shootCount == 1;
    }


    getDepartureTime = (shoot) => {
        return moment(shoot.scheduleddate).subtract(shoot.transit_time, 'seconds').format('HH:mm:ss');
    }

    getArrivalTime = (shootings, shoot) => {
        let firstShootTransit = 0;

        for(let i=0; i<shootings.length; i++){
            if(shootings[i].unavailability != true){
                firstShootTransit = shootings[i].transit_time;
                break;
            }
        }
        return moment(shoot.endTime).add(firstShootTransit, 'seconds').format('HH:mm:ss');
    }

    formatShootingsLink = (row, inject_at_office = false) => {
        let shootings_string = "";

        for(let i=0; i<row.shootings.length; i++){
            shootings_string += "/" + row.shootings[i].listing.lat + "," + row.shootings[i].listing.lng;
        }

        let office_string = "/45.50640,-73.43888"

        let depot_string = "/" + row.depot.lat + "," + row.depot.lng

        if(inject_at_office){
            return "https://www.google.com/maps/dir" + depot_string + shootings_string + office_string + depot_string;
        }
        else{
            return "https://www.google.com/maps/dir" + depot_string + shootings_string + depot_string;
        }


    }

    copyShootingAddresses = (row) => {
        let shooting_addresses = "";

        for(let i=0; i<row.shootings.length; i++){
            shooting_addresses += row.shootings[i].address +  "	" + row.techName +  "	" + row.shootings[i].listing.broker.firstname + " " + row.shootings[i].listing.broker.lastname + "\n";
        }

        navigator.clipboard.writeText(shooting_addresses)
    }

    invoicesAvailable = (data) => {
        let invoicesAvailable = false;

        if(data.shootings && data.shootings.length > 0){
            data.shootings.forEach(({invoice})=>{
                if(invoice.invoice_id){
                    invoicesAvailable = true;
                }
            });
        }
        
        return invoicesAvailable;
    }

    openAllInvoices = (data) => {
        if(data.shootings && data.shootings.length > 0){
            data.shootings.forEach(({invoice})=>{
                if(invoice.invoice_id){
                    window.open('https://books.zoho.com/app#/invoices/' + invoice.invoice_id);
                }
            });
        }
    }


    handleOpenModalMap = () => {
        this.setState({ open: true });
      };

      handleCloseModalMap = () => {
        this.setState({ open: false });
      };


    calculateTotalCostForShootings = (shootings) => {
        let totalCost = 0;

        if(shootings && shootings.length > 0){
            shootings.forEach((shooting)=> {
                if (shooting.shootingPackage) {
                    totalCost += shooting.shootingPackage.freelancer_prod_commission;
                }
            });
        }

        return totalCost;
    } 

    calculateTotalCostForShootingOffers = (shootings) => {
        let totalCost = 0;
        shootings.forEach((shooting)=> {
            if (shooting.serviceOffers) {
                const filteredServiceOffers = shooting.serviceOffers.filter((serviceOffer)=> {
                    return serviceOffer.freelancer_prod_commission !== null;
                });

                filteredServiceOffers.forEach((serviceOffer)=> {
                    totalCost += serviceOffer.freelancer_prod_commission;
                });
               
            }
        });
   
        return totalCost;
    }
    
    calculateCostForServiceOffers = (shooting) => {
        let totalCost = 0;

        if(shooting.serviceOffers){
            const filteredServiceOffers = shooting.serviceOffers.filter((serviceOffer)=> {
                return serviceOffer.freelancer_prod_commission !== null;
            });

            filteredServiceOffers.forEach((serviceOffer)=> {
                totalCost += serviceOffer.freelancer_prod_commission;
            });
        }

        if(shooting.shootingPackage){
            totalCost += shooting.shootingPackage.freelancer_prod_commission;
        }

        return totalCost;

    }

    calculateCostForServicesAndShoot = (shootings) => {
        let cost = 0;

        cost = this.calculateTotalCostForShootingOffers(shootings);
        cost += this.calculateTotalCostForShootings(shootings);

        return cost;
    }


    calculateTotalCostForKm = (shootings, user_id) => {
        let sumOfKms = 0;

        if(shootings && shootings.length > 0){
            shootings.forEach((shooting)=> {
                sumOfKms += shooting.distance;                
            });
        }

        const kmCost = globals.kmCostException.includes(user_id) ? 0.59 : globals.kmCost;
        let millageCost = 0;

        if (sumOfKms - 50 > 0) {
            millageCost = (sumOfKms - 50) * kmCost;
        }

        return millageCost.toFixed(2);
    }

    calculateKmPrice = (distance, user_id) => {
        const kmCost = globals.kmCostException.includes(user_id) ? 0.59 : globals.kmCost;
        let millageCost = 0;

        if (distance - 50 > 0) {
            millageCost = (distance - 50) * kmCost;
        }

        return millageCost.toFixed(2);
    }

    
    calculateTotalCost = (shootings, user_id) => {
        
        const totalCost = 
            parseFloat(this.calculateTotalCostForShootings(shootings)) + 
            parseFloat(this.calculateTotalCostForKm(shootings, user_id)) +
            parseFloat(this.calculateTotalCostForShootingOffers(shootings));

        return totalCost.toFixed(2);
    } 

    render() {
        const { classes } = this.props;

        return (
            <Paper className={classes.root}>
                { this.state.listEditShow==true &&
                    <ListingEdit
                        style={{width:'100%'}}
                        show={this.state.listEditShow}
                        didClose={()=>this.setState({listEditShow:false})}
                        listing ={this.state.listingToEdit}
                    />
                }

                <AlertDialog
                    show={this.state.dlgShow}
                    didClose={this.alertClose}
                    title={this.state.dlgTitle}
                    text={this.state.dlgText}
                    confirm={this.state.dlgConfirm}
                    data={this.state.dlgData}
                />

                <Modal
                    aria-labelledby="modal-google-map"
                    aria-describedby="modal-google-map"
                    open={this.state.open}
                    onClose={this.handleCloseModalMap}
                    >
                    <div className={classes.modalBoxClass}>
                     <DailyShootingsMap
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCBlV8_octv2fJn5m6dAPJPn0UozRFm_q8&v=3.exp"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `800px` }} />}
                        isMarkerShown
                        mapElement={<div style={{ height: `100%` }} />}
                        availableTechs = {this.state.allAvailableTechs}
                        todayShootings = {this.state.allDailyShootings}
                      />

                    </div>
                </Modal>

                <Grid container className="shooting-planner">
                    <Grid item xs={12}>
                        <Typography className={classes.title} variant="h3" gutterBottom>Planification des captures</Typography>
                    </Grid>

                    <Grid item xs={12}  className="button-operations">
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleOpenModalMap}
                                className={classes.showMapButton}
                            >
                                <p>Map</p>
                            </Button>
                            <Button
                                variant="contained"
                                color="inherit"
                                className={classes.recalculateDistancesButton}
                                onClick={this.recalculateShootingDistances.bind(this)}>
                                <p>Actualiser</p>
                            </Button>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.forceAssignEnabled}
                                        onChange={this.toggleForceAssign.bind(this)}
                                        value="Assignation forcée"
                                    />
                                }
                                label={<Typography className={classes.forceAssignLabel}>Assignation forcée</Typography>}
                                className={classes.forceAssignContainer}
                            />
                        </>

                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="textPrimary" style={{display:"inline-flex", fontWeight:"bold", margin: 10}}>
                            {this.countTotalShootings() + " captures"}
                        </Typography>
                        <Typography variant="h5" color="textPrimary" style={{display:"block", fontWeight:"bold", margin: 10}}>
                            {this.getFormattedDate()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <>
                            <IconButton  disabled={!this.state.loaded} onClick={()=>this.changeCalendayDay(false)} title={"Une journée avant"}>
                                <ArrowBackIos/>
                            </IconButton>
                            <TextField
                                id="date"
                                label="Date "
                                type="date"
                                disabled={!this.state.loaded}
                                defaultValue={ this.state.selectedDate }
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={this.handleChange('cal')}
                                value={this.state.selectedDate}
                            />
                            <IconButton  disabled={!this.state.loaded} onClick={()=>this.changeCalendayDay(true)} title={"Une journée après"}>
                                <ArrowForwardIos/>
                            </IconButton>
                        </>
                    </Grid>
                   
                </Grid>

                {this.state.loaded==false || this.state.loadingData == true &&
                    <PleaseWait />
                }

                { this.state.loaded==true && !this.state.loadingData &&
                    <Grid container spacing={8}>
                        <Grid item md={6}  className={classes.mainCol} style={{width:'100%'}}>
                            {this.state.techs.length==0 &&
                                <Paper className={classes.noShooting} variant="h3">
                                    Aucun photographe de disponible pour cette date.
                                </Paper>
                            }
                            {this.state.techs.map(row => (
                                
                                <Card
                                    key={row.techId}
                                    className={classes.card}
                                    style={{
                                        backgroundColor:this.state.dropZoneColor[row.techId]
                                    }}
                                    onDragOver={this.dragOver(row)}
                                    onDragEnter={this.dragEnter(row)}
                                    onDragLeave={this.dragLeave(row)}
                                    onDrop={this.dragDrop(row)}>
                                    <CardContent>
                                        <div>
                                            <Typography  variant="h5" color="textPrimary" gutterBottom>
                                                {row.techName}

                                                <IconButton  disabled={this.state.loadingLocks} onClick={()=>this.toggleLockForTech(row.techId)} title={this.isTechDayLocked(row.techId) ? "Unlock Tech":"Lock Tech"}>
                                                    <Icon>{this.isTechDayLocked(row.techId) ? "lock":"lock_open"}</Icon>
                                                </IconButton>
                                            </Typography>
                                            {row.shootings.length > 0 ? (
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header">
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <div>
                                                                {row.shootings.length} capture{row.shootings.length > 1 ? "s: " : ": "} {row.distanceTotal} kms - {this.calculateTotalCost(row.shootings, row.techId)}$
                                                                <MaterialUILink rel="noopener noreferrer" href={this.formatShootingsLink(row, false)} target="_blank" color="inherit">
                                                                    <IconButton title={"Voir trajet avec le inject à la maison"}>
                                                                        <Icon>{"home"}</Icon>
                                                                    </IconButton>
                                                                </MaterialUILink>
                                                                <MaterialUILink rel="noopener noreferrer" href={this.formatShootingsLink(row, true)} target="_blank" color="inherit">
                                                                    <IconButton title={"Voir trajet avec le inject au bureau"}>
                                                                        <Icon>{"maps_home_work"}</Icon>
                                                                    </IconButton>
                                                                </MaterialUILink>
                                                                <IconButton onClick={()=>this.copyShootingAddresses(row)} title={"Copier les addresses des captures"}>
                                                                    <Icon>{"content_copy"}</Icon>
                                                                </IconButton>
                                                                {
                                                                    this.invoicesAvailable(row) ? (
                                                                        <IconButton onClick={()=>this.openAllInvoices(row)} title={"Ouvrir les factures"}>
                                                                            <Icon>{"receipt"}</Icon>
                                                                        </IconButton>
                                                                    ) : null
                                                                }
                                                                
                                                            </div>
                                                            
                                                            {this.calculateTotalCostForKm(row.shootings, row.techId) > 0 ? (
                                                                <Typography>
                                                                    <span>
                                                                        {`Kms : ${this.calculateTotalCostForKm(row.shootings, row.techId)}$      Capture : ${this.calculateCostForServicesAndShoot(row.shootings, row.techId)}$`}
                                                                    </span> 
                                                                </Typography>
                                                            ) : null}                                                          
                                                            {row.techDailyActivity.map(item => {
                                                                const { schedule, summary, unavailability } = item;
                                                                const { civic_number, street, city } = row.depot;
                                                                const { distance, transit_time, address } = item;
                                                                const isOnlyShoot = this.onlyShoot(row.techDailyActivity, item);
                                                                const isFirstShoot = this.firstShoot(row.techDailyActivity, item);
                                                                const isLastShoot = this.lastShoot(row.techDailyActivity, item);
                                                            
                                                                const renderShoot = () => (
                                                                    <>
                                                                    <Typography style={{fontWeight:'bold'}}>
                                                                      {`${schedule} - (${distance} kms - ${this.readableTransitTime(transit_time)}) (${this.calculateCostForServiceOffers(item)}$)`}  
                                                                    </Typography>
                                                                    <Typography>{address}</Typography>
                                                                    </>
                                                                );
                                                            
                                                                return (
                                                                    <div style={{margin:10}}>
                                                                        {unavailability ? (
                                                                            <>
                                                                                <Typography style={{fontWeight:'bold', color:'red'}}>
                                                                                    {`${schedule} ${summary != "" && summary != null ? " - (" + summary + ")" : " - (Indisponibile)"}`}
                                                                                </Typography>
                                                                                <Typography>{`${civic_number} ${street}, ${city}`}</Typography>
                                                                            </>
                                                                            ) : (
                                                                            <>
                                                                            {isOnlyShoot ? (
                                                                                <>
                                                                                    <Typography style={{fontWeight:'bold', color:'blue'}}>
                                                                                        {`Départ du ${civic_number} ${street}, ${city} à ${this.getDepartureTime(item)}`}
                                                                                    </Typography>
                                                                                    <br />
                                                                                    {renderShoot()}
                                                                                    <br />
                                                                                    <Typography style={{fontWeight:'bold', color:'blue'}}>
                                                                                        {`Arrivé au ${civic_number} ${street}, ${city} à ${this.getArrivalTime(row.techDailyActivity, item)}`}
                                                                                    </Typography>
                                                                                </>
                                                                            ) : isFirstShoot ? (
                                                                                <>
                                                                                    <Typography style={{fontWeight:'bold', color:'blue'}}>
                                                                                        {`Départ du ${civic_number} ${street}, ${city} à ${this.getDepartureTime(item)}`}
                                                                                    </Typography>
                                                                                    <br />
                                                                                    {renderShoot()}
                                                                                </>
                                                                            ) : isLastShoot ? (
                                                                                <>
                                                                                    {renderShoot()}
                                                                                    <br />
                                                                                    <Typography style={{fontWeight:'bold', color:'blue'}}>
                                                                                        {`Arrivé au ${civic_number} ${street}, ${city} à ${this.getArrivalTime(row.techDailyActivity, item)}`}
                                                                                    </Typography>
                                                                                </>
                                                                            ) : renderShoot()
                                                                            }
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails style={{flexDirection:"column"}}>

                                                        {row.shootings.map(item => (
                                                                <Card
                                                                    key={item.shootingId}
                                                                    className={classes.itemCard}
                                                                    style={item.production_incomplete ? {backgroundColor:'#a11500', color:'#fff'} : undefined}
                                                                    draggable
                                                                    onDragStart={this.dragStart(item,row)}
                                                                    onDragEnd={this.dragEnd()}>
                                                                    <CardContent style={{padding:10}}>
                                                                        {item.force_assigned == true || item.ignore_scheduler == true ? (
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="inherit"
                                                                                id="mobil-unassign"
                                                                                className={classes.removeShootingFlags}
                                                                                onClick={this.removeShootingFlags(item)}>
                                                                                {'Réinitiliser'}
                                                                            </Button>
                                                                        ) : null}

                                                                        <Typography variant="subtitle1" style={{ display:'flex',flexDirection:'row',justifyContent:'flex-end' }}>
                                                                            {item.direct_drive == true ? (
                                                                                <span style={{fontWeight:'bold'}}>Direct Drive</span>
                                                                                ) : null                                                                                
                                                                            }
                                                                            {
                                                                                item.serviceOffers ? (
                                                                                    item.serviceOffers.map((serviceOffer) => {
                                                                                        return (
                                                                                            <span style={{fontWeight:'bold'}}>{serviceOffer.display_name_fr}</span>
                                                                                        )
                                                                                    }))
                                                                                 : null
                                                                            }
                                                                        </Typography>

                                                                        <Typography>
                                                                            <span style={{fontWeight:'bold'}}>Coût de production:</span> {this.calculateCostForServiceOffers(item)}$ 
                                                                        </Typography>
                                                                       
                                                                        { item.shootingPackage != null ?
                                                                            (<>
                                                                                <Typography style={{ fontWeight:'bold' }}>
                                                                                    {item.shootingPackage.display_name_fr}
                                                                                </Typography>                                                                               
                                                                                <Typography >
                                                                                   <span style={{ fontWeight:'bold' }}> Nombre de photos à livrer: </span> 
                                                                                   {item.shootingPackage.recommendation_min} - {item.shootingPackage.recommendation_max}
                                                                                </Typography>
                                                                            </>) : null
                                                                        }                                                                          

                                                                        { item.ignore_scheduler == true ?
                                                                            (<Typography>
                                                                                <span
                                                                                    style={{
                                                                                        color:'red',
                                                                                        fontWeight:'bold'}}>
                                                                                    {"HORAIRE IGNORÉ"}
                                                                                </span>
                                                                            </Typography>) : null
                                                                        }
                                                                        { item.force_assigned == true ?
                                                                            (<Typography>
                                                                                <span
                                                                                    style={{
                                                                                        color:'red',
                                                                                        fontWeight:'bold'}}>
                                                                                    {"ASSIGNATION FORCÉE"}
                                                                                </span>
                                                                            </Typography>) : null
                                                                        }
                                                                        
                                                                        { item.production_incomplete ? <h4 style={{float:'left', textTransform:'uppercase'}}>capture incomplète</h4> : undefined}
                                                                        <div style={{display:'flex',justifyContent:'flex-end'}} >
                                                                            <FormControl className={'select-multiple'} style={{width:150, marginBottom:10}}>
                                                                                <InputLabel  style={ item.production_incomplete ?{color:'#fff'}:{color:'black'}} className="mobile-select" htmlFor="select-multiple">
                                                                                    Photographe
                                                                                </InputLabel>
                                                                                <Select className="mobile-select"
                                                                                    value={this.state.techs}
                                                                                    onChange={this.assignTech(item) }
                                                                                    input={<Input id="select-multiple"  value="foo"/>}>
                                                                                    {this.state.techs.map(item => (
                                                                                        item.techName != row.techName ? (
                                                                                            <MenuItem key={item.techId} value={item.techId} >
                                                                                                {item.techName}
                                                                                            </MenuItem>
                                                                                            ) : null
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                        <Typography variant="h7">
                                                                            <span
                                                                                onClick={()=>this.showListing(item)}
                                                                                style={{
                                                                                    cursor:'pointer'}}>
                                                                                {item.address}
                                                                            </span>
                                                                        </Typography>
                                                                        <Typography >
                                                                            <span style={item.production_incomplete ? {fontWeight:'bold', color:'#fff'} : {fontWeight:'bold'}}>
                                                                                {item.schedule}
                                                                            </span>
                                                                        </Typography>
                                                                        <Typography >
                                                                            <Link style={item.production_incomplete ? {color:'#22c9f2'}:{color:'#1F3068'}} to={"/users/edit/"+item.brokerId}>
                                                                                <b>{"Courtier : " + item.broker}</b>
                                                                            </Link>
                                                                        </Typography>

                                                                        {item.note ?
                                                                            (
                                                                                <Typography component="p">
                                                                                    {item.note}
                                                                                </Typography>
                                                                            ) : null
                                                                        }
                                                                        <ExpansionPanel>
                                                                            <ExpansionPanelSummary expandIcon={<InfoIcon />} style={{width:50, float:'right'}}/>
                                                                            <ExpansionPanelDetails style={{flexDirection:"column"}}>
                                                                                <Typography  cstyle={{fontWeight:"bold"}}>
                                                                                    <Link style={{fontWeight:'bold',color:'blue'}} to={"/users/edit/"+item.brokerId}>
                                                                                        {"Courtier : " + item.broker}
                                                                                    </Link>
                                                                                </Typography>
                                                                                <Typography >
                                                                                    {"Listing ID : " + item.listingId}
                                                                                </Typography>
                                                                                <Typography >
                                                                                    {"Shooting ID : " + item.shootingId}
                                                                                </Typography>
                                                                                <Typography component="p">
                                                                                    {item.distance} kms
                                                                                </Typography>
                                                                                {  item.banner!=null &&
                                                                                    <Typography component="p">
                                                                                        {item.banner}
                                                                                    </Typography>
                                                                                }
                                                                                {item.reshoot==true &&
                                                                                    <Typography component="p" >
                                                                                        Reshoot ({item.reshootIndex})
                                                                                    </Typography>
                                                                                }
                                                                                <Typography component="p">
                                                                                    {item.rooms} pièces
                                                                                </Typography>

                                                                                <Typography component="p">
                                                                                    Facturable :
                                                                                    <Checkbox className={classes.dispatchCheckbox}
                                                                                        onChange={this.handleBillableCheckboxChange(item,'tech',row.techId)}
                                                                                        checked={item.billable}
                                                                                        color="primary"/>
                                                                                </Typography>
                                                                            </ExpansionPanelDetails>
                                                                        </ExpansionPanel>
                                                                    </CardContent>
                                                                </Card>
                                                            ))}
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            ) : null}
                                            <ExpansionPanel defaultExpanded={row.shootings.length == 0}>
                                            <ExpansionPanelSummary expandIcon={<InfoIcon />} style={{width:50, float:'right'}}/>
                                                <ExpansionPanelDetails style={{flexDirection:"column"}}>                                                    
                                                    {row.depot != null ? (
                                                        <Typography  variant="h6" gutterBottom>
                                                            {"Dépot: " }
                                                            <Typography  variant="subheading" color="textPrimary" gutterBottom>
                                                                {row.depot.civic_number + " " + row.depot.street + ", " + row.depot.city}
                                                            </Typography>
                                                        </Typography>
                                                    ) : null}
                                                    {row.unavailabilities.length > 0 ? (
                                                        <Typography  variant="h6" gutterBottom>
                                                            Indisponibilités:
                                                        </Typography>
                                                    ) : null}
                                                    {row.unavailabilities.map(unavailability => (
                                                        <>
                                                            <Typography  variant="subtitle1"   gutterBottom>
                                                                {moment(unavailability.start).format('HH:mm') + "-" + moment(unavailability.end).format('HH:mm') + (unavailability.summary != "" ? " " + unavailability.summary : "")}
                                                            </Typography>
                                                        </>
                                                    ))}
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    </CardContent>
                                </Card>
                            ))}
                        </Grid>
                        <Grid item md={6} className={[classes.mainCol, classes.unassignedShoots]} style={{marginTop:this.state.scrollPosition}}>
                            {this.state.unAssignedShootings.map(row => (
                                <Card
                                    key={row.techId}
                                    className={[classes.card, classes.unassignedShoots]}
                                    style={{backgroundColor:this.state.dropZoneColor[row.techId]}}
                                    onDragOver={this.dragOver(row)}
                                    onDragEnter={this.dragEnter(row)}
                                    onDragLeave={this.dragLeave(row)}
                                    onDrop={this.dragDrop(row)}>

                                    <CardContent>
                                        <Grid container >
                                            <Grid item xs={8}>
                                                <Typography style={{minHeight:45}} variant="h6" gutterBottom>
                                                    {row.techName}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {row.shootings.map(item=> (
                                            <Card
                                                key={item.shootingId}
                                                className={classes.itemCard}
                                                draggable style={{backgroundColor:item.backgroundColor}}
                                                onDragStart={this.dragStart(item,row)}
                                                onDragEnd={this.dragEnd()}>

                                                <CardContent>
                                                    <Typography variant="subtitle1" style={{ display:'flex',flexDirection:'row',justifyContent:'flex-end' }}>
                                                        {item.direct_drive == true ? (
                                                            <span style={{color:'#3f51b5',fontWeight:'bold'}}>Direct Drive</span>
                                                            ) : null                                                                                
                                                        }
                                                         {
                                                            item.serviceOffers ? (
                                                                item.serviceOffers.map((serviceOffer) => {
                                                                    return (
                                                                        <span style={{fontWeight:'bold'}}>{serviceOffer.display_name_fr}</span>
                                                                    )
                                                                })
                                                            )
                                                            : null
                                                        }
                                                    </Typography>
                                                    <>
                                                        { item.shootingPackage != null ?
                                                            (<>
                                                                <Typography style={{ fontWeight:'bold' }}>
                                                                    {item.shootingPackage.display_name_fr}
                                                                </Typography>

                                                                <Typography>
                                                                    <span style={{ fontWeight:'bold' }}>Coût de production: </span> 
                                                                    {item.shootingPackage.freelancer_prod_commission}$
                                                                </Typography>
                                                 
                                                                <Typography>
                                                                    <span style={{ fontWeight:'bold' }}> Nombre de photos à livrer: </span> 
                                                                    {item.shootingPackage.recommendation_min} - {item.shootingPackage.recommendation_max}
                                                                </Typography>
                                                            </>) : null
                                                        }
                                                        {item.serviceOffers ? 
                                                            <Typography>
                                                                <span style={{fontWeight:'bold'}}>Total pour les offres de service:</span> {this.calculateCostForServiceOffers(item)}$ 
                                                            </Typography>
                                                        : null}
                                                        { item.ignore_scheduler == true ?
                                                            (<Typography>
                                                                <span
                                                                    style={{
                                                                        color:'red',
                                                                        fontWeight:'bold'}}>
                                                                    {"HORAIRE IGNORÉ - À ASSIGNER RAPIDEMENT"}
                                                                </span>
                                                            </Typography>) : null
                                                        }
                                                        <div style={{display:'flex',justifyContent:'flex-end'}} >
                                                            <FormControl className={'select-multiple'} style={{width:150, marginBottom:10}}>
                                                                <InputLabel className="mobile-select" htmlFor="select-multiple">
                                                                    Photographe
                                                                </InputLabel>
                                                                <Select className="mobile-select"
                                                                    value={this.state.techs}
                                                                    onChange={this.assignTech(item) }
                                                                    input={<Input id="select-multiple"  value="foo"/>}>
                                                                    {this.state.techs.map(item => (
                                                                        <MenuItem key={item.techId} value={item.techId} >
                                                                            {item.techName}
                                                                        </MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <Typography variant="h7">
                                                            <span
                                                                onClick={()=>this.showListing(item)}
                                                                style={{

                                                                    color:'black',
                                                                    cursor:'pointer'}}>
                                                                {item.address}
                                                            </span>
                                                        </Typography>
                                                        <Typography >
                                                            {item.schedule}
                                                        </Typography>
                                                        <Typography >
                                                            <Link style={{color:'blue'}} to={"/users/edit/"+item.brokerId}>
                                                                {"Courtier : " + item.broker}
                                                            </Link>
                                                        </Typography>

                                                        {item.note ?
                                                            (
                                                                <Typography component="p">
                                                                    {item.note}
                                                                </Typography>
                                                            ) : null
                                                        }

                                                        <ExpansionPanel>
                                                            <ExpansionPanelSummary expandIcon={<InfoIcon />} style={{width:50, float:'right'}}/>
                                                            <ExpansionPanelDetails style={{flexDirection:"column"}}>
                                                                <Typography  cstyle={{fontWeight:"bold"}}>
                                                                    <Link style={{fontWeight:'bold',color:'blue'}} to={"/users/edit/"+item.brokerId}>
                                                                        {"Courtier : " + item.broker}
                                                                    </Link>
                                                                </Typography>
                                                                <Typography >
                                                                    {"Listing ID : " + item.listingId}
                                                                </Typography>
                                                                <Typography >
                                                                    {"Shooting ID : " + item.shootingId}
                                                                </Typography>
                                                                {  item.banner!=null &&
                                                                    <Typography component="p">
                                                                        {item.banner}
                                                                    </Typography>
                                                                }
                                                                {item.reshoot==true &&
                                                                    <Typography component="p" >
                                                                        Reshoot ({item.reshootIndex})
                                                                    </Typography>
                                                                }

                                                                <Typography component="p">
                                                                    {item.rooms} pièces
                                                                </Typography>

                                                                <Typography component="p">
                                                                    Facturable :
                                                                    <Checkbox className={classes.dispatchCheckbox}
                                                                        onChange={this.handleBillableCheckboxChange(item,'unassigned')}
                                                                        checked={item.billable}
                                                                        color="primary"/>
                                                                </Typography>
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>
                                                    </>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </CardContent>
                                </Card>
                            ))}
                        </Grid>
                    </Grid>
                }
            </Paper>
        );
    }
}


function ListWithDays(props) {
    return (
        <CardContent>
            {Object.keys(props.days).map((day,idx) => (
                <Card key={idx} style={{backgroundColor:'#eeeeee', marginBottom:10}}>
                    <CardContent>
                        <Typography variant="h6" component="h6">
                            {day}
                        </Typography>

                        {props.days[day].map(item=>(
                            <Card key={item.shootingId} style={{marginBottom:10}}>
                                <CardContent>
                                    <Typography variant="h6" component="h6">
                                        {item.address}
                                    </Typography>
                                    <Typography variant="h6" component="h6">
                                        {item.only360 ? "360 Seulement" : "2D + 360"}
                                    </Typography>
                                    {item.note ?
                                        (
                                            <Typography component="p">
                                                {item.note}
                                            </Typography>
                                        ) : null
                                    }
                                    <Typography component="p">
                                        {item.schedule}
                                    </Typography>
                                    <Typography component="p">
                                        {item.distance} kms
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </CardContent>
                </Card>
            ))}
        </CardContent>
    )
}
export default withRouter(withStyles(styles)(ShootingPlanner));
