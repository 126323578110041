import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MUIPlacesAutocomplete, { geocodeBySuggestion } from 'mui-places-autocomplete'

import HausButton from './HausButton'

import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';

var _ = require('lodash');

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    tableRow: {
      cursor:'pointer'
    },
    button: {
        margin: theme.spacing.unit,
    }
});

class AddOrModifyDepot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            depot: null,
            loaded: false,
            addmode: false,
            loading:false,
            depotRequestResult:null,
            depotRequestSuccess:false,
            showingGeoFetchModal:false,
            geoFetchCoords:null
        };
    }

    componentWillMount() {
        //get depot info
        if(this.props.location == undefined || this.props.location.state == undefined || this.props.location.state.depot == undefined)
        {
            //new Depot
            this.setState({addmode:true, depot:{
                lng:"",
                lat:"",
                civic_number:"",
                street:"",
                city:""
            }});
        }
        else
        {
            //console.log(this.props.location.state.depot);
            this.setState({addmode:false, depot:this.props.location.state.depot});
        }
    }

    handleDepotInformationChange = (keyname, value) =>
    {
        console.log(keyname, value.target.value);
        let depotCopy = {...this.state.depot};

        depotCopy[keyname] = value.target.value;

        this.setState({depot:depotCopy});
    }

    saveDepot = () =>
    {
        this.setState({loading:true, depotRequestResult: null, depotRequestSuccess:false},()=>{
            if(this.state.addmode == true)
            {
                window.Hauslife.addDepot(this.state.depot.lng, this.state.depot.lat, this.state.depot.civic_number, this.state.depot.street, this.state.depot.city)
                .then((res) => {

                    let depotCopy = {...this.state.depot};

                    depotCopy["id"] = res.data.depot.id;

                    this.setState({ addmode:false, depot:depotCopy, depotRequestSuccess:true, depotRequestResult:res.data, loading:false });
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ depotRequestSuccess:false, depotRequestResult:e, loading:false });
                });
            }
            else
            {
                window.Hauslife.modifyDepot(this.state.depot.id, this.state.depot.lng, this.state.depot.lat, this.state.depot.civic_number, this.state.depot.street, this.state.depot.city)
                .then((res) => {
                    this.setState({ depotRequestSuccess:true, depotRequestResult:res.data, loading:false });
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ depotRequestSuccess:false, depotRequestResult:e, loading:false });
                });
            }
        });
    }

    onback = () =>
    {
        this.props.history.goBack();
    }

    showGeoFetchModal = () =>
    {
        this.setState({showingGeoFetchModal:true});
    }

    geoFetchClosed = () =>
    {
        this.setState({showingGeoFetchModal:false});
    }

    geoFetchClosedApply = () =>
    {
        let newDepot = {...this.state.depot};

        newDepot = {...newDepot, ...this.state.geoFetchCoords};

        this.setState({showingGeoFetchModal:false, depot: newDepot, geoFetchCoords: null});
    }

    onSuggestionSelected = (suggestion) =>
    {
        // Once a suggestion has been selected by your consumer you can use the utility geocoding
        // functions to get the latitude and longitude for the selected suggestion.
        geocodeBySuggestion(suggestion).then((results) => {
            if (results.length < 1) {    
                console.log("No suggestions.");
                return;
            }
    
            // Just use the first result in the list to get the geometry coordinates
            //console.log(results[0]);
            const { geometry, address_components } = results[0]
    
            let geoFetchedDepot = {
                lat: geometry.location.lat(),
                lng: geometry.location.lng()
            };

            address_components.forEach((adr)=>{
                if(adr.types.includes("street_number"))
                {
                    geoFetchedDepot.civic_number = adr.long_name;
                }

                if(adr.types.includes("route"))
                {
                    geoFetchedDepot.street = adr.long_name;
                }

                if(adr.types.includes("locality"))
                {
                    geoFetchedDepot.city = adr.long_name;
                }
            });

            // Add your business logic here. In this case we simply set our state to show our <Snackbar>.
            //this.setState({ open: true, coordinates })
            this.setState({geoFetchCoords: geoFetchedDepot});
        }).catch((err) => {
            //this.setState({ open: true, errorMessage: err.message })
            console.log(err);
        });
    }

    render() {
        const { classes } = this.props;

        if(this.state.depot == null)
        {
            return(
                <Grid direction="column"
                justify="flex-start"
                alignItems="stretch" container>
                    <Grid item xs={12} style={{marginBottom:20}}>
                        <PleaseWait />
                    </Grid>
            </Grid>
            );
        }

        return (
            <>
                <Dialog scroll={"body"} fullWidth open={this.state.showingGeoFetchModal} onClose={this.geoFetchClosed} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Geolocation finder</DialogTitle>
                    <DialogContent style={{minHeight:270}}>
                        <MUIPlacesAutocomplete
                            onSuggestionSelected={this.onSuggestionSelected}
                            renderTarget={() => (<div/>)}
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.geoFetchClosed} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.geoFetchClosedApply} color="primary">
                        Save
                    </Button>
                    </DialogActions>
                </Dialog>
                <Grid style={{padding:10}} direction="column"
                justify="flex-start"
                alignItems="stretch" container>
                    <Grid item xs={12} style={{marginBottom:20}}>
                        <Paper style={{padding:10}}>
                            <Typography variant="h5" component="h3" style={{marginBottom:30}}>
                                <Button
                                    variant={"outlined"}
                                    color={"primary"}
                                    style={{marginRight:10}}
                                    onClick={this.onback}
                                >
                                    Retour
                                </Button>
                                {this.state.addmode ? "Ajout d'un dépot" : "Modification du dépot"}
                            </Typography>
                            {this.state.depotRequestResult != null && <SnackbarContent style={{backgroundColor:this.state.depotRequestSuccess ? "green":"red",maxWidth:"98%", margin:"1%"}} message={this.state.depotRequestSuccess ? (this.state.addmode?"Le dépot à été ajouté!":"Le dépot à été modifié!") :this.state.depotRequestResult.message} />}
                            <HausButton
                                variant={"outlined"}
                                color={"primary"}
                                style={{width:"98%", margin:"1%", padding:"1%"}}
                                disabled={this.state.loading}
                                onClick={this.showGeoFetchModal}
                            >
                                Get information from Google (Recommended)
                            </HausButton>
                            <TextField
                                style={{width:"18%", margin:"1%"}}
                                label="Civic number"
                                value={this.state.depot.civic_number}
                                onChange={(e)=>this.handleDepotInformationChange("civic_number",e)}
                                margin="normal"
                            />
                            <TextField
                                label="Street"
                                style={{width:"78%", margin:"1%"}}
                                value={this.state.depot.street}
                                onChange={(e)=>this.handleDepotInformationChange("street",e)}
                                margin="normal"
                            />
                            <TextField
                                style={{width:"98%", margin:"1%"}}
                                label="City"
                                value={this.state.depot.city}
                                onChange={(e)=>this.handleDepotInformationChange("city",e)}
                                margin="normal"
                            />
                            <br />
                            <TextField
                                style={{width:"48%", margin:"1%"}}
                                label="LAT"
                                value={this.state.depot.lat}
                                onChange={(e)=>this.handleDepotInformationChange("lat",e)}
                                margin="normal"
                            />
                            <TextField
                                style={{width:"48%", margin:"1%"}}
                                label="LNG"
                                value={this.state.depot.lng}
                                onChange={(e)=>this.handleDepotInformationChange("lng",e)}
                                margin="normal"
                            />

                            <HausButton
                                variant={"outlined"}
                                color={"primary"}
                                style={{width:"98%", margin:"1%", padding:"1%"}}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                                onClick={this.saveDepot}
                            >
                                {this.state.addmode ? "Ajouter le dépot" : "Sauvgarder le dépot"}
                            </HausButton>
                        </Paper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withRouter(withStyles(styles)(AddOrModifyDepot));
