import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

class HausButton extends React.Component {

    renderLoadingIcon = () =>
    {
        return(
            <CircularProgress style={{marginRight:10}} size={16} />
        );
    }

    render()
    {
        console.log("loading", this.props.loading);
        return(
            <Button {...this.props}>
                {(this.props.loading != undefined && this.props.loading === true) && this.renderLoadingIcon()}
                <FormattedMessage id={this.props.children} />
            </Button>
        );
    }
}

export default HausButton;
  
