import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@material-ui/core";

import { action_types, useReduxReducer } from "./useReduxState";
import _ from "lodash";
import moment from "moment";
import global from "../../globals";

const styles = {
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3,
    overflowX: "clip",
    // padding:20,
    fontSize: 23,
  },
  table: {
    minWidth: 700,
  },
  tableRow: {
    cursor: "pointer",
    alignItems: "flex-end",
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  card: {
    marginBottom: 20,
  },
  itemCard: {
    marginBottom: 10,
    backgroundColor: "#eeeeee",
  },
};

const Dashboard = (props) => {
  const [state, dispatch] = useReduxReducer();

  const fetchListingVideos = () => {
    window.Hauslife.getAllJobsAndVMs()
      .then((response) => {
        if (response.status == 200) {
          dispatch({ type: action_types["FETCH_LISTING_VIDEOS_AND_VMS"], data: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Quelque chose s'est mal passé")
      });
  };

  useEffect(() => {
    fetchListingVideos();
  }, []);

  const [showLogs, setShowLogs] = useState(null);

  const handleShowLogs = (job_id) => {
    window.Hauslife.getLogsForJob(job_id)
      .then((response) => {
        setShowLogs(job_id);
        if (response.status == 200) {
          dispatch({ type: action_types.FETCH_JOB_LOGS, data: { job_id: job_id, logs: response.data } });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResetJob = (job) => {

    const listing_video_config = { ...job, listing_video_id: job.id };
    window.Hauslife.resetJob(listing_video_config)
      .then((response) => {
        if (response.status == 200) {
          dispatch({ type: action_types.RESET_JOB, data: {...response.data.data, status: 1} });
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Quelque chose s'est mal passé")
      });
  };

  const Jobs = () => (
    <Grid style={{ height: "100%", flexGrow: 0, alignContent: "flex-start" }} container>
      <Grid xs={12} style={{ marginBottom: 20 }}>
        <Paper style={{ padding: 10, textAlign: "center" }}>
          <Typography style={{ marginBottom: 5 }}>
            All Jobs
            <Button variant="contained" color="primary" style={{ marginLeft: 20, marginRight: 20 }} onClick={fetchListingVideos}>
              Refresh
            </Button>
            Green: success, Grey: waiting, Red: Error
          </Typography>
        </Paper>
      </Grid>

      {showLogs && (
        <Dialog open={showLogs != null} onClose={() => setShowLogs(null)} maxWidth="md" style={{ width: "100%", textAlign: "center" }}>
          <DialogTitle id="form-dialog-title">{`Logs ${"e"}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <ul>
                {_.get(state.jobs, [showLogs, "logs"], []).map((log) => (
                  <li>
                    <Typography variant="body2">{moment(log.timestamp).format()}</Typography>
                    <Typography variant="body1">{log.message}</Typography>
                  </li>
                ))}
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ alignSelf: "center" }}>
            <Button onClick={() => setShowLogs(null)} color="primary" variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Grid xs={12} style={{ alignSelf: "flex-start", flex: 1 }}>
        <Paper className={styles.root}>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell className="listing-list-cell center-text">Id</TableCell>
                <TableCell className="listing-list-cell center-text">Listing Id</TableCell>
                {/* <TableCell className="listing-list-cell">Is Instagram?</TableCell> */}
                <TableCell className="listing-list-cell center-text">Requested</TableCell>
                <TableCell className="listing-list-cell center-text">Run Start</TableCell>
                <TableCell className="listing-list-cell center-text">Run End</TableCell>
                <TableCell className="listing-list-cell center-text">Requesting broker</TableCell>
                <TableCell className="listing-list-cell center-text">1st and 2rd broker</TableCell>
                <TableCell className="listing-list-cell center-text">VM</TableCell>
                <TableCell className="listing-list-cell center-text">Logs</TableCell>
                <TableCell className="listing-list-cell center-text">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(state.jobs) &&
                [...state.jobs.values()].map((row) => (
                  <TableRow style={styles.tableRow} key={row.id}>
                    <TableCell className="listing-list-cell center-text">
                      <span
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          display: "inline-block",
                          backgroundColor:
                            row.status == global.videoCreationStatus.success
                              ? "green"
                              : row.status == global.videoCreationStatus.enqueued
                              ? "grey"
                              : "red",
                          // It is not enqueued, it either stops at the validation stage, or fail in VM
                        }}
                      />
                      <span style={{ paddingBottom: 20 }}>{row.id}</span>
                    </TableCell>
                    <TableCell className="listing-list-cell center-text">
                      <Link style={{ color: "blue" }} to={`/tech_review/${row.listing_id}`} target="_blank">
                        {row.listing_id}
                      </Link>
                    </TableCell>
                    {/* <TableCell className="listing-list-cell center-text" > {row.is_instagram.toString()} </TableCell> */}
                    <TableCell className="listing-list-cell center-text"> {moment(row.requested_at).format("Do MMM, HH:m")}</TableCell>
                    <TableCell className="listing-list-cell center-text">
                      {" "}
                      {row.run_start_at && moment(row.run_start_at).format("Do MMM, HH:m")}
                    </TableCell>
                    <TableCell className="listing-list-cell center-text">
                      {" "}
                      {row.video_uploaded_at && moment(row.video_uploaded_at).format("Do MMM, HH:m")}
                    </TableCell>
                    <TableCell className="listing-list-cell center-text"> {row.current_broker_id} </TableCell>
                    <TableCell className="listing-list-cell center-text">
                      {" "}
                      {`${row.main_broker_id} ${row.secondary_broker_id ? ", " + row.secondary_broker_id : ""}`}{" "}
                    </TableCell>

                    <TableCell className="listing-list-cell center-text"> {row.vm} </TableCell>
                    <TableCell className="listing-list-cell center-text">
                      <Button onClick={() => handleShowLogs(row.job_id)} variant="contained" color="primary">
                        Fetch
                      </Button>
                    </TableCell>
                    <TableCell className="listing-list-cell center-text">
                      {
                        // not equal to 2 means either 0 which is only requested but not enqueued, or 1 is on waiting queue, or 3 failure
                        row.status != 2 && (
                          <Button onClick={handleResetJob.bind(null, row)} variant="contained" color="secondary">
                            Reset
                          </Button>
                        )
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );


  return (
    <Grid container>
      <Grid xs={12} style={{ marginBottom: 20 }}><Jobs /></Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(Dashboard));
