import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import LinkButton from './LinkButton.js'
import PleaseWait from './PleaseWait.js'
import ShootingTimeframeSelector from './ShootingTimeframeSelector.js'


import { injectIntl, FormattedMessage } from 'react-intl';

import Icon from '@material-ui/core/Icon';

import { geocodeByPlaceID } from 'mui-places-autocomplete'

import MUIPlacesAutocomplete from './MUIPlacesAutocomplete.js'

import logo_google from './../powered_by_google_on_white.png';

import * as moment from 'moment';
import 'moment/locale/fr-ca';

const styles = theme => ({
    table:
    {
    },
    snackbar:{
        padding: theme.spacing.unit,
        backgroundColor: theme.palette.error.dark,
        maxWidth:"100%",
        marginBottom: theme.spacing.unit * 2
    },
    tableCell:
    {
        padding:5,
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    stepsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    actionsContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        margin: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 3,
    },
    snackbar_good: {
        margin: theme.spacing.unit,
        backgroundColor: green[600],
    },
    textField: {
        marginRight: theme.spacing.unit,
        minWidth: 250,
    },
});

/**
 * Class used to display the creation of a shooting
 */
class BookShooting extends React.Component {

    constructor(props) {
      super(props);
      let sessionGenerate = this.uuid();
      this.state = {autocompleteSessionToken:sessionGenerate,errorWhileBooking:false,selectedTimeframe:null,isWaitingForShootingConfirmationCall:false,activeStep: 0,isCheckingCity:false,isValidAddress:false,foundAddress:false,is_existing_listing:false,nb_rooms:4,is_house:true,visual_address:"",address:"",suite:"",city:"",postal_code:"",note:"",gps_location:{lat:0,lng:0}};
    }


    /**
     * Moves to the next step in the creation process
     */
    handleNext = () => {
        this.setState(state => ({
          activeStep: state.activeStep + 1,
        }), ()=>{
            if(this.state.activeStep===2)
            {
                this.DoBookShootingWithServer();
            }
        });
    };

    /**
     * Tries to book the shooting with the server
     */
    DoBookShootingWithServer = () => {
        this.setState({isWaitingForShootingConfirmationCall:true});

        

        var selectedTimeFrameTS = this.state.selectedTimeframe.ts;
        var selectedTimeFrameAM = this.state.selectedTimeframe.am;

        window.Hauslife.createShooting(this.state.address,this.state.suite,this.state.city,this.state.postal_code,this.state.is_house,this.state.nb_rooms,selectedTimeFrameTS,selectedTimeFrameAM,this.state.is_existing_listing,this.state.note, this.state.gps_location.lat, this.state.gps_location.lng)
        .then((res)=>{
            console.log(res);
            this.setState({isWaitingForShootingConfirmationCall:false,errorWhileBooking:false});
        })
        .catch((e)=>{
            this.setState({activeStep:this.state.activeStep-1,errorWhileBooking:true,isWaitingForShootingConfirmationCall:false});
        });
    };

    /**
     * Back button
     */
    handleBack = () => {
        this.setState(state => ({
        activeStep: state.activeStep - 1,
        }));
    };

    /**
     * Resets the state
     */
    handleReset = () => {
        this.setState({
        activeStep: 0,
        });
    };

    checkIfValidCity = () => {
        //isValidAddress
        window.Hauslife.validateShootingCity(this.state.city)
        .then((res)=>{
            this.setState({isCheckingCity:false,isValidAddress:(res.data.status === 200)});
        })
        .catch((e)=>{
            this.setState({isCheckingCity:false,isValidAddress:false});
        });
    };

    /**
     * Called when the user selects a location from Google maps
     * @param {*} suggestion 
     */
    onSuggestionSelected = (suggestion) => {
        geocodeByPlaceID(suggestion.place_id).then((results) => {

            const { address_components, geometry } = results[0]
      
            var postal_code = "";
            var city = "";
            var street_number = "";
            var route = "";

            address_components.forEach(element => {
                if(element.types.includes("postal_code")){
                    postal_code = element.short_name;
                }
                if(element.types.includes("locality") || element.types.includes("administrative_area_level_3")){
                    city = element.short_name;
                }
                if(element.types.includes("route")){
                    route = element.short_name;
                }
                if(element.types.includes("street_number")){
                    street_number = element.short_name;
                }
            });
      
            this.setState({
                visual_address:suggestion.description,
                address:street_number+" "+route,
                city: city,
                gps_location: geometry.location,
                postal_code:postal_code,
                foundAddress:true,
                isCheckingCity:true
            },()=>{
                this.checkIfValidCity();
            });
            
          }).catch((err) => {
            
          })
    };

    uuid = () => {
        var uuid = "", i, random;
        for (i = 0; i < 32; i++) {
          random = Math.random() * 16 | 0;
      
          if (i == 8 || i == 12 || i == 16 || i == 20) {
            uuid += "-"
          }
          uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
        }
        return uuid;
      }

    /**
     * Function used by google maps autocomplete to know what to give us
     * @param {*} inputValue 
     */
    createAutocompleteRequest = (inputValue) => {
        console.log(this.state.autocompleteSessionToken);
        return {
          input: inputValue,
          types: ['geocode'],
          componentRestrictions: {'country': ['ca']}
        }
    };

    /**
     * Called when the address is modified
     * @param {*} event 
     */
    PlacesOnChange = (event) =>
    {
        this.setState({visual_address:event.target.value});
    };

    /**
     * Called when the house type is changed
     * @param {*} event 
     */
    HandleHouseTypeChange = (event) => 
    {
        this.setState({is_house:event.target.value});
    };

    /**
     * Called when the listing type is changed
     * @param {*} event 
     */
    HandleExistingListingChange = (event) => 
    {
        this.setState({is_existing_listing:event.target.value});
    };

    RenderError = (id) => {
        const { classes, intl } = this.props;

        return(
            <Paper style={{marginBottom:5,padding:15}} className={classes.snackbar} elevation={1}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography style={{color:'white'}} component="p">
                        <Icon style={{verticalAlign:"middle",marginRight:15}}>error</Icon> <FormattedMessage id={id} />
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    /**
     * Renders property informations
     */
    RenderPropertyInfos = () =>
    {
        const { classes, intl } = this.props;
        const housetypes = [{label:intl.formatMessage({id:"bookshooting.housetype_house"}),value:true},{label:intl.formatMessage({id:"bookshooting.housetype_condo"}),value:false}];
        const listing_existings = [{label:intl.formatMessage({id:"bookshooting.listing_exists_false"}),value:false},{label:intl.formatMessage({id:"bookshooting.listing_exists_true"}),value:true}];

        return(
            <Grid container>
                {(this.state.foundAddress == true && this.state.isValidAddress==false && this.state.isCheckingCity==false) && <Grid item xs={12}>{this.RenderError("bookshooting.city_not_supported")}</Grid>}
                <Grid xs={12}>
                    <MUIPlacesAutocomplete
                        isOpen={true}
                        textFieldProps={{ variant:"outlined", fullWidth:true, onChange: this.PlacesOnChange, value: this.state.visual_address }}
                        onSuggestionSelected={this.onSuggestionSelected}
                        createAutocompleteRequest={this.createAutocompleteRequest}
                        renderTarget={() => (<div><img style={{marginTop:5}} src={logo_google}/></div>)}
                    />
                </Grid>
                <Grid xs={12}>
                    <TextField
                        id="standard-select-is-house"
                        variant="outlined"
                        select
                        label={intl.formatMessage({id:"bookshooting.select_property_type"})}
                        className={classes.textField}
                        value={this.state.is_house}
                        onChange={this.HandleHouseTypeChange}
                        margin="normal"
                        >
                        {housetypes.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        variant="outlined"
                        id="standard-disabled"
                        label={intl.formatMessage({id:"bookshooting.number_or_rooms"})}
                        value={this.state.nb_rooms}
                        onChange={(event)=>{this.setState({nb_rooms:event.target.value})}}
                        className={this.props.classes.textField}
                        margin="normal"
                    />
                    <TextField
                        variant="outlined"
                        id="standard-select-is-existing"
                        select
                        label={intl.formatMessage({id:"bookshooting.does_listing_exists"})}
                        className={classes.textField}
                        value={this.state.is_existing_listing}
                        onChange={this.HandleExistingListingChange}
                        margin="normal"
                        >
                        {listing_existings.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid xs={12}>
                    <TextField
                        variant="outlined"
                        label={intl.formatMessage({id:"bookshooting.adrs_suite"})}
                        value={this.state.suite}
                        onChange={(event)=>{this.setState({suite:event.target.value})}}
                        className={this.props.classes.textField}
                        margin="normal"
                    />
                    <TextField
                        disabled
                        variant="outlined"
                        label={intl.formatMessage({id:"bookshooting.city"})}
                        value={this.state.city}
                        className={this.props.classes.textField}
                        margin="normal"
                    />
                    <TextField
                        disabled
                        variant="outlined"
                        label={intl.formatMessage({id:"bookshooting.postal_code"})}
                        value={this.state.postal_code}
                        className={this.props.classes.textField}
                        margin="normal"
                    />
                </Grid>
                <Grid xs={12}>
                    <TextField
                    id="standard-multiline-static"
                    label={intl.formatMessage({id:"bookshooting.notes"})}
                    multiline
                    fullWidth
                    rows="4"
                    value={this.state.note}
                    className={classes.textField}
                    onChange={(event)=>{this.setState({note:event.target.value})}}
                    margin="normal"
                    variant="outlined"
                    />
                </Grid>
          </Grid>
        );
    };
    
    /**
     * Renders the timeframe select step
     */
    RenderTimeFrameSelect = () => {
        const { classes, intl } = this.props;

        var listing = {
            address:this.state.visual_address,
            is_house:this.state.is_house,
            room_count:this.state.nb_rooms,
            is_existing_listing:this.state.is_existing_listing,
            lat:this.state.gps_location.lat,
            lng:this.state.gps_location.lng
        };

        return (<ShootingTimeframeSelector onTimeframeSelect={(ts,am)=>{console.log(ts,am); this.setState({selectedTimeframe:{ts:ts,am:am}});}} listing={listing} />);
    };

    /**
     * Helper function to the steps titles
     */
    getSteps = () => {
        const { intl } = this.props;
        return [intl.formatMessage({id:"bookshooting.step1_title"}), intl.formatMessage({id:"bookshooting.step2_title"})];
    };
    
    /**
     * Helper function to get the step's content
     */
    getStepContent = (step) => {
        switch (step) {
            case 0:
            return this.RenderPropertyInfos();
            case 1:
            return this.RenderTimeFrameSelect();
            default:
            return 'Unknown step';
        }
    };

    render()
    {
        const { classes, intl } = this.props;
        const steps = this.getSteps();
        const { activeStep } = this.state;
    
        return (
          <div className={classes.root}>

        {this.state.errorWhileBooking === true && 
            <Paper style={{marginBottom:5,padding:15}} className={classes.snackbar} elevation={1}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography style={{color:'white'}} component="p">
                        <Icon style={{verticalAlign:"middle",marginRight:15}}>error</Icon> <FormattedMessage id="bookshooting.error" />
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        }

          {activeStep !== steps.length && (
            <Stepper style={{paddingLeft:5}} activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      <div className={classes.stepsContainer}>{this.getStepContent(index)}</div>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={this.handleBack}
                            className={classes.button}
                          >
                            <FormattedMessage id="bookshooting.back" />
                          </Button>
                          <Button
                            disabled={(activeStep === 0 && (this.state.foundAddress == false || this.state.isValidAddress == false || this.state.postal_code.trim().length<6)) || (activeStep === 1 && this.state.selectedTimeframe === null)}
                            variant="contained"
                            color="primary"
                            onClick={this.handleNext}
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1 ? intl.formatMessage({id:"bookshooting.book_shooting"}) : intl.formatMessage({id:"bookshooting.next"})}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
            )}
            {(activeStep === steps.length) && (this.state.isWaitingForShootingConfirmationCall == false ? (
                <div>
                    <Grid container justify={"center"} className={classes.root}>
                        <Grid item xs={12}>
                            <Typography align={"center"}><Icon style={{color:green[600],fontSize:120}}>check_circle</Icon></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h3" style={{margin:15}} align={"center"}><FormattedMessage id="bookshooting.confirmation_message" /></Typography>
                            <Typography style={{margin:30}} align={"center"}>
                                <LinkButton variant="outlined" color="primary" to={'/shooting'}>
                                    bookshooting.return
                                </LinkButton>
                            </Typography>
                        </Grid>
                    </Grid>
              </div>
            ) : (
                <PleaseWait />
            ))}
          </div>
        );
    }
}

export default injectIntl(withRouter(withStyles(styles)(BookShooting)));