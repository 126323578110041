import React, {useReducer, useEffect, useState} from "react";
import CardDragLayer from "./CardDragLayer";
import Card from "./Card";
import {
  Button,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText,
} from '@material-ui/core';
import Review from "../Review";
import globals from "../../../globals";

const cardReducer = (state, action) => {
  switch (action.type) {
    case "CLEAR_SELECTION":
      return {
        ...state,
        selectedCards: init_state.selectedCards,
        lastSelectedIndex: init_state.lastSelectedIndex
      };
    case "UPDATE_SELECTION":
      return {
        ...state,
        selectedCards: action.newSelectedCards,
        lastSelectedIndex: action.newLastSelectedIndex
      };
    case "REARRANGE_CARDS":
      return { ...state, cards: action.newCards };
    case "SET_INSERTINDEX":
      return {
        ...state,
        dragIndex: action.dragIndex,
        hoverIndex: action.hoverIndex,
        insertIndex: action.insertIndex
      };
    default:
      throw new Error();
  }
};


const init_state = {
  cards: null,
  selectedCards: [],
  lastSelectedIndex: -1,
  dragIndex: -1,
  hoverIndex: -1,
  insertIndex: -1,
  isDragging: false
};

function PictureReordering({pictures, pictureActions, handlePictureOrderChange, broker}) {

  const [state, dispatch] = useReducer(cardReducer, init_state);
  const [showValidationFinished, setShowValidationFinished] = useState(false)
  const [modalPicture, setModalPicture] = useState(null);
  const [pictureServiceOfferId, setPictureServiceOfferId] = useState({traditional:0, drone:0, blueHour:0 });

  useEffect(()=>{
    state.cards = pictures.filter((pic)=>pic.thumbnail_url!=null).map((picture) => ({
      id: picture.id,
      order: picture.picture_index,
      url: picture.show_watermark ? picture.watermarked_url : picture.thumbnail_url,
      picture: picture,
    }));

  },[]);

  useEffect(()=> {
    let pictureServiceOfferIdCopy = pictureServiceOfferId;

    if (broker.subscription_id == globals.subscription.platform) {
      pictureServiceOfferIdCopy.traditional = globals.picture_service_offer.plateformeTraditionalPic;
      pictureServiceOfferIdCopy.drone =  globals.picture_service_offer.plateformeDronePic;
      pictureServiceOfferIdCopy.blueHour = globals.picture_service_offer.plateformeBlueHourPic;
    } else {
      pictureServiceOfferIdCopy.traditional = globals.picture_service_offer.carteTraditionalPic;
      pictureServiceOfferIdCopy.drone =  globals.picture_service_offer.carteDronePic;
      pictureServiceOfferIdCopy.blueHour = globals.picture_service_offer.carteBlueHourPic;
    }

    setPictureServiceOfferId(pictureServiceOfferIdCopy);
    
  });

  const clearItemSelection = () => {
    dispatch({ type: "CLEAR_SELECTION" });
  };
  const handleItemSelection = (index, ctrlKey, shiftKey) => {
    let newSelectedCards;

    const cards = state.cards;
    const card = index < 0 ? "" : cards[index];
    const newLastSelectedIndex = index;

    if (!ctrlKey && !shiftKey) {
      newSelectedCards = [card];
    } else if (shiftKey) {
      if (state.lastSelectedIndex >= index) {
        newSelectedCards = [].concat.apply(
          state.selectedCards,
          cards.slice(index, state.lastSelectedIndex)
        );
      } else {
        newSelectedCards = [].concat.apply(
          state.selectedCards,
          cards.slice(state.lastSelectedIndex + 1, index + 1)
        );
      }
    } else if (ctrlKey) {
      const foundIndex = state.selectedCards.findIndex((f) => f === card);
      // If found remove it to unselect it.
      if (foundIndex >= 0) {
        newSelectedCards = [
          ...state.selectedCards.slice(0, foundIndex),
          ...state.selectedCards.slice(foundIndex + 1)
        ];
      } else {
        newSelectedCards = [...state.selectedCards, card];
      }
    }
    const finalList = cards
      ? cards.filter((f) => newSelectedCards.find((a) => a === f))
      : [];
    dispatch({
      type: "UPDATE_SELECTION",
      newSelectedCards: finalList,
      newLastSelectedIndex: newLastSelectedIndex
    });
  };

  const rearrangeCards = (dragItem) => {
    let cards = state.cards.slice();
    const new_pictures = [];
    const draggedCards = dragItem.cards;
    let dividerIndex;
    if ((state.insertIndex >= 0) && (state.insertIndex < cards.length)) {
      dividerIndex = state.insertIndex;
    } else {
      // If missing insert index, put the dragged cards to the end of the queue
      dividerIndex = cards.length;
    }
    const upperHalfRemainingCards = cards
      .slice(0, dividerIndex)
      .filter((c) => !draggedCards.find((dc) => dc.id === c.id));
    const lowerHalfRemainingCards = cards
      .slice(dividerIndex)
      .filter((c) => !draggedCards.find((dc) => dc.id === c.id));
    const newCards = [
      ...upperHalfRemainingCards,
      ...draggedCards,
      ...lowerHalfRemainingCards
    ];

    dispatch({ type: "REARRANGE_CARDS", newCards: newCards });

    newCards.forEach((card,index)=>{
      card.picture_index = index;
      new_pictures.push(card.picture)
    });
   handlePictureOrderChange(new_pictures);
  };

  const setInsertIndex = (dragIndex, hoverIndex, newInsertIndex) => {
    if (
      state.dragIndex === dragIndex &&
      state.hoverIndex === hoverIndex &&
      state.insertIndex === newInsertIndex
    ) {
      return;
    }
    dispatch({
      type: "SET_INSERTINDEX",
      dragIndex: dragIndex,
      hoverIndex: hoverIndex,
      insertIndex: newInsertIndex
    });
  };


  const setNextUnseenPictureModal = () => {
    let currIndex = pictures.findIndex(p => p.id === modalPicture.id)
    let nextIndex = currIndex
    let allVisited = true

    const getNext = (idx) => {
      return (idx === pictures.length - 1) ? 0 : (idx + 1)
    }

    while(nextIndex >= currIndex) {
      nextIndex = getNext(nextIndex)
      if(pictures[nextIndex]['is_validated'] === null) {
        allVisited = false
        setModalPicture(pictures[nextIndex])
        break;
      }
    }

    if(allVisited) {
      setModalPicture(null)
      setShowValidationFinished(true)
    }
  }

  const handlePictureChange = (isNext) => {
    let currIndex = pictures.findIndex(p => p.id === modalPicture.id)
    let newIndex;
    if(isNext) {
      newIndex = (currIndex === pictures.length - 1) ? 0 : (currIndex + 1)
    } else {
      newIndex = (currIndex === 0) ? (pictures.length - 1) : (currIndex - 1)
    }
    setModalPicture(pictures[newIndex])
  }


  const handleVisibilityToggle = (picture_id, is_visible) => {
    if (state.selectedCards.length !== 0) {
        const pictureIds = [];
        for(let i = 0; i < state.selectedCards.length; i++) {
          if (!state.selectedCards[i].picture.is_purchased) {
            pictureIds.push(state.selectedCards[i].picture.id); 
          } 
        } 

        if (!pictureIds.includes(picture_id)) {
            pictureActions.updatePicturesVisibility([picture_id], is_visible); 
        } else {
           pictureActions.updatePicturesVisibility(pictureIds, is_visible);
        }    
      
    } else {
        pictureActions.updatePicturesVisibility([picture_id], is_visible);  
    }
  }

  const handleVideoSelectionToggle = (picture_id, is_selected) => {
    if (state.selectedCards.length !== 0) {
        const pictureIds = [];
        for(let i = 0; i < state.selectedCards.length; i++) {
            pictureIds.push(state.selectedCards[i].picture.id); 
        }

        if (!pictureIds.includes(picture_id)) {
            pictureActions.updatePicturesVideoSelection([picture_id], is_selected); 
        } else {
          pictureActions.updatePicturesVideoSelection(pictureIds, is_selected); 
        }

    } else {
        pictureActions.updatePicturesVideoSelection([picture_id], is_selected);  
    }
  }

  const handleWatermarkToggle = (picture_id, showWatermark) => {

      if (state.selectedCards.length !== 0) {
          const pictureIds = [];
          for(let i = 0; i < state.selectedCards.length; i++) {
              if (!state.selectedCards[i].picture.is_purchased) {
                pictureIds.push(state.selectedCards[i].picture.id); 
              }
          }

          if (!pictureIds.includes(picture_id)) {
              pictureActions.enableWatermark([picture_id], showWatermark); 
          } else {
            pictureActions.enableWatermark(pictureIds, showWatermark);
          }

      } else {
          pictureActions.enableWatermark([picture_id], showWatermark);  
      }
  }

  const setPictureType = (picture_id, pictureType) => {
    let picture_service_offer_id = 0;

    if (pictureType == 'traditional') {
      picture_service_offer_id = pictureServiceOfferId.traditional;
    } else if (pictureType == 'drone') {
      picture_service_offer_id = pictureServiceOfferId.drone;
    } else if (pictureType == 'blueHour') {
      picture_service_offer_id = pictureServiceOfferId.blueHour;
    }

    if (state.selectedCards.length !== 0) {
        const pictureIds = [];
        for(let i = 0; i < state.selectedCards.length; i++) {
            pictureIds.push(state.selectedCards[i].picture.id); 
        }

        if (!pictureIds.includes(picture_id)) {
            pictureActions.assignPicturesType([picture_id], picture_service_offer_id); 
        } else {
            pictureActions.assignPicturesType(pictureIds, picture_service_offer_id);
        }

    } else {
        pictureActions.assignPicturesType([picture_id], picture_service_offer_id);  
    }
  }

  const handleClick = (picture) => {
      setModalPicture(picture);
      clearItemSelection();
  }

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center'
    }}>
      {
          showValidationFinished &&
          <Dialog
              open={showValidationFinished}
              onClose={() => setShowValidationFinished(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
          >
            <DialogTitle style={{textAlign: 'center'}}>{"Validation terminée"}</DialogTitle>
            <DialogContent style={{padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{margin: '5%', minWidth: '25em', textAlign: 'center'}}>
                <DialogContentText>
                  {
                    `Photos envoyées en retouche: ${pictures.filter(p => p.is_validated === false).length}`
                  }
                </DialogContentText>
                <DialogContentText>
                  {
                    `Photos validées: ${pictures.filter(p => p.is_validated === true).length}`
                  }
                </DialogContentText>
              </div>
            </DialogContent>
            <Button onClick={() => setShowValidationFinished(false)} color="primary" autoFocus>
              Ok
            </Button>
          </Dialog>
      }
      {
          (modalPicture != null) &&
          <Review
              picture={modalPicture}
              handleChange={handlePictureChange}
              onClose={() => setModalPicture(null)}
              pictureActions={pictureActions}
              setNextUnseenPictureModal={setNextUnseenPictureModal}
          />
      }
      <div>
        <CardDragLayer />
        <div style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}>
          { state.cards !== null ? (state.cards.map((card, i) => {
            const insertLineOnLeft = state.hoverIndex === i && state.insertIndex === i;
            const insertLineOnRight = state.hoverIndex === i && state.insertIndex === i + 1;
            return (
              <Card
                key={"card-" + card.id}
                id={card.id}
                index={i}
                order={card.order}
                url={card.url}
                picture={card.picture}
                onVisibilityToggle={handleVisibilityToggle}
                onVideoSelectionToggle={handleVideoSelectionToggle}
                onWatermarkToggle={handleWatermarkToggle}
                selectedCards={state.selectedCards}
                rearrangeCards={rearrangeCards}
                setInsertIndex={setInsertIndex}
                onSelectionChange={handleItemSelection}
                clearItemSelection={clearItemSelection}
                isSelected={state.selectedCards.includes(card)}
                insertLineOnLeft={insertLineOnLeft}
                insertLineOnRight={insertLineOnRight}
                handleClick={()=>handleClick(card.picture)}
                pictureServiceOfferId={pictureServiceOfferId}
                handlePictureTypeChange={setPictureType}
              />
            );
          })) : undefined}
        </div>
      </div>
   </div>
  );
}
export default PictureReordering;
