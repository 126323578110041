import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PleaseWait from './PleaseWait.js'
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

import * as moment from 'moment';
import 'moment/locale/fr-ca';

const styles = theme => ({
    table:
    {
    }
});

class ShootingTimeframeSelector extends React.Component {

    constructor(props) {
      super(props);
      //moment.utc().startOf('day')
      this.state = {currentDateForBooking:moment.utc().startOf('day'),shooting:null,changedDate:false,error:false,isChangingDate:false,timeframes:[]};
    }

    nextDays = () => {
        var newmoment = moment(this.state.currentDateForBooking);
        newmoment.add(7,'day');
        this.setState({timeframes:[],currentDateForBooking:newmoment},()=>{
            this.GetTimeFramesForCurrentAddress();
        });
    }

    prevDays = () => {
        var newmoment = moment(this.state.currentDateForBooking);
        newmoment.subtract(7,'day');
        if(newmoment.isBefore(moment(),'day'))
        {
            newmoment = moment();
        }
        this.setState({timeframes:[],currentDateForBooking:newmoment},()=>{
            this.GetTimeFramesForCurrentAddress();
        });
    }

    componentWillMount()
    {
        this.GetTimeFramesForCurrentAddress();
    }

    /**
     * Helper function to make sure the user has at least one timeframe selected
     */
    GetNumberOfTimeFramesSelected = () => {
        if(this.state.timeframes.length === 0) return 0;

        let addedCount = 0;
        this.state.timeframes.forEach((e,i)=>{
            e.frames.forEach((e2,i2)=>{
                if(e2 == 1)
                {
                    addedCount++;
                }
            });
        });
        
        return addedCount;
    };

    /**
     * Get the current selected timeframe
     */
    GetSelectedTimeframeDay = () => {

        for(var i=0; i < this.state.timeframes.length; i++)
        {
            let e = this.state.timeframes[i];

            for(var i2=0; i2 < e.frames.length; i2++)
            {
                if(e.frames[i2] === 1)
                {
                    //we have found!
                    //console.log(e.frames[i2],moment().add(i2+1,"day").valueOf());
                    var addedMoment = moment(this.state.currentDateForBooking).add(i2,"day");

                    return addedMoment.toISOString();
                }
            }
        }

        return 0;
    };

    /**
     * Helper function to know if the selected timeframe is in the morning or not
     */
    IsSelectedTimeframeAm = () => {
        if(this.state.timeframes.length === 0) return false;
        if(this.state.timeframes[0].frames.includes(1)) return true;
        return false;
    };

    /**
     * Sets the available timeframes for the next days
     */
    GetTimeFramesForCurrentAddress = () => {
        //this.setState({timeframes:[{name:"AM",frames:[0,0,0]},{name:"PM",frames:[0,0,0]}]});

        this.setState({timeframes:[]},()=>{
            var timeframes = [{name:"AM (8h30-12h30)",old:[],disabled:[],frames:[]},{name:"PM (12h30-17h00)",old:[],disabled:[],frames:[]}];

            var listing = this.props.listing;

            var is360 = false;

            if(typeof(this.props.shooting) !== "undefined")
            {
                var currentDate = moment.utc(this.props.shooting.scheduleddate);
                console.log(currentDate.format("LLL"));
                var currentAm = this.props.shooting.shooting.am;
                is360 = this.props.shooting.only_360;
            }
            else
            {
                is360 = listing.is_existing_listing;
            }

            window.Hauslife.validateShootingAvailability(listing.address, listing.is_house, listing.room_count, is360, this.state.currentDateForBooking.toISOString(), 1, listing.lat, listing.lng)
            .then((res)=>{
                console.log(res.data.time_slot);

                //find the first data from server

                var smalldate = null;

                for(var j =0;j<res.data.time_slot.length;j++)
                {
                    var e = res.data.time_slot[j];
                    var currentDate = moment(e.date_in_ts);
                    if(smalldate == null || currentDate.isBefore(smalldate))
                    {
                        smalldate = currentDate;
                    }
                }

                console.log("smalldate",smalldate.toISOString());
                
                //smalldate.subtract(1,'day');

                //console.log("today from server " + smalldate.format("LLLL"));

                for(var j =0;j<res.data.time_slot.length;j++)
                {
                    var e = res.data.time_slot[j];
                

                    var myDate = moment(e.date_in_ts);
                    
                    console.log("myDate",myDate.toISOString());
    
                    for(var i = 0;i<res.data.time_slot.length/2;i++)
                    {
                        //console.log(e);

                        timeframes[0].frames[i] = 0;
                        timeframes[1].frames[i] = 0;

                        if(myDate.isSame(moment(smalldate).add(i,"day"), 'day'))
                        {
                            //console.log(e);
                            if(e.is_am === true)
                            {
                                timeframes[0].disabled[i] = e.success !== true;
                            }
                            else
                            {
                                timeframes[1].disabled[i] = e.success !== true;
                            }
                        }

                        if(typeof(this.props.shooting) !== "undefined")
                        {
                            if(currentDate.isSame(moment(smalldate).add(i,"day"), 'day'))
                            {
                                if(currentAm === true)
                                {
                                    timeframes[0].disabled[i] = true;
                                    timeframes[0].old[i] = true;
                                }
                                else
                                {
                                    timeframes[1].disabled[i] = true;
                                    timeframes[1].old[i] = true;
                                }
                            }
                        }
                    }
                }
                
    
                this.setState({timeframes:timeframes});
    
            })
            .catch((e)=>{
                console.error(e);
            });
        });
    };

    /**
     * Called when the user selects a timeframe
     * @param {*} indexOfDay 
     * @param {*} index 
     * @param {*} event 
     */
    HandleTimeFrameChange = (indexOfDay,index, event) => {
        
        var timeframes = [...this.state.timeframes];

        //un select all frames
        for(var i = 0; i < timeframes.length; i++)
        {
            for(var j = 0; j < timeframes[i].frames.length; j++)
            {
                timeframes[i].frames[j] = 0;
            }
        }

        timeframes[indexOfDay].frames[index] = event.target.checked?1:0;
        
        this.setState({timeframes:timeframes},()=>{
            var selectedTimeFrameTS = this.GetSelectedTimeframeDay();
            console.log("from deep", selectedTimeFrameTS);
            var selectedTimeFrameAM = this.IsSelectedTimeframeAm();
            this.props.onTimeframeSelect(selectedTimeFrameTS,selectedTimeFrameAM);
        });
    };

    /**
     * Renders the timeframe select step
     */
    RenderTimeFrameSelect = () => {
        const { classes } = this.props;

        if(this.state.timeframes.length === 0)
        {
            return (
            <PleaseWait alwaysShow={true} />
            );
        }

        return (
            <Table style={{tableLayout:"fixed"}} className={classes.table}>
                <TableHead>
                <TableRow>
                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                    {this.state.timeframes[0].frames.map((e,j) => {
                        var thisTime = moment(this.state.currentDateForBooking);
                        var myTimeFormated = thisTime.add(j,"day").utc().format("LL");
                        return (<TableCell className={classes.tableCell}>{myTimeFormated}</TableCell>);
                    })}
                </TableRow>
                </TableHead>
                <TableBody>
                
                {this.state.timeframes.map((timeOfDay,indexOfDay) => {
                    return (
                    <TableRow>
                    <TableCell className={classes.tableCell}>
                    {timeOfDay.name}
                    </TableCell>
                    {
                        timeOfDay.frames.map((daysAfterNow, index) => {
                            return (
                            <TableCell className={classes.tableCell}>
                                <Radio
                                    disabled={this.state.timeframes[indexOfDay].disabled[index]==1}
                                    checked={this.state.timeframes[indexOfDay].frames[index] === 1 || this.state.timeframes[indexOfDay].old[index] == 1}
                                    onChange={(event)=>this.HandleTimeFrameChange(indexOfDay,index,event)}
                                    name="radio-button-timeframe-select"
                                    value={indexOfDay+"-"+index}
                                    color="primary"
                                />
                            </TableCell>);
                        })
                    }
                    </TableRow>
                    );
                })}
                </TableBody>
            </Table>
        );
        
    };

    render()
    {
        const { classes } = this.props;



        return (
            <div>
                {this.RenderTimeFrameSelect()}
                <div style={{display: "flex",marginTop:15,marginBottom:15,justifyContent:"flex-end"}}>
                    <Button disabled={this.state.timeframes.length === 0} style={{marginRight:15}} onClick={this.prevDays} variant="outlined"><FormattedMessage id="bookshooting.prev" /></Button>
                    <Button disabled={this.state.timeframes.length === 0} onClick={this.nextDays} variant="outlined"><FormattedMessage id="bookshooting.next" /></Button>
                </div>
            </div>
        );
    }
}

ShootingTimeframeSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    onTimeframeSelect: PropTypes.func.isRequired,
    listing: PropTypes.object.isRequired
  };

export default withRouter(withStyles(styles)(ShootingTimeframeSelector));
  