import React, {useReducer} from 'react';
import { get } from 'lodash'

const initialState = {
    jobs: {},
    brokers: {},
    machines: {}
}

export const action_types = {
    'RESET_JOB': 'RESET_JOB',
    'FETCH_JOB_LOGS': 'FETCH_JOB_LOGS',
    'FETCH_LISTING_VIDEOS_AND_VMS': 'FETCH_LISTING_VIDEOS_AND_VMS',
}

function reducer(state, action) {
    switch(action.type) {
        case action_types['FETCH_LISTING_VIDEOS_AND_VMS']:
            let {jobs: jobsInHausvaletDB} = action.data

            // Convert array of object to object of object for later operation convenience
            let jobs = new Map();
            jobsInHausvaletDB.forEach(job => jobs.set(job.id, job));
            
            return {
                ...state,
                jobs
            }

        case action_types.RESET_JOB:
            let updatedJob = action.data
            let jobsClone = state.jobs;
            jobsClone.set(updatedJob.id, updatedJob)
            return {
                ...state,
                jobs: jobsClone
            }
        
        case action_types.FETCH_JOB_LOGS:
            let { job_id, logs } = action.data
            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    [job_id]: {
                        ...state['jobs'][job_id],
                        logs: logs
                    }
                }
            }
        default:
            return state
    }
}

export const useReduxReducer = () => useReducer(reducer, initialState)