import React from 'react';
import { withRouter } from "react-router-dom";
import SuperCrud from '../components/SuperCrud'


//TODO load this from API
const Subscriptions = {
    'Base': 'Base',
    '10% Discount': 'Rabais 10',
    '20% Discount': 'Rabais 20',
    '30% Discount': 'Rabais 30'
}

export const BaseForm = {
    id: {
        "type": 'number',
        "editable": false
    },
    display_name_fr: {
        "type": 'text',
        "editable": true,
        "required": true
    },
    display_name_en: {
        "type": 'text',
        "editable": true,
        "required": true
    },
    price: {
        "type": 'number',
        "editable": true,
        "required": true
    },
    hdr_photo_count: {
        "type": 'number',
        "editable": true,
        "required": true
    },
    items_fr: {
        "type": 'multiline',
        "editable": true,
        "required": true
    },
    items: {
        "type": 'multiline',
        "editable": true,
        "required": true
    },
    order_index: {
        "type": 'number',
        "editable": true,
        "required": true
    },
    is_visible_to_brokers: {
        "type": 'boolean',
        "editable": true,
        "required": true
    },
    recommendation_min: {
        "type": 'number',
        "editable": true,
        "required": false
    },
    recommendation_max: {
        "type": 'number',
        "editable": true,
        "required": false
    },
    zoho_item_id: {
        "type": 'number',
        "editable": true,
        "required": true
    },
    gf_clause: {
        "type": 'boolean',
        "editable": true,
        "required": true
    },
    key_name: {
        "type": 'text',
        "editable": true,
        "required": true
    },
    subscription: {
        "type": 'select',
        "editable": true,
        "required": true,
        "multiple": false,
        options: Subscriptions
    },
    excluded_items_en: {
        "type": 'multiline',
        "editable": true,
        "required": false
    },
    excluded_items_fr: {
        "type": 'multiline',
        "editable": true,
        "required": false
    },
    included_service_offers: {
        "type": 'select',
        "editable": true,
        "required": false,
        "multiple": true,
        options: {},
        fetchOptions: () => window.Hauslife.getAllServiceOffers()
    }
}

const ManageShootingPackages = () => {
    return (
        <SuperCrud 
            superFetchAll={window.Hauslife.getAllShootingPackages}
            superCreate={window.Hauslife.createShootingPackage}
            superUpdate={window.Hauslife.updateShootingPackage}
            superDelete={window.Hauslife.deleteShootingPackage}
            title={'Shooting Packages Management'} 
            display_name_key={'key_name'}
            baseForm={BaseForm}
        />
    )
}

export default withRouter(ManageShootingPackages);