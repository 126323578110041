import React, { useState, useEffect } from 'react'

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import UnDeleteIcon from '@material-ui/icons/RestoreFromTrash'

import VisibleIcon from '@material-ui/icons/Visibility'
import HiddenIcon from '@material-ui/icons/VisibilityOff'

import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const styles = {
    listItem: {
    	display: 'flex',
    	justifyContent: 'center',
    	alignItems: 'center'
    },
    clickableListItem: {
    	display: 'flex',
    	justifyContent: 'center',
    	alignItems: 'center',
    	cursor: 'pointer'
    }
};

export const ViewListItem = props => {
	let { zone, onToggleZoneSelect, onZoneEdit, onToggleZoneDelete, checked, toggleZoneVisibility } = props
	return (
		<ListItem 
			key={zone.id}
			style={styles.listItem}
			divider
		>	
			<ListItemIcon>
				<Checkbox 
					checked={checked}
					onChange={event => onToggleZoneSelect(zone.id)}
				/>
			</ListItemIcon>
			<ListItemText primary={zone.zone_name} />
			<ListItemSecondaryAction style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
				{
					(zone.hasOwnProperty('isHidden') && zone['isHidden'] == true)
					?	<HiddenIcon color="primary" onClick={() => toggleZoneVisibility(zone)} />
					:	<VisibleIcon color="primary" onClick={() => toggleZoneVisibility(zone)} />
				}
				<EditIcon color="primary" onClick={(e) => onZoneEdit(e, zone)} />
				{
					(zone.hasOwnProperty('isRemoved') && zone['isRemoved'] == true)
					?	<UnDeleteIcon color="secondary" onClick={(e) => onToggleZoneDelete(e, zone)} />
					:	<DeleteIcon color="secondary" onClick={(e) => onToggleZoneDelete(e, zone)} />
				}
			</ListItemSecondaryAction>
		</ListItem>
	)
}

export const EditListItem = props => {

	const { zoneName, onSave, onCancel } = props

	const [newZoneName, setNewZoneName] = useState("")

	useEffect(() => {
		if(zoneName) {
			setNewZoneName(zoneName)
		}
	}, [zoneName])

	const _handleSave = () => {
		onSave(newZoneName)
		setNewZoneName("")
	}

	return (
		<ListItem 
			style={styles.listItem}
			divider 
		>
			<TextField 
				id="new-zone-name" 
				label="Enter Zone Name" 
				variant="outlined" 
				style={{width: '100%'}} 
				value={newZoneName}
				onChange={evt => {
					evt.persist()
					setNewZoneName(evt.target.value)
				}}
			/>
			
			<ListItemSecondaryAction style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start'}}>
				<DoneOutlineIcon color="primary" onClick={_handleSave} />
				<CancelIcon color="secondary" onClick={onCancel} />
			</ListItemSecondaryAction>
		</ListItem>
	)	
}