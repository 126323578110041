import React from 'react';
import { Calendar, momentLocalizer, Views, Navigate } from 'react-big-calendar';
import moment from 'moment';
import * as dates from 'date-arithmetic'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'

class MyWeek extends React.Component {
    render() {
        console.log("Props", this.props)
      let { date } = this.props
      
      let range = MyWeek.range(date)
  
      return <TimeGrid {...this.props} date={moment(date).toDate()} range={range} eventOffset={15} />
    }
  }
  
  MyWeek.range = date => {
    let count = 7
    let range = []

    let start = moment(date).startOf('week')

    for(let i=0; i < count; i++) {
        range.push(moment(start).add(i, 'days').toDate())
    }
  
    return range
  }
  
  MyWeek.navigate = (date, action) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return moment(date).subtract(5, 'days').toDate()
        case Navigate.NEXT:
            return moment(date).add(5, 'days').toDate()
        default:
            return moment(date).toDate()
    }
  }
  
  MyWeek.title = date => {
      return `${moment(date).format('DD MMM')} - ${moment(date).add(5, 'days').format('DD MMM')}`
  }

  export default MyWeek