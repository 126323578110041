import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';

import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing.unit,
    }
});

class ReleasesApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            releases: [],
            loaded: false,
            userId: null
        };
    }

    componentWillMount() {
        this.fetchReleases();
    }

    fetchReleases = () =>
    {
        window.Hauslife.getAppReleases(this.props.appname)
        .then((res) => {
            //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
            console.log(res.data);
            this.setState({ releases: res.data.app.releases });
            //  this.setState({ticket:res.data.data, is_reloading:false});
        })
        .catch((e) => {
            console.log(e);
        });
    }

    makeReleasePrivate = (release) =>
    {
        window.Hauslife.makeReleasePrivate(this.props.appname,release.id)
        .then((res) => {
            console.log(res.data);
            this.fetchReleases();
        })
        .catch((e) => {
            console.log(e);

        });
    }

    makeReleasePublic = (release) =>
    {
        window.Hauslife.makeReleasePublic(this.props.appname,release.id)
        .then((res) => {
            console.log(res.data);
            this.fetchReleases();
        })
        .catch((e) => {
            console.log(e);

        });
    }

    render() {
        const { classes } = this.props;
        let rows = this.state.releases;
        return (

            <Grid style={{ padding: 15, height: "100%" }} alignItems="stretch" justify="space-around" container>
                <Grid xs={12}>

                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}><h2>{this.props.appname}</h2></Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Table className={styles.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Version</TableCell>
                                        <TableCell>public?</TableCell>
                                        <TableCell>CDN URL</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                        <TableRow className={classes.tableRow} key={row.id}>
                                            <TableCell component="th" scope="row">{row.version}</TableCell>
                                            <TableCell >{row.public?"PUBLIC":"PRIVATE"}</TableCell>
                                            <TableCell>{row.cdn_url}</TableCell>
                                            <TableCell>
                                            <Button onClick={()=>{row.public?this.makeReleasePrivate(row):this.makeReleasePublic(row)}} variant="outlined" color="inherit" className={styles.button}>
                                                Make {row.public?"PRIVATE":"PUBLIC"}
                                            </Button>
                                            </TableCell>
                                        </TableRow>

                                    ))}

                                </TableBody>
                            </Table>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>


                </Grid>
            </Grid>

        );
    }
}

export default withRouter(withStyles(styles)(ReleasesApp));
