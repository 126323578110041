import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';

import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';

var _ = require('lodash');

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    tableRow: {
      cursor:'pointer'
    },
    button: {
        margin: theme.spacing.unit,
    }
});

class ViewUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loaded: false,
            userId: null,
            selectedRole: -1,
            search:""
        };
    }

    componentWillMount() {

        window.Hauslife.getUsers()
            .then((res) => {
                //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
                this.setState({ users: res.data.user });
                //  this.setState({ticket:res.data.data, is_reloading:false});
            })
            .catch((e) => {
                console.log(e);

            });

    }

    handleClick = (event, id) => {
        this.props.history.push('/users/edit/' + id);
    }

    handleChangeRole = (event) => {
        this.setState({selectedRole:event.target.value});
    }

    handleChangeSearch = (event) => {
        this.setState({search:event.target.value});
    }

    render() {
        const { classes } = this.props;
        let rows = this.state.users;

        //apply filters
        //
        let selectedRole = this.state.selectedRole;
        if(selectedRole != -1)
        {
            rows = _.filter(rows, function(o) { return o.role==selectedRole; });
        }

        let search = this.state.search;
        if(search != "")
        {
            rows = _.filter(rows, function(o) { return o.firstname.toLowerCase().includes(search) || o.lastname.toLowerCase().includes(search); });
        }

        return (

            <Grid style={{   height: "100%" }} alignItems="stretch" justify="space-around" container>
                <Grid xs={12} style={{marginBottom:20}}>
                    <Paper style={{padding:10}}>
                        <Select
                            value={this.state.selectedRole}
                            onChange={this.handleChangeRole}
                            >
                            <MenuItem value={-1}>None</MenuItem>
                            <MenuItem value={1}>SuperAdmin</MenuItem>
                            <MenuItem value={2}>Admin</MenuItem>
                            <MenuItem value={3}>Broker</MenuItem>
                            <MenuItem value={4}>Tech Capture</MenuItem>
                            <MenuItem value={5}>Tech Post Prod</MenuItem>
                            <MenuItem value={6}>Client</MenuItem>
                            <MenuItem value={7}>Tech Injection</MenuItem>
                            <MenuItem value={8}>Tech PP Lead</MenuItem>
                            <MenuItem value={9}>Releaser</MenuItem>
                            <MenuItem value={10}>Social Medias</MenuItem>
                        </Select>
                        <TextField
                            label="Search"
                            value={this.state.search}
                            onChange={this.handleChangeSearch}
                            margin="normal"
                        />
                    </Paper>
                </Grid>
                <Grid xs={12} style={{marginBottom:20}}>
                    <Button component={Link} to="/users/add" variant="outlined" color="inherit" className={styles.button}>
                        Ajouter
                    </Button>
                </Grid>
                <Grid xs={12}>
                    <Paper className={styles.root}>
                        <Table className={styles.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>User Id</TableCell>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Courriel</TableCell>
                                    <TableCell>Bannière</TableCell>
                                    <TableCell>Suspendue</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => (
                                    <TableRow className={classes.tableRow} key={row.id} onClick={event => this.handleClick(event, row.id)}>
                                        <TableCell component="th" scope="row">{row.id}</TableCell>
                                        <TableCell >{row.firstname + ' ' + row.lastname}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.franchise_id!=null?row.franchise.name:''}</TableCell>
                                        <TableCell>{row.suspended==true?'OUI':'NON'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>

        );
    }
}

export default withRouter(withStyles(styles)(ViewUsers));
