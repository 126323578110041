const axios = require('axios');

/**
 * Class used to interact with the Hauslife API
 */
class HauslifeAPI
{
    constructor() 
    {
        this.logged = false;
        this.token = "";
        this.tokenExpiration = 0;
        this.userObject = {};
        this.appRoot = null;
    }



    getAPIURL() 
    {  
        //return 'http://127.0.0.1:3001/hb/api/';
        if(window.location.href.indexOf("localhost") > -1) return "http://localhost:3001/hb/api/";
        if(window.location.href.indexOf("devadmin") > -1) return "https://api-dev.hauslifenetwork.com/hb/api/";
        if(window.location.href.indexOf("stagingadmin") > -1) return "https://api-staging.hauslifenetwork.com/hb/api/";
        if(window.location.href.indexOf("zohoadmin") > -1) return "https://api-zoho.hauslifenetwork.com/hb/api/";
        //return 'http://127.0.0.1:3001/hb/api/';
        //return 'http://localhost:3001/hb/api/';
        return "https://api.hauslifenetwork.com/hb/api/";
    }

    setAppRoot(root)
    {
        this.appRoot = root
    }

    getAppRoot()
    {
        return this.appRoot;
    }

    /**
     * Helper class to get the user's real name formated correctly
     */
    getUserFullName()
    {
        return this.userObject.firstname + " " + this.userObject.lastname;
    }

    /**
     * Helper class to get the current time in unix timestamp format.
     */
    getCurrentUnixTimestamp()
    {
        return Date.now() / 1000 | 0;
    }

    /**
     * Helper classs to know if the user logged in
     */
    isLoggedIn()
    {
        if(this.getCurrentUnixTimestamp() > this.tokenExpiration)
        {
            //token has gone bad...
            this.logout();
            return false;
        }
        return this.logged;
    }

    /**
     * Validate a token with the API
     * @param {*} token 
     */
    getUserInfoFromToken(token)
    {
        return axios.get(this.getAPIURL()+"getUserInfoFromToken", {headers:{token:token}});
    }

    /**
     * Try to login the token stored in the localStorage
     */
    tryToLoginWithStoredToken()
    {
        //are we logged in with jwt token stored in local storage?
        var storedToken = localStorage.getItem('jwt_token');
        if(storedToken != null && storedToken !== "" && storedToken !== "undefined")
        {
            //we have a token, lets validate it.
            return this.getUserInfoFromToken(storedToken).then((result)=>{
                var isValid = result.data.status && result.data.status === 200;
                if(isValid === false)
                {
                    //rare instance where the token will not be valid
                    localStorage.setItem('jwt_token',"");
                }
                else
                {
                    this.token = storedToken;
                    this.tokenExpiration = this.getCurrentUnixTimestamp() + (60*60*24*15); //expires 15 days
                    this.userObject = result.data.user;
                    this.logged = true;
                }
            })
            .catch((e)=>{
                console.log("error while validating token", e);
            });
        }

        return Promise.resolve();
    }

    /**
     * Login using provided informations
     * (Will save a token in localStorage when successful)
     * @param {*} email 
     * @param {*} password 
     */
    login(email,password)
    {
        return axios.post(this.getAPIURL()+"login",{email:email,password:password})
        .then((result)=>{
            if(result.data.token)
            {
                //we have logged in
                this.token = result.data.token;
                this.tokenExpiration = this.getCurrentUnixTimestamp() + (60*60*24*15); //expires 15 days
                this.userObject = result.data.user;
                this.logged = true;
                localStorage.setItem('jwt_token',this.token);
            }
            else
            {
                throw new Error("Server Error");
            }
        });
    }

    /**
     * Deletes the local token and tell this instance we are logged out
     */
    logout()
    {
        this.logged = false;
        this.token = "";
        this.tokenExpiration = 0;
        localStorage.removeItem('jwt_token');
    }

    //Deliveries

    leaveFeedbackOnPicture(picture, feedback)
    {
        return axios.post(this.getAPIURL()+"leaveFeedbackOnDelivery",{picture_id:picture.id, feedback:feedback},{headers:{token:this.token}});
    }

    getSocialMediasListings()
    {
        return axios.get(this.getAPIURL()+"getSocialMediasValidatedListings", {headers:{token:this.token}});
    }

    getShootingsWithDeliveriesForTech() {
        return axios.get(this.getAPIURL()+"getShootingsWithDeliveriesForTech", {headers:{token:this.token}});
    }
    
    getDeliveries(listing_id)
    {
        return axios.get(this.getAPIURL()+"getListingWithDeliveries?listing_id="+listing_id, {headers:{token:this.token}});
    }

    getDeliveriesFromToken(delivery_token)
    {
        return axios.get(this.getAPIURL()+"getListingWithDeliveriesFromToken?distribution_token="+delivery_token, {headers:{token:this.token}});
    }

    getDeliveriesFromTokenZip(delivery_token)
    {
        return this.getAPIURL()+"getDeliveriesFromTokenZip?distribution_token="+delivery_token;
    }

    purgeListingCache(listing_id){
        return axios.post(this.getAPIURL()+"purgeListingCache",{listing_id:listing_id},{headers:{token:this.token}});
    }

    generateListingDeliveryToken(listing_id)
    {
        return axios.get(this.getAPIURL()+"generateListingDeliveryToken?listing_id="+listing_id, {headers:{token:this.token}});
    }

    updatePicturesVisibility(pictureIds, _public)
    {
        return axios.post(this.getAPIURL()+"updatePictureVisibility",{picture_ids: pictureIds, public:_public},{headers:{token:this.token}});
    }

    updatePicturesVideoSelection(pictureIds, is_selected)
    {
        return axios.post(this.getAPIURL()+"updatePictureVideoSelection",{picture_ids: pictureIds, is_selected: is_selected},{headers:{token:this.token}});
    }

    getListingVideos(listing_id) {
        return axios.get(this.getAPIURL()+"getListingVideos/"+listing_id, {headers:{token:this.token}});
    }

    showWatermark = (pictureIds, show) => {
        return axios.post(this.getAPIURL()+'showWatermark',{picture_ids: pictureIds, show: show,},{headers:{token:this.token}});
    }

    updatePicturesType (pictureIds, picture_service_offer_id) {
        return axios.post(this.getAPIURL()+'updatePicturesType',{picture_ids: pictureIds, picture_service_offer_id: picture_service_offer_id},{headers:{token:this.token}});
    }

    //Shootings

    /**
     * Creates a shooting on the api
     * @param {*} address 
     * @param {*} app 
     * @param {*} city 
     * @param {*} postalcode 
     * @param {*} is_house 
     * @param {*} room_count 
     * @param {*} scheduleddate 
     * @param {*} is_am 
     * @param {*} is_existing_listing 
     * @param {*} note 
     */
    createShooting(address,app,city,postalcode,is_house,room_count,scheduleddate,is_am,is_existing_listing, note, lat, lng)
    {

        var createShootingObject = 
        {
            "address": address,
            "app":app,
            "city":city,
            "postalcode":postalcode,
            "is_house": is_house,
            "room_count": room_count,
            "scheduleddate":scheduleddate,
            "is_am":is_am,
            "is_existing_listing":is_existing_listing,
            "note":note,
            "lat":lat,
            "lng":lng
        };

        return axios.post(this.getAPIURL()+"createShooting",createShootingObject,{headers:{token:this.token}});
    }

    /**
     * Get the user's shootings from the API.
     */
    getShooting(shooting_id)
    {
        return axios.get(this.getAPIURL()+"getShooting?shooting_id="+shooting_id, {headers:{token:this.token}});
    }

    /**
     * Get the user's shootings from the API.
     */
    getAllShootings()
    {
        return axios.get(this.getAPIURL()+"getAllShootings", {headers:{token:this.token}});
    }

    getAllShootingsOfTech(tech_id)
    {
        return axios.get(this.getAPIURL()+"getAllShootingsOfTech?tech_id="+tech_id, {headers:{token:this.token}});
    }

    getAllShootingsOfBroker(broker_id)
    {
        return axios.get(this.getAPIURL()+"getAllShootingsOfBroker?broker_id="+broker_id, {headers:{token:this.token}});
    }
    
    /**
     * Cancels a user's shooting
     * @param {*} shooting_id 
     */
    cancelShooting(shooting_id, cancellation_reason = "")
    {
        return axios.put(this.getAPIURL()+"cancelShooting", {shooting_id:shooting_id, cancellation_reason:cancellation_reason},{headers:{token:this.token}});
    }

    generateInvoice(shooting_id){
        return axios.post(this.getAPIURL()+"generateShootingInvoice", {shooting_id:shooting_id},{headers:{token:this.token}});
    }

    generateNotarizedInvoice(listing_id){
        return axios.post(this.getAPIURL()+"generateListingNotarizedInvoice", {listing_id:listing_id},{headers:{token:this.token}});
    }

    attemptShootingFlagRemoval(shooting_id){
        return axios.post(this.getAPIURL()+"attemptShootingFlagRemoval", {shooting_id:shooting_id},{headers:{token:this.token}});
    }

    /**
     * Updates a user's shooting date
     * @param {*} shooting_id
     * @param {*} scheduleddate 
     * @param {*} is_am 
     */
    updateShootingTime(shooting_id, scheduleddate, is_am)
    {
        return axios.post(this.getAPIURL()+"updateShootingTime", {shooting_id:shooting_id,scheduleddate:scheduleddate,is_am:is_am},{headers:{token:this.token}});
    }

    /**
     * Updates a user's shooting note
     * @param {*} shooting_id
     * @param {*} note 
     */
    updateShootingNote(shooting_id,note)
    {
        return axios.post(this.getAPIURL()+"updateShootingNote", {shooting_id:shooting_id,note:note},{headers:{token:this.token}});
    }

    //Routes

    /**
     * Gets the available slots in the following days
     * @param {*} address 
     * @param {*} is_house 
     * @param {*} nb_rooms 
     * @param {*} is_am 
     * @param {*} is_existing_listing 
     */
    validateShootingRoute(address, is_house, nb_rooms, is_am, is_existing_listing)
    {
        return axios.post(this.getAPIURL()+"validateShootingRoute",{address:address,is_house:is_house,nb_rooms:nb_rooms,time:is_am?"am":"pm",is_existing_listing:is_existing_listing},{headers:{token:this.token}});
    }

    /**
     * Gets the available slots in the following <days> from <fromDate>
     * @param {*} address 
     * @param {*} is_house 
     * @param {*} nb_rooms 
     * @param {*} is_existing_listing 
     * @param {*} fromDate moment js parsable date
     * @param {int} days
     */
    validateShootingAvailability(address, is_house, nb_rooms, is_existing_listing, fromDate, days, lat, lng)
    {
        var payload =
        {
            "address": address,
            "is_existing_listing": is_existing_listing,
            "is_house": is_house,
            "nb_rooms": nb_rooms,
            "timestamp": fromDate,
            "future_days":days,
            "lat":lat,
            "lng":lng
        };

        return axios.post(this.getAPIURL()+"validateShootingAvailability",payload,{headers:{token:this.token}});
    }

    /**
     * Validates that we have service in this city
     * @param {*} city 
     */
    validateShootingCity(city)
    {
        return axios.post(this.getAPIURL()+"validateShootingCity",{city:city},{headers:{token:this.token}});
    }

    //Tickets

    /**
     * Creates a ticket on the API
     * @param {*} type 
     * @param {*} urgency
     * @param {*} subject
     * @param {*} description
     */
    createTicket(type, urgency, subject, description)
    {
        return axios.post(this.getAPIURL()+"ticket",{type:type,urgency:urgency,subject:subject,description:description},{headers:{token:this.token}});
    }

    /**
     * Gets all tickets from the API (will return only mines)
     */
    getAllTickets()
    {
        return axios.get(this.getAPIURL()+"allTickets", {headers:{token:this.token}});
    }

    /**
     * Gets information on the ticket specified with the param id
     * @param {*} id
     */
    getTicket(id)
    {
        return axios.get(this.getAPIURL()+"ticket?ticket_id="+id, {headers:{token:this.token}});
    }

    followupTicket(ticket_id,message)
    {
        return axios.post(this.getAPIURL()+"followupTicket",{ticket_id:ticket_id,message:message},{headers:{token:this.token}});
    }

    reopenTicket(ticket_id)
    {
        return axios.post(this.getAPIURL()+"reopenTicket",{ticket_id:ticket_id},{headers:{token:this.token}});
    }

    resolveTicket(ticket_id)
    {
        return axios.post(this.getAPIURL()+"resolveTicket",{ticket_id:ticket_id},{headers:{token:this.token}});
    }

    getUsers(filter)
    {
        if(filter !== undefined){
            return axios.get(this.getAPIURL()+"getUsers", { params : filter,  headers:{token:this.token}});
        } else {
            return axios.get(this.getAPIURL()+"getUsers", {headers:{token:this.token}});
        }
    }
    
    getListingsWithNoMLS()
    {
        return axios.get(this.getAPIURL()+"getListingsWithNoMLS", {headers:{token:this.token}});
    }

    setMLS(listing_id, mls)
    {
        return axios.post(this.getAPIURL()+"setMLS",{listing_id:listing_id,mls:mls},{headers:{token:this.token}});
    }

    getUserData(user_id)
    {
        return axios.get(this.getAPIURL()+"UserData",{ params : {user_id:user_id},
                                                 headers:{token:this.token}});
    }

    putUserData(userData){
        return axios.post(this.getAPIURL()+"UserData",userData,{headers:{token:this.token}});
    }

    createUser(userData)
    {
        return axios.post(this.getAPIURL()+"register",userData,{headers:{token:this.token}});
    }
    changePassword(passwordData){

        return axios.post(this.getAPIURL()+"changePassword",passwordData,{headers:{token:this.token}});
    }

    getBrokerListings()
    {
        return axios.get(this.getAPIURL()+"getBrokerListings", {params:{}, headers:{token:this.token}});
    }

    allDailyShootings(timestamp)
    {
        return axios.get(this.getAPIURL()+"allDailyShootings",{ params : {timestamp:timestamp}, headers:{token:this.token}});
    }

    dailyShootingActivity(timestamp)
    {
        return axios.get(this.getAPIURL()+"dailyShootingActivity",{ params : {timestamp:timestamp}, headers:{token:this.token}});
    }

    getAppReleases(app)
    {
        return axios.get(this.getAPIURL()+"releases/"+app,{params : {forceJson:true}, headers:{token:this.token}});
    }

    makeReleasePublic(app, release_id)
    {
        return axios.post(this.getAPIURL()+"release/"+app+"/makepublic",{release_id:release_id},{headers:{token:this.token}});
    }

    makeReleasePrivate(app, release_id)
    {
        return axios.post(this.getAPIURL()+"release/"+app+"/makeprivate",{release_id:release_id},{headers:{token:this.token}});
    }

    assignProdShooting(force_assigned, shooting_id, tech_id, timestamp){
        if(tech_id === -1){
            return axios.post(this.getAPIURL()+"unassignShootingTech",{ shooting_id  }, {headers:{token:this.token}});
        }else {
            return axios.put(this.getAPIURL()+"assignProdShooting",{shooting_id, tech_id, timestamp, force_assigned  }, {headers:{token:this.token}});
        }
    }

    getDepots(){
        return axios.get(this.getAPIURL()+"depots",{ headers:{token:this.token}});
    }

    getZones(){
        return axios.get(this.getAPIURL()+"getZones",{ headers:{token:this.token}});
    }

    addDepot(lng, lat, civic_number, street, city)
    {
        return axios.post(this.getAPIURL()+"depot",{ lng, lat, civic_number, street, city }, {headers:{token:this.token}});
    }

    modifyDepot(depot_id, lng, lat, civic_number, street, city)
    {
        return axios.put(this.getAPIURL()+"depot",{ depot_id, lng, lat, civic_number, street, city }, {headers:{token:this.token}});
    }

    removeDepot(depot_id)
    {
        return axios.post(this.getAPIURL()+"removeDepot",{ depot_id }, {headers:{token:this.token}});
    }

    updateLockStatus(tech_id, locked, date)
    {
        return axios.post(this.getAPIURL()+"updateLockStatus",{ tech_id, locked, date }, {headers:{token:this.token}});
    }

    getAllTechLocksForTheDay(date)
    {
        return axios.get(this.getAPIURL()+"tech_locks?date="+date, {headers:{token:this.token}});
    }
    
    unassignShootingTech(shooting_id, ){
        return axios.post(this.getAPIURL()+"unassignShootingTech",{ shooting_id  }, {headers:{token:this.token}});
    }

    dispatchShooting(shooting_id){
        return axios.post(this.getAPIURL()+"dispatchShooting",{ shooting_id  }, {headers:{token:this.token}});
    }

    shootingFromTech(user_id,startDate,endDate,assigned){
        if(startDate === undefined){
            return axios.post(this.getAPIURL()+"shootingFromTech",{user_id}, {headers:{token:this.token}});
        }
        return axios.post(this.getAPIURL()+"shootingFromTech",{user_id,startDate,endDate,assigned}, {headers:{token:this.token}});
    }

    techDailyShootings(timestamp)
    {
        return axios.get(this.getAPIURL()+"techDailyShootings?timestamp="+timestamp,{ headers:{token:this.token}});
    }

    floorplan(listing_id)
    {
        return axios.get(this.getAPIURL()+"floorplan/",{params : {listing_id:listing_id}, headers:{token:this.token}});
    }

    assignShootingsToTechs(date){
        return axios.post(this.getAPIURL()+"assignShootingsToTechs",{ date }, {headers:{token:this.token}});
    }

    assignSDCard(shootingId,sdCard){
        return axios.post(this.getAPIURL()+"assignSDCard",{ shooting_id: shootingId,sd_card:sdCard }, {headers:{token:this.token}});
    }

    updateShootingCompletion (shooting_id,all_services_delivered){
        return axios.post(this.getAPIURL()+"updateShootingCompletion",{ shooting_id:shooting_id,all_services_delivered:all_services_delivered }, {headers:{token:this.token}});
    }

    recalculateShootingDistances(date){
        return axios.post(this.getAPIURL()+"recalculateShootingDistances",{ date }, {headers:{token:this.token}});
    }
 
    updateShootingBillable(shooting){
        return axios.put(this.getAPIURL()+"billShooting",  shooting  , {headers:{token:this.token}});
    }
 
    getHMDsByUserId(user_id){
        return axios.get(this.getAPIURL()+"getHMDsByUserId?user_id="+user_id, {headers:{token:this.token}});
    }

    unassignHmd(serial_number)
    {
        return axios.post(this.getAPIURL()+"unassignHmd",{ serial_number }, {headers:{token:this.token}});
 
    }

    assignHmd(user_id, serial_number)
    {
        return axios.post(this.getAPIURL()+"assignHmd",{ user_id, serial_number }, {headers:{token:this.token}});
 
    }

    registerHMD(serial_number)
    {
        return axios.post(this.getAPIURL()+"registerHMD",{ serial_number }, {headers:{token:this.token}});
    }

    getListingsList(search, loadAll = false ){
        if(search==undefined) search =''; 
 
        return axios.get(this.getAPIURL()+`admin/listings?search=${search}&loadAll=${loadAll}`, {headers:{token:this.token}});
    }

    getListingsListForMap() {
        return axios.get(this.getAPIURL()+`admin/listingsForMap`, {headers:{token:this.token}});   
    }

    adminUpdateListing(data ){
        console.log("Going to post data:")
        console.log(data)
        console.log("To: " + this.getAPIURL()+'admin/listing')
        return axios.post(this.getAPIURL()+'admin/listing',data , {headers:{token:this.token}});
    }

    adminHmdList(search,sort,dir ){
        if(search==undefined) search =''; 
        return axios.get(this.getAPIURL()+`admin/hmdList?search=${search}&sort=${sort}&dir=${dir}`, {headers:{token:this.token}});
    }

    adminUsersSearch(search){
        if(search==undefined) search =''; 
        return axios.get(this.getAPIURL()+`users/search?search=${search}`, {headers:{token:this.token}});
    }
    adminEditHmd(data ){
        return axios.post(this.getAPIURL()+'admin/hmd',data , {headers:{token:this.token}});
    }
    adminDeleteHmd (id){
        return axios.delete(this.getAPIURL()+`admin/hmd/${id}` , {headers:{token:this.token}});
    }
    adminListingShootings(listing_id){
        return axios.get(this.getAPIURL()+`admin/listing/shooting?listing_id=${listing_id}`, {headers:{token:this.token}});
    }
    adminBadListing( ){
        return axios.get(this.getAPIURL()+`admin/getBadCentrisListing`, {headers:{token:this.token}});
    }
    adminListing (id){
        return axios.get(this.getAPIURL()+`admin/listing?id=${id}`, {headers:{token:this.token}});
    }


    adminCentrisListingForBroker (code){
        return axios.get(this.getAPIURL()+`admin/centrislistingsforbroker?code=${code}`, {headers:{token:this.token}});
    }
    adminCentrisListingForMls (code){
        return axios.get(this.getAPIURL()+`admin/centrislistingformls?mls=${code}`, {headers:{token:this.token}});
    }

   getBrokerTeam ( ){
        return axios.get(this.getAPIURL()+`broker/teams`, {headers:{token:this.token}});
    }
    getSuperLogs(search,sort,offset ){
        if(search==undefined) search =''; 

        var endPoint = this.getAPIURL()+`admin/logs?search=${search}&order=${sort.order}&sort=${sort.sort}`;

        if(offset!=undefined){
            endPoint=`${endPoint}&offset=${offset}`;
        }
        console.log('End point',endPoint);
        return axios.get(endPoint, {headers:{token:this.token}});
    }

    getSuperAnalytics(search,sort ) {
        
        var endPoint = this.getAPIURL()+`admin/analytics?search=${search}&order=${sort.order}&sort=${sort.sort}`;

         
        console.log('End point',endPoint);
        return axios.get(endPoint, {headers:{token:this.token}});
    }
    
    analyticsRequestByDay(search  ) {
        if(search==undefined) search =''; 
        return axios.get(this.getAPIURL()+`admin/analyticsRequestByDay?search=${search}`, {headers:{token:this.token}});

    }

    analyticsUserRequest (search  ) {
        if(search==undefined) search =''; 
        return axios.get(this.getAPIURL()+`admin/analyticsUserRequest?search=${search}`, {headers:{token:this.token}});

    }
    
    centrisBrokerReport (search ) {
        var params='';
        if(search!=undefined){

            var params = Object.keys(search).reduce( (acc,value,idx)=>{
                                                                    if(search[value]==null){
                                                                        return acc;
                                                                    }
                                                                    return acc +=`${value}=${search[value]}&`;
                                                                    },'');
        }
        var endPoint = this.getAPIURL()+`admin/centrisBrokerReport?${params}`;
        return axios.get(endPoint, {headers:{token:this.token}});
    }

    getCentrisbroker   (code  ) {
        return axios.get(this.getAPIURL()+`admin/centrisbroker/${code}`, {headers:{token:this.token}});

    }

    getCentrisBanner  (code  ) {
        return axios.get(this.getAPIURL()+`admin/centris/banner`, {headers:{token:this.token}});

    }

    getCentrisFranchises  (code  ) {
        return axios.get(this.getAPIURL()+`admin/centris/franchises`, {headers:{token:this.token}});
    }
    
    //PP

    abandonShooting(shooting_id)
    {
        return axios.post(this.getAPIURL()+"abandonShooting",{shooting_id:shooting_id},{headers:{token:this.token}});
    }

    //Remote Injector

    rij_getStatesByDate(date) {
        return axios.get(this.getAPIURL()+'rij/getStatesByDate?date='+date, {headers:{token:this.token}});
    }

    getUploadURL(filename, filetype)
    {
        return axios.post(this.getAPIURL()+"rij/getUploadURL", {filename:filename,filetype:filetype}, {headers:{token:this.token}});
    }

    getFilesForRemoteInjection(date)
    {
        return axios.get(this.getAPIURL()+"rij/getFilesOnServer?date="+date+"&showOnlyUserFiles=true", {headers:{token:this.token}});
    }

    getFileDownloadLink(filename)
    {
        return axios.get(this.getAPIURL()+"rij/getDownloadLink?filename="+filename, {headers:{token:this.token}});
    }

    rij_uploadedFile(filename)
    {
        return axios.post(this.getAPIURL()+"rij/uploadedFile", {filename:filename}, {headers:{token:this.token}});
    }

    rij_uploadedFileStatus(filename, userId)
    {
        return axios.post(this.getAPIURL()+"rij/uploadedFileStatus", {filename:filename}, {headers:{token:this.token}});
    }

    saveZonesToDB(zones)
    {
        return axios.post(this.getAPIURL()+"saveZones", {zones:zones}, {headers:{token:this.token}});   
    }

    getZones() {
        return axios.get(
            this.getAPIURL()+"getZones",
            {headers:{token:this.token}}
        ).then(result => {
            const { data: {status, zones} } = result;
            if(status == 200 && zones && zones.length) {
                return zones.map(zone => {
                    let { id, zone_name, coordinates } = zone;
                    coordinates = JSON.parse(coordinates)['coordinates'][0]
                    return {
                        id,
                        zone_name,
                        coordinates: coordinates.map(coord => ({lat: coord[0], lng: coord[1]}))
                    }
                });
            }
            return []
        }).catch(error => {
            console.log("error fetching zones", error)
            return []
        })
    }

    getShootingsAndDepotsForZones(zone_id, start_date, end_date) {
        return axios.get(
            this.getAPIURL()+`getShootingsAndDepotsForZones`, {
                headers: {token: this.token}, 
                params: {
                    zone_id: zone_id, 
                    start_date: start_date,
                    end_date: end_date
                }
            })
            .then(res => {
                return res.data.result
            }).catch(error => {
                console.log("error fetching shootings", error)
                return []
            })
    }

    // All Tech Schedule
    getAllUsersCalendarForMonth(startDate, endDate) {
        return axios.get(
            this.getAPIURL()+`getAllScheduleForTimeperiod`, {
                headers: {token: this.token}, 
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
            .then(res => {
                console.log("Result", res.data)
                return res.data
            }).catch(error => {
                console.log("error fetching shootings", error)
                return []
            })
    }

    // Tech Schedule
    getUserScheduleForTimeperiod(userId, startDate, endDate) {
        return axios.get(
            this.getAPIURL()+`getUserScheduleForTimeperiod`, {
                headers: {token: this.token}, 
                params: {
                    userId: userId,
                    startDate: startDate,
                    endDate: endDate
                }
            })
            .then(res => {
                console.log("Result", res.data)
                return res.data
            }).catch(error => {
                console.log("error fetching shootings", error)
                return []
            })
    }

    getPhotographers() {
        return axios.get(
            this.getAPIURL()+`getPhotographers`, {
                headers: {token: this.token}, 
            })
            .then(res => {
                return res.data
            }).catch(error => {
                console.log("error fetching shootings", error)
                return []
            })
    }

    addUserAvailability(techId, dates) {
        return axios.post(this.getAPIURL()+"addUserAvailability", {userId: techId, dates: dates}, {headers:{token:this.token}});   
    }

    addAvailabilityForTechs(techIds, dates) {
        return axios.post(this.getAPIURL()+"addAvailabilityForTechs", {userIds: techIds, dates: dates}, {headers:{token:this.token}});   
    }

    removeAvailability(techId, date) {
        return axios.post(this.getAPIURL()+"removeAvailability", {user_id: techId, time: date}, {headers:{token:this.token}});   
    }

    createUserUnavailability(techId, start, end, unavailability_id, summary) {
        return axios.post(this.getAPIURL()+"createUserUnavailability", {user_id: techId, start_time: start, end_time: end, unavailability_id: unavailability_id, summary: summary}, {headers:{token:this.token}});   
    }

    removeUnavailability(unavailability_id) {
        return axios.post(this.getAPIURL()+"removeUnavailability", {unavailability_id: unavailability_id}, {headers:{token:this.token}});
    }

    sendShootingReminderToPhotographers() {
        return axios.post(this.getAPIURL()+"sendShootingReminderToPhotographers", {}, {headers:{token:this.token}});
    }

    getListingsForReview() {
        return axios.get(this.getAPIURL()+"getListingsForReview", {headers:{token:this.token}});
    }

    getListingPicturesWithComments(listing_id) {
        return axios.get(
            this.getAPIURL()+"getListingPicturesWithComments", 
            {
                headers:{token:this.token}, 
                params: {listing_id: listing_id}
            });
    }

    saveOrderIndex(pictures) {
        return axios.post(this.getAPIURL()+"saveOrderIndex", {pictures: pictures}, {headers:{token:this.token}});
    }

    sendPictureForReview(picture_id) {
        return axios.post(this.getAPIURL()+"sendPictureForReview", {picture_id: picture_id}, {headers:{token:this.token}});
    }

    markPictureValid(picture_id) {
        return axios.post(this.getAPIURL()+"markPictureValid", {picture_id: picture_id}, {headers:{token:this.token}});
    }

    createTicket(picture_id, comment) {
        return axios.post(this.getAPIURL()+"ticket", {picture_id: picture_id, description: comment}, {headers:{token:this.token}});
    }

    createFollowupTicket(ticket_id, message) {
        return axios.post(this.getAPIURL()+"followupTicket", {ticket_id: ticket_id, message: message}, {headers:{token:this.token}});
    }

    validateShooting(shooting_id) {
        return axios.post(this.getAPIURL()+"validateShooting", {shooting_id: shooting_id}, {headers:{token:this.token}});
    }

    sendMediaEmail(listing_id) {
        return axios.post(this.getAPIURL()+"sendMediaEmail", {listing_id: listing_id}, {headers:{token:this.token}});
    }

    sendShootingForRetouch(shooting_id) {
        return axios.post(this.getAPIURL()+"retouchShooting", {shooting_id: shooting_id}, {headers:{token:this.token}});
    }

    updateServiceOfferData(id, url){
        return axios.post(this.getAPIURL()+"updateServiceOffer",{id: id, url: url}, {headers:{token:this.token}});
    }

    retreiveTokenFor3D() {
        return axios.post(this.getAPIURL()+"retreiveTokenFor3D", {}, {headers:{token:this.token}});
    }

    // sets the listing state to vr_ready
    publishListing(listing_id) {
        return axios.post(this.getAPIURL()+"publishListing", {listing_id: listing_id}, {headers:{token:this.token}});
    }

    getListingFloorplan(listing_id) {
        return axios.get(
            this.getAPIURL()+"floorplan", 
            {
                headers:{token:this.token}, 
                params: {listing_id: listing_id}
            });
    }

    getAllServiceOffers = () => {
        return axios.get(
            this.getAPIURL()+"getAllServiceOffers", 
            {
                headers:{token:this.token}
            });
    }

    getAllShootingPackages = () => {
        return axios.get(
            this.getAPIURL()+"shootingPackage", 
            {
                headers:{token:this.token}
            });
    }

    createServiceOffer = (serviceOffer) => {
        return axios.post(this.getAPIURL()+"serviceOffer", {service_offer: serviceOffer}, {headers:{token:this.token}});
    }

    updateServiceOffer = (serviceOffer) => {
        return axios.put(this.getAPIURL()+"serviceOffer", {service_offer: serviceOffer}, {headers:{token:this.token}});
    }

    deleteServiceOffer = (serviceOffer) => {
        return axios.delete(this.getAPIURL()+"serviceOffer"+"/"+serviceOffer, {headers:{token:this.token}});
    }

    getAllShootingPackages = () => {
        return axios.get(this.getAPIURL()+"shootingPackage", {headers:{token:this.token}});
    }

    createShootingPackage = (shootingPackage) => {
        return axios.post(this.getAPIURL()+"shootingPackage", {shooting_package: shootingPackage}, {headers:{token:this.token}});
    }

    updateShootingPackage = (shootingPackage) => {
        const shootingPackageId = shootingPackage.id
        return axios.put(this.getAPIURL()+"shootingPackage/"+shootingPackageId, {shooting_package: shootingPackage}, {headers:{token:this.token}});
    }

    deleteShootingPackage = (shootingPackageId) => {
        return axios.delete(this.getAPIURL()+"shootingPackage/"+shootingPackageId, {headers:{token:this.token}});
    }

    getVideoTemplates = () => {
        return axios.get(this.getAPIURL()+"videoTemplate", {headers:{token:this.token}});
    }

    createVideo = (listing_id, template_id) => {
        return axios.post(this.getAPIURL()+"video", {listing_id, template_id}, {headers:{token:this.token}});
    }

    addVideoToQueue = (listing_video_id) => {
        return axios.post(this.getAPIURL()+"renderVideo", {listing_video_id}, {headers:{token:this.token}});
    }

    getAllJobsAndVMs = () => {
        return axios.get(this.getAPIURL()+"getAllJobsForDashboard", {headers:{token:this.token}});
    }
    getUnlikedFacebookPages = () => {
        return axios.get(this.getAPIURL()+"getUnlinkedFacebookPage", {
            headers:{token:this.token
        }});
    }
    linkFacebookPageToBroker = (broker_id, page_id) => {
        return axios.post(this.getAPIURL()+"linkFacebookPageToBroker", { broker_id, page_id }, {headers:{token:this.token}});
    }
    getLogsForJob = (job_id) => {
        return axios.get(this.getAPIURL()+"getLogsForJob?job_id="+job_id, {
            headers:{token:this.token
        }});
    }

    resetJob = (listing_video_config) => {
        return axios.post(this.getAPIURL()+"requestVideo", listing_video_config, {
            headers:{token:this.token}
        });
    }


    getListingVirtualVisit = (listing_id) => {
        return axios.get(this.getAPIURL()+"stats/ListingVirtualVisit/"+listing_id, {
            headers:{token:this.token
        }});
    }

    getListingVirtualVisitReportTop = (listing_id) => {
        return axios.get(this.getAPIURL()+"stats/ListingVirtualVisit/report/top", {
            headers:{token:this.token
        }});
    }

    getListingVirtualVisitReportVisitors = (from,to,listing_id) => {
         if(listing_id==undefined){
            listing_id='visitors'
        }
        return axios.get(this.getAPIURL()+`stats/ListingVirtualVisit/report/${listing_id}/${from}/${to}`, {
            headers:{token:this.token
        }});
    }

    getListingAutoMls = (listing_id) => {
        return axios.get(this.getAPIURL()+"automls", {
            headers:{token:this.token
        }});
    }

    updateShootingNote = (shooting) => {
        return axios.post(this.getAPIURL()+'setShootingNotes', {shooting: shooting}, {headers:{token:this.token}});
    }

    updateShootingSaisieSource (shootingsIds, isCompleted) {
        return axios.post(this.getAPIURL()+'updateShootingSaisieSource',{shootingsIds: shootingsIds, isCompleted: isCompleted},{headers:{token:this.token}});
    }

    fetchExtraServices = (broker_subscription_id, gf_clause) => {
        return axios.get(this.getAPIURL()+`fetchExtraServices?broker_subscription_id=${broker_subscription_id}&gf_clause=${gf_clause}`, {headers:{token:this.token}}); 
    }

    addServiceToShooting = (service_id, shooting_id) => {
        return axios.post(this.getAPIURL()+'addServiceToShooting', {shooting_id: shooting_id, service_id: service_id}, {headers:{token:this.token}}); 
    }
}

export default HauslifeAPI;