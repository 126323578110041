import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { injectIntl, intl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import PleaseWait from './PleaseWait.js'
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';


import * as moment from 'moment';
import 'moment/locale/fr-ca';

const styles = theme => ({
    root: {
    },
    cell: {
      padding:5
    },
    innerGrid:{
        margin: theme.spacing.unit
    }
});

/**
 * Class used to display tickets owned by the user
 */
class MyTickets extends React.Component {

    constructor(props) {
      super(props);
      this.state = {loading:true,rows:[]};
    }

    componentWillMount()
    {
        window.Hauslife.getAllTickets()
        .then((result)=>{
            //console.log(result.data.data);
            this.setState({loading:false,rows:result.data.data});
        })
        .catch((e)=>{
            this.setState({loading:false});
        });
    }

    handleCloseticket = (id) => {
        console.log(id);
    };

    handleTicketClick = (event, id) => {
        this.props.history.push("/tickets/"+id);
    };

    render()
    {
        const { classes, intl } = this.props;

        return(
            <div>
            {(this.state.loading == true) ? (<PleaseWait />):(
            <Grid>
                <div style={{display: "flex",marginTop:15,marginBottom:15,justifyContent:"flex-end"}}>
                    <LinkButton variant="outlined" color="primary" to="/tickets/add">mytickets.btn.make_ticket</LinkButton>
                </div>
                <Grid xs={12}>

                        {this.state.rows.length > 0 ? (
                        <div>
                            <h3><FormattedMessage id="mytickets.ticket.open" /></h3>
                            <Table style={{ tableLayout: 'auto' }} className={classes.table}>
                                <TableBody>
                                {this.state.rows.map(row => {
                                    return !row.resolved ? (
                                        <TableRow style={{cursor:"pointer",backgroundColor:(row.urgency==1?"#fbfbfb":(row.urgency==2?yellow[50]:red[50]))}} hover onClick={event => this.handleTicketClick(event, row.id)} key={row.id}>
                                            <TableCell className={classes.cell} component="th" scope="row">
                                            {row.subject}
                                            </TableCell>
                                            <TableCell className={classes.cell}>{moment(row.created_at).format("l LTS")}</TableCell>
                                        </TableRow>
                                    ) : (null);
                                })}
                                </TableBody>
                            </Table>
                            <h3 style={{color:"#888"}}><FormattedMessage id="mytickets.ticket.closed" /></h3>
                            <Table style={{ tableLayout: 'auto' }} className={classes.table}>
                                <TableBody>
                                {this.state.rows.map(row => {
                                    return !row.resolved ? (null) : (
                                        <TableRow style={{cursor:"pointer",backgroundColor:"#fbfbfb"}} hover onClick={event => this.handleTicketClick(event, row.id)} key={row.id}>
                                            <TableCell style={{color:"#aaa"}} className={classes.cell} component="th" scope="row">
                                            {row.subject}
                                            </TableCell>
                                            <TableCell style={{color:"#aaa"}} className={classes.cell}>{moment(row.created_at).format("l LTS")}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                </TableBody>
                            </Table>
                        </div>
                        ) :
                        (
                            
                            <Typography style={{padding:15}} component="h3" variant="h3" align={"center"}><FormattedMessage id="mytickets.no_tickets" /></Typography>
                        )
                        }
                    
                </Grid>
            </Grid>
            )
            }
            </div>
        );
    }
}

MyTickets.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default injectIntl(withRouter(withStyles(styles)(MyTickets)));
  