import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles = theme => ({
    root: {
    },
    innerGrid:{
    },
    snackbar:{
        padding: theme.spacing.unit,
        backgroundColor: theme.palette.error.dark,
        marginBottom:theme.spacing.unit
      },
});

/**
 * Class used to view a single ticket
 */
class CancelShooting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {cancelled:false,loading:false};
    }

    handleCancelShooting = () => {
        const { match } = this.props;
        console.log(match.params.id);
        if(match.params.id != "undefined")
        {
            this.setState({loading:true});
            window.Hauslife.cancelShooting(match.params.id)
            .then((res)=>{
                if(res.data.status === 200)
                {
                    this.setState({loading:false, cancelled:true});
                }
                else
                {
                    this.setState({error:false,loading:false, cancelled:false,error:res.data.message});
                }
            })
            .catch((e)=>{
                this.setState({error:true,loading:false});
            });
        }
    };

    render()
    {
        const { classes, match, intl } = this.props;

        if(this.state.cancelled == false && this.state.loading == false)
        {
            return (
                <div>
                    {this.state.error ? ( 
                    <div>
                        <SnackbarContent className={classes.snackbar} message={intl.formatMessage({id:"cancelshooting.error"})}/>
                        <LinkButton fullWidth variant="outlined" color="secondary" to={'/shooting'}>
                            bookshooting.return
                        </LinkButton>
                    </div>
                    ) : (
                    <Grid container justify={"center"} className={classes.root}>
                        <Grid item xs={12}>
                            <Typography align={"center"}><Icon style={{color:yellow[600],fontSize:80}}>warning</Icon></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" style={{margin:15}} align={"center"}><FormattedMessage id="cancelshooting.confirmation_message" /></Typography>
                            <Grid container justify="space-around">
                            <Grid xs={5}>
                                <Button fullWidth onClick={this.handleCancelShooting} variant="outlined" color="primary">
                                    <FormattedMessage id="generic.yes" />
                                </Button>
                                </Grid>
                                <Grid xs={5}>
                                <LinkButton fullWidth variant="outlined" color="secondary" to={'/shooting'}>
                                generic.no
                                </LinkButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    )}
                </div>
            );
            //return (<div><LinkButton to="/tickets" color="secondary" variant="outlined"><FormattedMessage id="viewticket.back" /></LinkButton></div>);
        }
        else if(this.state.loading)
        {
            return (<PleaseWait />);
        }
        else
        {
            return (
            <div>
                <Grid container justify={"center"} className={classes.root}>
                    <Grid item xs={12}>
                        <Typography align={"center"}><Icon style={{color:green[600],fontSize:120}}>check_circle</Icon></Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h3" style={{margin:15}} align={"center"}><FormattedMessage id="cancelshooting.feedback_message" /></Typography>
                        <Typography style={{margin:30}} align={"center"}>
                            <LinkButton variant="outlined" color="primary" to={'/shooting'}>
                                bookshooting.return
                            </LinkButton>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            );
        }
    }
}

CancelShooting.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default injectIntl(withRouter(withStyles(styles)(CancelShooting)));
  