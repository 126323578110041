import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Route, Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from "./AlertDialog";
import ShootingPlanner from './ShootingPlanner';
import * as moment from 'moment';
import 'moment/locale/fr-ca';
import { isFunction } from 'util';
import { resolveMx } from 'dns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './admin.css';
import ListingEdit from './ListingEdit';

var _ = require('lodash');

const styles = theme => ({


    root: {
        width: '100%',
       // marginTop: theme.spacing.unit * 3,
        overflowX: 'clip',
       // padding:20,
        fontSize:23,
    },
    table: {
         minWidth: 700, 
       
    },
    tableRow: {
      cursor:'pointer'
    },
    button: {
        margin: theme.spacing.unit,
    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
      },
      
      card:{
          marginBottom:20
      },
      itemCard : {
        marginBottom:10,
        backgroundColor:'#eeeeee'
      } ,
      
});

class BadListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loaded: false,
            userId: null,
            selectedRole: -1,
            search:"",
            list:[],
            dlgShow:false
        };
    }

    componentWillMount() {
      
        window.Hauslife.adminBadListing('')
            .then((res) => {
                //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
                console.log(  res.data.result  )
                this.setState({ list: res.data.result,loaded:true,count:res.data.result.length });
                //  this.setState({ticket:res.data.data, is_reloading:false});
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleClick = (event, id) => {

        window.Hauslife.adminListing(id)
            .then((res) => {
                //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
                console.log('listing to show ', res.data.result  )
                
                this.setState({dlgShow:true,listing:res.data.result,count:res.data.result.length});
                
                //  this.setState({ticket:res.data.data, is_reloading:false});
            })
            .catch((e) => {
                console.log(e);
            });


       // this.setState({dlgShow:true,hmd});
    }

    searchChange= (event)=>{
        this.setState({search:event.target.value})
    }

    handleChangeSearch = (event) => {
 
        this.setState({loaded:false});
        window.Hauslife.adminHmdList(event.target.value)
        .then((res) => {
            //res.data.data.description = this.processDescription(res.data.data, res.data.data.description);
           // console.log( res.data.listings  )
            this.setState({ list: res.data.result,loaded:true });
            //  this.setState({ticket:res.data.data, is_reloading:false});
        })
        .catch((e) => {
            console.log(e);
        });

        this.setState({search:event.target.value});
    }

    brokerName (listing){
        if(listing.broker==undefined) return;
        return listing.broker.firstname+' '+listing.broker.lastname
    }

    alertClose = (button,data) => {
        if(button=='cancel'){
            this.setState({dlgShow: false});
            return;
        }
        this.setState({loaded:false});
        window.Hauslife.adminBadListing(  )
            .then((res) => {
            this.setState({ list: res.data.result,loaded:true ,dlgShow: false});
        })
        .catch((e) => {
            console.log(e);
        });
    }

    userRow(row){
        if (row.user_id==null) return '';

        var str = row.user.firstname+' '+ row.user.lastname;
        return str;
    }

    courtierRow(row){
        if (row.user_id==null) return '';

        var str =''  

        if( row.user.broker){
            str += row.user.broker.firstname +' ' +row.user.broker.lastname
        }
        return str;

    }

    
    render() {
        const { classes } = this.props;
        
        var rows =this.state.list;
        var loaded = this.state.loaded;

        return (

            <Grid style={{   height: "100%",flexGrow:0, alignContent:"flex-start" }}  container>

                {this.state.dlgShow==true &&
                    <ListingEdit style={{width:'100%'}} show={this.state.dlgShow} didClose={this.alertClose}   listing ={this.state.listing}></ListingEdit>
                }
                <Grid xs={12} style={{marginBottom:20}}>
                    <Paper style={{padding:10}}>
                        
                       
                    </Paper>
                </Grid>
                { loaded==false &&
                     <PleaseWait />
                }

                { loaded==true  &&
                    <Grid xs={12} style={{alignSelf:"flex-start",flex:1}}>
                      <Typography style={{ marginBottom:5}}>
                             Le total de cette Requête : { this.state.count}
                        </Typography>
                        <Paper className={styles.root}>
                        
                            <Table className={styles.table}>
                                <TableHead>
                                    <TableRow>
                                    <TableCell className="listing-list-cell">Listing Id</TableCell>
                                        <TableCell className="listing-list-cell">Listing Mls</TableCell>
                                        
                                        <TableCell className="listing-list-cell">Broker ID</TableCell>
                                        <TableCell className="listing-list-cell">Broker name</TableCell>
                                        <TableCell className="listing-list-cell">Broker Code</TableCell>
                                        <TableCell className="listing-list-cell">Adresse</TableCell>

                                        <TableCell className="listing-list-cell">centrisMls</TableCell>
                                        <TableCell className="listing-list-cell">centrisAddress</TableCell>
                                        <TableCell className="listing-list-cell">Centris broker code</TableCell>
                                         
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                        <TableRow className={classes.tableRow}  key={row.id} onClick={event => this.handleClick(event, row.listingId)}>
                                            <TableCell className="listing-list-cell">{row.listingId}</TableCell>
                                          
                                            <TableCell className="listing-list-cell"> {row.listingMls}   </TableCell>
                                            <TableCell className="listing-list-cell"> {row.brokerId}    </TableCell>
                                            <TableCell className="listing-list-cell" > {row.listingBrokerName } </TableCell>
                                            <TableCell className="listing-list-cell" > <span style={{color:(row.listingBrokerCode=='MISSING'?'red':'black') }}> {row.listingBrokerCode } </span></TableCell>
                                            <TableCell className="listing-list-cell" > {row.listingAddress } </TableCell>
                                            <TableCell className="listing-list-cell"  > <span style={{color:(row.centrisMls=='NO DATA'?'red':'black') }}>{row.centrisMls } </span></TableCell>
                                            <TableCell className="listing-list-cell" > {row.centrisAddress } </TableCell>
                                            <TableCell className="listing-list-cell" > <ul className="no-bullet">
                                                                                            <li style={{marginLeft:20}}><span style={{color:(row.listingBrokerCode!='MISSING'?'red':'black') }}>{row.centrisMain_broker_code }</span></li>
                                                                                            <li style={{marginLeft:20}}><span style={{color:(row.listingBrokerCode!='MISSING'?'red':'black') }}>{row.centrisSecond_broker_code }</span></li>
                                                                                            <li style={{marginLeft:20}}><span style={{color:(row.listingBrokerCode!='MISSING'?'red':'black') }}>{row.centrisThird_broker_code }</span></li>
                                                                                            <li style={{marginLeft:20}}><span style={{color:(row.listingBrokerCode!='MISSING'?'red':'black') }}>{row.centrisFourth_broker_code }</span></li>
                                                                                        </ul>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                }
            </Grid>

        );
    }
}


export default withRouter(withStyles(styles)(BadListing));
