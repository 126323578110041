import React, { useState, useEffect } from 'react'
import { Button, Typography, ListItemText, List, ListItem } from '@material-ui/core';
import _ from 'lodash'

export default ({floorplanRooms}) => {
    const [selectedFloor, setSelectedFloor] = useState(0)
    const [rooms, setRooms] = useState([])
    let floors = _.flatMap(floorplanRooms, (value, key) => ({floorname: value.floorname, id: key}))

    useEffect(() => {
        if(floorplanRooms[selectedFloor]){
            setRooms(floorplanRooms[selectedFloor]['rooms'])
        }
    }, [selectedFloor])
    
    return (
        <div style={{display: 'flex', flexDirection: 'row', }}>
            <List>
                {
                    floors.map(({floorname, id}) => (
                        <>
                            <ListItem
                                button
                                divider
                                selected={selectedFloor === id}
                                onClick={() => setSelectedFloor(id)}
                                color="primary"
                                variant="outlined"
                            >
                                <ListItemText
                                    primary={floorname}
                                />
                            </ListItem>
                        </>
                    ))
                }
            </List>

            <List>
                {
                    rooms.map(room => (
                        <ListItem
                            button
                            style={{padding: '5px 0px 5px 2px !important',width: 'auto',height: 'auto'}}
                            divider
                            variant="outlined"
                        >
                            <ListItemText
                                primary={`${room.roomname} (${room.markers[0]['order_index']})`}
                            />
                        </ListItem>
                    ))
                }
            </List>

        </div>
    )
}