import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import PleaseWait from './PleaseWait.js'

const styles = theme => ({
    root: {
    },
    innerGrid:{
        margin: theme.spacing.unit
    }
});

/**
 * Class used to display the ticket creation
 */
class AddTicket extends React.Component {

    constructor(props) {
      super(props);
      this.state = {createdTicket:false,loading:false,ticket_urgency:1,ticket_problem:"software",subject:"",description:""};
    }

    /**
     * Change of type
     */
    handleProblemChange = (event) => {
        console.log(event);
        this.setState({ticket_problem:event.target.value});
    };

    /**
     * Change urgency
     */
    handleUrgencyChange = (event) => {
        console.log(event);
        this.setState({ticket_urgency:event.target.value});
    };

    /**
     * Creates the ticket on the server
     */
    handleCreateTicket = (event) => {

        this.setState({loading:true});

        window.Hauslife.createTicket(this.state.ticket_problem, this.state.ticket_urgency, this.state.subject, this.state.description)
        .then((res)=>{
            this.setState({loading:false,createdTicket:true});
        })
        .catch((e)=>{
            this.setState({loading:false});
        });
    };

    render()
    {
        const { classes, intl } = this.props;

        const problem_types = [
            {label:intl.formatMessage({id:"addticket.problem.software"}),value:"software"},
            {label:intl.formatMessage({id:"addticket.problem.hardware"}),value:"hardware"},
            {label:intl.formatMessage({id:"addticket.problem.booth"}),value:"booth"},
            {label:intl.formatMessage({id:"addticket.problem.vr"}),value:"vr"},
            {label:intl.formatMessage({id:"addticket.problem.broker"}),value:"broker"},
            {label:intl.formatMessage({id:"addticket.problem.other"}),value:"other"},
        ];

        const problem_urgencies = [
            {label:intl.formatMessage({id:"addticket.problem.important"}),value:1},
            {label:intl.formatMessage({id:"addticket.problem.critical"}),value:2},
            {label:intl.formatMessage({id:"addticket.problem.urgent"}),value:3}
        ];

        return(

            <div>{this.state.loading ? (<PleaseWait />) : (
                <div>
                {this.state.createdTicket ? (
                <Grid container justify={"center"} className={classes.root}>
                    <Grid item xs={12}>
                        <Typography align={"center"}><Icon style={{color:green[600],fontSize:120}}>check_circle</Icon></Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h3" style={{margin:15}} align={"center"}><FormattedMessage id="addticket.confirmation_message" /></Typography>
                        <Typography style={{margin:30}} align={"center"}>
                            <LinkButton variant="outlined" color="primary" to={'/tickets'}>
                                bookshooting.return
                            </LinkButton>
                        </Typography>
                    </Grid>
                </Grid>
                ) : (
                <Grid container>
                    <Grid className={classes.innerGrid} xs={12}>
                        <TextField
                        variant="outlined"
                        fullWidth
                        select
                        label={intl.formatMessage({id:"addticket.problem_type"})}
                        value={this.state.ticket_problem}
                        onChange={this.handleProblemChange}
                        margin="normal"
                        >
                        {problem_types.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid className={classes.innerGrid} xs={12}>
                        <TextField
                        fullWidth
                        variant="outlined"
                        select
                        label={intl.formatMessage({id:"addticket.problem_urgencies"})}
                        className={classes.textField}
                        value={this.state.ticket_urgency}
                        onChange={this.handleUrgencyChange}
                        margin="normal"
                        >
                        {problem_urgencies.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid className={classes.innerGrid} xs={12}>
                        <TextField
                            variant="outlined"
                            label={intl.formatMessage({id:"addticket.subject"})}
                            fullWidth
                            value={this.state.subject}
                            onChange={(event)=>{this.setState({subject:event.target.value})}}
                            margin="normal"
                        />
                    </Grid>
                    <Grid className={classes.innerGrid} xs={12}>
                        <TextField
                            variant="outlined"
                            label={intl.formatMessage({id:"addticket.description"})}
                            multiline
                            fullWidth
                            rows={5}
                            value={this.state.description}
                            onChange={(event)=>{this.setState({description:event.target.value})}}
                            margin="normal"
                        />
                    </Grid>
                    <Grid container justify="space-between" className={classes.innerGrid}>
                        <Grid xs={5}>
                            <Button onClick={this.handleCreateTicket} fullWidth style={{color:"white",marginRight:15,backgroundColor:green[600]}} variant="contained"><FormattedMessage id="addticket.add_ticket" /></Button>
                        </Grid>
                        <Grid xs={5}>
                            <LinkButton fullWidth to="/tickets" color="secondary" variant="outlined"><FormattedMessage id="addticket.cancel" /></LinkButton>
                        </Grid>
                    </Grid>
                </Grid>
                )}
                </div>
            )}</div>
            

        );
    }
}

AddTicket.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default injectIntl(withRouter(withStyles(styles)(AddTicket)));
  