import React, { useState, useEffect, useRef, useContext } from 'react';

import { uniqueId } from 'lodash'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AlertDialog from "../../widgets/AlertDialog";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';

import CircularProgress from '@material-ui/core/CircularProgress';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { ViewListItem, EditListItem } from './ListItems'

import moment from 'moment'
// store
import { ZoneContext } from "./store"
import actions from "./store/actions"

// React-google-maps
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline, Polygon, OverlayView } from "react-google-maps"
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { HausMap } = require('./HausMap')


const styles = {
    rowContainer: {
    	width: '100%',
    	display: 'flex',
    	flexDirection: 'row',
    	justifyContent: 'center',
    	alignItems: 'flex-start',
    	marginTop: '10px'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '10px',
        maxHeight: '100%',
        overflow: 'scroll'
    },
    listItem: {
    	display: 'flex',
    	justifyContent: 'center',
    	alignItems: 'center'
    },
    clickableListItem: {
    	display: 'flex',
    	justifyContent: 'center',
    	alignItems: 'center',
    	cursor: 'pointer'
    },
    error: {
    	color: 'crimson',
    	padding: '10px'
    }
};

// functional component
export default (props) => {

	const {state, dispatch} = useContext(ZoneContext)

	let { currentZones, activeZone, currentUserScreen, shootings, techs, selectedZones } = state

	// Non deleted zones
	let visibleZones = currentZones.filter(zone => 
		!(zone.hasOwnProperty('isRemoved') && zone['isRemoved'] == true) && 
		!(zone.hasOwnProperty('isHidden') && zone['isHidden'] == true)
	)
	
	const [saving, setSaving] = useState(false)
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [showError, setShowError] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")

	useEffect(() => {
		loadZones()
	}, []) // The empty array causes this effect to only run on mount

	const loadZones = () => {
		window.Hauslife.getZones().then(zones => {
			let zonesWithRef = zones.map(zone => ({...zone, ref: React.createRef()}))
			dispatch({type: actions.FETCHING_ZONES_SUCCESS, data: zonesWithRef})
		})
	}

	const onCreateNewZone = () => {
		dispatch({type: actions.ADD_NEW_ZONE})
	}

	const onZoneSave = (newZoneName) => {
		let newZoneRef = activeZone.ref;
		let coords = newZoneRef.current.getPath().getArray();

		if(coords.length == 0) {
			setError("Please mark the zone on the map before saving it!")
			return;
		} else if(newZoneName.trim() == "") {
			setError("Please set the zone name before saving it!")
			return;
		} else {
			setError(null)
		}

		let zoneToSave = {
			zone_name: newZoneName,
			coordinates: coords,
			ref: newZoneRef
		}

		if(activeZone.id != null) {
			// edit case
			zoneToSave['id'] = activeZone.id
			dispatch({type: actions.EDIT_ZONE_SAVE, data: zoneToSave})
		} else {
			// create zone
			zoneToSave['id'] = uniqueId("temp-")
			dispatch({type: actions.SAVE_NEW_ZONE, data: zoneToSave})
		}
	}

	const onToggleZoneDelete = (event, zone) => {
		dispatch({type: actions.TOGGLE_DELETE_ZONE, data: zone})
	}

	const onZoneCancel = () => {
		dispatch({type: actions.CANCEL_NEW_ZONE})
	}

	const onToggleZoneSelect = zone_id => {
		dispatch({type: actions.TOGGLE_ZONE_SELECTION, data: zone_id})
	}

	const onPolygonClick = (event, zone) => {
		console.log("poly clicked on map", zone)
		onToggleZoneSelect(zone.id)
	}

	const onZoneEdit = (event, zone) => {
		dispatch({type: actions.EDIT_ZONE, data: zone})
		console.log("zone clicked from map", zone)
	}

	const onToggleZoneVisibility = (zone) => {
		dispatch({type: actions.TOGGLE_ZONE_VISIBILITY, data: zone})
	}

	const onFetchShootings = () => {

		let zones = selectedZones.filter(zoneId => zoneId.toString().indexOf('temp') == -1)

		if(zones.length == 0) {
			return;
			// show some meaningful error alert
		}
		setLoading(true)
		window.Hauslife.getShootingsAndDepotsForZones(zones).then(result => {
			// let markers = shootings.data.data.map(shoot => shoot.listing).filter(({lat, lng}) => (lat != null || lng != null))
			if(result.shootings){
				dispatch({type: actions.FETCHED_SHOOTINGS, data: result.shootings})
			}
			if(result.techs){
				
				dispatch({type: actions.FETCHED_DEPOTS, data: result.techs})
			}
			setLoading(false)
		})
	}

	const saveToDB = () => {

		if(saving) {
			return;
		}

		setSaving(true)

		let changedZones = currentZones
								.filter(zone => zone.hasOwnProperty('hasChanged'))
								.map(zone => {
									let { ref, hasChanged, ...base} = zone

									let coordinates = base.coordinates.map(({lat, lng}) => ([lat(), lng()]))
									// add first coordinate at the end to make it circular
									coordinates.push([base.coordinates[0].lat(), base.coordinates[0].lng()])
									
									let geometry = {
										type: "Polygon",
										coordinates: [coordinates]
									}
									return {...base, coordinates: geometry};
								})

		let newZones = currentZones
							.filter(zone => zone.id.toString().indexOf("temp") != -1)
							.map(zone => {
								let { ref, hasChanged, ...base} = zone

								let coordinates = base.coordinates.map(({lat, lng}) => ([lat(), lng()]))
								// add first coordinate at the end to make it circular
								coordinates.push([base.coordinates[0].lat(), base.coordinates[0].lng()])

								let geometry = {
									type: "Polygon",
									coordinates: [coordinates]
								}
								console.log("geojson for new coordinates", geometry)
								return {...base, coordinates: geometry};
							})

		let deletedZones = currentZones.filter(zone => (
								zone.hasOwnProperty('isRemoved') && 
								zone['isRemoved'] == true &&
								zone.id.toString().indexOf('temp') == -1
							)).map(zone => zone.id)

		window.Hauslife.saveZonesToDB({"update": changedZones, "create": newZones, "delete": deletedZones}).then(result => {
			setSaving(false);

			if(result.data.status != 200){
				let errorMessage = "";

				if(result.data.status == 409 && result.data.shootings != null){
					errorMessage = "Veuillez assigner les captures suivantes avant de supprimer les zones:\n\n";

					result.data.shootings.forEach((shooting)=>{
						errorMessage += moment(shooting.scheduleddate).format("DD MMMM hh:mm") + ": " + shooting.listing.address + "\n";
					})
				}
				else if(result.data.status == 409 && result.data.userZones != null){
					console.log(result.data.userZones)
					errorMessage = "Veuillez changer la zone de couverture des utilisateurs suivants avant de poursuivre:\n\n";

					result.data.userZones.forEach((userZone)=>{
						errorMessage += userZone.user.firstname + " " + userZone.user.lastname + "\n";
					})
				}
				else{
					errorMessage = "An unexpected error has occured:\n\n";
					errorMessage += result.data.err;
				}
				setShowError(true);
				setErrorMessage(errorMessage);
			} else {
				dispatch({type: actions.ON_SAVE_DB, data: result.data.result})
			}
		})
		.catch(err => {
			console.log("result from api", err)
			setSaving(false);
			setShowError(true);
			let errorMessage  = "An unexpected error has occured:\n\n";
			errorMessage += err;
			setErrorMessage(errorMessage);
		})
	}

	const alertClose = () => {
		setShowError(false)
		// loadZones();
		dispatch({type: actions.ON_SAVE_DB_FAILURE})
	}

	return (
		
		<div style={styles.rowContainer}>
			<AlertDialog show={showError} didClose={alertClose} title={"Erreur lors de la supression de zone"} text={errorMessage} ></AlertDialog>

			<Grid item xs={12} sm={12} md={12}>
				<HausMap
	              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCBlV8_octv2fJn5m6dAPJPn0UozRFm_q8&v=3.exp"
	              loadingElement={<div style={{ height: `${window.innerHeight - 200}px`, width: '100%' }} />}
	              containerElement={<div style={{ height: `${window.innerHeight - 200}px`, width:'100%' }} />}
	              mapElement={<div style={{ height: `${window.innerHeight - 200}px`, width:'100%' }} />}
	              polygons={visibleZones}
	              onPolygonClick={onPolygonClick}
	              userMode={currentUserScreen}
	              activeZone={activeZone}
	              selectedPolygonIdx={activeZone.id}
	              shootings={shootings}
	              depots={techs}
	              selectedZones={selectedZones}
	            />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
            	<div style={styles.listContainer}>
            		<div style={{display: 'flex', flexDirection: 'row'}}>
            			<Button 
            				variant="contained" 
            				color="primary" 
            				onClick={saveToDB}
            				style={{margin: '10px', minWidth: '180px'}}
        				>
        					{	
        						saving == true ? <CircularProgress color="white" size={30} /> : <div>Save Zones to DB</div>
        					}
	                    </Button>
	                    <Button 
            				variant="contained" 
            				color="primary" 
            				onClick={onFetchShootings}
            				style={{margin: '10px', minWidth: '180px'}}
        				>
        					{	
        						loading == true ? <CircularProgress color="white" size={30} /> : <div>Fetch Markers</div>
        					}
	                    </Button>
            		</div>
            		{
                    	error &&
                    	<div style={styles.error}>{error}</div>
                	}
	            	<List style={{width:"100%"}}>
	            		<Divider />
	            		{
	            			currentUserScreen == 'create'
	            			? 	<EditListItem 
	            					onSave={onZoneSave}
	            					onCancel={onZoneCancel}
	            				/>
	            			: 	<ListItem>
									<ListItemText primary="Create New Zone" />
									<ListItemSecondaryAction style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
										<AddCircleOutlineIcon color="primary" onClick={onCreateNewZone}/>
									</ListItemSecondaryAction>
	            				</ListItem>
            			}
            			<Divider />
	            		{

	            			currentZones.map(zone => {
	            				if(zone.id && (zone.id == activeZone.id)) {
	            					return <EditListItem 
	            						zoneName={zone.zone_name}
		            					onSave={onZoneSave}
		            					onCancel={onZoneCancel}
		            				/>
	            				} else {
	            					return <ViewListItem 
		            					key={zone.id}
		            					zone={zone}
		            					onZoneEdit={onZoneEdit}
		            					onToggleZoneDelete={onToggleZoneDelete}
		            					onToggleZoneSelect={onToggleZoneSelect}
										checked={selectedZones.indexOf(zone.id) != -1}
										toggleZoneVisibility={onToggleZoneVisibility}
		            				/>
	            				}
	            			})
	        			}
	            	</List>
            	</div>
            </Grid>
      	</div>
	)
}