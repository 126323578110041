import React, { useState, useEffect, useRef } from 'react';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';

import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline, Polygon, OverlayView } from "react-google-maps"
import { Button } from '@material-ui/core';
import { func } from 'prop-types';
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const $ = window.jQuery

function pointUpdated(index) {
    var path = this;
    var btnDelete = getDeleteButton('http://i.imgur.com/RUrKV.png');

    if (btnDelete.length === 0) {
        var undoimg = $("img[src$='https://maps.gstatic.com/mapfiles/undo_poly.png']");

        undoimg.parent().css('height', '21px !important');
        undoimg.parent().parent().append('<div style="overflow-x: hidden; overflow-y: hidden; position: absolute; width: 30px; height: 27px;top:21px;"><img src="http://i.imgur.com/RUrKV.png" class="deletePoly" style="height:auto; width:auto; position: absolute; left:0;"/></div>');

        // now get that button back again!
        btnDelete = getDeleteButton(path.btnDeleteImageUrl);
        btnDelete.hover(function () { $(this).css('left', '-30px'); return false; },
                    function () { $(this).css('left', '0px'); return false; });
        btnDelete.mousedown(function () { $(this).css('left', '-60px'); return false; });
    }

    // if we've already attached a handler, remove it
    if (path.btnDeleteClickHandler)
        btnDelete.unbind('click', path.btnDeleteClickHandler);

    // now add a handler for removing the passed in index
    path.btnDeleteClickHandler = function () {
        path.removeAt(index);
        return false;
    };
    btnDelete.click(path.btnDeleteClickHandler);
}

function getDeleteButton(imageUrl) {
    return $("img[src$='" + imageUrl + "']");
}

export const HausMap = withGoogleMap((props) => {

	let { polygons, onPolygonClick, selectedPolygonIdx, userMode, activeZone, shootings, depots, selectedZones } = props
	let { markers } = props

	const [ coordinates, setCoordinates ] = useState([])

	const onMapClick = ({latLng}) => {
		if(userMode == 'create') {
			setCoordinates(c => ([...c, {lat: latLng.lat(), lng: latLng.lng()}]))
		}
	}

	const undo = () => {
		setCoordinates(c => c.slice(0, -1))
	}

	useEffect(() => {
		setCoordinates([])
	}, [userMode])

	useEffect(() => {
		if(selectedPolygonIdx == null) {
			return
		}
		let polygon = polygons.find(({id}) => id == selectedPolygonIdx)
		window.google.maps.event.addListener(polygon.ref.current.getPath(), 'set_at', pointUpdated)
		window.google.maps.event.addListener(polygon.ref.current.getPath(), 'insert_at', pointUpdated)

	}, [selectedPolygonIdx])
	
	return (
		
    	<div style={{position: 'absolute', left: '475px', top: '155px'}}>
			{
				(userMode == 'create')
					?
				<Button onClick={undo} variant="contained" color="primary">
					UNDO
				</Button>
					: null
			}
    		<GoogleMap
	            defaultZoom={props.zoom || 8}
	            defaultCenter={{ lat: 45.6035, lng: -73.5107 }}
				onClick={onMapClick}
	        >

	        	{
	        		shootings.map(({listing}) => 
	        			<Marker 
	        				icon={{
	        					url: require('../../images/small_listing_marker.png'),
	        					scaledSize: { width: 32, height: 20}
        					}}
	        				position={{ lat: parseFloat(listing.lat), lng: parseFloat(listing.lng) }} 
        				/>
    				)
        		}


        		{					
					depots.map(({depot}) =>	
						<Marker 
							icon={{
								url: require('../../images/depot_icon.png'),
								scaledSize: { width: 32, height: 32}
							}}
							position={{ lat: parseFloat(depot.lat), lng: parseFloat(depot.lng) }} 
						/>													
					)					
        		}

	        	{
	        		userMode == "create" &&
	        		<Polygon 
	        			ref={activeZone.ref}
	        			path={coordinates}
	        			editable={true}
        				options={{
        					strokeColor: 'red',
        					strokeWidth: 6
    					}}
        			/>
        		}
	        	{
	        		polygons.map(polygon => {
	        			// polygon is array of nodes(lat, lng)
	        			let isSelectedForEdit = polygon.id == selectedPolygonIdx
						let isSelectedForView = selectedZones.indexOf(polygon.id) != -1

	        			return <Polygon 
	        				ref={polygon.ref}
	        				key={polygon.id}
	        				path={polygon.coordinates}
	        				editable={isSelectedForEdit}
	        				options={{
	        					strokeColor: isSelectedForEdit ? 'red' : isSelectedForView ? 'rgba(38, 58, 166, 1)' : 'black',
	        					strokeWeight: isSelectedForView ? 5 : isSelectedForEdit ? 3 : 2
        					}}
        					onClick={e => {
        						return isSelectedForEdit ? false : onPolygonClick(e, polygon)
    						}}
        				/>
        			})	
    			}
          	</GoogleMap>
    	</div>
    )
})