import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { injectIntl, FormattedMessage } from 'react-intl';
import LinkButton from './LinkButton.js'
import ActivityButton from './ActivityButton.js'
import PleaseWait from './PleaseWait.js'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';

import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';

import {
    Chart,
    PieSeries,
    Title,
  } from '@devexpress/dx-react-chart-material-ui';

import { Animation } from '@devexpress/dx-react-chart';

import * as moment from 'moment';
import 'moment/locale/fr-ca';

const humanizeDuration = require('humanize-duration')

var _ = require('lodash');

const styles = theme => ({
    root: {
        margin: theme.spacing.unit * 3
    },
    innerGrid: {
        margin: 0
    }
});

/**
 * Class used to view a single ticket
 */
class ShootingTimeInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = { rooms:[], see_more:false, is_reloading: true };
    }

    componentWillMount() {
        this.reloadFloorplan();
    }

    reloadFloorplan = () => {

        window.Hauslife.floorplan(this.props.shooting.listing.id)
            .then((res) => {
                //console.log(res.data.data.floorplanRooms);
                this.setState({ rooms:res.data.data.floorplanRooms, is_reloading: false });
            })
            .catch((e) => {
                console.log(e);
                this.setState({ is_reloading: false });
            });
        
    };

    /**
     * Calculate service time
     * 
     * Return time in seconds
     * 
     * @param {any} nb_rooms
     * @param {any} is_house
     * @param {any} is_existing_listing
     */
    calculateServiceTime = (nb_rooms, is_house, is_existing_listing ) =>
    {
        const { intl } = this.props;

        var new_service_time;
        var hauslife_room_count;
        var threeD_house_setup_facade_backyard_minutes = 20;
        var hybrid_house_setup_facade_backyard_minutes = 25;
        var threeD_time_per_room = 4.5;
        var hybrid_time_per_room = 5.25;
        var threeD_condo_setup_facade = 15;
        var hybrid_condo_setup_facade = 15;

        if (is_house === true) {
            hauslife_room_count = parseInt(nb_rooms) + 1; //entree

            if (is_existing_listing) {
                new_service_time = threeD_time_per_room * hauslife_room_count + threeD_house_setup_facade_backyard_minutes;
            }
            else {
                new_service_time = hybrid_time_per_room * hauslife_room_count + hybrid_house_setup_facade_backyard_minutes;
            }
        }
        else {
            hauslife_room_count = parseInt(nb_rooms) + 2; //entree + terasse

            if (is_existing_listing) {
                new_service_time = threeD_time_per_room * hauslife_room_count + threeD_condo_setup_facade;
            }
            else {
                new_service_time = hybrid_time_per_room * hauslife_room_count + hybrid_condo_setup_facade;
            }
        }

        return new_service_time;
    };

    isRoomOutside = (room) =>
    {
        return (room == "Facade" || room == "Pool" || room == "Patio" || room == "Veranda" || room == "Terrace" || room == "Balcony" || room == "Backyard" || room == "Picsine" || room == "Piscine" || room == "Terrasse" || room == "Balcon" || room == "Cour arrière");
    }

    getFirstRoomCreateTime = (shooting) => {
        let thethis = this;
        var sorted = _.sortBy(this.state.rooms, function(o) { return new moment(o.created_at); });
        sorted = _.filter(sorted, function(o) { return !thethis.isRoomOutside(o.roomname) });
        sorted = _.filter(sorted, function(o) { return moment(o.created_at).isAfter(moment(shooting.starttime)) });
        sorted = _.filter(sorted, function(o) { return moment(o.created_at).isBefore(moment(shooting.starttime).add(2,'hours')) });
        
        if(sorted.length == 0) return moment();

        return moment(sorted[0].created_at);
    }

    getLastRoomCreateTime = (shooting) => {
        let thethis = this;
        var sorted = _.sortBy(this.state.rooms, function(o) { return new moment(o.created_at); }).reverse();
        sorted = _.filter(sorted, function(o) { return !thethis.isRoomOutside(o.roomname) });
        sorted = _.filter(sorted, function(o) { return moment(o.created_at).isAfter(moment(shooting.starttime)) });
        sorted = _.filter(sorted, function(o) { return moment(o.created_at).isBefore(moment(shooting.starttime).add(2,'hours')) });
        
        if(sorted.length == 0) return moment();
        
        return moment(sorted[0].created_at);
    }

    toggleMore = () => {
        this.setState({see_more:!this.state.see_more});
    }

    drawSingleShooting = (shooting) => {
 
        var travelTime = moment.duration(shooting.transit_time, "seconds");
        var estimatedMins = (shooting.estimated_duration / 60);

        var pingTime = moment(shooting.ping_time);

        var starttime = moment(shooting.starttime);        
        var endtime = moment(shooting.endtime);

        var diff = moment.duration(endtime.diff(starttime));
        var broker = shooting.listing.broker.firstname +' ' +  shooting.listing.broker.lastname;

        var listing_room_count = shooting.listing.room_count;
        var listing_360_only = shooting.only_360;
        var listing_is_house = shooting.listing.is_house;

        var beforeShootTime = moment.duration(this.getLastRoomCreateTime(shooting).diff(starttime));

        var shootTime = moment.duration(endtime.diff(this.getLastRoomCreateTime(shooting)));

        var chartData = [{region:"PreShoot",val:beforeShootTime.asMinutes()},{region:"Shoot",val:shootTime.asMinutes()}];

        let thethis = this;//lol !
        var sortedRooms = _.sortBy(this.state.rooms, function(o) { return new moment(o.created_at); });
        var sortedRoomsToCount = _.filter(sortedRooms, function(o) { return !thethis.isRoomOutside(o.roomname) });
        
        
        //this.calculateServiceTime(listing_room_count, listing_is_house, listing_360_only);

        var totalMins = Math.round(diff.asMinutes());

        var plusminus = estimatedMins - totalMins;

        var plusminusPercent = Math.round(-(plusminus / estimatedMins) * 100);


        if(!shooting.endtime) return (
            <Card style={{width:"100%"}}>
                <CardHeader
                    avatar={<Icon style={{color:"black"}}>{shooting.listing.is_house?"home":"domain"}</Icon>}
                    title={(shooting.listing.app?shooting.listing.app+"-":"") + shooting.listing.address + ", " + shooting.listing.city}
                    subheader={moment(shooting.scheduleddate).format("lll")}
                    action={
                        <div style={{display:"flex",flexDirection:"row"}}>
                            {shooting.seasonal==true &&
                                <Chip style={{color:"#fff",margin:2,backgroundColor:'#3f51b5'}} label={"Saisonnière"} />
                            }
                            {shooting.blue_hour==true &&
                                <Chip style={{color:"#fff",margin:2,backgroundColor:'#3f51b5'}} label={"Heure Bleue"} />
                            }
                        </div>
                    }
                />
                <CardContent>
                    <div style={{display:"flex",flexDirection:"row", marginBottom:10}}>
                        <Chip style={{margin:2}} icon={<Icon>timer</Icon>} label={estimatedMins + " est. mins"} />
                        <Chip style={{margin:2}} icon={<Icon>directions_car</Icon>} label={Math.round(travelTime.asMinutes()) + " mins ("+shooting.distance+"km)"} />
                        <Chip style={{margin:2}} label={shooting.room_count + " rooms " + (shooting.only_360?"3D only":"2D + 3D")} />
                    </div>
                    {shooting.ping_time != null && <Chip style={{marginTop:10,marginBottom:2}} icon={<Icon>notifications_active</Icon>} label={pingTime.format("lll")} />}
                    {(shooting.starttime != null && shooting.endtime == null) && 
                        <>
                            <p>Shooting in progress...</p>
                            <p>{broker} </p>
                        </>
                    }
                    {(shooting.starttime == null && shooting.endtime == null) && 
                        <>
                            <p>Shooting planned....</p>
                            <p>{broker} </p>
                        </>
                    }
                </CardContent>
                <CardActions>
                    <p style={{color:"#ccc"}}>{"listing : " + shooting.listing.id + " shooting : " + shooting.id}</p>
                </CardActions>
            </Card>
        );

        return(
            <Card style={{width:"100%"}}>
                <CardHeader
                    avatar={<Icon style={{color:"black"}}>{shooting.listing.is_house?"home":"domain"}</Icon>}
                    action={
                        <div style={{display:"flex",flexDirection:"row"}}>
                            {shooting.seasonal==true &&
                                <Chip style={{color:"#fff",margin:2,backgroundColor:'#3f51b5'}} label={"Saisonnière"} />
                            }
                            {shooting.blue_hour==true &&
                                <Chip style={{color:"#fff",margin:2,backgroundColor:'#3f51b5'}} label={"Heure Bleue"} />
                            }
                        </div>
                    }
                    title={(shooting.listing.app?shooting.listing.app+"-":"") + shooting.listing.address + ", " + shooting.listing.city}
                    subheader={moment(shooting.scheduleddate).format("lll")}
                />
                <CardContent>
                <div style={{display:"flex",flexDirection:"row",marginBottom:10}}>
                    <Chip style={{margin:2}} icon={<Icon>timer</Icon>} label={totalMins + " mins"} />
                    <Chip style={{margin:2}} icon={<Icon>directions_car</Icon>} label={Math.round(travelTime.asMinutes()) + " mins ("+shooting.distance+"km)"} />
                    <Chip style={{margin:2}} color={plusminus > 0 ? "primary" : "secondary"} icon={<Icon>import_export</Icon>} label={(plusminus*-1) + " m (" + plusminusPercent+"%)"} />
                </div>

                <p style={{fontSize:' 0.875rem', fontWeight:"bold", marginBottom:5}}>Courtier: {broker} </p> 
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Informations</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{flexDirection:"column"}}>
                        <p>{"Broker : " + shooting.listing.broker.firstname + " " + shooting.listing.broker.lastname}</p>
                        <p>{"Listing ID : " + shooting.listing.id}</p>
                        <p>{"Shooting ID : " + shooting.id}</p>
                        <p>{"Projected rooms : " + shooting.room_count}</p>
                        <p>{"Actual rooms : " + sortedRoomsToCount.length}</p>
                        <p>{"Tech note : " + shooting.tech_note}</p>
                        <p>{"Completed : " + (shooting.state != 0)}</p>
                        <p>{"client_present : " + shooting.client_present}</p>
                        <p>{"broker_present : " + shooting.broker_present}</p>
                        <p>{"key_box : " + shooting.key_box}</p>
                        <p>{"only_360 : " + shooting.only_360}</p>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                    {shooting.ping_time != null && <Chip style={{marginTop:10,marginBottom:2}} icon={<Icon>notifications_active</Icon>} label={pingTime.format("lll")  + (endtime!=null?' - ' + endtime.format('HH:mm'):'') } />}

                    <p>{"Scouting : " + Math.round(beforeShootTime.asMinutes()) + " mins"}</p>
                    <p>{"Shoot : " + Math.round(shootTime.asMinutes()) + " mins"}</p>
                    <p>{"TOTAL : " + totalMins + " mins"}</p>
                    <p>{"Estimated time : " + estimatedMins + " mins"}</p>
                    <p style={{color:(plusminus > 0 ? "green" : "red")}}><b>{(plusminus > 0 ? "Less than estimated : " : "More than estimated : ") + Math.abs(plusminus) + " mins"}</b></p>
                    

                    {!this.state.see_more &&
                        <Button onClick={this.toggleMore}>More details</Button>
                    }

                    {this.state.see_more &&
                        <div>
                            <Button onClick={this.toggleMore}>Less details</Button>
                            <p>_____________</p>
                            <p>{"Start : " + starttime.format("h:mm:ss")}</p>
                            {sortedRooms.map(row => (
                                <p>{row.roomname + " : " + moment(row.created_at).format("h:mm:ss")}</p>
                            ))}
                            <p>{"End : " + endtime.format("h:mm:ss")}</p>
                            <Chart
                                data={chartData}
                                >
                                <PieSeries
                                    valueField="val"
                                    argumentField="region"
                                    innerRadius={0.6}
                                />
                                <Animation />
                            </Chart>
                        </div>
                    }

                </CardContent>
                <CardActions>
                    <p style={{color:"#ccc"}}>{"listing : " + shooting.listing.id + " shooting : " + shooting.id}</p>
                    <a href={"/tech_review/"+ shooting.listing.id } target="_blank" style={{color:'black'}}>Photos</a>
                </CardActions>
            </Card>
        );
    }

    render() {
        const { classes, match, intl } = this.props;

        return (
            <Grid container>
                {this.state.is_reloading === true ?
                    (
                        <PleaseWait />
                    )
                    :
                    (
                        this.drawSingleShooting(this.props.shooting)
                    )}
            </Grid>
        );
    }
}

ShootingTimeInformation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(withStyles(styles)(ShootingTimeInformation)));
