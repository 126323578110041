import React from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinkButton from './LinkButton.js'
import PleaseWait from './PleaseWait.js'
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';

import ShootingTimeframeSelector from './ShootingTimeframeSelector.js'

import { FormattedMessage } from 'react-intl';

import * as moment from 'moment';
import 'moment/locale/fr-ca';


const styles = theme => ({
    table:
    {
    }
});

class ChangeTimeShooting extends React.Component {

    constructor(props) {
      super(props);
      this.state = {selectedTimeframe:null,shooting:null,changedDate:false,error:false,isChangingDate:false};
    }

    componentWillMount()
    {
        const { match } = this.props;
        if(match.params.id != "undefined")
        {
            //match.params.id
            window.Hauslife.getShooting(match.params.id)
            .then((res)=>{
                this.setState({shooting:res.data.data});
            })
            .catch((e)=>{
                console.log(e);
            });
        }
        //get tocket info
    }

    /**
     * Tries to change the shooting time with the server
     */
    handleShootingChangeTimeClick = () => {
        this.setState({isChangingDate:true});
        
        var selectedTimeFrameTS = this.state.selectedTimeframe.ts;
        var selectedTimeFrameAM = this.state.selectedTimeframe.am;

        window.Hauslife.updateShootingTime(this.state.shooting.id,selectedTimeFrameTS,selectedTimeFrameAM)
        .then((res)=>{
            console.log(res);
            this.setState({changedDate:true,isChangingDate:false});
        })
        .catch((e)=>{
            this.setState({error:true,isChangingDate:false});
        });
    };

    /**
     * Renders the timeframe select step
     */
    RenderTimeFrameSelect = () => {
        const { classes, intl } = this.props;

        if(this.state.shooting === null)
        {
            return("&nbsp;");
        }

        var listing = {
            address:this.state.shooting.listing.address+", "+this.state.shooting.listing.city,
            is_house:this.state.shooting.listing.is_house,
            room_count:this.state.shooting.listing.room_count,
            is_existing_listing:this.state.shooting.only_360
        };

        return (
            <div>
            <ShootingTimeframeSelector onTimeframeSelect={(ts,am)=>{console.log(ts,am); this.setState({selectedTimeframe:{ts:ts,am:am}});}} listing={listing} />
            <div style={{display: "flex",marginTop:15,justifyContent:"flex-end"}}>
                <LinkButton style={{marginRight:15}} variant="outlined" color="secondary" to={'/shooting'}>
                    cancelshooting.btn.cancel
                </LinkButton>
                <Button disabled={this.state.isChangingDate || this.state.selectedTimeframe===null} onClick={this.handleShootingChangeTimeClick} color="primary" variant="contained"><FormattedMessage id="shooting.changetime.submit" /></Button>
            </div>
            </div>
        );
        
    };


    RenderPropertyInformations = () => {

        if(this.state.shooting === null)
        {
            return (<p></p>);
        }

        return(
            <div>
            <Typography component="p">
            <b><FormattedMessage id="bookshooting.address" /> : </b>{this.state.shooting.listing.address + (this.state.shooting.listing.app?", Apt. "+this.state.shooting.listing.app:"") + ", " + this.state.shooting.listing.city}
            </Typography>
            <Typography component="p">
            <b><FormattedMessage id="bookshooting.number_or_rooms" /> : </b>{this.state.shooting.listing.room_count}
            </Typography>
            </div>
        );
        
        
    };

    render()
    {
        const { classes } = this.props;

        if(this.state.isChangingDate === true)
        {
            return (
            <PleaseWait />
            );
        }
        else if(this.state.changedDate == true)
        {
            return (
                <div>
                    <Grid container justify={"center"} className={classes.root}>
                        <Grid item xs={12}>
                            <Typography align={"center"}><Icon style={{color:green[600],fontSize:120}}>check_circle</Icon></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h3" style={{margin:15}} align={"center"}><FormattedMessage id="bookshooting.confirmation_message" /></Typography>
                            <Typography style={{margin:30}} align={"center"}>
                                <LinkButton variant="outlined" color="primary" to={'/shooting'}>
                                    bookshooting.return
                                </LinkButton>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            );
        }

        return (
            <Grid container>
                <Grid style={{marginTop:15,marginBottom:15}} item xs={12}>
                <Paper style={{padding:15,backgroundColor:"#fbfbfb"}} elevation={1}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Typography component="p">
                            <Icon style={{verticalAlign:"middle",marginRight:15}}>info</Icon> <FormattedMessage id="shooting.changetime.why_cannot_change_informations" />
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                </Grid>
                <Grid item xs={12}>
                    {this.RenderPropertyInformations()}
                </Grid>
                <Grid item xs={12}>
                    {this.RenderTimeFrameSelect()}
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(ChangeTimeShooting));
  