import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import
{
    Paper,
    MenuItem,
    TextField,
    CardMedia,
    FormControlLabel,
    Checkbox,
    Card,
    Select,
    Input,
    FormControl,
    CardContent,
    Button,
    OutlinedInput,
    Typography
} from '@material-ui/core';
import AlertDialog from "./AlertDialog";
import ChangePassword from "./ChangePassword";
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    button: {
        marginTop: 15
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200
    },
    wideTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300
    },
    paper:{
         margin:10,
         fontSize:24
    },
    itemCard:{
        marginTop:25,
        marginLeft:5,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    }
});

const roles = [
    { id: 3, value: 'Courtier' },
    { id: 4, value: 'Technicien Capture' },
    { id: 5, value: 'Technicien Post Production' },
    { id: 6, value: 'Client' },
    { id: 7, value: 'Technicien Injector' },
    { id: 8, value: 'Technicien Post Production Lead' },
    { id: 2, value: 'Admin' },
    {id: 10, value: 'Social Media'},
    {id: 12, value: 'Sales'}
];

const admin_role = 2;
const broker_role = 3;
const prod_tech_role = 4;
const post_prod_tech_role = 5;
var emptyUser = {
    id: null,
    lastname: '',
    firstname: '',
    email: '',
    phone_number: '',
    role: '',
    password: '',
    zones: [],
    team_id:null,
    broker_code:'',
    broker_facebook_business_page: {
        page_id: '',
        page_name: ''
    },
    pixel_id: null,
    service_offers: [],
    shooting_packages: [],
    depot_id:null,
    backupZones:[],
    zone_id:null
}

class EditUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: emptyUser,
            passwordField: '123456',
            passwordValidationField: '123456',
            loaded: false,
            dlgShow: false,
            passwordRequired:true,
            userShootings: [],
            userHMDS: [],
            teams:[],
            franchises:[],
            depots: [],
            zones: [],
            serviceOfferings: [],
            shootingPackages: [],
            notifPhone: [],
            notifEmail: [],
            notifEmailToAdd:"",
            notifPhoneToAdd:"",
            emailToDelete:"",
            phoneToDelete:""
        };
    }
    componentDidMount() {

        window.Hauslife.getBrokerTeam()
            .then((res)=>{
                this.setState({
                    teams:res.data.result.teams,
                    franchises:res.data.result.franchises
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
            });

        window.Hauslife.getDepots()
            .then((res)=>{
                this.setState({
                    depots:res.data.depots
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
            });

        window.Hauslife.getZones()
            .then((res)=>{
                this.setState({
                    zones:res
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
            });

        window.Hauslife.getAllServiceOffers()
            .then((res)=>{
                this.setState({
                    serviceOfferings: res.data.data
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
            });

        window.Hauslife.getAllShootingPackages()
            .then((res)=>{
                this.setState({
                    shootingPackages: res.data.data
                });
            }).catch( (e)=>{
                console.log('Error   : ',e);
            });

        if(this.props.match.params.id === undefined) return;

        this.callOnMe('getUserData', this.props.match.params.id);
    }

    handleCheckboxChange = name => event => {
        let obj = {};
        obj[name] = !this.state[name];
        this.setState(obj);
    }

    handle_gf_clause_change = name => event => {
        let user = this.state.user;
        user.gf_clause = !user.gf_clause;
        this.setState(user);
    }

    handle_360Video_Change = name => event => {
        let user = this.state.user;
        user.enable_threesixty_video = !user.enable_threesixty_video;
        console.log(user);
        this.setState(user);
    }

    handleSuspended = event => {
        let user = this.state.user;
        user.suspended=  !user.suspended;
        this.setState(user);
    }

    handleFreelance = event => {
        let user = this.state.user;
        user.freelance =  !user.freelance;
        this.setState(user);
    }

    handleChange = name => event => {
        let user = this.state.user;
        user[name] = event.target.value;
        this.setState({ user });
    }

    handleChangePassword = name => event => {
        this.setState({ [name]: event.target.value });
    }

    addNotifEmail  = () =>{
        this.state.notifEmail.push(this.state.notifEmailToAdd)
        this.setState({notifEmailToAdd: ""});
        let user = this.state.user;
        user.notification_email = this.state.notifEmail;
        this.setState(user);

    }

    loadEmailList = () => {

        return this.state.notifEmail.map((email) =>{
            return email + "\n";
        });
    }

    deleteEmailMenu = () =>{
        return this.state.notifEmail.map((email)=>{
            return <MenuItem value={email}>{email}</MenuItem>;
        });
    }

    deletePhoneMenu = () =>{
        return this.state.notifPhone.map((phone)=>{
            return <MenuItem value={phone}>{phone}</MenuItem>;
        });
    }


    deleteNotifEmail = () => {
       let user = this.state.user;
       let notifEmailCopy = [...this.state.notifEmail];
       let itemToDelete = notifEmailCopy.indexOf(this.state.emailToDelete);

       notifEmailCopy.splice(itemToDelete,1);
       this.state.notifEmail = notifEmailCopy;
       this.state.emailToDelete = ""
       user.notification_email = this.state.notifEmail;
       this.setState(user)
    }

    deleteNotifPhone = () => {
        let user = this.state.user;
        let notifPhoneCopy = [...this.state.notifPhone];
        let itemToDelete = notifPhoneCopy.indexOf(this.state.phoneToDelete);

        notifPhoneCopy.splice(itemToDelete,1);
        this.state.notifPhone = notifPhoneCopy;
        this.state.phoneToDelete = ""
        user.notification_phone_number = this.state.notifPhone;
        this.setState(user)
    }

    addNotifPhoneNumber  = () =>{
        this.state.notifPhone.push(this.state.notifPhoneToAdd)
        this.setState({notifPhoneToAdd: ""});
        let user = this.state.user;
        user.notification_phone_number = this.state.notifPhone;
        this.setState(user);

    }

    loadPhoneList = () => {
        return this.state.notifPhone.map((phone_number) =>{
            return phone_number + "\n";
        });
    }
    updateBrokerNote = (text) => {
        let user = this.state.user;
        user.note_about_broker = text;
        this.setState(user);
    }

    callOnMe = (service, data) => {
        window.Hauslife[service](data)
            .then((res) => {
                switch(service){
                    case 'getUserData':
                        let zone_id = -1;
                        let backupZones = [];

                        res.data.user.userZones.forEach( (item)=>{
                            if(item.backup === false){
                                zone_id = item.zone.id;
                            }
                            else{
                                backupZones.push(item.zone.id);
                            }
                        });
                        console.log(res.data.user);
                        this.setState({
                            user: {
                                id: res.data.user.id,
                                lastname: res.data.user.lastname,
                                firstname: res.data.user.firstname,
                                email: res.data.user.email,
                                phone_number: res.data.user.phone_number,
                                role: res.data.user.role,
                                gf_clause: res.data.user.gf_clause,
                                enable_threesixty_video: res.data.user.enable_threesixty_video,
                                suspended:res.data.user.suspended,
                                freelance:res.data.user.freelance,
                                notification_email: res.data.user.notification_email,
                                notification_phone_number: res.data.user.notification_phone_number,
                                broker_code:res.data.user.broker_code,
                                note_about_broker: res.data.user.note_about_broker,
                                team_id:res.data.user.team_id,
                                franchise_id:res.data.user.franchise_id,
                                pixel_id: res.data.user.pixel_id,
                                broker_facebook_business_page: res.data.user.broker_facebook_business_page,
                                depot_id: res.data.user.depot_id,
                                zone_id: zone_id,
                                service_offers: res.data.user.userServiceOffers != null && res.data.user.userServiceOffers.length > 0 ? res.data.user.userServiceOffers.map(so => so.service_offer_id) || [] : [],
                                shooting_packages: res.data.user.userShootingPackages != null && res.data.user.userShootingPackages.length > 0 ? res.data.user.userShootingPackages.map(so => so.shooting_package_id) || [] : [],
                                backupZones,
                            },
                            passwordField: '',
                            passwordValidationField: '',
                            passwordRequired:false,
                            loaded: false,
                            dlgShow: false,
                            dlgTitle: 'Hauslife',
                            dlgText: ""
                        },()=>{
                            if(this.state.user.role === prod_tech_role)
                            {
                                window.Hauslife.getAllShootingsOfTech(this.props.match.params.id)
                                .then(res=>{
                                    this.setState({userShootings:res.data.shootings});
                                })
                                .catch(e=>{
                                    console.log(e);
                                });
                            }

                            if(this.state.user.role === broker_role)
                            {
                                window.Hauslife.getAllShootingsOfBroker(this.props.match.params.id)
                                .then(res=>{
                                    this.setState({userShootings:res.data.shootings});
                                })
                                .catch(e=>{
                                    console.log(e);
                                });
                                if(this.state.user.note_about_broker === null){
                                    let user = this.state.user;
                                    user.note_about_broker = "";
                                    this.setState(user);
                                }
                            }
                            if(this.state.user.notification_email !== null){
                                this.state.user.notification_email.forEach((email)=>{
                                    this.state.notifEmail.push(email);
                                });
                            }
                            if(this.state.user.notification_phone_number !== null){
                                this.state.user.notification_phone_number.forEach((phone_number)=>{
                                    this.state.notifPhone.push(phone_number);
                                });
                            }
                            window.Hauslife.getHMDsByUserId(this.state.user.id)
                            .then((response)=>{
                                if(response.data.status === 200)
                                {
                                    this.setState({userHMDS:response.data.hmds});
                                }
                            })
                            .catch((err)=>{
                                console.log(err);
                            });
                        });

                        break;
                    case 'createUser':
                        this.setState({
                            user: {
                                id: null,
                                lastname: '',
                                firstname: '',
                                email: '',
                                phone_number: '',
                                role: '',
                                password: null ,
                                zones: [],
                                backupZones: []
                            },
                            passwordField: '',
                            passwordValidationField: '',
                            loaded: false,
                            dlgShow: true,
                            dlgTitle: 'Hauslife',
                            dlgText: "L’usager a été créé avec succès. Vous pouvez en créer un nouveau ou retourner à la liste."
                        });
                        break;
                    case 'putUserData':
                        this.setState({
                            dlgShow: true,
                            dlgTitle: 'Hauslife',
                            dlgText: "La sauvegarde effectué avec succès."
                        });
                        break;
                }
            })
            .catch((e) => {
                let errObj = { dlgShow: true };

                if(e.response === undefined) {
                    errObj.dlgTitle = 'Exception';
                    errObj.dlgText = e.toString();
                    this.setState(errObj);
                    return;
                }
                switch (e.response.status) {
                    case 409:
                        errObj.dlgTitle = 'Une erreur est survenue.';
                        errObj.dlgText = "L'usager existe déjà.";
                        break;
                    default:
                        errObj.dlgTitle = e.response.statusText;
                        errObj.dlgText = e.response.data.message;
                }
                this.setState(errObj);
            });
    }

    WelcomeMessage = () => {
        if(this.state.user.id==null){
            return "Création d'un nouveau utilisateur.";
        }
        return 'Edition : ( ' + this.state.user.id+' ) ' + this.state.user.firstname+' '+this.state.user.lastname;
    }

    handleSubmit = event => {
        event.preventDefault();
        // Par defaut le service est en mode edition
        let service = 'putUserData';

        let data = {};

        for (var attr in this.state.user) {
            if (this.state.user.hasOwnProperty(attr)){
                data[attr] = this.state.user[attr];
                if(typeof data[attr] == 'string'){
                    data[attr] = data[attr].trim();
                }
            }
        }

        // L'écran est en mode ajout d'utilisateur (state.user.id est null)
        if(this.state.user.id==null) {
            service = 'createUser';
            delete data.id;

            if (this.state.passwordField.length < 6) {
                this.setState({
                    dlgShow: true,
                    dlgTitle: 'Une erreur est survenue.',
                    dlgText: 'Le mot de passe doit contenir six caractères.',
                });
                return;
            }
            if (this.state.passwordField !== this.state.passwordValidationField) {
                this.setState({
                    dlgShow: true,
                    dlgTitle: 'Une erreur est survenue.',
                    dlgText: 'La validation du mot de passe a échoué.'
                });
                return;
            }
            data.password = this.state.passwordField;
        }
        this.callOnMe(service, data);
    }
    alertClose = () => {
        this.setState({ dlgShow: false });
    }

    convertShootingStateToHuman = (state) =>
    {
        switch (state) {
            case 0:
                return "Created";
            case 1:
                return "Captured";
            case 2:
                return "Waiting or Uploading";
            case 3:
                return "Retouching";
            case 4:
                return "Ready for post";
            case 5:
                return "Uploaded";
            case 6:
                return "Confirmed";
            case 7:
                return "Unconfirmed";
            case 8:
                return "In-progress";
            case 9:
                return "Completed";
            case 10:
                return "Canceled";
            case 11:
                return "Confirmation required";
            case 12:
                return "Shooting recommended";
            case 13:
                return "Shooting abandoned";
        }
    }

    displayRelatedShootings = () =>
    {
        this.state.userShootings.sort((a, b) => new Date(a.scheduleddate) - new Date (b.scheduleddate)).reverse();
        return (
            <div>
                <div style={{overflow:'scroll',height:'400px', padding:'10px'}}>
                    <p><b>Shootings in HMD</b></p>
                    {this.state.userShootings.filter(shooting=>shooting.listing.state === 3).map(shooting => (
                        <Card style={{display:"flex", margin: 10}}>
                            <CardContent style={{flex: '1 0 auto',padding:10}}>
                                <p>{"Shooting ID : " + shooting.id}</p>
                                <p>{"Capture Date : " + moment(shooting.scheduleddate).format('YYYY/MM/DD')}</p>
                                <p>{this.convertShootingStateToHuman(shooting.state)}</p>
                                <Link style={{color:"#000"}} to={"/tech_review/" + shooting.listing.id}>{shooting.listing.id + " | " +shooting.listing.address + (shooting.listing.app ? ", # " + shooting.listing.app + " " : "") + ", " + shooting.listing.city}</Link>
                            </CardContent>
                            <CardMedia
                                style={{width:151}}
                                image={shooting.listing.picture_url}
                            />
                        </Card>
                    ))}
                </div>
                <div style={{overflow:'scroll',height:'400px', padding:'10px'}}>
                    <p><b>Others</b></p>
                    {this.state.userShootings.filter(shooting=>shooting.listing.state !== 3).map(shooting => (
                        <Card style={{display:"flex", margin: 10}}>
                            <CardContent style={{flex: '1 0 auto',padding:10}}>
                                <p>{"Shooting ID : " + shooting.id}</p>
                                <p>{"Capture Date : " + moment(shooting.scheduleddate).format('YYYY/MM/DD')}</p>
                                <p>{this.convertShootingStateToHuman(shooting.state)}</p>
                                <Link style={{color:"#000"}} to={"/tech_review/" + shooting.listing.id}>{shooting.listing.id + " | " +shooting.listing.address + (shooting.listing.app ? ", # " + shooting.listing.app + " " : "") + ", " + shooting.listing.city}</Link>
                            </CardContent>
                            <CardMedia
                                style={{width:151}}
                                image={shooting.listing.picture_url}
                            />
                        </Card>
                    ))}
                </div>
            </div>
        );
    }

    unassignHMD = (serial_number) =>
    {
        var yesorno = window.confirm("Are you sure?");

        if(yesorno === true)
        {
            window.Hauslife.unassignHmd(serial_number)
            .then((res)=>{
                if(res.data.status === 200)
                {
                    alert("Unassigned.");
                }
            })
            .catch((err)=>{
                alert(err);
            });
        }
    }

    displayHMDsInformation = () =>
    {
        if(this.state.userHMDS.length > 0)
        {
            return (
                <div>
                    <p><b>HMDS assigned</b></p>
                    {this.state.userHMDS.map(hmd => (
                        <Card style={{display:"flex", margin: 10}}>
                            <CardContent style={{flex: '1 0 auto',padding:10}}>
                                <p>{"HMD ID : "+hmd.id}</p>
                                <p>{"HMD SERIAL : "+hmd.serial_number}</p>
                                <Button onClick={()=>{this.unassignHMD(hmd.serial_number)}} variant="outlined">UNassign</Button>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            );
        }
        else
        {
            return(<div/>);
        }
    }

    render() {
        var suspendedCheck= this.state.user.suspended === undefined?false:this.state.user.suspended;
        var freelanceCheck= this.state.user.freelance === undefined?false:this.state.user.freelance;

        const { classes } = this.props;

        return (
            <Paper className={classes.root}>

                <AlertDialog show={this.state.dlgShow} didClose={this.alertClose} title={this.state.dlgTitle} text={this.state.dlgText} />

                {window.Hauslife.userObject.role <= admin_role  &&

                        <Button  variant="outlined" color="inherit" className={classes.button} style={{marginLeft:'10px'}} onClick={this.props.history.goBack}>
                            Retour
                        </Button>
                }
                <div  className={classes.paper}>
                   <h3>{this.WelcomeMessage()}</h3>
                </div>
                <form onSubmit={this.handleSubmit} className={classes.container} autoComplete="off" style={{padding:'10px'}}  autoFill="off">
                    <TextField
                        id="firstname"
                        label="Prénom"
                        className={classes.textField}
                        value={this.state.user.firstname}
                        onChange={this.handleChange('firstname')}
                        margin="normal"
                        variant="outlined"
                        required
                    />

                    <TextField
                        id="lastname"
                        label="Nom"
                        className={classes.textField}
                        value={this.state.user.lastname}
                        onChange={this.handleChange('lastname')}
                        margin="normal"
                        variant="outlined"
                        required
                    />

                    <TextField
                        id="email"
                        type="email"
                        label="Courriel"

                        className={classes.wideTextField}
                        value={this.state.user.email}
                        onChange={this.handleChange('email')}
                        margin="normal"
                        variant="outlined"
                        required
                    />

                    <TextField
                        id="phone_number"
                        label="Téléphone"
                        className={classes.textField}
                        value={this.state.user.phone_number}
                        onChange={this.handleChange('phone_number')}
                        margin="normal"
                        variant="outlined"
                    />
                  
                    <div style={{padding: "1px", display: "flex", alignItems: "center", flexDirection:"row"}}>
                        <TextField
                            id="outlined-multiline"
                            label="Notification Emails"
                            multiline
                            style={{width: "350px"}}
                            rows="4"
                            value={this.loadEmailList()}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                        <div style={{display: "flex", flexFlow: "column"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <TextField
                                    className={classes.textField}
                                    onChange={e => this.setState({notifEmailToAdd: e.target.value})}
                                    value={this.state.notifEmailToAdd}
                                    label="email à ajouter"
                                    margin="normal"
                                    variant="outlined"
                                />
                                <Button variant="outlined" color="inherit" onClick={this.addNotifEmail}>Ajouter</Button>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="email">
                                        Email à supprimer
                                    </InputLabel>
                                    <Select style={{minWidth: "200px"}} value={this.state.emailToDelete}
                                            onChange={(e) => {
                                                this.setState({emailToDelete: e.target.value})
                                            }}
                                            input={<OutlinedInput labelWidth={0} name="email"/>}
                                    >
                                        <MenuItem value="">
                                            <em>Selectionner un email</em>
                                        </MenuItem>
                                        {this.deleteEmailMenu()}
                                    </Select>
                                </FormControl>
                                <Button variant="outlined" color="inherit"
                                        onClick={this.deleteNotifEmail}>Supprimer</Button>
                            </div>
                        </div>

                        <TextField
                            id="outlined-multiline"
                            label="Notification Phone Numbers"
                            multiline
                            style={{width: "350px"}}
                            rows="4"
                            value={this.loadPhoneList()}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                        <div style={{display: "flex", flexFlow: "column"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <TextField
                                    className={classes.textField}
                                    onChange={e => this.setState({notifPhoneToAdd: e.target.value})}
                                    value={this.state.notifPhoneToAdd}
                                    label="Numéro à ajouter"
                                    margin="normal"
                                    variant="outlined"
                                />
                                <Button variant="outlined" color="inherit" onClick={this.addNotifPhoneNumber}>Ajouter</Button>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="phone">
                                        Email à supprimer
                                    </InputLabel>
                                    <Select style={{minWidth: "200px"}} value={this.state.phoneToDelete}
                                            onChange={(e) => {
                                                this.setState({phoneToDelete: e.target.value})
                                            }}
                                            input={<OutlinedInput labelWidth={0} name="phone"/>}
                                    >
                                        <MenuItem value="">
                                            <em>Selectionner un numéro de téléphone</em>
                                        </MenuItem>
                                        {this.deletePhoneMenu()}
                                    </Select>
                                </FormControl>
                                <Button variant="outlined" color="inherit" onClick={this.deleteNotifPhone}>Supprimer</Button>
                            </div>
                         </div>
                    </div>

                    {this.state.user.id==null &&
                        <TextField
                            id="passwordField"
                            label="Mot de passe"
                            className={classes.textField}
                            value={this.state.passwordField}
                            onChange={this.handleChangePassword('passwordField')}
                            margin="normal"
                            variant="outlined"
                            type="password"
                            required={this.state.passwordRequired}
                        />
                    }
                    {this.state.user.id==null &&
                        <TextField
                            id="passwordValidationField"
                            label="Vérification mot de passe"
                            className={classes.textField}
                            value={this.state.passwordValidationField}
                            onChange={this.handleChangePassword('passwordValidationField')}
                            margin="normal"
                            variant="outlined"
                            type="password"
                            required={this.state.passwordRequired}
                        />
                    }

                    {window.Hauslife.userObject.role <= admin_role  &&
                      <>
                        <TextField
                            id="role"
                            select
                            label="Role"
                            className={classes.textField}
                            value={this.state.user.role}
                            onChange={this.handleChange('role')}
                            margin="normal"
                            variant="outlined"
                            required>
                            {roles.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>

                        {this.state.user.role === post_prod_tech_role ||  this.state.user.role === prod_tech_role ? (
                            <>
                                <TextField
                                    id="depot_id"
                                    select
                                    label="Depot"
                                    className={classes.wideTextField}
                                    value={this.state.user.depot_id}
                                    onChange={this.handleChange('depot_id')}
                                    margin="normal"
                                    variant="outlined"
                                    required>
                                    {this.state.depots.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.civic_number + ", " + option.street + ", " + option.city}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    id="zone_id"
                                    select
                                    label="Zone Principale"
                                    className={classes.wideTextField}
                                    value={this.state.user.zone_id}
                                    onChange={this.handleChange('zone_id')}
                                    margin="normal"
                                    variant="outlined"
                                    required>
                                    {this.state.zones.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.zone_name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-multiple-zones">Backup Zones</InputLabel>
                                    <Select
                                        multiple
                                        value={this.state.user.backupZones}
                                        onChange={this.handleChange('backupZones')}
                                        input={<Input id="select-multiple-zones" />}>
                                        {this.state.zones.map(item => (
                                        <MenuItem key={item.id} value={item.id} >
                                            {item.zone_name}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-multiple-packages">Forfaits</InputLabel>
                                    <Select
                                        multiple
                                        value={this.state.user.shooting_packages}
                                        onChange={this.handleChange('shooting_packages')}
                                        input={<Input id="select-multiple-packages" />}>
                                        {this.state.shootingPackages.map(item => {
                                            return (
                                                <MenuItem key={item.id} value={item.id} >
                                                    {item.key_name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-multiple-offers">Offres de services</InputLabel>
                                    <Select
                                        multiple
                                        value={this.state.user.service_offers}
                                        onChange={this.handleChange('service_offers')}
                                        input={<Input id="select-multiple-offers" />}>
                                        {this.state.serviceOfferings.map(item => {
                                            return (
                                                <MenuItem key={item.id} value={item.id} >
                                                    {item.key_name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        checked={freelanceCheck}
                                        onChange={this.handleFreelance}
                                        color="primary"
                                    />
                                    }
                                    label="Pigiste"
                                />
                            </>
                        ) : null }

                        {this.state.user.role !== broker_role ? (
                            <>
                                <TextField
                                    id="broker_code"
                                    label="code courtier"
                                    className={classes.textField}
                                    value={this.state.user.broker_code}
                                    onChange={this.handleChange('broker_code')}
                                    margin="normal"
                                    variant="outlined"  />

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-team">Équipe</InputLabel>
                                    <Select
                                        value={this.state.user.team_id}
                                        onChange={this.handleChange('team_id')}
                                        input={<Input id="select-team" />}>
                                        <MenuItem key={null} value={null} >  </MenuItem>
                                        {this.state.teams.map(item => (
                                            <MenuItem key={item.id} value={item.id} >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="select-franchise">Franchise</InputLabel>
                                    <Select
                                        value={this.state.user.franchise_id}
                                        onChange={this.handleChange('franchise_id')}
                                        input={<Input id="select-franchise" />}>
                                        <MenuItem key={null} value={null} />
                                        {this.state.franchises.map(item => (
                                            <MenuItem key={item.id} value={item.id} >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.user.gf_clause === true}
                                            onChange={this.handle_gf_clause_change()}
                                            color="primary"
                                        />
                                    }
                                    label="Clause Grand-Père"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.user.enable_threesixty_video}
                                            onChange={this.handle_360Video_Change()}
                                            color="primary"
                                        />
                                    }
                                    label="Enable 360 video"
                                />
                            </>
                         ) : null }

                            <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={suspendedCheck}
                                    onChange={this.handleSuspended}
                                    color="primary"
                                />
                                }
                            label="Suspendue"
                            />
                          <div>
                              <TextField
                                  id="outlined-multiline"
                                  label="Note"
                                  multiline
                                  rows="10"
                                  style={{width:"450px"}}
                                  value={this.state.user.note_about_broker + ""}
                                  onChange={(e)=>{this.updateBrokerNote(e.target.value)}}
                                  className={classes.textField}
                                  margin="normal"
                                  variant="outlined"
                              />
                          </div>
                        </>
                    }

                    <div style={{ display: "flex", flexDirection:"column" }}>
                        <h2>Facebook</h2>
                        <div style={{ display: "flex", alignItems: "center", flexDirection:"row" }}>
                            
                            <TextField
                                id="facebook_business_name"
                                label="Facebook business page"
                                value= {
                                        this.state.user.broker_facebook_business_page !== null ?
                                        this.state.user.broker_facebook_business_page.page_name 
                                        : ''
                                    }
                                onChange={(e) => {
                                        let user = this.state.user;
                                        user.broker_facebook_business_page.page_name = e.target.value; 
                                        this.setState({ user })
                                    } 
                                }
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />

                            <TextField
                                id="facebook_business_id"
                                label="Facebook business id"
                                value= {
                                    this.state.user.broker_facebook_business_page !== null ?
                                    this.state.user.broker_facebook_business_page.page_id 
                                    : ''
                                }
                                onChange={(e) => {
                                        let user = this.state.user;
                                        user.broker_facebook_business_page.page_id = e.target.value; 
                                        this.setState({ user })
                                    } 
                                }
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />

                            <TextField
                                id="facebook_pixel_id"
                                label="Pixel Id"
                                value={this.state.user.pixel_id !== null ? this.state.user.pixel_id : ''}
                                onChange={this.handleChange('pixel_id')}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                            
                        </div>
                    </div>

                    {this.state.user.id==null &&
                        <Button type="submit" variant="outlined" color="inherit" className={classes.button}>Ajouter</Button>
                    }

                    {this.state.user.id!=null &&
                        <Button type="submit" variant="outlined" color="inherit" style={{marginLeft:'10px'}} className={classes.button}>Sauvegarder</Button>
                    }

                </form>

                {this.state.user.id!=null &&
                    <ChangePassword userId={this.state.user.id}/>
                }

                {this.displayRelatedShootings()}
                {this.displayHMDsInformation()}
            </Paper>
        );
    }

}


export default withStyles(styles)(EditUser);
