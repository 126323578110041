import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter, Link } from "react-router-dom";
import { 
    Grid, Paper, Button, Typography, CircularProgress, TextField,
    List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Divider 
} from '@material-ui/core';

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    list: {
        display: 'flex',
        flex: '1',
        flexDirection: 'column'
    },
    create: {
        display: 'flex',
        flex: 1,
        alignSelf: 'flex-end',
        margin: '6px'
    }
};

const MODES = {
    'list': 0,
    'view': 1,
    'edit': 2,
    'create': 3
}

export default (props) => {

    let { entities, handleSelect, onCreateStart, onDelete, display_name_key } = props

    return (
        <div style={styles.container}>
            <div style={styles.create}>
                <Button variant="contained" color="primary" onClick={onCreateStart}>
                    Create New
                </Button>
            </div>
            <List style={styles.list}>
            {
                entities.map(entity => (
                    <ListItem
                        style={{ cursor: "pointer" }} 
                        button
                        divider
                        variant="outlined"
                        onClick={() => handleSelect(entity, MODES.view)}
                    >
                        <ListItemText 
                            primary={entity[display_name_key]}
                        />
                        <ListItemSecondaryAction>
                        <Button 
                            onClick={() => handleSelect(entity, MODES.edit)} 
                            variant="outlined"
                            style={{marginRight: '6px'}}
                        >
                            Edit
                        </Button>
                        <Button 
                            onClick={() => onDelete(entity)} 
                            variant="outlined"
                        >
                            Delete
                        </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))
            }
            </List>
        </div>
    )
}